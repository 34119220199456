import React from 'react'

function Message() {
  return (
    <div className='MainMesage'>
      <h1>hello</h1>
    </div>
  )
}

export default Message
