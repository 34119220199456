import React, { useState, useContext, useEffect } from 'react';
import { USER_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import AllUsers from './AllUsers';
import Admin from './Admin';
import AuthContext from '../../Utils/auth-context';
import { allAdminUsers, allUsers,faqalldata,aboutalldata ,codeConductalldata ,codeDisclaimeralldata, ReportedUserdata, NewRegistrationUsers, NewRegistrationDeclineUsers} from '../../Utils/services';
import { toast } from 'react-toastify';
import AdminForm from '../ModalTemplate/AdminForm';
import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"
import NewRegistration from './NewRegistration';
import { useTranslation } from 'react-i18next';
import DeclinedUser from './DeclinedUser';
import Faq from './Faq';
import FaqForm from '../ModalTemplate/FaqForm';
import About from './About';
import CodeConduct from './CodeConduct';
import Disclaimer from './Disclaimer';
import AboutForm from '../ModalTemplate/AboutForm';
import ConductForm from '../ModalTemplate/ConductForm';
import DisclaimerForm from '../ModalTemplate/DisclaimerForm';
import ReportedUser from './ReportedUser';


const a11yProps = (index) => {
  // console.log(index, "index, ")
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const Users = ({match}) => {

  const {t} = useTranslation();

  const [tabsValue, setTabsValue] = useState(0);
  const [userData, setUserData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [adminFaqData, setadminFaqData] = useState([]);
  const [adminReporteduserData, setadminReporteduserData] = useState([]);
  const [adminAboutData, setadminAboutData] = useState([]);
  const [adminConductData, setadminConductData] = useState([]);
  const [adminDisclaimerData, setadminDisclaimerData] = useState([]);
  const context = useContext(ModalContext)
  const authCtx = useContext(AuthContext);
  const { handleModalData } = context;
  const [loader, setLoader] = useState(true);
  const [newRegistrationData, setNewRegistrationData] = useState([]);
  const [declinedUserData, setDeclinedUserData] = useState([]);
  const [Count, setCount] = useState();
  const [Counts, setCounts] = useState();
  const [Countss, setCountss] = useState();
  const [Countsss, setCountsss] = useState();

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  //  const params = new URLSearchParams(location.search);
    var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
    window.history.pushState({ path: newurl }, '', newurl);
    
  };
  function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
useEffect(()=>{
    var idValue = getParameterByName('id');
    if(idValue){
        setTabsValue(parseInt(idValue))
    }
},[])


  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getAdminFAQTableData = async () => {
    try {
      const result = await faqalldata();
      if (result.data.faq.length > 0) {
        setLoader(false)
        setadminFaqData(result.data.faq);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getReporteduserTableData = async (sendData) => { 
    try {
      const result = await ReportedUserdata(sendData);
      if (result?.res.data?.user?.length > 0) {  
        setLoader(false)
        setadminReporteduserData(result?.res.data.user);
        setCountsss(result?.res?.data.count);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getABOUTTableData = async () => {
    try {
      const result = await aboutalldata();
      if (result.data.about.length > 0) {
        setLoader(false)
        setadminAboutData(result.data.about);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getConductTableData = async () => {
    try {
      const result = await codeConductalldata();
      if (result.data.conduct.length > 0) {
        setLoader(false)
        setadminConductData(result.data.conduct);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getDisclaimerTableData = async () => {
    try {
      const result = await codeDisclaimeralldata();
      if (result.data.disclaimer.length > 0) {
        setLoader(false)
        setadminDisclaimerData(result.data.disclaimer);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const getTablesData = async (sendData) => { 
    try {
      const result = await allUsers(sendData);
      // console.log(result, "This is results user All Data");
      setLoader(false)
      setUserData(result.res?.data?.user);
      setCount(result.res?.data?.userCount)
      if (result.res?.data?.user?.length > 0) { 
       
        // const _resData = result.res?.data?.user;

      //  let _UserData=  _resData.filter(data =>{
      //   return data?.isApproved === true
      //  })
      //  let _NewRegData=  _resData.filter(data =>{
        
      //   return data?.isApproved === false && data?.isDeclined === false
      //  })
      //  let _DeclinedData=  _resData.filter(data =>{
      //   return data?.isDeclined
      //  })
       
      //  setDeclinedUserData(_DeclinedData)
       
      //  setNewRegistrationData(_NewRegData)
      }
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }
  const getnewRegistraionTablesData = async (sendData) => { 
    try {
      const result = await NewRegistrationUsers(sendData);
      // console.log(result, "This is results user All Data");
      setLoader(false)
       setNewRegistrationData(result.res?.data?.user)
      setCounts(result.res?.data?.userCount)
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }
  const getnewDeclineTablesData = async (sendData) => { 
    try {
      const result = await NewRegistrationDeclineUsers(sendData);
      // console.log(result, "This is results user All Data");
      setLoader(false)
      setDeclinedUserData(result.res?.data?.user)
       setCountss(result.res?.data?.userCount)
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }
  

  useEffect(() => {
    getAdminTableData();
    // getTablesData();
    // getAdminFAQTableData();
  }, [])
  
  const tabsData = [
    {
      label: `${t("Users")}`,
      isNotshowAdd : true,
      data: (
        <>
          <Loader loader={loader} />
          <AllUsers Count={Count} adminData={adminData} getAdminTableData={getAdminTableData}  data={userData} getnewRegistraionTablesData={getTablesData} />
        </>
      )
    },
    {
      label: `${t("Admin")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <Admin match ={match} data={adminData} getAdminTableData={getAdminTableData} />
        </>
      )
    },
    {
      label: `${t("Faq")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <Faq match ={match} adminData={adminData} getAdminTableData={getAdminTableData} data={adminFaqData} getAdminFAQTableData={getAdminFAQTableData} />
        </>
      )
    },
    {
      label: `${t("About")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <About adminData={adminData} getAdminTableData={getAdminTableData} match ={match} data={adminAboutData} getABOUTTableData={getABOUTTableData} />
        </>
      )
    },
    {
      label: `${t("Code_of_Conduct")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <CodeConduct adminData={adminData} getAdminTableData={getAdminTableData} match ={match} data={adminConductData} getConductTableData={getConductTableData} />
        </>
      )
    },
    {
      label: `${t("Disclaimer")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <Disclaimer adminData={adminData} getAdminTableData={getAdminTableData} match ={match} data={adminDisclaimerData} getDisclaimerTableData={getDisclaimerTableData} />
        </>
      )
    },
  
    {
      label: `${t("New_Registration")}`,
      isNotshowAdd : true,
      // isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <NewRegistration adminData={adminData} Counts={Counts} data={newRegistrationData} getnewRegistraionTablesData={getnewRegistraionTablesData} />
        </>
      )
    },
    {
      label: `${t("Declined_User")}`,
      isNotshowAdd : true,
      data: (
        <>
          <Loader loader={loader} />
          <DeclinedUser adminData={adminData} Count={Countss} data={declinedUserData} getnewRegistraionTablesData={getnewDeclineTablesData} />
        </>
      )
    },
    {
      label: `${t("Reported_User")}`,
      isNotshowAdd : true,
      data: (
        <>
          <Loader loader={loader} />
          <ReportedUser data={adminReporteduserData} Countsss={Countsss} getTablesData={getReporteduserTableData} />
        </>
      )
    },
   
  
  ]

  const handleModalForm = () => { 
    switch (tabsValue) {
      case 0:
        handleModalData(<UserForm getTablesData={getTablesData} title="add new user" buttonText="add user" />);
        break;
      case 1:
        handleModalData(<AdminForm refreshDataMethod={getAdminTableData} />);
        break;
      case 2:
        handleModalData(<FaqForm refreshDataMethod={getAdminFAQTableData} />);
        break;
      case 3:
        handleModalData(<AboutForm refreshDataMethod={getABOUTTableData} />);
        break;
      case 4:
        handleModalData(<ConductForm refreshDataMethod={getConductTableData} />);
        break;
      case 5:
        handleModalData(<DisclaimerForm refreshDataMethod={getDisclaimerTableData} />);
        break;
      default:
        handleModalData(USER_FORM);
        break;
    }
  }
  // console.log(tabsData, "tabsData")

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            className="px-10 homemainresponsive"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
}


export default Users;

