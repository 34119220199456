import Table from "../../Common/Table";
import { useMemo } from "react";
import { Button } from "@material-ui/core";
import { useEffect, useState, useContext } from "react";
import { getCountriesData } from "../getRequests";
import AddCountry from "../../ModalTemplate/AddCountry";
import { ModalContext } from "../../../Context";
import { BASE_URL_IMG, getCountries } from "../../../Utils/services";
import dummyImg from "../../../Assets/images/DummyDp.png";
import { useTranslation } from 'react-i18next';
const Country = ({ setCountryData, countryData,adminData }) => {
  const context = useContext(ModalContext);
  const { handleModalData } = context;
  const { t } = useTranslation();
  const getCountryApiData = async () => {
    try {
      const result = await getCountriesData();
      // console.log(result, "Result627");
      setCountryData(result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountryApiData();
  }, []);

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const editHandler = (data) => {
    const EDIT_COUNTRY = (
      <AddCountry
        setCountryData={setCountryData}
        title={t('Edit_Country')}
        id={data.cell.row.original._id}
        name={data.cell.row.original.name}
        region={data.cell.row.original.region}
        image={data.cell.row.original.flag}
        btnText={t('SAVE')}
        edit
      />
    );
    handleModalData(EDIT_COUNTRY, "md");
  };

  const countryColumns = useMemo(
    () => [
      {
        Header: `${t('Image')}`,
        accessor: "flag",
        Cell: ({ cell: { value } }) => (
          <img src={`${BASE_URL_IMG}${value}`} className="w-10" alt="" />
        ),
      },

      {
        Header: `${t('Country')}`,
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <span className="capitalize">{value}</span>
        ),
      },
      {
        Header: `${t('Region')}`,
        accessor: "region",
        Cell: ({ cell: { value } }) => (
          <span className="capitalize">{value?.name}</span>
        ),
      },

      {
        Header: `${t('Added_On')}`,
        accessor: "createdOn",
        Cell: ({ cell: { value } }) => new Date(value).toDateString(),
      },
      {
        id: "view",
        Header: `${t('Action')}`,
        accessor: "_id",
        Cell: adminData[0]?.AdminType === "Coordination team" ? "" : (props) => (
          <Button className="btn2" onClick={() => editHandler(props)}>
            {" "}
            {t('Edit')}{" "}
          </Button>
        ),
      },
    ],
    []
  );

  return (
    <>
      {countryData && (
        <Table
          columns={countryColumns}
          data={countryData.sort(dynamicSort("name"))}
        />
      )}
    </>
  );
};

export default Country;
