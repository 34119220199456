import axios from "axios";
import {
  LOGIN,
  ADD_ADMIN,
  REGION_COUNRTY_LIST,
  BADGES,
  PARTIES,
  ALL_USER,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_PARTIES,
  RESET_PASSWORD,
  GET_HUBS,
  GET_EXPERTISE,
  ADD_EXPERTISE,
  ADD_COUNTRY,
  EDIT_EXPERTISE,
  DELETE_EXPERTISE,
  ADD_REGION,
  DELETE_REGION,
  EDIT_REGION,
  DELETE_COUNTRY,
  EDIT_COUNTRY,
  ADD_HUB,
  GET_SINGLE_HUB,
  DELETE_HUB,
  ADD_MINI_HUB,
  GET_SINGLE_MINI_HUB,
  DELETE_MINI_HUB,
  ADD_SECTION,
  GET_SINGLE_SECTION,
  ADD_AFFILIATION,
  DELETE_AFFLIATION,
  ADD_USER,
  GET_SINGLE_PARTY,
  EDIT_AFFILIATION,
  GET_FEED,
  ADD_POST,
  ADD_FEED_POST,
  GET_MEMBERS,
  GET_MEMBERS_BY_ID,
  ADD_FEED_POLL,
  GET_ADMIN_DATA,
  HOME_DATA,
  GET_MANAGE_FLAG,
  KEEP_POST,
  DELETE_MANAGE_FLAG,
  GET_REMOVED_FLAG,
  FLAG_REINSTATE,
  EVENT_DATA,
  ADD_EVENT,
  GET_MINIHUBS,
  EDIT_ADMIN,
  CHANGEPASSWORD_API,
  OTPVERIFICATION_API,
  FORGOTPASSWORD_API,
  ADMIN_DETAILS,
  EDIT_HUB,
  GET_REQUESTDATA,
  DIRECTORY_ACCESS,
  APPROVE_PEOPLE,
  DECLINE_PEOPLE,
  APPROVE_Calendar,
  EDIT_MINIHUB,
  EDIT_SECTION,
  EDIT_USER,
  FEEDS_SINGLEPOST,
  GET_GENERAL_NOTIFICATION,
  ADD_GENERAL_NOTIFICATION,
  GET_ORGANIZATION,
  GET_THINKTANK,
  ADD_RESOURCES,
  INVITE_NOTIFICATION,
  INVITE_ALL_NOTIFICATION,
  EDIT_EVENT,
  REMOVE_EVENT,
  EDIT_RESOURCES,
  DELETE_RESOURCES,
  FEED_GET_POLL,
  FEED_GET_POST,
  NON_HUB_MEMBERS,
  ADD_NON_HUB_MEMBERS,
  MAINTENANCE_MODE,
  DEACTIVATE_USER,
  REMOVE_MEMBERS_HUB,
  PARTY_DISABLED,
  OTPVERIFICATION_API_LOGIN,
  LOGIN_FIRST,
  GET_LANGUAGE,
  GET_SECTOR,
  ADD_SECTOR,
  GET_TAGS,
  EDIT_SECTOOR,
  ADD_TAG,
  EDIT_TAG,
  GET_FORUMS,
  ADD_FORUMS,
  EDIT_FORUM,
  GET_GALLERY,
  USER_APPROVED,
  SINGLE_FORUM,
  MANAGE_POST,
  EDIT_GALLERY,
  GET_SEGMENT,
  ADD_SEGMENT,
  EDIT_SEGMENT,
  USER_DECLINE,
  REMOVED_POST,
  DELETE_GALLERY_FLAG,
  GALLERY_KEEP_POST,
  FLAG_GALLERY_REINSTATE,
  RESOURCES_CATEGORY,
  ADD_RESOURCES_DATA,
  GET_RESOURCES,
  DELETE_GALLERY_POST,
  DELETE_RESOURCES_API,
  EDIT_RESOURCES_API,
  EDIT_TOOLKIT_API,
  STATUS_RESOURCES_API,
  DELETE_FORUMS,
  STATUS_FORUM_API,
  ADD_COMMENT_FORUM,
  DELETE_COMMENT_FORUM,
  GET_TOOLKITS,
  ADDTOOL_RESOURCES_DATA,
  DELETE_TOOLKIT_API,
  STATUS_TOOLKIT_API,
  GET_STORY,
  EDIT_STORY,
  DELETE_STORY_POST,
  ADD_STORY_POST,
  STORY_KEEP_POST,
  MANAGESTORY_POST,
  DELETE_STORY_FLAG,
  REMOVEDSTORY_POST,
  SINGLESTORY_FORUM,
  STATUS_STORY_API,
  FLAG_STORY_REINSTATE,
  STATUS_GALLERY_API,
  GET_NEWSFEED,
  ADD_NEWSFEED_POST,
  NEWSFEEDEDIT_STORY,
  DELETE_NEWSFEED_POST,
  DELETE_NOTIFICATION_POST,
  DELETE_REPLYCOMMENT_FORUM,
  ADD_GALLERYCOMMENT_FORUM,
  DELETE_COMMENT_GALERY,
  ADD_COMMENT_STORY,
  DELETE_COMMENT_STORY,
  SEND_MESSAGE,
  GET_MESSAGE,
  DELETE_SECTOR,
  DELETE_TAG,
  ALL_DIRECTORIES,
  GET_CATEGORYLIST,
  ADD_DIRECTORY,
  ADD_CATEGORY,
  GET_SUGGEST_DATA,
  GET_ADMINFAQ_DATA,
  ADD_FAQ,
  DELETE_FAQ_POST,
  GET_ADMINABOUT_DATA,
  DELETE_ABOUT_POST,
  GET_ADMINCONDUCT_DATA,
  GET_ADMINDISCLAIMER_DATA,
  DELETE_CODE_POST,
  DELETE_DISCLAIMER_POST,
  ADD_ABOUT,
  ADD_CONDUCT,
  ADD_DISCLAIMER,
  DELETE_DIRECTORY_POST,
  EDIT_DIRECTORY,
  EDIT_FAQ,
  EDIT_ABOUT,
  EDIT_CONDUCT,
  EDIT_DISCLAIMER,
  GET_SEGMENTS,
  DELETE_CATEGORY_POST,
  EDIT_CATEGORY,
  GET_GALLERYCOMMENT_DATA,
  GET_STORIESCOMMENT_DATA,
  GET_FOURAMCOMMENT_DATA,
  CHAT_USERDATA,
  SUGGESTEAD_DELETE,
  GET_EDUCATION,
  ADD_COURSE_POST,
  GET_EDUCATIONSESSION_DATA,
  ADD_SESSION_POST,
  ADD_SUBSESSION_POST,
  GET_EDUCATIONSUBSESSION_DATA,
  GET_EDUCATIONQUESTIONDATA_DATA,
  ADD_QUESTION_POST,
  DELETE_COURSE_POST,
  DELETE_COURSESESSION_POST,
  DELETE_COURSESUBSESSION_POST,
  DELETE_QUESTION_POST,
  GET_REPORTEDUSER_DATA,
  ALL_USERDATA,
  ADD_READ_NOTIFICATION,
  EDIT_COURSE_POST,
  Edit_SESSION_POST,
  Edit_SUBSESSION_POST,
  EDIT_QUESTION_POST,
  NEWREGISTRATION_USER,
  NEWDECLINEREGISTRATION_USER,
  DEACTIVATE_ADMIN,
  LOGOUT,
  GET_FOURAMMEDIA_DATA,
  GET_STORYMEDIA_DATA,
  GET_GALLERYPOLL,
  ADD_GALERY_POLL,
  GET_SECTORS,
  DEACTIVATE_COURSE,
  PREVIEW_PDF,
  GETPOLLDATA,
  DELETEMESSAGE,
  GETFOURAMDETAIL,
  GETGALLERYDETAIL,
  GETRESOURCESDETAIL,
  GETTOOLKITDETAIL,
  GETSTORYDETAILS,
  GETNEWSFEEDDETAILS,
  GETUSERSDETAILS,
  GETAGORATOKEN,
  GETAGORALINK,
  GET_ROOM_DETAIL,
  GET_100MSTOKEN_DETAIL,
  GET_100MSTOKEN_RECORDINGDETAIL,
  GET_100MSTOKEN_RECORDINGDETAILVIEW,
  GETCOURSEDETAILS,
} from "./routes";
import moment from "moment";

// export const TRANSLATE_URL = `https://glp.techtonic.asia/admin/tarnslate?`; // develop
export const TRANSLATE_URL = `https://api.glp-connect.org/admin/tarnslate?`; // Production

// export const BASE_URL = "http://13.251.63.163/admin";   // For develop
// export const BASE_URL = "https://glp.techtonic.asia/admin"; // For develop
export const BASE_URL = "https://api.glp-connect.org/admin";  // For Production

// export const BASE_URL_IMG = "https://d1v4waqh4od8qe.cloudfront.net"; // develop
export const BASE_URL_IMG = "https://assests.glp-connect.org" // production

// export const host = "https://glp.techtonic.asia"

axios.defaults.baseURL = BASE_URL;
export const loginService = async (data) => {
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const loginServiceFirst = async (data) => {
  try {
    const response = await axios.post(LOGIN_FIRST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const adminlogout = async (data) => {
  try {
    const response = await axios.post(LOGOUT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const changePasswordApi = async (data) => {
  try {
    const response = await axios.post(CHANGEPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const Sendmessage = async (data) => {
//   try {
//     const response = await axios.post(SEND_MESSAGE, data, {
//       headers: { "Content-Type": "multipart/form-data",},
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const Sendmessage = async (data) => {
  try {
    const response = await axios.post(SEND_MESSAGE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const otpVerificationLoginApi = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION_API_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const forgotPasswordApi = async (data) => {
  try {
    const response = await axios.post(FORGOTPASSWORD_API, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addAdmin = async (data) => {
  try {
    const response = await axios.post(ADD_ADMIN, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addFaq = async (data) => {
  try {
    const response = await axios.post(ADD_FAQ, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addConduct = async (data) => {
  try {
    const response = await axios.post(ADD_CONDUCT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addDisclaimer = async (data) => {
  try {
    const response = await axios.post(ADD_DISCLAIMER, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addAbout = async (data) => {
  try {
    const response = await axios.post(ADD_ABOUT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addCategory = async (data) => {
  try {
    const response = await axios.post(ADD_CATEGORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditAdmin = async (data) => {
  try {
    const response = await axios.post(EDIT_ADMIN, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addUser = async (data) => {
  try {
    const response = await axios.post(ADD_USER, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addDirectory = async (data) => {
  try {
    const response = await axios.post(ADD_DIRECTORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editDirectory = async (data) => {
  try {
    const response = await axios.post(EDIT_DIRECTORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editCategory = async (data) => {
  try {
    const response = await axios.post(EDIT_CATEGORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addTagsApi = async (data) => {
  try {
    const response = await axios.post(ADD_TAG, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editTagsApi = async (data) => {
  try {
    const response = await axios.post(EDIT_TAG, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editSectorApi = async (data) => {
  try {
    const response = await axios.post(EDIT_SECTOOR, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editUser = async (data) => {
  try {
    const response = await axios.post(EDIT_USER, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editFaq = async (data) => {
  try {
    const response = await axios.post(EDIT_FAQ, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editAbout = async (data) => {
  try {
    const response = await axios.post(EDIT_ABOUT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editConduct = async (data) => {
  try {
    const response = await axios.post(EDIT_CONDUCT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editDisclaimer = async (data) => {
  try {
    const response = await axios.post(EDIT_DISCLAIMER, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getEventData = async (data) => {
  try {
    const response = await axios.post(EVENT_DATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getMinihubs = async (_id) => {
  try {
    const response = await axios.get(`${GET_MINIHUBS}/${_id}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getpolldatabyid = async (_id) => {
  try {
    const response = await axios.get(`${GETPOLLDATA}/${_id}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteMessage = async (_id) => {
  try {
    const response = await axios.delete(`${DELETEMESSAGE}/${_id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPreviewpdf = async (url) => {
  try {
    const response = await axios.get(`${PREVIEW_PDF}?link=${url}`);
    console.log(response, "sandy pdf");
    if (response.status === 200) {
      return response.data.data;
    } else {
      throw new Error(`Failed to fetch PDF: ${response.statusText}`);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        throw new Error(
          `API Error: ${error.response.status} - ${error.response.data.message}`
        );
      } else {
        throw new Error(`Network Error: ${error.message}`);
      }
    } else {
      throw error;
    }
  }
};

export const InviteNotification = async ({ people, id }) => {
  try {
    const response = await axios.post(`${INVITE_NOTIFICATION}/${people}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const InviteAllPeopleNotification = async ({ people }) => {
  try {
    const response = await axios.post(`${INVITE_ALL_NOTIFICATION}/${people}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addEvent = async (data) => {
  try {
    const response = await axios.post(ADD_EVENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editEvent = async (data) => {
  try {
    const response = await axios.post(EDIT_EVENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const DeleteEvent = async (id) => {
  try {
    const response = await axios.delete(`${REMOVE_EVENT}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getHomeApiData = async () => {
  try {
    const response = await axios.get(HOME_DATA);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const regionCountryList = async () => {
  try {
    const response = await axios.get(REGION_COUNRTY_LIST);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getLanguageList = async () => {
  try {
    const response = await axios.get(GET_LANGUAGE);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getagoratoken = async () => {
  try {
    const response = await axios.get(GETAGORATOKEN);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCategoryList = async () => {
  try {
    const response = await axios.get(GET_CATEGORYLIST);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSectorList = async () => {
  try {
    const response = await axios.get(GET_SECTOR);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSectorLists = async () => {
  try {
    const response = await axios.get(GET_SECTORS);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getRequestDataApi = async () => {
  try {
    const response = await axios.get(GET_REQUESTDATA);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const badgeList = async () => {
  try {
    const response = await axios.get(BADGES);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const partyList = async () => {
  try {
    const response = await axios.get(PARTIES);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// export const allUsers = async ({ size, page }) => {
//   try {
//     const response = await axios.get(`${ALL_USER}/${page}/${size}`);
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
//   // try {
//   //   const response = await axios.get(ALL_USER);
//   //   if (response.status === 200) {
//   //     return { res: response.data.data };
//   //   } else return response.data;
//   // } catch (err) {
//   //   if (err.response) throw err.response.data;
//   //   else throw err.message;
//   // }
// };
export const allUsers = async (data) => {
  try {
    const response = await axios.post(ALL_USER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const NewRegistrationUsers = async (data) => {
  try {
    const response = await axios.post(NEWREGISTRATION_USER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const NewRegistrationDeclineUsers = async (data) => {
  try {
    const response = await axios.post(NEWDECLINEREGISTRATION_USER, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const allUsersdata = async () => {
  try {
    const response = await axios.get(ALL_USERDATA);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const allDirectories = async () => {
//   try {
//     const response = await axios.get(ALL_DIRECTORIES);
//     if (response.status === 200) {
//       return { res: response.data.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const allDirectories = async (data) => {
  try {
    const response = await axios.post(ALL_DIRECTORIES, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const allAdminUsers = async () => {
  try {
    const response = await axios.get(GET_ADMIN_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const faqalldata = async () => {
  try {
    const response = await axios.get(GET_ADMINFAQ_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const ReportedUserdata = async () => {
//   try {
//     const response = await axios.get(GET_REPORTEDUSER_DATA);
//     return response.data;

//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const ReportedUserdata = async (data) => {
  try {
    const response = await axios.post(GET_REPORTEDUSER_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const aboutalldata = async () => {
  try {
    const response = await axios.get(GET_ADMINABOUT_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const codeConductalldata = async () => {
  try {
    const response = await axios.get(GET_ADMINCONDUCT_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const codeDisclaimeralldata = async () => {
  try {
    const response = await axios.get(GET_ADMINDISCLAIMER_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getAdminDetails = async (data) => {
  try {
    const response = await axios.post(ADMIN_DETAILS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getGalleryCommentData = async (data) => {
  try {
    const response = await axios.post(GET_GALLERYCOMMENT_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStoriesCommentData = async (data) => {
  try {
    const response = await axios.post(GET_STORIESCOMMENT_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getFouramCommentData = async (data) => {
  try {
    const response = await axios.post(GET_FOURAMCOMMENT_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getFouramMediaData = async (data) => {
  try {
    const response = await axios.post(GET_FOURAMMEDIA_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStoryMediaData = async (data) => {
  try {
    const response = await axios.post(GET_STORYMEDIA_DATA, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const approvePeopleApi = async (data) => {
  try {
    const response = await axios.post(`${APPROVE_PEOPLE}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const approveCalendarApi = async (data) => {
  try {
    const response = await axios.post(`${APPROVE_Calendar}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const declinePeopleApi = async (data) => {
  try {
    const response = await axios.post(`${DECLINE_PEOPLE}/${data}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getCountries = async () => {
  try {
    const response = await axios.get(GET_COUNTRIES);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const getSegments = async (data) => {
//   try {
//     const response = await axios.get(GET_SEGMENTS,data);
//     if (response.status === 200) {
//       return { res: response.data.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const getSegments = async (data) => {
  try {
    const response = await axios.post(GET_SEGMENTS, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getRegions = async () => {
  try {
    const response = await axios.get(GET_REGIONS);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getParties = async () => {
  try {
    const response = await axios.get(GET_PARTIES);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getHubs = async () => {
  try {
    const response = await axios.get(GET_HUBS);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (error) {
    if (error.response) throw error.response.data;
    else throw error.message;
  }
};

export const resetPassword = async (data) => {
  try {
    const response = await axios.post(RESET_PASSWORD, data, {});
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getExpertise = async () => {
  try {
    const response = await axios.get(GET_EXPERTISE);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addExpertise = async (data) => {
  try {
    const response = await axios.post(ADD_EXPERTISE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteExpertise = async (data) => {
  try {
    const response = await axios.delete(DELETE_EXPERTISE, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteSector = async (data) => {
  try {
    const response = await axios.delete(DELETE_SECTOR, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteTag = async (data) => {
  try {
    const response = await axios.delete(DELETE_TAG, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editExpertise = async (data) => {
  try {
    const response = await axios.post(EDIT_EXPERTISE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addCountry = async (data) => {
  try {
    const response = await axios.post(ADD_COUNTRY, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addRegion = async (data) => {
  try {
    const response = await axios.post(ADD_REGION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteRegion = async (data) => {
  try {
    const response = await axios.delete(DELETE_REGION, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editRegion = async (data) => {
  try {
    const response = await axios.post(EDIT_REGION, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteCountry = async (data) => {
  try {
    const response = await axios.delete(DELETE_COUNTRY, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editCountry = async (data) => {
  try {
    const response = await axios.post(EDIT_COUNTRY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const chatuserdata = async (data) => {
  try {
    const response = await axios.post(CHAT_USERDATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getFeed = async ({ size, page }) => {
  try {
    const response = await axios.get(`${GET_FEED}/${page}/${size}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getPoll = async ({ size, page }) => {
  try {
    const response = await axios.get(
      `${FEED_GET_POLL}?page=${page}&size=${size}`
    );
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getPost = async ({ size, page }) => {
  try {
    const response = await axios.get(`${FEED_GET_POST}/${page}/${size}`);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addFeedPost = async (data) => {
  try {
    const response = await axios.post(ADD_FEED_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addCoursePost = async (data) => {
  try {
    const response = await axios.post(ADD_COURSE_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const EditCoursePost = async (data) => {
//   try {
//     const response = await axios.post(EDIT_COURSE_POST, data, {
//       headers: {
//         "Content-Type": "application/json"
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const EditCoursePost = async (data) => {
  try {
    const response = await axios.post(EDIT_COURSE_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const allUsers = async (data) => {
//   try {
//     const response = await axios.post(ALL_USER, data, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const addQuestionPost = async (data) => {
  try {
    const response = await axios.post(ADD_QUESTION_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editQuestionPost = async (data) => {
  try {
    const response = await axios.post(EDIT_QUESTION_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addSessionPost = async (data) => {
  try {
    const response = await axios.post(ADD_SESSION_POST, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditSessionPost = async (data) => {
  try {
    const response = await axios.post(Edit_SESSION_POST, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetyAgoralink = async (data) => {
  try {
    const response = await axios.post(GETAGORALINK, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const EditSubSessionPost = async (data) => {
  try {
    const response = await axios.post(Edit_SUBSESSION_POST, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addSubSessionPost = async (data, setUploadProgress) => {
  try {
    const response = await axios.post(ADD_SUBSESSION_POST, data, {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        //  console.log(progress,"progress");
        setUploadProgress(progress);
        // headers: {
        //   "Content-Type": "application/json",
        // },
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addStoryPost = async (data) => {
  try {
    const response = await axios.post(ADD_STORY_POST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addNewsFeedPost = async (data) => {
  try {
    const response = await axios.post(ADD_NEWSFEED_POST, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addForumPost = async (data) => {
  try {
    const response = await axios.post(ADD_FORUMS, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editForumPost = async (data) => {
  try {
    const response = await axios.post(EDIT_FORUM, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editGalleryPost = async (data) => {
  try {
    const response = await axios.post(EDIT_GALLERY, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editStoryPost = async (data) => {
  try {
    const response = await axios.post(EDIT_STORY, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editNewsFeedPost = async (data) => {
  try {
    const response = await axios.post(NEWSFEEDEDIT_STORY, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addFeedPOll = async (data) => {
  try {
    const response = await axios.post(ADD_FEED_POLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addGalleryPOll = async (data) => {
  try {
    const response = await axios.post(ADD_GALERY_POLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addHub = async (data) => {
  try {
    const response = await axios.post(ADD_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteHub = async (id) => {
  const data = { id: id };
  try {
    const response = await axios.delete(DELETE_HUB, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addAffiliation = async (data) => {
  try {
    const response = await axios.post(ADD_AFFILIATION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addResources = async (data) => {
  try {
    const response = await axios.post(ADD_RESOURCES_DATA, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMembers = async () => {
  try {
    const response = await axios.get(GET_MEMBERS);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// export const getResources = async (data) => {
//   try {
//     const response = await axios.post(GET_RESOURCES);
//     return response.data;
//   }
//   catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// }
export const getResources = async (data) => {
  try {
    const response = await axios.post(GET_RESOURCES, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getResourcesCategory = async () => {
  try {
    const response = await axios.get(RESOURCES_CATEGORY);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getMembersById = async (id) => {
  try {
    const response = await axios.get(`${GET_MEMBERS_BY_ID}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addMiniHub = async (data) => {
  try {
    const response = await axios.post(ADD_MINI_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editHubApi = async (data) => {
  try {
    const response = await axios.post(EDIT_HUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editMiniHubApi = async (data) => {
  try {
    const response = await axios.post(EDIT_MINIHUB, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editSectionApi = async (data) => {
  try {
    const response = await axios.post(EDIT_SECTION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const removeMembersHubApi = async (data) => {
  try {
    const response = await axios.post(REMOVE_MEMBERS_HUB, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userDeactivate = async (id) => {
  try {
    const response = await axios.post(`${DEACTIVATE_USER}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const courseDeactivate = async (id) => {
//   try {
//     const response = await axios.post(`${DEACTIVATE_COURSE}/${id}`);
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const courseDeactivate = async (id) => {
  try {
    const response = await axios.get(`${DEACTIVATE_COURSE}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const adminDeactivate = async (id) => {
  try {
    const response = await axios.get(`${DEACTIVATE_ADMIN}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const partyDisabledApi = async (id) => {
  try {
    const response = await axios.post(`${PARTY_DISABLED}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteMiniHub = async (id) => {
  const data = { id: id };
  try {
    const response = await axios.delete(DELETE_MINI_HUB, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleMiniHub = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_MINI_HUB}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addSection = async (data) => {
  try {
    const response = await axios.post(ADD_SECTION, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addSectorApi = async (data) => {
  try {
    const response = await axios.post(ADD_SECTOR, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleSection = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_SECTION}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteAffiliation = async (data) => {
  try {
    const response = await axios.delete(DELETE_AFFLIATION, { data: data });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getSingleAffiliation = async (data) => {
  try {
    const response = await axios.get(GET_SINGLE_PARTY(data));
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchSingleHub = async (id) => {
  try {
    const response = await axios.get(`${GET_SINGLE_HUB}/${id}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const editAffiliation = async (data) => {
  try {
    const response = await axios.post(EDIT_AFFILIATION, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchManageFlag = async () => {
  try {
    const response = await axios.post(GET_MANAGE_FLAG);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const fetchManagePostFlag = async () => {
  try {
    const response = await axios.get(MANAGE_POST);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const fetchManageStoryPostFlag = async () => {
  try {
    const response = await axios.get(MANAGESTORY_POST);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const keepPost = async (data) => {
  try {
    const response = await axios.post(KEEP_POST, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const galleryKeepPost = async (data) => {
  try {
    const response = await axios.post(GALLERY_KEEP_POST, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const storyKeepPost = async (data) => {
  try {
    const response = await axios.post(STORY_KEEP_POST, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteManageFlag = async (data) => {
  try {
    const response = await axios.post(DELETE_MANAGE_FLAG, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteGalleryFlag = async (data) => {
  try {
    const response = await axios.post(DELETE_GALLERY_FLAG, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteStoryFlag = async (data) => {
  try {
    const response = await axios.post(DELETE_STORY_FLAG, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchRemovedFlag = async () => {
  try {
    const response = await axios.get(GET_REMOVED_FLAG);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetSuggestdata = async () => {
  try {
    const response = await axios.get(GET_SUGGEST_DATA);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const fetchRemovedFlagPost = async () => {
  try {
    const response = await axios.get(REMOVED_POST);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const fetchStoryRemovedFlagPost = async () => {
  try {
    const response = await axios.get(REMOVEDSTORY_POST);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const flagReinstate = async (data) => {
  try {
    const response = await axios.post(FLAG_REINSTATE, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const flagGalleryReinstate = async (data) => {
  try {
    const response = await axios.post(FLAG_GALLERY_REINSTATE, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const flagStoryReinstate = async (data) => {
  try {
    const response = await axios.post(FLAG_STORY_REINSTATE, data);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addDirectoryAccess = async (data) => {
  try {
    const response = await axios.post(DIRECTORY_ACCESS, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const getGeneralNotification = async ({ size, page }) => {
//   try {
//     const response = await axios.get(`${GET_GENERAL_NOTIFICATION}/${page}/${size}`);
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const getGeneralNotification = async (data) => {
  try {
    const response = await axios.post(GET_GENERAL_NOTIFICATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getOraganizationApi = async () => {
  try {
    const response = await axios.get(GET_ORGANIZATION);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getThinktankApi = async () => {
  try {
    const response = await axios.get(GET_THINKTANK);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const singlePostApi = async (id) => {
  try {
    const response = await axios.get(`${FEEDS_SINGLEPOST}/${id}`);
    if (response.status === 200) {
      return {
        res: response.data,
      };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const getNonHubMembersApi = async (id) => {
  try {
    const response = await axios.get(`${NON_HUB_MEMBERS}/${id}`);
    if (response.status === 200) {
      return {
        res: response.data,
      };
    } else {
      return response.data;
    }
  } catch (err) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err.message;
    }
  }
};
export const addNonHubMembers = async (data) => {
  try {
    const response = await axios.post(ADD_NON_HUB_MEMBERS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addGeneralNotification = async (data) => {
  try {
    const response = await axios.post(ADD_GENERAL_NOTIFICATION, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addResourcesApi = async (data) => {
  try {
    const response = await axios.post(ADD_RESOURCES, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editResources = async (data) => {
  try {
    const response = await axios.post(EDIT_RESOURCES_API, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editToolkit = async (data) => {
  try {
    const response = await axios.post(EDIT_TOOLKIT_API, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ResourceStatusApi = async (data) => {
  try {
    const response = await axios.post(`${STATUS_RESOURCES_API}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const ToolkitStatusApi = async (data) => {
  try {
    const response = await axios.post(`${STATUS_TOOLKIT_API}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const StoryStatusApi = async (data) => {
  try {
    const response = await axios.post(`${STATUS_STORY_API}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GalleryStatusApi = async (data) => {
  try {
    const response = await axios.post(`${STATUS_GALLERY_API}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const forumsStatusApi = async (data) => {
  try {
    const response = await axios.post(`${STATUS_FORUM_API}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteForumApi = async (data) => {
  try {
    const response = await axios.delete(`${DELETE_FORUMS}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editResourcesApi = async (data) => {
  try {
    const response = await axios.post(EDIT_RESOURCES, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const maintenanceModeApi = async () => {
  try {
    const response = await axios.post(MAINTENANCE_MODE);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteResourcesApi = async (id) => {
  try {
    const response = await axios.delete(`${DELETE_RESOURCES}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteResourcesPerticularData = async (id) => {
  try {
    const response = await axios.post(`${DELETE_RESOURCES_API}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteToolkitPerticularData = async (id) => {
  try {
    const response = await axios.post(`${DELETE_TOOLKIT_API}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteGalleryPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_GALLERY_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCoursePostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_COURSE_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCourseSessionPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_COURSESESSION_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCourseSubSessionPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_COURSESUBSESSION_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteQuestionPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_QUESTION_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteSuggesteadPostApi = async (id) => {
  try {
    const response = await axios.post(SUGGESTEAD_DELETE, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteStoryPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_STORY_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const deleteNotificationPostApi = async (id) => {
  try {
    const response = await axios.delete(`${DELETE_NOTIFICATION_POST}/${id}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteNewsFeedPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_NEWSFEED_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteFaqPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_FAQ_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteDirectoryPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_DIRECTORY_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCategoryPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_CATEGORY_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteAboutPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_ABOUT_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCodePostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_CODE_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteDisclaimerPostApi = async (id) => {
  try {
    const response = await axios.post(DELETE_DISCLAIMER_POST, id);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

// export const deleteNewsFeedPostApi = async (data) => {
//
//   try {
//     const response = await axios.delete(`${DELETE_NEWSFEED_POST}/${data}`,
//     {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const getTags = async (id) => {
  try {
    const response = await axios.get(GET_TAGS);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getForumsApi = async (data) => {
  try {
    const response = await axios.post(GET_FORUMS, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userApproved = async (data) => {
  try {
    const response = await axios.post(USER_APPROVED, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getmessage = async (data) => {
  try {
    const response = await axios.post(GET_MESSAGE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const userDecline = async (data) => {
  try {
    const response = await axios.post(USER_DECLINE, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addSegmentApi = async (data) => {
  try {
    const response = await axios.post(ADD_SEGMENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editSegmentApi = async (data) => {
  try {
    const response = await axios.post(EDIT_SEGMENT, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const singleForumApi = async (data) => {
  try {
    const response = await axios.get(`${SINGLE_FORUM}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const singleStoryApi = async (data) => {
  try {
    const response = await axios.get(`${SINGLESTORY_FORUM}/${data}`);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const getGalleryApi = async ({page, postsPerPage}) => {
//   try {
//     const response = await axios.get(`${GET_GALLERY}/${page}/${postsPerPage}`);
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };

export const getGalleryApi = async (data) => {
  try {
    const response = await axios.post(GET_GALLERY, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getGalleryPollApi = async (data) => {
  try {
    const response = await axios.post(GET_GALLERYPOLL, data,{
      headers: {
        "Content-Type": "application/json",
      },
    }
      // `${GET_GALLERYPOLL}?page=${page}&size=${postsPerPage}`
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const getStoryApi = async ({ page, postsPerPage }) => {
  try {
    const response = await axios.get(
      `${GET_STORY}?page=${page}&size=${postsPerPage}`
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const getNewsFeedApi = async () => {
//   try {
//     const response = await axios.get(GET_NEWSFEED);
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const getNewsFeedApi = async (data) => {
  try {
    const response = await axios.post(GET_NEWSFEED, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getSegmentApi = async (data) => {
  try {
    const response = await axios.post(GET_SEGMENT, data && data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addCommentForum = async (data) => {
  try {
    const response = await axios.post(ADD_COMMENT_FORUM, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addCommentStory = async (data) => {
  try {
    const response = await axios.post(ADD_COMMENT_STORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addGalleryCommentForum = async (data) => {
  try {
    const response = await axios.post(ADD_GALLERYCOMMENT_FORUM, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCommentForum = async (data) => {
  try {
    const response = await axios.post(DELETE_COMMENT_FORUM, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCommentStory = async (data) => {
  try {
    const response = await axios.post(DELETE_COMMENT_STORY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCommentGallery = async (data) => {
  try {
    const response = await axios.post(DELETE_COMMENT_GALERY, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteReplyCommentForum = async (data) => {
  try {
    const response = await axios.post(DELETE_REPLYCOMMENT_FORUM, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
// export const getResources = async (data) => {
//   try {
//     const response = await axios.post(GET_RESOURCES,data,{
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (response.status === 200) {
//       return { res: response.data };
//     } else return response.data;
//   } catch (err) {
//     if (err.response) throw err.response.data;
//     else throw err.message;
//   }
// };
export const gettoolkitsCategory = async ({ size, page }) => {
  try {
    const response = await axios.get(
      `${GET_TOOLKITS}?page=${page}&size=${size}`
    );
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getEducationData = async () => {
  try {
    const response = await axios.get(GET_EDUCATION);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getFouramHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETFOURAMDETAIL}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getGalleryHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETGALLERYDETAIL}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getResourcesHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETRESOURCESDETAIL}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getToolkitHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETTOOLKITDETAIL}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getStoryHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETSTORYDETAILS}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getNewsfeedHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETNEWSFEEDDETAILS}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getCourseHomedetail = async ({ startDate, endDate }) => {
  try {
    const response = await axios.get(`${GETCOURSEDETAILS}?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getHomedetail = async ({ startDate, endDate }) => {
  try {
    if (startDate && endDate) {
      const response = await axios.get(
        `${GETUSERSDETAILS}?startDate=${startDate}&endDate=${endDate}`
      );
      return response.data;
    } else {
      if (
        localStorage.getItem("homeData") &&
        localStorage.getItem("homedatatime")
      ) {
        const newtime = moment(localStorage.getItem("homedatatime"))
          .add(10, "m")
          .toDate();
        var currenttime = new Date();
        if (newtime < currenttime) {
          const response = await axios.get(
            `${GETUSERSDETAILS}?startDate=${startDate}&endDate=${endDate}`
          );
          localStorage.setItem("homeData", JSON.stringify(response));
          const TEN_MINUTES = new Date();
          localStorage.setItem("homedatatime", TEN_MINUTES);
          return response.data;
        }
        const response = JSON.parse(localStorage.getItem("homeData"));
        return response.data;
      } else {
        const response = await axios.get(
          `${GETUSERSDETAILS}?startDate=${startDate}&endDate=${endDate}`
        );
        localStorage.setItem("homeData", JSON.stringify(response));
        const TEN_MINUTES = new Date();
        localStorage.setItem("homedatatime", TEN_MINUTES);
        return response.data;
      }
    }
    // const response = await axios.get(GETUSERSDETAILS);
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const addToolResources = async (data) => {
  try {
    const response = await axios.post(ADDTOOL_RESOURCES_DATA, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetEducationSessiondata = async (data) => {
  try {
    const response = await axios.post(GET_EDUCATIONSESSION_DATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetEducationSubSessiondata = async (data) => {
  try {
    const response = await axios.post(GET_EDUCATIONSUBSESSION_DATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetEducationQuestiondata = async (data) => {
  try {
    const response = await axios.post(GET_EDUCATIONQUESTIONDATA_DATA, data);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addReadNotifictaion = async (data) => {
  try {
    const response = await axios.post(ADD_READ_NOTIFICATION, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetMeetingCode = async (data) => {
  try {
    const response = await axios.post(GET_ROOM_DETAIL, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const Get100msviewCode = async (data) => {
  try {
    const response = await axios.post(
      GET_100MSTOKEN_RECORDINGDETAILVIEW,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const get100mstoken = async () => {
  try {
    const response = await axios.get(GET_100MSTOKEN_DETAIL);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const get100msrecordinglisttoken = async () => {
  try {
    const response = await axios.get(GET_100MSTOKEN_RECORDINGDETAIL);
    return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
