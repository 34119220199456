import { NavLink } from "react-router-dom";
import AuthContext from "../../Utils/auth-context";
import { useContext, useEffect, useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { ModalContext } from '../../Context'
import { RESET_PASSWORD } from '../ModalTemplate';
import AntSwitch from "../Common/AntSwitch";
import { getAdminDetails, maintenanceModeApi } from "../../Utils/services";
import { toast } from "react-toastify";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import MaintenanceModeConfirmation from "../ModalTemplate/MaintenanceModeConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import avatar from "../../Assets/images/DummyDp.png"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import show from  '../../Assets/icoonsss/menu.png'
import close from  '../../Assets/icoonsss/close.png'


const Header = ({setnavopen, isNavopen, setnavopens, isNavopens}) => {
    const { t } = useTranslation();
    const authCtx = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;
    const [isMaintenance, setIsMaintenance] = useState(localStorage.getItem("maintenance") === " maintenance on" ? true : false)
    const [adminData, setAdminData] = useState();
   

    const getAdminDetailsApiData = async () => {
        const id = localStorage.getItem("adminId")
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            if (result.success) {
                setAdminData(result.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        authCtx.logout();
    }

    const handleResetPassword = () => {
        setAnchorEl(null);
        handleModalData(RESET_PASSWORD, 'sm');
    }

    const handleMaintenance = async () => {
        try {
            const result = await maintenanceModeApi();
            if (result.res.success) {
                localStorage.setItem("maintenance", result.res.message)
                if (result.res.message === " maintenance on") {
                    setIsMaintenance(true)
                }
                else {
                    setIsMaintenance(false)
                   
                }
                toast.success(result.res.message)
                closeModal();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleToggleChange = async (e) => {
        // setIsMaintenance(e.target.checked);
        const MAINTENANCE_MODE = <MaintenanceModeConfirmation
            title="maintenance Mode"
            data={modalTextsFile.MaintenanceMode}
            action={handleMaintenance}
        />
        handleModalData(MAINTENANCE_MODE, 'sm')
        // try {
        //     const result = await maintenanceModeApi();
        //     if (result.res.success) {
        //         localStorage.setItem("maintenance", result.res.message)
        //         if (result.res.message === " maintenance on") {
        //             setIsMaintenance(true)
        //         }
        //         else {
        //             setIsMaintenance(false)
        //         }
        //         toast.success(result.res.message)
        //     }
        // } catch (error) {
        //     toast.error(error.message)
        // }
    }

    // const handleClicksidebar = (e) => {
    //     setAnchorEl(event.currentTarget);
    // };
    useEffect(() => {
        function handleResize() {
          if (window.innerWidth <= 991) {
            setnavopens(true)
            setnavopen(true)
         

            console.log('Window width is less than or equal to 991 pixels');
          } else if(window.innerWidth > 991 ){
            setnavopens(false)
            setnavopen(false)
           
          }
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []); 


      localStorage.setItem('isNavopen', isNavopen);
      localStorage.setItem('isNavopens', isNavopens);

    return (

        <header className="header">
            <div className={isNavopens ? "search-wrapper" : " search-wrappers icon"}>
                {/* <input type="text" placeholder="Search" className="w-full h-full" /> */}
                <img onClick={() => setnavopen(!isNavopen)} src={!isNavopen ? close  : show} alt="avatar"/>
            </div>
            <ul className="ml-auto  flex items-center lg:px-16 headres px-24">
                {/* <li className="px-5 flex items-center justify-center">
                    <h3 className="text-paraText hover:text-sidenav uppercase">maintenance mode</h3>
                    <AntSwitch
                        checked={isMaintenance}
                        name="isMaintenance"
                        onChange={handleToggleChange}
                        className="ml-5"
                    />
                </li> */}
                {/* <li className="px-5">
                    <NavLink
                        exact to="/"
                        className="text-paraText hover:text-sidenav uppercase"
                        activeClassName="navbar_active"
                    >
                        Home
                    </NavLink>
                </li> */}
                {/* <li className="px-5">
                    <div className="profile-info">
                        <h3 className="title text-xl w-10 h-10 flex justify-center items-center bg-sidenav text-bgOrange rounded-full"> {adminData && adminData?.name?.slice(0, 1)} </h3>
                    </div>
                </li> */}
                <li className="pl-5">
                    <div>
                        <Button
                            className="text-paraText t hover:text-sidenav uppercase"
                            activeClassName="navbar_active"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            {t('MORE')}
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <Link to="/profile" >
                                <MenuItem>
                                     {t('Manage_Account')}
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={handleResetPassword}>  {t('Reset_Password')}</MenuItem>
                            <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
                        </Menu>
                    </div>
                </li>
            </ul>
        </header>
    )
}

export default Header;