import Events from "./Components/Events";
import Home from "./Components/Home";
import Users from "./Components/Users";
import Feeds from './Components/Feeds'
import Master from './Components/Master'
import ManageAcoount from "./Components/ManageAccount";
import AdminViewPage from "./Components/Users/AdminViewPage";
import Notification from "./Components/Notification";
import UserBulkUpload from "./Components/Users/UserBulkUpload";
import Gallery from "./Components/Gallary";
import PostDetails from "./Components/ModalTemplate/PostDetails";
import PostGalleryDetails from "./Components/ModalTemplate/PostGalleryDetails";
import PollingCard from "./Components/ModalTemplate/PollingCard"
import PostResourcedetail from "./Components/ModalTemplate/PostResourcedetail";
import PostToolkitDetail from "./Components/ModalTemplate/PostToolkitDetail";
import PostStoryDeatil from "./Components/ModalTemplate/PostStoryDeatil";
import Resources from "./Components/Resources";
import Toolkits from "./Components/Toolkits";
import Education from "./Components/Education";
import EducationSession from "./Components/Education/EducationSession";
import EducationSubSession from "./Components/Education/EducationSubSession";
import EducationQuestion from "./Components/Education/EducationQuestion";
import Story from "./Components/Story";
import NewsFeed from "./Components/NewsFeed";
import Chat from "./Components/Chat";
import Directory from "./Components/Directory";


export const routes = [
  { path: "/", component: Home },
  // { path: "post/:slug", component: PostDetails },
  // { path: "gallery/:slug", component: PostGalleryDetails },
  { path: "users", component: Users },
  { path: "users/bulk-upload", component: UserBulkUpload },
  { path: "notification", component: Notification },

  {path : "users/:slug", component : AdminViewPage},
  { path: "forum", component: Feeds },
  //{ path: "forum/:slug", component: PollingCard },
  { path: "forum/:slug", component: PostDetails },
  { path: "gallery", component: Gallery },
  { path: "gallery/:slug", component: PostGalleryDetails },
  { path: "resources/:slug", component: PostResourcedetail },
  { path: "Toolkits/:slug", component: PostToolkitDetail },
  { path: "Story/:slug", component: PostStoryDeatil },
  { path: "/:slug", component: PollingCard },
  { path: "events", component: Events },
  { path: "master", component: Master },
  { path: "profile", component:  ManageAcoount},
  { path: "resources", component:  Resources},
  { path: "Toolkits", component:  Toolkits},
  { path: "Education", component:  Education},
  { path: "Education/:EducationSession", component:  EducationSession},
  { path: "Education/:EducationSession/:id", component: EducationSubSession},
  { path: "Education/:EducationSession/:EducationSubSession/:id", component: EducationQuestion},
  { path: "Story", component:  Story},
  { path: "NewsFeed", component:  NewsFeed},
  { path: "Chat", component:  Chat},
  { path: "Directory", component:  Directory},


];