import { useContext, useEffect } from "react";
import { ModalContext } from "../../Context";
import avatar from "../../Assets/images/DummyDp.png";
import { navlist } from "./navlist";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useState } from "react";
import { getAdminDetails } from "../../Utils/services";
import logo from "../../Assets/images/glp-logo-white.png";
import { useTranslation } from 'react-i18next';

const SideNav = ({isNavopen}) => {
  const [adminData, setAdminData] = useState();
  const context = useContext(ModalContext);
  const location = useLocation();
  const {t} = useTranslation();
  const getAdminDetailsApiData = async () => {
    const id = localStorage.getItem("adminId");
    try {
      const result = await getAdminDetails({
        id: id,
        type: "admin",
      });
      if (result.success) {
        setAdminData(result.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdminDetailsApiData();
  }, []);
  const length = localStorage.getItem("Notificationlength");
  const registration = localStorage.getItem("registration");
//   console.log(length, "length");
  return (
    <div id="sideNav">
      <div className="profile-info">
        <img src={logo} alt="avatar" />
      </div>
      <div className="navlist">
        <ul>
          {navlist.map((item) => (
            <li key={item.ref}>
              <Link
                to={item.path}
                className={classNames({
                  "flex items-center justify-center cursor-pointer ": true,
                  "opacity-100": location.pathname === item.path,
                  "opacity-50": location.pathname !== item.path,
                })}
              >
                <div className="grid grid-cols-6 gap-4 items-center">
                  <span className="col-span-2">
                    <img src={item.icon} alt="icon" className="h-6 iconwidth" />
                  </span>
                  <span className="uppercase">{t(item.label)}</span>
                  {item?.ref == "chat" && (
                    <span className="col-span-2 length">
                      {length > 0 ?<p>{length}</p> : null}
                    </span>
                  )}
                  {item?.ref == "people" && (
                  
                    <span className="col-span-2 dot">
                        {
                            registration > 0 ?  <div className="rounded-full left bg-orange h-3 w-3"></div> : null
                        }
                      
                    </span>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideNav;
