import classNames from "classnames"
import ReactHlsPlayer from 'react-hls-player';
import Slider from "react-slick";
import { BASE_URL_IMG } from "../../Utils/services";
import styles from '../../Assets/Comment.module.css'



const settings = {
    speed: 800,
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: false
};

const PostDeatilhome = ({
    data,
    isModalView,
}) => {
    const checkLink = url => {
        const linkType = url.split('.').slice(-1)[0]

        const isCompleteUrl = url.startsWith('https://') || url.startsWith('http://');
        if (linkType === 'm3u8') {
            return (
                <ReactHlsPlayer
                    src={url}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                    hlsConfig={{
                        maxLoadingDelay: 4,
                        minAutoBitrate: 0,
                        lowLatencyMode: true,
                    }}
                />
            )
        }
        else if(isCompleteUrl) {
            return <img src={url}  />
        }else{
            return <img src={`${BASE_URL_IMG}${url}`}  />
        }
    }

    return (
        <>
            {
                data && (
                    <div className={classNames({
                        'post-detail': true,
                        'relative': true,
                        'modal-view': isModalView,
                        'without-text': (data?.media?.length === 0 && !data?.isPoll)
                    })}>
                        <>
                         { data?.media?.length > 0 ? 
                              <div className="content">
                              <Slider {...settings}>
                                  {
                                      data?.media?.map(item => (
                                          <div className="w-full">
                                              {checkLink(item.link)}
                                          </div>
                                      ))
                                  }
                              </Slider>
                          </div> :
                           <Slider {...settings}>
                            <div className="w-full">
                            {checkLink(data.image)}
                        </div>
                        </Slider>
                         }
                          
                            <div className="footer p-2 pr-16">
                                <h3 className="text-md font-semibold tracking-wider mb-1"> {data?.question ? data?.question : data?.title} </h3>
                                {/* <p className="text text-sm">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data.content ? data.content :  data.caption }}
                                    />
                                </p> */}
                                 <div className="flex justify-start sticky top-0">
                <div className="flex pl-2 pt-2 comment-bg rounded-tl">
                    <span className={styles.num}>{data && data.likes}</span>
                    <img src="../../assets/comment/like.svg" className={styles.icon} alt="likes" />
                </div>
                <div className="flex px-2 pt-2 comment-bg">
                    <span className={styles.num}>{data && data.comments}</span>
                    <img src="../../assets/comment/comment.svg" className={styles.icon} alt="comment" />
                </div>
            </div>
                            </div>
                        </>
                    </div>
                )
            }
        </>
    )
}

export default PostDeatilhome