import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import AntSwitch from '../Common/AntSwitch'
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { editUser, getLanguageList, getSectorList, getSectorLists, getSegmentApi, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader";
import { useTranslation } from 'react-i18next';

const UserEditForm = ({
    title,
    buttonText,
    getTablesData,
    getnewRegistraionTablesData,
    id,
    perticularData
}) => { 
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState(perticularData?.language?.name || []);
    const [formData, setFormData] = useState(perticularData);
    const [gender, setGender] = useState(perticularData?.gender);
    const [loader, setLoader] = useState(false);
    const [isApprove, setIsApprove] = useState(perticularData?.isApproved)
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const getSegmentData = async () => {
        try {
            const result = await getSegmentApi();
            let _resultData = result?.res?.data?.filter((data) => {
                return data?.country?.name === perticularData?.country?.name
            })
            setSegmentData(_resultData)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, []);
    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }

    useEffect(() => {
        getCountryData();
        getLanguageListData();
        getSectorListData();
    }, [])
    const getSectorListData = async () => {
        try {
            const result = await getSectorLists();
            setSectorData(result.res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }
    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }
    const handleApprove = (e) => {
        setIsApprove(e.target.checked)
    }
    const onGenderChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setGender(value)
    }
    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            setCountries(result?.res?.data?.country)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }
    const onInputSectorChange = (event, newInputValue, name) => {
        setSector({
            ...sector,
            [name]: newInputValue
        })
    }
    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        const addFormData = new FormData();
        addFormData.append("name", formData.name);
        addFormData.append("aliasName", formData.aliasName);
        addFormData.append("language", language?.language?.name ? language?.language?.name : perticularData?.language?.name);
        addFormData.append("gender", gender);
        addFormData.append("age", formData.age);
        addFormData.append("sector", sector?.sector?.name ? sector?.sector?.name : perticularData?.sector?.name);
        addFormData.append("isApproved", isApprove);
        addFormData.append("segment", segment?.segment?.name ? segment?.segment?.name : perticularData?.segment?.name);
        addFormData.append("id", id);
        try {
            const results = await editUser(addFormData);
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("User edited successfully");
                setLoader(false);
                closeModal();
                getnewRegistraionTablesData({
                    page: page,
                    size: postsPerPage,
                 });
            }
        }
        catch (e) {
            setLoader(false);
            toast.error(e.messages);
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Name')}
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label={t('Alias_Name')}
                        name="aliasName"
                        value={formData.aliasName || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label={t('Age')}
                        name="age"
                        value={formData.age || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label={t('Mobile_No.')}
                        name="phone"
                        value={formData.phone || ''}
                        onChange={e => onChangeHandler(e)}
                        disabled
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        defaultValue={perticularData?.language}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')} />}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        sx={{ width: 300 }}
                        disabled
                        defaultValue={perticularData.country}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')}/>}
                    />
                    {
                        perticularData?.country?.name &&
                        <Autocomplete
                            id="combo-box-demo"
                            options={segmentData?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name || ""}
                            value={segment?.name}
                            defaultValue={perticularData?.segment}
                            onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                            renderInput={(params) => <TextField {...params} label= {t('Segment')}/>}
                        />
                    }
                    <Autocomplete
                        id="sector"
                        options={sectorData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        sx={{ width: 300 }}
                        defaultValue={perticularData.sector}
                        value={sector?.name}
                        onChange={(e, newVal) => onInputSectorChange(e, newVal, 'sector')}
                        renderInput={(params) => <TextField {...params} label={t('Sector')} />}
                    />
                    <div className="mt-4 w-6/12">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Gender</label>
                        <RadioGroup aria-label="gender" name="gender" className="radio-group" defaultValue={gender} onChange={e => onGenderChangeHandler(e)}>
                            <FormControlLabel value="Male" control={<Radio />} label={t('Male')} />
                            <FormControlLabel value="Female" control={<Radio />} label={t('Female')} />
                            <FormControlLabel value="Other" control={<Radio />} label={t('Other')} />
                        </RadioGroup>
                    </div>
                </div>
                <div className="mt-4 w-6/12 flex items-center">
                    <span className='text-minihubPara'>{t('Approved')}</span>
                    <AntSwitch
                        checked={isApprove}
                        name="isApproved"
                        className='ml-5'
                        onChange={handleApprove}
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UserEditForm;