import styles from '../../Assets/Comment.module.css'
import CommentBox from './CommentBox'


const CommentSection = ({ commentSectionData, singlePostData,Likes }) => { 
    // console.log(singlePostData)
    // Nested part yet to be done
    return (
        <>
            <div className="flex justify-end sticky top-0">
                <div className="flex pl-2 pt-2 comment-bg rounded-tl">
                    <span className={styles.num}>{Likes && Likes.likes && Likes.likes.length}</span>
                    <img src="../../assets/comment/like.svg" className={styles.icon} alt="likes" />
                </div>
                <div className="flex px-2 pt-2 comment-bg">
                    <span className={styles.num}>{singlePostData && singlePostData.comments && singlePostData.comments.length}</span>
                    <img src="../../assets/comment/comment.svg" className={styles.icon} alt="comment" />
                </div>
            </div>
            <div className="comment-bg comment-section">
                <CommentBox commentBoxDataProps={commentSectionData} singlePostData={singlePostData.comments} postId={singlePostData._id} />
            </div>
        </>
    )
}

export default CommentSection
