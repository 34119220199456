import UsersTable from './UsersTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { InviteAllPeopleNotification, InviteNotification, userDeactivate,deleteAboutPostApi } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import AboutEdit from '../ModalTemplate/AboutEdit';
import { Translatedata } from '../Common/Option';
import { Autocomplete } from '@material-ui/lab';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';

const About = ({ data, getABOUTTableData,adminData, getAdminTableData }) => {  
  const [showFilteredData, setShowFilteredData] = useState([]);
  const [cordinater, setcordinater] = useState(adminData[0]?.AdminType);
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  useEffect(() => {
    getAdminTableData();
    getABOUTTableData();
  }, [])

  const history = useNavigate();
  const { t } = useTranslation();

  const editHandler = (data) => {
    const EDIT_PARTY = <AboutEdit
      title={t('Edit_About')}
      buttonText={t('Save_Changes')}
      id={data.row.original._id}
      perticularData={data.row.original}
      getABOUTTableData={getABOUTTableData}
    />
    handleModalData(EDIT_PARTY);
  }

  const handleInvite = async (data) => {
    console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "user",
        id: data.row.original._id
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleInviteAll = async () => {
    try {
      const result = await InviteAllPeopleNotification({
        people: "user",
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  // const handleUserBulkUpload = () => {
  //   history({
  //     pathname: "users/bulk-upload",
  //     state: {
  //       isInterNationalStatus: false
  //     }
  //   })
  // }

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getABOUTTableData();
      }
    } catch (error) {
      toast.error(error.message)
    }

  }

  const handleDeactivate = async (props) => {
    const id = props.row.original._id;
    const USER_DEACTIVATE = <MaintenanceModeConfirmation
      title="Change User Status"
      data={modalTextsFile.userActivateDeactivate}
      action={() => handleMaintenance(id)}
    />
    handleModalData(USER_DEACTIVATE, 'sm')
  }


  const deletePostGallery = async (item) => {  
    const id = item._id
    try {
        const result = await deleteAboutPostApi({
                id: item._id
        });
        if (result.res.success) {
            toast.success(result.res.message)
            getABOUTTableData();
        }

    } catch (error) {
        toast.error(error.messages)
    }
}

const HandleRemoveEvent = (id) => {
  const REMOVE_CONFIRMATION = (
    <RemoveConfirmation
      title={t('Remove_About')}
      action={deletePostGallery}
      data={t('AboutRemoveText')}
      id={id}
    />
  );
  handleModalData(REMOVE_CONFIRMATION, "sm");
};
function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}
const onInputCategoryChange = (event, newInputValue, name) => {
  setCategory({
    ...category,
    [name]: newInputValue
  })
}

useEffect(() => { 
  if (category?.category?.value) { 
    let _result = data?.filter((item) => { 
      return item?.language?.name === category?.category?.value
    })
    setShowFilteredData(_result)
  }
  else {
    setShowFilteredData(data)
  }
});
  const userColumns = useMemo(
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
    //   {
    //     Header: `${t('Users')}`,
    //     accessor: userData => (
    //       <div className="flex justify-start items-center">
    //         <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
    //         <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
    //       </div>
    //     ),
    //   },
    // {
    //     Header: `${t('About')}`,
    //     accessor: properties => {
    //       return (
    //         <>
    //           <span className='capitalize'>{`${properties?.about ? properties?.about : ' - '}`}</span>
    //           {/* <span className='capitalize'  dangerouslySetInnerHTML={{__html: properties?.quation ? properties?.quation : ' - '}}></span> */}
    //         </>
    //       )
    //     }
    //   },
    {
      Header: `${t('Language')}`,
      accessor: Value => {
        return (
          <>
            {
              <span>{Value && Value.language ? Value?.language?.name : " - "}</span>
            }
          </>
        )
      }
    },
      {
        Header: `${t('About')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            <span className="ml-2"  dangerouslySetInnerHTML={{__html: userData.about ? userData.about : " - "}}></span>
          </div>
        ),
      },
    //   {
    //     Header: `${t('Age')}`,
    //     accessor: Value => { 
    //       return (
    //         <>
    //           {
    //             <span>{Value && Value.age ? Value.age : " - "}</span>
    //           }
    //         </>
    //       )
    //     }
    //   },
    
      {
        Header: `${t('Delete')}`,
        accessor: (Value) => {
            return (
              cordinater ===  "Coordination team" ? "" :
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={Value._id}
                    onClick={() => HandleRemoveEvent(Value)}
                >
                    <span className="MuiButton-label">{t('Delete')}</span>
                </button>
            )
        },
    },
   
    //   {
    //     Header: `${t('Sector')}`,
    //     accessor: properties => {
    //       return (
    //         <>
    //           <span className='capitalize'>{`${properties?.sector ? properties?.sector?.name : ' - '}`}</span>
    //         </>
    //       )
    //     }
    //   },
    //   {
    //     Header: `${t('Segment')}`,
    //     accessor: properties => {
    //       return (
    //         <>
    //           <span className='capitalize'>{`${properties?.segment?.name ? properties?.segment?.name : ' - '}`}</span>
    //         </>
    //       )
    //     }
    //   },
      {
        Header: `${t("Action")}`,
        accessor: "_id",
        Cell: props => cordinater ===  "Coordination team" ? "" : <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')} </Button>
      },
      // {
      //   Header: `${t("Send_SMS")}`,
      //   accessor: "",
      //   Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Send </Button>
      // },
    //   {
    //     Header: `${t("Status")}`,
    //     accessor: "deactivated",
    //     Cell: props => <Grid item>
    //       <AntSwitch
    //         checked={!props.row.original.deactivated}
    //         onChange={() => handleDeactivate(props)}
    //         name="isDirectory"
    //       />
    //     </Grid>
    //   }
    ], [])

  return (
    <>
      <>
      <div className="flex justify-between flex-wrap items-center">
      <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
         <div>
          <Autocomplete
            options={Translatedata?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.value || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label={t('Filter_By_Langauge')}/>}
          />
        </div>
      </div>
      </>
      {
        showFilteredData && <UsersTable columns={userColumns} data={showFilteredData.filter((val, index) => { 
          if (searchValue === "") {  
            return val
          }
          else if (val.language && val.language.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.about && val.about.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          // else if (val.quation && val.quation.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
          //   return val
          // }
        
        })} />
      }
    </>
  )
}

export default About;