import { useContext, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import {
  regionCountryList,
  addUser,
  getResourcesCategory,
  addResources,
  getAdminDetails,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import Grid from "@material-ui/core/Grid";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from 'react-i18next';

const AddResourcesForm = ({ getTablesData }) => {
  const {t} = useTranslation();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [pdfFile, setPdfFile] = useState("");
  const [mediaType, setMediaType] = useState("photo");
  const [VideoFile, setVideoFile] = useState("");
  const [caption, setcaption] = useState("");
  const [videoValidation, setVideoValidation] = useState("");
  const [disablePdf, setDisablePdf] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [countryNotification, setcountryNotification] = useState(null);
  const [pdfValidation, setPdfValidation] = useState("");
  const [captionFileName, setCaptionFileName] = useState("");
  const context = useContext(ModalContext);
  const { closeModal } = context;
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    getCategoryData();
    getAdminDetailsApiData();
  }, []);

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleVideoFile = async (val) => {
    setMediaType("video");
    if (val && val.size > 0) {
      console.log(val, "val");
      if (val?.size && val?.size > 0) {
        if (Math.round(val.size / 1048576) > 50) {
          setVideoValidation("Video size should be less than 50 MB");
          setVideoFile("");
        }
        const duration = await getVideoDuration(val);
        let durationSecond = Math.round(duration);

        if (durationSecond && durationSecond > 300) {
          setVideoValidation("Video duration should be less than 5 minutes");
          setVideoFile("");
        } else {
          setVideoFile(val);
          setVideoValidation("");
          if (duration && duration > 300) {
            setVideoValidation("Video duration should be less than 2 minutes");
            setVideoFile("");
          } else {
            setVideoFile(val);
            setVideoValidation("");
          }
        }
        console.log(Math.round(val.size / 1048576), "Val");
      }
    }
    if (val?.name !== "") {
      setDisablePdf(true);
    }
  };

  const handleCaptionFile = (file) => {
    setcaption(file); // Save the file object
    if (file) {
      setCaptionFileName(file.name); // Save the file name
    }
  };
  const handlePdfFile = (val) => {
    // setPdfFile(val);
    // setMediaType("file");
    if(val.size <= 30 * 1024 * 1024) {
      setPdfFile(val);
      setMediaType("file");
    } else {
      setPdfFile("");
      setPdfValidation("Pdf size should be less than 50 MB");
    }
    if (val.name !== "") {
      setDisableVideo(true);
    }
  };

  const onInputCountryChange = (event, newInputValue, name) => {
    setCountry({
      ...country,
      [name]: newInputValue,
    });
  };

  const getCategoryData = async () => {
    try {
      const result = await getResourcesCategory();
      setCategoryData(result?.data);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong!");
    }
  };
  useEffect(() => {
    getCategoryData();
  }, [country]);
  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue,
    });
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // const getCountryData = async () => {
  //     try {
  //         const result = await regionCountryList();
  //         setCountries(result?.res?.data?.country)
  //     }
  //     catch (error) {
  //         console.log(error);
  //         toast.warning("Something went wrong!")
  //     }
  // }
  const adminId = localStorage.getItem("adminId");

  const getAdminDetailsApiData = async () => {
    try {
      const result = await getAdminDetails({
        id: adminId,
        type: "admin",
      });
      setCountries(result.data.country);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const countryData = country?.country?.map((data) => {
      return data.name;
    });
    try {
      const postFormData = new FormData();
      postFormData.append("title", formData.title ? formData.title : "");
      postFormData.append("category", category?.category?._id);
      postFormData.append("countrys", countryData);
      postFormData.append(
        "media",
        (VideoFile && VideoFile) || (pdfFile && pdfFile)
      );
      postFormData.append("mediaType", mediaType);
      postFormData.append("target", countryNotification);
      postFormData.append("caption", caption);
      const results = await addResources(postFormData);
      if (results.res.success) {
        // toast.success(results.res.message);
        // toast.success("Resources activated");//
        toast.success("‘Resource Added Successfully’");
        closeModal();
        setTimeout(() => {
          getTablesData({
            page: page,
            size: postsPerPage,
          });
        }, 5000);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
      toast.error(e.messages);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  console.log(captionFileName.name)
  console.log(captionFileName)
// debugger
  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title">{t("add_new_resources")}  </h3>
      </div>

      <form
        noValidate
        autoComplete="off"
        className="w-full"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            label={t("Title")}
            name="title"
            value={formData.title || ""}
            onChange={(e) => onChangeHandler(e)}
          />
          <Autocomplete
            multiple
            id="combo-box-demo2"
            options={countries?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            sx={{ width: 300 }}
            value={country?.name}
            onChange={(e, newVal) => onInputCountryChange(e, newVal, "country")}
            renderInput={(params) => <TextField {...params} label={t("Country")} />}
          />
          <Autocomplete
            id="combo-box-demo"
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            onChange={(e, newVal) =>
              onInputCategoryChange(e, newVal, "category")
            }
            renderInput={(params) => <TextField {...params} label={t("Category")} />}
          />
           <div className="relative w-auto ">
                <TextField
                  id="profilephoto"
                  label={t("Caption")}
                  value={captionFileName || ""}
                  className="w-full text-xs"
                />
                <input
                  disabled={disableVideo}
                  type="file"
                  // accept="video/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="caption"
                  onChange={(e) => handleCaptionFile(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
          <br />
        </div>
        <div className="mt-12 w-full">
          <Grid container alignItems="center" spacing={1}>
            <Grid item md={2}>
              <span className="text-paraText">{t("Add_Media")}</span>
            </Grid>
            <Grid item md={4}>
              <div className="relative w-auto overflow-hidden">
                <TextField
                  id="profilephoto"
                  label={t("Add_a_Video")}
                  value={VideoFile.name || ""}
                  className="w-full text-xs"
                />
                <input
                  disabled={disableVideo}
                  type="file"
                  accept="video/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="video-file"
                  onChange={(e) => handleVideoFile(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
              {videoValidation && videoValidation && (
                <p className="text-red-600">{videoValidation}</p>
              )}
              <div className="media-box mt-2">
                <img src="../../assets/video.svg" alt="" />
                {t("Video")}
              </div>
              <div className="note">
                <span>{t("Video_size_shouldbelessthan50_MB")}</span>
              </div>
            </Grid>
            <Grid item md={2} className="flex items-center justify-center">
              <span className="text-muteText">{t("or")}</span>
            </Grid>
            <Grid item md={4}>
              <div className="relative w-auto overflow-hidden">
                <TextField
                  id="profilephoto"
                  label={t("Add_a_PDF_File")}
                  value={pdfFile.name || ""}
                  className="w-full text-xs"
                />

                <input
                  disabled={disablePdf}
                  type="file"
                  accept="application/pdf, application/vnd.ms-excel"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="pdf-file"
                  onChange={(e) => handlePdfFile(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
              {pdfValidation && pdfValidation && (
                  <p className="text-red-600">{pdfValidation}</p>
                )}
              <div className="media-box mt-2">
                <img src="../../assets/photos.svg" alt="" />
                {t("Files")}
              </div>
              <div className="note">
                  <span> {t("Pdfsizeshouldbelessthan30MB")}</span>
                </div>
            </Grid>
            <Grid item md={4}>
              <div className="flex items-center  customeCheckbox InviteOnlyCheckbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="country"
                      checked={countryNotification}
                      onChange={(e) => {
                        setcountryNotification(e.target.checked);
                      }}
                      defaultChecked
                      color="primary"
                    />
                  }
                  label={t("Country_Notification")}
                  labelPlacement="end"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="flex justify-center mt-10">
          <button
            // className="button-1 mx-auto"
            className={`mx-auto ${ formData.title !== "" && country && country?.country && country?.country[0]?.name !== undefined && category && category?.category && category?.category?.name !== undefined && VideoFile.name !== undefined || pdfFile.name !== undefined ? "button-1" : "buttons-1"}`} 
            type="submit"
            disabled={formData.title !== "" && country && country?.country && country?.country[0]?.name !== undefined && category && category?.category && category?.category?.name !== undefined && VideoFile.name !== undefined || pdfFile.name !== undefined ? false : true }
            //disabled={language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? false : true}
          >
           {t("Submit")}
          </button>
        </div>
      </form>
    </>
  );
};

export default AddResourcesForm;
