import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { BASE_URL_IMG, deleteCoursePostApi, deleteToolkitPerticularData, GetEducationSessiondata, getResourcesCategory, InviteNotification, ToolkitStatusApi, userDeactivate,courseDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate, useMatch } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
// import EditResourcesForm from '../ModalTemplate/EditResourceForm';
import EditToolkitForm from '../ModalTemplate/EditToolkitForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import Educationtable from './Educationtable';
import AddCourse from '../ModalTemplate/AddCourse';
import EditCourse from '../ModalTemplate/EditCourse';
import Educationsidebar from './EducationSidebar';
import EducationSidebar from './EducationSidebar';
import { Pagination } from '@material-ui/lab';


const EducationResources = ({ data, getTablesData, adminData ,totalCount}) => {
  const [searchValue, setSearchValue] = useState("");
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [open, setOpen] = useState(false);
  const [ResourcesData, setResourcesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [courseid, setCourseid] = useState();
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);

  // let { path } = useMatch();

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  
  useEffect(()=>{ 
    if (totalCount && totalCount > 0) {
        setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
    }
// if(selectfilter?.Country?.name !== undefined  )

  getTablesData({
       
        page: page,
        size: postsPerPage,
    });

},[page, totalCount])
  // const getCategoryData = async () => {
  //   try {
  //     const result = await getResourcesCategory();
  //     setCategoryData(result?.data)
  //   }
  //   catch (error) {
  //     console.log(error);
  //     toast.warning("Something went wrong!")
  //   }
  // }
  // useEffect(() => {
  //   getCategoryData();
  // }, []);

  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }

  useEffect(() => {
    if (category?.category?._id) {
      let _result = data?.filter((item) => {
        return item?.category?.id === category?.category?._id
      })
      setShowFilteredData(_result)
    }
    else {
      setShowFilteredData(data)
    }
  });

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  const history = useNavigate();
  const { t } = useTranslation();

  // const handleMaintenance = async (id) => {
  //   try {
  //     const _result = await userDeactivate(id);
  //     if (_result.res.success) {
  //       toast.success(_result.res.message)
  //       closeModal();
  //       getTablesData();
  //     }
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteCoursePostApi(id);
      console.log(result)
      if (result.res.success) {
        toast.success(result.res.success)
        getTablesData();
      }

    } catch (error) {
      toast.error(error.message)
    }
  }
  const HandleRemoveEvent = (id) => { 
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t("Course_Delete")}
        action={HandleDeleteResource}
        data={t("remove_this_Course?")}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  // const handleDeactivate = async (props) => {
  //   const id = props.row.original._id;
  //   const USER_DEACTIVATE = <MaintenanceModeConfirmation
  //     title="Change User Status"
  //     data={modalTextsFile.userActivateDeactivate}
  //     action={() => handleMaintenance(id)}
  //   />
  //   handleModalData(USER_DEACTIVATE, 'sm')
  // }

  const handleEditResource = async (data) => {
    const editResourceForm = <EditCourse
      data={data}
      id={data._id}
      getFeedData={getTablesData}
      title={t("Edit_Course")}
    />
    handleModalData(editResourceForm)
  }

  
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const showResourceDetail = async (item) => { 
    // try {
    //   setCourseid(item)
    //   const result = await GetEducationSessiondata({
    //     courseId: item?.courseidid?._id ? item?.courseidid?._id : item?._id
    //   });
    //   console.log(result,"result")
      
    //   setResourcesData(result?.res?.session?.reverse())
    //   setOpen(true); 
    //   setLoader(false)
    // }
    // catch (error) {
    //   setLoader(false)
    //   console.log(error, "Error");
    //   toast.error(error.message);
    // }
    history(`${item._id}`,{
   
      state: {
        data: item,
        title: 'Toolkit Details'
      }
    })
  }

  // const handleActiveDeactive = async (props) => { 
  //   const id = props.row.original._id;
  //   try {
  //     const result = await ToolkitStatusApi(id);
  //     if (result.res.success) {
  //       toast.success(result.res.message)
  //       getTablesData();
  //     }
  //   } catch (error) {
  //     toast.error(error.message)
  //   }
  // }
  const handleMaintenance = async (id) => {
    try {
      const _result = await courseDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getTablesData()
      //   getnewRegistraionTablesData({
      //     page: page,
      //     size: postsPerPage,
      //  });
      }
    } catch (error) {
      toast.error(error.message)
    }

  }
  const handleDeactivate = async (props) => { 
    const id = props.row.original._id;
   // const msg = props.row.original.status === true ? {t("Are_you_sure,_you_want_to_change_the_statusopposite_of_course")} : {t("Are_you_sure,_you_want_to_change_the_status_of_course")}
    const USER_DEACTIVATE = <MaintenanceModeConfirmation
      title="Change Course Status"
      data={t(props.row.original.status === true ? "Are_you_sure,_you_want_to_change_the_statusopposite_of_course" : "Are_you_sure,_you_want_to_change_the_status_of_course")}
      action={() => handleMaintenance(id)}
    />
    handleModalData(USER_DEACTIVATE, 'sm')
  }
  const handlePageChange = (event, value) => { 
    setPage(value);
};
  const userColumns = useMemo(
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
      {
        Header: `${t("Media_Image")}`,
        accessor: data => {
          return (
            <>
              {
                // data?.media[0]?.mediaType === "file" ?
                  <img src={`${BASE_URL_IMG}${data?.image}`} alt="pdfimg" className='w-28' />
                //   :
                //   <img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
              }
            </>
          )
        }
      },
      {
        Header: `${t("Title")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.title}</span>
            </>
          )
        }
      },
      {
        Header: `${t("Enrolled_Count")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.enrolledCount}</span>
            </>
          )
        }
      },
      {
        Header: `${t("OnGoing-Course")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.ongoingCount}</span>
            </>
          )
        }
      },
      {
        Header: `${t("Complete_Course")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.completedCount}</span>
            </>
          )
        }
      },
    
     
      // {
      //   Header: "Category",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.category?.name}</span>
      //       </>
      //     )
      //   }
      // },
      {
        Header: `${t("Country")}`,
        accessor: data => {
          return (
            <>
              {data?.Country?.map((country, index) => (
                <span key={index}>
                  {country?.name}
                  {index < data?.Country?.length - 1 && ', '} {/* Add comma between names */}
                </span>
              ))}
            </>
          )
        }
      },
      // {
      //   Header: `${t("Country")}`,
      //   accessor: data => { 
      //     return (
      //       <>
      //         <span>{data?.Country[0]?.name}</span>
      //       </>
      //     )
      //   }
      // },
    //   {
    //     Header: 'Country',
    //     accessor: data => { 
    //         return (
    //             <>
    //                 {data?.countries?.map(item => { 
    //                     return (
    //                         <p>{`${item?.name} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
      {
        Header: `${t("Course_Type")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.CourseType}</span>
            </>
          )
        }
      },
      {
        Header: `${t("Course_Duration")}`,
        accessor: data => {
          return (
            <>
              <span>{data?.CourseDuretion}</span>
            </>
          )
        }
      },
    //   {
    //     Header: "Posted On",
    //     accessor: data => {
    //       return (
    //         <>
    //           <span>{formatDate(data?.date)}</span>
    //         </>
    //       )
    //     }
    //   },
      {
        Header: `${t("Edit")}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => handleEditResource(data)}
            >
              <span className="MuiButton-label">{t("Edit")}</span>
            </button>
          )
        }
      },
      {
        Header: `${t("View")}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => showResourceDetail(data)}
            >
              <span className="MuiButton-label">{t("View_Session")}</span>
            </button>
          )
        }
      },
      {
        Header: `${t("Delete")}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => HandleRemoveEvent(data?._id)}
            >
              <span className="MuiButton-label">{t("Delete")}</span>
            </button>
          )
        }
      },
      {
        Header: `${t("Status")}`,
        accessor: "deactivated",

        Cell: adminData[0]?.AdminType==="Coordination team"? "" :props => { 
        return(
        <Grid item>
          <AntSwitch
            checked={props.row.original.status}
            onChange={() => handleDeactivate(props)}
            name="isDirectory"
          />
        </Grid>
        )
        }
      }
    //   {
    //     Header: 'Status',
    //     accessor: "deactivated",
    //     Cell:adminData[0]?.AdminType === "Coordination team" ? "" : props => {
    //       return (
    //         <Grid item>
    //           <AntSwitch
    //             checked={props.row.original.status}
    //             onChange={() => handleActiveDeactive(props)}
    //             name="isDirectory"
    //           />
    //         </Grid>
    //       )
    //     }
    //   }
    ], [adminData])
    console.log(showFilteredData, "showFilteredData")

  return (
    <>
      <div className="flex justify-between items-center">
      <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t("Search")}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        {/* <div>
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label="Filter By Category" />}
          />
        </div> */}
      </div>
      {
        showFilteredData && <Educationtable columns={userColumns} data={showFilteredData.filter((val, index) => {
          if (searchValue === "") { 
            return val
          }
          else if (val.title && val.title.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.CourseType && val.CourseType.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          // else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
          //   return val
          // }
        })} />
      }
      {/* <EducationSidebar open={open} onClose={handleClose} adminData={adminData} ResourcesData={ResourcesData} getFeedData={showResourceDetail} courseid={courseid} /> */}
    </>
  )
}

export default EducationResources;