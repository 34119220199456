import { useContext,useState } from 'react';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { userDecline } from '../../Utils/services';
import { TextareaAutosize } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const UserDeclineModal = ({
    getnewRegistraionTablesData,
    id
}) => {
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const { t } = useTranslation();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            const result = await userDecline({
                id: id,
                message: message
            });
            if (result.res.success) {
                setLoader(false)
                toast.success(result.res.message)
                closeModal();
                getnewRegistraionTablesData({
                    page: page,
                    size: postsPerPage,
                 });
            }
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('User_Decline')}</h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <TextareaAutosize
                    name="description"
                    label="Enter Description"
                    placeholder={t('Whydoyouwanttodeclinethisuser')}
                    minRows={5}
                    className="w-full"
                    value={message}
                    onChange={ (e) => setMessage(e.target.value)}
                />
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                        {t('Decline')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UserDeclineModal;