import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState } from 'react';
import { addCoursePost, addFeedPost, addForumPost, addSessionPost, addSubSessionPost, EditSubSessionPost, getAdminDetails, getTags, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { CountryOPtion, CourseOptions, CourseType } from '../Common/Option';
import { useTranslation } from 'react-i18next';

const EditSubSession = ({SessionId, CourseId, getFeedData, title, data,courseidid }) => {    
    console.log(data.QuestionData[0]?.Question, "data.QuestionData[0]?.Question")
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState(data ? data : {});
    const [FormQuestion, setFormQuestion] = useState(data.QuestionData[0]);
    const [imgFile, setImgFile] = useState('');
    const [imgFile2, setImgFile2] = useState('');
    const [imgFile3, setImgFile3] = useState('');
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo")
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [CourseType, setCourseType] = useState(data?.SessionType);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
    const [showImgError, setshowImgError] = useState(false);
    const [VideoFile, setVideoFile] = useState("");
    const [videoValidation, setVideoValidation] = useState("");
    const [pdfValidation, setPdfValidation] = useState("");
    const [disablePdf, setDisablePdf] = useState(false);
    const [disableVideo, setDisableVideo] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const {t} = useTranslation();

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }

    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }
  
//   useEffect(() =>{
//     let questiondata = {}
//      questiondata = data && data.QuestionData.map((item)=>{
//        return item?.Question
//     })
//     
//     setFormQuestion(questiondata)
//   },[])
//   console.log(FormQuestion,"FormQuestion")
  
    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData()
        // getCountryData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    const onInputSectorsChange = (event, newInputValue, name) => { 
        setCourseType({
          ...CourseType,
          [name]: newInputValue,
        });
      };

    const handleImgFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile2 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile2(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile2("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile3 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile3(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile3("");
                    setshowImgError(true)
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    const onChangequestionHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormQuestion({ ...FormQuestion, [name]: value })
    }

    const handlePostSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        if( CourseType === "Test"){
        const postFormData = new FormData();
        postFormData.append("title", formData?.title ? formData.title : "");
        // postFormData.append("Question", FormQuestion?.Question ? FormQuestion?.Question : "" );
        postFormData.append("id", data?._id);
        postFormData.append("SessionType", CourseType ? CourseType : data?.SessionType);
        // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
        try {
            const result = await EditSubSessionPost(postFormData);
            if (result.res.success) {
                toast.success(result.res.message);
                closeModal();
                getFeedData(courseidid);
                setLoader(false);
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    } else if(CourseType === "Video"){
        const postFormData = new FormData();
        postFormData.append("title", formData?.title ? formData.title : "");
        postFormData.append("Question", FormQuestion?.Question ? FormQuestion?.Question : "" );
        postFormData.append("subsessionId", data?._id);
        postFormData.append("questionId", data.QuestionData[0]?._id);
        postFormData.append("SessionType", CourseType ? CourseType : data?.SessionType);
        postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
        try {
            const result = await EditSubSessionPost(postFormData);
            if (result.res.success) {
                setTimeout(() => {
                    toast.success(result.res.message);
                    closeModal();
                    getFeedData(courseidid);
                    setLoader(false);
                    console.log(result.res, "This is result response")
                }, 50000);
               
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    } else if(CourseType === "Pdf"){
        const postFormData = new FormData();
        postFormData.append("title", formData?.title ? formData.title : "");
        postFormData.append("Question", FormQuestion?.Question ? FormQuestion?.Question : "" );
        postFormData.append("subsessionId", data?._id);
        postFormData.append("questionId", data.QuestionData[0]?._id);
        postFormData.append("SessionType", CourseType ? CourseType : data?.SessionType);
        postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
        try {
            const result = await EditSubSessionPost(postFormData);
            if (result.res.success) {
                setTimeout(() => {
                    toast.success(result.res.message);
                closeModal();
                getFeedData(courseidid);
                setLoader(false);
                console.log(result.res, "This is result response")
                }, 50000);
             
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const onAdminChangeHandler = e => {
        e.preventDefault()
        setCountry([]);
        const value = e.target.value
        setCourseType(value);
      
    }
    const handleVideoFile = async (val) => {
        if(val.size <= 200 * 1024 * 1024) {
          setVideoFile(val);
          setMediaType("video");
        } else {
            setVideoValidation(`${t("Video_sizeshouldbelessthan200MB")}`);
          setVideoFile("");
        }
        if (val?.name !== "") {
          setDisablePdf(true);
        }
      };

      const handlePdfFile = (val) => { 
        if(val.size <= 50 * 1024 * 1024) {
          setPdfFile(val);
          setMediaType("file");
        } else {
          setPdfFile("");
          setPdfValidation(`${t("Pdf_sizeshouldbelessthan50MB")}`);
        }
       
        if (val.name !== "") {
          setDisableVideo(true);
        }
      };
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
            <RadioGroup aria-label="CourseType" name="CourseType" className="radio-group" defaultValue={CourseType} onChange={e => onAdminChangeHandler(e)}>
                        {
                             CourseType === "Video" ?
                             <FormControlLabel value="Video" control={<Radio />} label={t('Video')} /> :
                              CourseType === "Test" ?
                              <FormControlLabel value="Test" control={<Radio />} label={t('Test')} /> :
                              CourseType === "Pdf" ? 
                              <FormControlLabel value="Pdf" control={<Radio />} label={t('Pdf')} /> : null
                        }   
                       
                       
                       
                        {/* <FormControlLabel value="Coordination team" control={<Radio />} label="Coordination team" /> */}
                    </RadioGroup>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="title"
                            value={formData.title || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                    { CourseType === "Video" ?
                     <div className='col-span-2'>
                     <TextareaAutosize
                         name="Question"
                        //  defaultValue={data.QuestionData[0]?.Question}
                         value={FormQuestion?.Question || ''}
                         onChange={(e) => onChangequestionHandler(e)}
                         className="w-full"
                         id="postname"
                         aria-label="Empty"
                         placeholder={t('Video_Title')}
                         minRows={3}
                     />
                 </div> : 
                 CourseType === "Pdf" ?
                 <div className='col-span-2'>
                     <TextareaAutosize
                         name="Question"
                         value={FormQuestion?.Question}
                        //  defaultValue={data.QuestionData[0]?.Question}
                         onChange={(e) => onChangequestionHandler(e)}
                         className="w-full"
                         id="postname"
                         aria-label="Empty"
                         placeholder={t('Pdf_Title')}
                         minRows={3}
                     />
                 </div> : null
                   }
                             <div className='col-span-2'>
                    <div className="mt-12 w-full">
          <Grid container alignItems="center" spacing={1}>
            {CourseType === "Video" ||
            CourseType === "Pdf" ? (
              <Grid item md={2}>
                <span className="text-paraText">{t('Add_Media')}</span>
              </Grid>
            ) : null}
            {CourseType === "Video" ? (
              <Grid item md={4}>
                <div className="relative w-auto overflow-hidden">
                  <TextField
                    id="profilephoto"
                    label={t('Add_a_Video')}
                    value={VideoFile.name || ""}
                    className="w-full text-xs"
                  />
                  <input
                    disabled={disableVideo}
                    type="file"
                    accept="video/*"
                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                    name="video-file"
                    onChange={(e) => handleVideoFile(e.target.files[0])}
                  />
                  <div className="floating-box">+</div>
                </div>
                {videoValidation && videoValidation && (
                  <p className="text-red-600">{videoValidation}</p>
                )}
                <div className="media-box mt-2">
                  <img src="../../assets/video.svg" alt="" />
                  {t('Video')}
                </div>
                <div className="note">
                  <span>{t('Video_sizeshouldbelessthan200MB')}</span>
                </div>
              </Grid>
            ) : null}

            <Grid item md={2} className="flex items-center justify-center">
              {/* <span className="text-muteText">or</span> */}
            </Grid>
            {CourseType === "Pdf" ? (
              <Grid item md={4}>
                <div className="relative w-auto overflow-hidden">
                  <TextField
                    id="profilephoto"
                    label={t('Add_a_PDF_File')}
                    value={pdfFile.name || ""}
                    className="w-full text-xs"
                  />

                  <input
                    disabled={disablePdf}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                    name="pdf-file"
                    onChange={(e) => handlePdfFile(e.target.files[0])}
                  />
                  <div className="floating-box">+</div>
                </div>
                {pdfValidation && pdfValidation && (
                  <p className="text-red-600">{pdfValidation}</p>
                )}
                <div className="media-box mt-2">
                  <img src="../../assets/photos.svg" alt="" />
                  {t('Files')}
                </div>
                <div className="note">
                  <span>{t('Pdf_sizeshouldbelessthan50MB')}</span>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
        </div>
                    {/* <div className='editorSectionStart col-span-2'>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />
                    </div> */}
                    {/* <Autocomplete
                       id="sectors"
                       options={CourseOptions?.sort(dynamicSort("name"))}
                       getOptionLabel={(option) => option?.value || ""}
                       sx={{ width: 300 }}
                       value={CourseType?.value}
                       onChange={(e, newVal) => onInputSectorsChange(e, newVal, "TopicType")}
                       renderInput={(params) => <TextField {...params} label="TopicType" />}
                     /> */}
                   
                       {/* <TextField
                       id="CourseDuration"
                       label="Course Duration"
                       name="CourseDuration"
                       value={formData.CourseDuration || ''}
                       onChange={(e) => onChangeHandler(e)}
                      />
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label="Tags" />}
                    /> */}
                     
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={4}>
                        {/* <Grid item md={1}>
                            <span className="text-paraText">Add Media</span>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 1"
                                    value={imgFile.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 2"
                                    value={imgFile2.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile2(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 3"
                                    value={imgFile3.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile3(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <p className='mt-4 text-sidenav'> Note : Image should be 400 x 400px</p> */}
                    </Grid>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                    {t('Submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditSubSession