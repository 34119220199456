// import UsersTable from '../Users/UsersTable';
import DirectoryTable from '../Directory/DirectoryTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { InviteAllPeopleNotification, InviteNotification, userDeactivate,deleteDirectoryPostApi,Get100msviewCode } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import EditDirectoryForm from '../ModalTemplate/EditDirectoryForm';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';

const RecordEvent = ({ data, getTablesData, adminData,Count }) => {  
  const [searchValue, setSearchValue] = useState("");
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;




  const history = useNavigate();
  const { t } = useTranslation();


  const delRequest = async (id) => { 
  

    try {
        const result = await Get100msviewCode({
            "asset_id": id
        });
        
        if (result.res.success) {
            window.open(result?.res?.data?.recording?.url, "_blank");
        }
    }
    catch (e) {
       // setLoader(false)
        console.log(e);
        toast.error(e.message);
    }
}



  // console.log(data,"userData")
  let AdminType = localStorage.getItem('AdminType');
  const userColumns = useMemo(
    
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
    //   {
    //     Header: `${t('Users')}`,
    //     accessor: userData => (  
         
    //       <div className="flex justify-start items-center">
    //         <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
    //         <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
    //       </div>
    //     ),
    //   },
    //   {
    //     Header: `${t('RecordingId')}`,
    //     accessor: data => { 
    //         return (
    //             <span>{data?.id}</span>
    //         )
    //     }
    // },
      {
        Header: `${t('Recording Create date')}`,
        accessor: data => { 
            return (
               // <span>{data?.created_at}</span>
                <span>{new Date(data?.created_at).toDateString()}</span>
            )
        }
    },
      {
        Header: `${t('status')}`,
        accessor: data => { 
            return (
                <span>{data?.status}</span>
            )
        }
    },
      {
        Header: `${t('size')}`,
        accessor: data => { 
            return (
                <span>{data?.size}</span>
            )
        }
    },
    
//       {
//         Header: `${t('Description')}`,
//         accessor: data => { 
//             return (
//                 <span>{data?.description}</span>
//             )
//         }
//     },
    
//     {
//       Header: `${t('Delete')}`,
//       accessor: AdminType === "Coordination team" ? "" : (data) => {
//           return (
//               <button
//                   className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
//                   type="button"
//                   key={data._id}
//                 //   onClick={() => HandleRemoveEvent(data?._id)}
//               >
//                   <span className="MuiButton-label">{t('Delete')}</span>
//               </button>
//           )
//       },
//   },
  {
    Header: `${t('View')}`,
    accessor: AdminType === "Coordination team" ? "" : (data) => { 
        return (
            <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                type="button"
                key={data.id}
                onClick={() => delRequest(data?.id)}
            >
                <span className="MuiButton-label">{t('View')}</span>
            </button>
        )
    },
},
  // {
  //   Header: `${t("Action")}`,
  //   accessor: "_id",
  //   Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
  // },
    //   {
    //     Header: 'Name',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.OrganisationName} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
      
     
      
      
      
     
      
    
    
    //   {
    //     Header: `${t("Action")}`,
    //     accessor: "_id",
    //     Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
    //   },
      // {
      //   Header: `${t("Send_SMS")}`,
      //   accessor: "",
      //   Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Send </Button>
      // },
    //   {
    //     Header: `${t("Status")}`,
    //     accessor: "deactivated",
    //     Cell: props => <Grid item>
    //       <AntSwitch
    //         checked={!props.row.original.deactivated}
    //         onChange={() => handleDeactivate(props)}
    //         name="isDirectory"
    //       />
    //     </Grid>
    //   }
    ], [])

  return (
    <>
      <div className="flex justify-between items-center">
        {/* <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div> */}
      </div>
      {
        data && <DirectoryTable columns={userColumns} data={data} 
         
        />
      }
    </>
  )
}

export default RecordEvent;