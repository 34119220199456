import PostDetail from "./Components/Common/PostDetail"
import YoutubeVideoPost from "./Components/Common/YoutubeVideoPost"
import FilePost from "./Components/Common/FilePost"
import ArticlePost from "./Components/Common/ArticlePost"
import PostPolling from "./Components/Common/PostPolling"
import VideoPlayer from "./Components/Common/VideoPlayer"
import PostDeatilhome from "./Components/Common/PostDeatilhome"




export const filterPostTypehome = (data, onClickHandler, isModalView = false, isReportBtn = false) => { 
    const isMedia = data &&  (data.media != null) && (data.media.length > 0) 
    const isImage = data &&  (data.image != null) && (data.image.length > 0) 
   // const isLink = data &&  (data.links != null) && (data.image.length >= 0) 
    if (data && data.isPoll) {
        return renderPost('poll', data, onClickHandler, isModalView, isReportBtn)

    } else if (isMedia) {
        const type = data.media[0].mediaType 
        return renderPost(type, data, onClickHandler, isModalView, isReportBtn)
    } else if (isImage) {
        const type = data.image[0].mediaType || data.newsfeedtype
        return renderPost(type, data, onClickHandler, isModalView, isReportBtn)
    }
    else {
        return renderPost('articlePost', data, onClickHandler, isModalView, isReportBtn)
    }
}


export const renderPost = (type, data, onClickHandler, isModalView, isReportBtn) => { 
    switch (type) {
        case 'photo':
            return (
                <PostDeatilhome
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )         
        case 'link':
            return (
                <YoutubeVideoPost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )
        case 'video':
            return (
                <VideoPlayer
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'file':
            return (
                <FilePost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'articlePost':
            return (
                <ArticlePost
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )

        case 'poll':
            return (
                <PostPolling
                    data={data}
                    onClickHandler={onClickHandler}
                    isReportBtn={isReportBtn}
                    isModalView={isModalView}
                />
            )
    }

}