export const LOGIN = "admin-signin";
export const LOGIN_FIRST = "signin-first";
export const ADD_ADMIN = "add-admin";
export const REGION_COUNRTY_LIST = "data/countries-regions";
export const BADGES = "data/badges"
export const PARTIES = "data/party"
export const ALL_USER = "people"
export const ALL_USERDATA = "notification/chat-notification"
export const GET_ADMIN_DATA = "data/admin-details"
export const GET_COUNTRIES = "master/countries"
export const GET_REGIONS = "master/regions"
export const GET_PARTIES = "master/party"
export const RESET_PASSWORD = "reset-password"
export const REFRESH_TOKEN = "refresh"
export const GET_HUBS = "master/hubs"
export const GET_EXPERTISE = "master/expertise"
export const ADD_EXPERTISE = "master/add-expertise"
export const ADD_COUNTRY = "master/add-country"
export const EDIT_EXPERTISE = "master/edit-expertise"
export const DELETE_EXPERTISE = "master/remove-expertise"
export const ADD_REGION= "master/add-region"
export const DELETE_REGION = "master/remove-region"
export const EDIT_REGION = "master/edit-region"
export const DELETE_COUNTRY = "master/remove-country"
export const EDIT_COUNTRY = "master/edit-country"
export const GET_MEMBERS = "data/all-members"
export const GET_MEMBERS_BY_ID = "data/hub-members"
export const ADD_HUB = "master/add-hub"
export const EDIT_HUB = "master/edit-hub"
export const GET_SINGLE_HUB = "data/hub"
export const DELETE_HUB = "master/remove-hub"
export const ADD_MINI_HUB = "master/add-minihub"
export const GET_SINGLE_MINI_HUB = "data/minihub"
export const DELETE_MINI_HUB = "master/remove-minihub"
export const ADD_SECTION = "master/add-section"
export const GET_SINGLE_SECTION = "data/section"
export const ADD_AFFILIATION ="master/add-party"
export const DELETE_AFFLIATION = "master/remove-party"
export const GET_SINGLE_PARTY =(id)=> `data/single-party/${id}`
export const EDIT_AFFILIATION = 'master/edit-party'
export const ADD_USER = "add-user"
export const GET_FEED ="feeds/all"
export const ADD_FEED_POST = "feeds/add-post"
export const ADD_STORY_POST = "resources/addStories"
export const ADD_NEWSFEED_POST = "feeds/add-news"
export const ADD_FEED_POLL = "feeds/add-poll"
export const HOME_DATA = "/home"

export const GET_MANAGE_FLAG = "flagged/manage"
export const KEEP_POST = "flagged/keep"
export const GALLERY_KEEP_POST = "flagged/keepPost"
export const STORY_KEEP_POST = "flagged/keepStory"
export const DELETE_MANAGE_FLAG = "flagged/remove"
export const DELETE_GALLERY_FLAG = "flagged/removePost"
export const DELETE_STORY_FLAG = "flagged/removeStory"
export const GET_REMOVED_FLAG = "flagged/removed"
export const FLAG_REINSTATE = "flagged/reinstate"
export const FLAG_GALLERY_REINSTATE = "flagged/reinstatePost"
export const FLAG_STORY_REINSTATE = "flagged/reinstateStory"
export const EVENT_DATA = "event"
export const ADD_EVENT = "event/add-event"
export const GET_MINIHUBS = "master/minihubs"
export const EDIT_ADMIN = "edit-admin";
export const CHANGEPASSWORD_API = "changePassword";
export const OTPVERIFICATION_API = "otpVerification";
export const OTPVERIFICATION_API_LOGIN = "verify-second";
export const FORGOTPASSWORD_API = "forgotPassword";
export const ADMIN_DETAILS = "data/profile";
export const GET_REQUESTDATA = "request";
export const APPROVE_PEOPLE = "request/approvePeople";
export const APPROVE_Calendar = "request/approveEvent";
export const DECLINE_PEOPLE = "request/decline";
export const DIRECTORY_ACCESS = "user/access";
export const EDIT_MINIHUB = "master/edit-minihub";
export const EDIT_SECTION = "master/edit-section";
export const EDIT_USER = "edit-user";
export const EDIT_FAQ = "edit-Faq";
export const EDIT_ABOUT = "edit-about-us";
export const EDIT_CONDUCT = "edit-Conduct";
export const EDIT_DISCLAIMER = "edit-Disclaimer";
export const FEEDS_SINGLEPOST = "feeds/singlePost";
export const GET_GENERAL_NOTIFICATION = "notification/general-notification"
export const ADD_GENERAL_NOTIFICATION = "notification/general"
export const GET_ORGANIZATION = "data/organization";
export const GET_THINKTANK = "data/thinktank";
export const ADD_RESOURCES = "master/section/post"
export const EDIT_RESOURCES = "master/section/edit-post"
export const DELETE_RESOURCES = "master/section/remove-post"
export const INVITE_NOTIFICATION = "invite"
export const INVITE_ALL_NOTIFICATION = "inviteAll"
export const EDIT_EVENT = "event/edit-event"
export const REMOVE_EVENT = "event/remove-event"
export const FEED_GET_POLL = "feeds/getPoll"
export const FEED_GET_POST = "feeds/getPost"
export const NON_HUB_MEMBERS = "data/non-hub-members"
export const ADD_NON_HUB_MEMBERS = "master/add-members"
export const MAINTENANCE_MODE = "maintenance"
export const DEACTIVATE_USER = "user/deactivate"
export const REMOVE_MEMBERS_HUB = "master/remove-members"
export const PARTY_DISABLED = "master/disable-party"
export const GET_LANGUAGE = "master/get-language"
export const GET_SECTOR = "/master/sectors"
export const GET_SECTORS = "/master/sector"
export const ADD_SECTOR = "/master/add-sector"
export const GET_TAGS = "/master/tags"
export const EDIT_SECTOOR = "/master/edit-sector"
export const ADD_TAG = "/master/add-tag"
export const EDIT_TAG = "/master/edit-tag"
export const GET_FORUMS = "/forums/getAllThread"
export const ADD_FORUMS = "/forums/addThread"
export const DELETE_FORUMS = "forums"
export const EDIT_FORUM = "/forums/editThread"
export const EDIT_GALLERY = "/feeds/editPost"
export const GET_GALLERY = "/feeds/all"
export const USER_APPROVED = "/user/approved"
export const USER_DECLINE = "/user/decline"
export const SINGLE_FORUM = "/forums/getThread"
export const SINGLESTORY_FORUM = "/resources/getSingleStory"
export const MANAGE_POST = "flagged/managePost"
export const MANAGESTORY_POST = "flagged/manageComReplyStory"
export const REMOVED_POST = "flagged/removedPost"
export const REMOVEDSTORY_POST = "flagged/RemovedStory"
export const GET_SEGMENT = "master/get-segment"
export const ADD_SEGMENT = "master/add-segment"
export const EDIT_SEGMENT = "master/edit-segment"
export const RESOURCES_CATEGORY = "resources/getcategory"
export const ADD_RESOURCES_DATA = "resources/addResource"
export const GET_RESOURCES = "resources/getResource"
export const DELETE_GALLERY_POST = "feeds/post/delete"
export const DELETE_RESOURCES_API = "resources/deleteResource"
export const DELETE_TOOLKIT_API = "resources/deleteToolkit"
export const EDIT_RESOURCES_API = "resources/editResource"
export const EDIT_TOOLKIT_API = "resources/editToolkit"
export const STATUS_RESOURCES_API = "resources/ResourceStatus"
export const STATUS_TOOLKIT_API = "resources/ToolkitStatus"
export const STATUS_STORY_API = "resources/StoryStatus"
export const STATUS_GALLERY_API = "feeds/postStatus"
export const STATUS_FORUM_API = "forums/threadStatus"
export const ADD_COMMENT_FORUM = "forums/commentThreadByAdmin"
export const ADD_COMMENT_STORY = "resources/commentStoriesByAdmin"
export const DELETE_COMMENT_FORUM = "forums/deleteCommentByAdmin"
export const GET_TOOLKITS = "resources/getToolkit"
export const ADDTOOL_RESOURCES_DATA = "resources/addToolkit"
export const GET_STORY = "resources/getStories"
export const GET_NEWSFEED = "feeds/getNewsfeed"
export const EDIT_STORY = "resources/editStory"
export const NEWSFEEDEDIT_STORY = "feeds/edit-news"
export const DELETE_STORY_POST = "resources/deleteStory"
export const DELETE_NOTIFICATION_POST = "notification/delete/general"
export const DELETE_NEWSFEED_POST = "feeds/remove-news"
export const DELETE_REPLYCOMMENT_FORUM = "forums/deleteCommentByAdmin"
export const ADD_GALLERYCOMMENT_FORUM = "feeds/comment"
export const DELETE_COMMENT_GALERY = "feeds/delComment"
export const DELETE_COMMENT_STORY = "resources/StorydelCommentReplyByAdmin"
export const SEND_MESSAGE = "addMessage"
export const GET_MESSAGE = "getMessages"
export const DELETE_SECTOR = "master/delete-sector"
export const DELETE_TAG = "master/delete-tag"
export const ALL_DIRECTORIES = "Directory/get-directory"
export const GET_CATEGORYLIST = "Directory/get-category"
export const ADD_DIRECTORY = "Directory/add-directory"
export const ADD_CATEGORY = "master/addDirectoryCategory"
export const GET_SUGGEST_DATA = "forums/getsuggention"
export const GET_ADMINFAQ_DATA = "get-Faq"
export const ADD_FAQ = "add-Faq"
export const DELETE_FAQ_POST = "delete-Faq"
export const GET_ADMINABOUT_DATA = "get-about-us"
export const DELETE_ABOUT_POST = "delete-about-us"
export const GET_ADMINCONDUCT_DATA = "get-Conduct"
export const GET_ADMINDISCLAIMER_DATA = "get-Disclaimer"
export const DELETE_DISCLAIMER_POST = "delete-Disclaimer"
export const DELETE_CODE_POST = "delete-Conduct"
export const ADD_ABOUT = "add-about-us"
export const ADD_CONDUCT = "add-Conduct"
export const ADD_DISCLAIMER = "add-Disclaimer"
export const DELETE_DIRECTORY_POST = "Directory/delete-directory"
export const EDIT_DIRECTORY = "Directory/edit-directory"
export const GET_SEGMENTS = "master/segment"
export const DELETE_CATEGORY_POST = "master/deleteDirectoryCategory"
export const EDIT_CATEGORY = "master/editDirectoryCategory"
export const GET_GALLERYCOMMENT_DATA = "feeds/getcomment"
export const GET_STORIESCOMMENT_DATA = "resources/getcommentStoriesByAdmin"
export const GET_FOURAMCOMMENT_DATA = "forums/showThreadCommentsByAdmin"
export const CHAT_USERDATA = "getUser"
export const SUGGESTEAD_DELETE = "forums/DeleteSuggestTopic"
export const GET_EDUCATION = "Course/get-course"
export const ADD_COURSE_POST = "Course/add-course"
export const GET_EDUCATIONSESSION_DATA = "Course/get-session"
export const ADD_SESSION_POST = "Course/add-session"
export const ADD_SUBSESSION_POST = "Course/add-sub-session"
export const GET_EDUCATIONSUBSESSION_DATA = "Course/get-sub-session"
export const GET_EDUCATIONQUESTIONDATA_DATA = "Course/get-question"
export const ADD_QUESTION_POST = "Course/add-question"
export const DELETE_COURSE_POST = "Course/delete-course"
export const DELETE_COURSESESSION_POST = "Course/delete-session"
export const DELETE_COURSESUBSESSION_POST = "Course/delete-sub-session"
export const DELETE_QUESTION_POST = "Course/delete-question"
export const GET_REPORTEDUSER_DATA = "get-repoted-users"
export const ADD_READ_NOTIFICATION = "notification/read-notification"
export const EDIT_COURSE_POST = "Course/edit-course"
export const Edit_SESSION_POST = "Course/edit-session"
export const Edit_SUBSESSION_POST = "Course/edit-sub-session"
export const EDIT_QUESTION_POST = "Course/edit-question"
export const NEWREGISTRATION_USER = "get-newUsers"
export const NEWDECLINEREGISTRATION_USER = "get-Decline-Users"
export const DEACTIVATE_ADMIN = "statusAdmin"
export const LOGOUT = "logout"
export const GET_FOURAMMEDIA_DATA = "forums/getThread"
export const GET_STORYMEDIA_DATA = "resources/getSingleStory"
export const GET_GALLERYPOLL = "/forums/getPoll"
// export const ADD_GALERY_POLL = "/feeds/add-poll"
export const ADD_GALERY_POLL = "/forums/add-poll"
export const DEACTIVATE_COURSE = "Course/courseStatus"
export const PREVIEW_PDF = "/View"
export const GETPOLLDATA = "forums/singlePoll"
export const DELETEMESSAGE = "chatDelete"
export const GETFOURAMDETAIL = "Dashboard/get-forumsDetails"
export const GETGALLERYDETAIL = "Dashboard/get-galleryDetails"
export const GETRESOURCESDETAIL = "Dashboard/get-resourceDetails"
export const GETTOOLKITDETAIL = "Dashboard/get-toolkitDetails"
export const GETSTORYDETAILS = "Dashboard/get-storyDetails"
export const GETNEWSFEEDDETAILS = "Dashboard/get-newsfeedDetails"
export const GETUSERSDETAILS = "Dashboard/get-userDetails"
export const GETAGORATOKEN = "create_room"
export const GETAGORALINK = "meetingLink"
export const TRANSLATEURL = "tarnslate"
export const GETCOURSEDETAILS = "Dashboard/get-userCourses"

// get meeting code 

export const GET_ROOM_DETAIL="get_room_detail"
export const GET_100MSTOKEN_DETAIL="chat100msToken"
export const GET_100MSTOKEN_RECORDINGDETAIL="recording100ms"
export const GET_100MSTOKEN_RECORDINGDETAILVIEW="singleRecording100ms"