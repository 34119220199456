import React, { useState, useContext, useEffect } from 'react';
import { FEEDS_FORM, GALLERY_FORM, STORY_FORM} from '../ModalTemplate';
import { ModalContext } from '../../Context'
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import {fetchManageFlag, fetchManagePostFlag, fetchRemovedFlagPost,fetchStoryRemovedFlagPost, getStoryApi,fetchManageStoryPostFlag, allAdminUsers} from '../../Utils/services';
import { toast } from 'react-toastify';
import Storypage from './Storypage';
import ManageStory from './ManageStory';
import RemovedFlagStory from './RemovedFlagStory';
// import ManageGallery from './ManageGallery';
// import RemovedFlagGallery from './RemovedFlagGallery';
import { FunctionStoryContext } from '../../Context';
import { useTranslation } from 'react-i18next';

const a11yProps = (index) => {
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Story = () => {
    const {t} = useTranslation();
    const {postStoryDatas, getStoryDataApi,paginationpostStoryDatas} = useContext(FunctionStoryContext)
    const [tabsValue, setTabsValue] = useState(0);
    // const [postData, setPostData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [totalCount, setotalCount] = useState(0)
    const [flagData, setFlagData] = useState([])
    const [removedData, setRemovedData] = useState([]);
    const [adminData, setAdminData] = useState([]);


    const context = useContext(ModalContext)
    const { handleModalData } = context;
    // const getForumsDataApi = async (data) => {
    //     try {
    //         const result = await getStoryApi(data);
    //         setPostData(result.res.data.reverse())
    //     }
    //     catch (error) {
    //         setLoader(false)
    //         console.log(error)
    //         toast.error(error.message);
    //     }
    // }
    useEffect(() => {
        setotalCount(paginationpostStoryDatas && paginationpostStoryDatas.totalCount)
    }, [paginationpostStoryDatas && paginationpostStoryDatas.totalCount])

    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }

    const getManageFlag = async () => {
        try {
            const res = await fetchManageStoryPostFlag()
            
            let _data = res?.data?.commentdata;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => { 
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData =  result[0] ? result[0].concat(res?.data?.flaggedReply) : res?.data?.flaggedReply
            setFlagData(_AllData);
            // toast.success(res.message)
        } catch (error) { 
            toast.error(error.message)
        }
    }
    const getRemovedFlag = async () => {
        try {
            const res = await fetchStoryRemovedFlagPost();
            let _data = res?.data?.removedComment;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = result[0].concat(res?.data?.flaggedReply)
            setRemovedData(_AllData);
            // toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() =>{
        getAdminTableData()
        getStoryDataApi();
        getManageFlag();
    }, []);

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
        var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
        window.history.pushState({ path: newurl }, '', newurl);

    };
    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(()=>{
        var idValue = getParameterByName('id');
        if(idValue){
            setTabsValue(parseInt(idValue))
        }
    },[])
    const tabsData = [
        {
            label: `${t("Stories")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: <Storypage totalCount ={totalCount}  data={postStoryDatas} getStoryDataApi={getStoryDataApi} adminData={adminData} />
        },
        {
            label: `${t("Manage_comments")}`,
            isNotshowAdd: true,
            data: <ManageStory flagData={flagData} getManageFlag={getManageFlag} adminData={adminData}  />
        },
        {
            label: `${t("Removed_comments")}`,
            isNotshowAdd: true,
            data: <RemovedFlagStory flagData={removedData} getManageFlag={getRemovedFlag} adminData={adminData} />
        }
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(STORY_FORM(true, getStoryDataApi));
                break;

            default:
                handleModalData(STORY_FORM(true, getStoryDataApi));
                break;
        }
    }
    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />
            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}
export default Story;

