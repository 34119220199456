import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  addCoursePost,
  addFeedPost,
  addForumPost,
  addQuestionPost,
  getAdminDetails,
  getTags,
  regionCountryList,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
  CountryOPtion,
  CourseOption,
  CourseType,
  Questiontype,
} from "../Common/Option";


const AddQuestion = ({ QuestionType, getFeedData, title, questiontype,courseidid }) => { 
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [formData, setFormData] = useState({});
  const [imgFile, setImgFile] = useState("");
  const [imgFile2, setImgFile2] = useState("");
  const [imgFile3, setImgFile3] = useState("");
  const [loader, setLoader] = useState(false);
  const [mediaType, setMediaType] = useState("photo");
  const [tags, setTags] = useState([]);
  const [tagsValue, setTagsValue] = useState([]);
  const [CourseType, setCourseType] = useState(questiontype === "Pdf" || questiontype === "Video" ? [ ] : "Single");
  const [Answer, setAnswer] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [article, setArticle] = useState("");
  const [showImgError, setshowImgError] = useState(false);
  const [VideoFile, setVideoFile] = useState("");
  const [videoValidation, setVideoValidation] = useState("");
  const [pdfValidation, setPdfValidation] = useState("");
  const [disablePdf, setDisablePdf] = useState(false);
  const [disableVideo, setDisableVideo] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [serviceLists, setServiceLists] = useState([{ services: "" }]);
  const {t} = useTranslation();
  // const [flavors, setFlavors] = useState({
  //   A: true,
  //   B: false,
  //   C: false,
  //   D: false,
  //   E: false,
  //   F: false,
  //   G: false,
  // });
  const [flavors, setFlavors] = useState([]);


  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };
  console.log(serviceList, "serviceList");
  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };
  const handleServiceChanges = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceLists];
    list[index][name] = value;
    setServiceLists(list);
  };
  console.log(serviceLists[0]?.links, "serviceList");
  const handleServiceRemoves = (index) => {
    const list = [...serviceLists];
    list.splice(index, 1);
    setServiceLists(list);
  };

  const handleServiceAdds = () => {
    setServiceLists([...serviceLists, { services: "" }]);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let convertedData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setArticle(convertedData);
  };

  const embedVideoCallBack = (link) => {
    link = link.replace("watch?v=", "embed/");
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
    return link;
  };

  // const getCountryData = async () => {
  //     try {
  //         const result = await regionCountryList();
  //         setCountries(result?.res?.data?.country)
  //     }
  //     catch (error) {
  //         console.log(error);
  //         toast.warning("Something went wrong!")
  //     }
  // }
  const adminId = localStorage.getItem("adminId");

  const getAdminDetailsApiData = async () => {
    try {
      const result = await getAdminDetails({
        id: adminId,
        type: "admin",
      });
      setCountries(result.data.country);
    } catch (error) {
      console.log(error);
    }
  };
  const geTagsApi = async () => {
    try {
      const result = await getTags();
      if (result.res.success) {
        setTags(result.res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAdminDetailsApiData();
    // getCountryData();
    geTagsApi();
  }, []);

  const onInputCountryChange = (event, newInputValue, name) => {
    setCountry({
      ...country,
      [name]: newInputValue,
    });
  };

  const onInputTagsChange = (event, newInputValue, name) => {
    setTagsValue({
      ...tagsValue,
      [name]: newInputValue,
    });
  };

  const onInputSectorsChange = (event, newInputValue, name) => {
    setCourseType({
      ...CourseType,
      [name]: newInputValue,
    });
  };

  const handleImgFile = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile("");
          setshowImgError(true);
        }
      };
    }
  };
  const handleImgFile2 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile2(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile2("");
          setshowImgError(true);
        }
      };
    }
  };
  const handleImgFile3 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile3(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile3("");
          setshowImgError(true);
        }
      };
    }
  };

  const context = useContext(ModalContext);
  const { closeModal } = context;

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handlePdfFile = (val) => { 
    if(val.size <= 50 * 1024 * 1024) {
      setPdfFile(val);
      setMediaType("file");
    } else {
      setPdfFile("");
      setPdfValidation(`${t("Pdf_sizeshouldbelessthan50MB")}`);
    }
   
    if (val.name !== "") {
      setDisableVideo(true);
    }
  };

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleVideoFile = async (val) => {
    if(val.size <= 200 * 1024 * 1024) {
      setVideoFile(val);
      setMediaType("video");
    } else {
      // setVideoValidation("Video size should be less than 200 MB");
      setVideoValidation(`${t("Video_sizeshouldbelessthan200MB")}`);
      setVideoFile("");
    }
    if (val?.name !== "") {
      setDisablePdf(true);
    }
  };

  const handlePostSubmit = async (e) => {
  
    console.log(serviceLists[0]?.links, "serviceLists[0]?.links")

    setLoader(true);
    e.preventDefault();
    const tagsData = tagsValue?.tags?.map((data) => {
      return data._id;
    });
    const countryData = country?.country?.map((data) => {
      return data._id;
    });
    let linksdata = serviceList.map((item) => {
      return item.links;
    });
    console.log(linksdata, "linksdata");
    let linksdatas = serviceLists.map((item) => {
      return item.links;
    });
    // console.log(flavors, "flavors");

    let postFormData;
    
    if (CourseType === "Single") {
      postFormData = new FormData();
      postFormData.append(
        "Question",
        formData.question ? formData.question : ""
      );
      postFormData.append("MultipleAns", Answer ? Answer : "");
      postFormData.append("options", linksdata ? linksdata : "");
      postFormData.append("CourseId", QuestionType?.courseId);
      postFormData.append("SessionId", QuestionType?.sessionId);
      postFormData.append("sub_SessionId", QuestionType?._id);
      // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
      postFormData.append("QuestionType", CourseType ? CourseType : "");
      postFormData.append("hint", formData.Hint ? formData.Hint : "");
    } else if(CourseType === "Multiple"){
      postFormData = new FormData();
      postFormData.append(
        "Question",
        formData.question ? formData.question : ""
      );
      postFormData.append("MultipleAns", flavors ? flavors : "");
      postFormData.append("options", linksdata ? linksdata : "");
      postFormData.append("CourseId", QuestionType?.courseId);
      postFormData.append("SessionId", QuestionType?.sessionId);
      postFormData.append("sub_SessionId", QuestionType?._id);
      // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
      postFormData.append("QuestionType", CourseType ? CourseType : "");
      postFormData.append("hint", formData.Hint ? formData.Hint : "");
    }else if (CourseType === "video") {
      postFormData = new FormData();
      postFormData.append(
        "Question",
        formData.question ? formData.question : ""
      );
      // postFormData.append("Answer", formData.Answer ? formData.Answer : "");
      // postFormData.append("options", linksdata ? linksdata : "");
      postFormData.append("CourseId", QuestionType?.courseId);
      postFormData.append("SessionId", QuestionType?.sessionId);
      postFormData.append("sub_SessionId", QuestionType?._id);
      postFormData.append(
        "media",
        VideoFile ? VideoFile : pdfFile ? pdfFile : null
      );
      postFormData.append("QuestionType", CourseType ? CourseType : "");
    } else if (CourseType === "pdf") {
      postFormData = new FormData();
      postFormData.append(
        "Question",
        formData.question ? formData.question : ""
      );
      // postFormData.append("Answer", formData.Answer ? formData.Answer : "");
      // postFormData.append("options", linksdata ? linksdata : "");
      postFormData.append("CourseId", QuestionType?.courseId);
      postFormData.append("SessionId", QuestionType?.sessionId);
      postFormData.append("sub_SessionId", QuestionType?._id);
      postFormData.append(
        "media",
        VideoFile ? VideoFile : pdfFile ? pdfFile : null
      );
      postFormData.append("QuestionType", CourseType ? CourseType : "");
    }

    try {
      const result = await addQuestionPost(postFormData);
      if (result.res.success) {
        // setTimeout(() => {
          toast.success(result.res.message);
          setLoader(false);
          getFeedData(courseidid);
          closeModal();
        // }, 5000);

        console.log(result.res, "This is result response");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  const onAdminChangeHandler = (e) => {
    e.preventDefault();
    setCountry([]);
    const value = e.target.value;
    setCourseType(value);
  };
  const onAdminChangeAnswer = (e) => {
    e.preventDefault();
    // setCountry([]);
    const value = e.target.value;
    setAnswer(value);
  };

  // const handleFlavorChange = (event) => {
  //   setFlavors({ ...flavors, [event.target.name]: event.target.checked });
  // };
  const handleFlavorChange = (e) => { 
    const value = e.target.value;
    const checked = e.target.checked;
    console.log(value,checked)
   if(checked){
    setFlavors(prev => [...prev, value])
   }
   else{
    setFlavors(prev => prev.filter((e) => (e !== value)))
  
   }
    
  };
  
  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>
      <form
        noValidate
        autoComplete="off"
        className="w-full"
        onSubmit={handlePostSubmit}
      >
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          {questiontype === "Test" && (
            <RadioGroup
              aria-label="CourseType"
              name="CourseType"
              className="radio-group"
              defaultValue={CourseType}
              onChange={(e) => onAdminChangeHandler(e)}
            >
              {/* <FormControlLabel value="video" control={<Radio />} label="video" /> */}
              <FormControlLabel
                value="Single"
                control={<Radio />}
                label={t('Single')}
              />
              {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" /> */}
              <FormControlLabel
                value="Multiple"
                control={<Radio />}
                label={t('Multiple')}
              />
            </RadioGroup>
          )}
          {questiontype === "Video" && (
            <RadioGroup
              aria-label="CourseType"
              name="CourseType"
              className="radio-group"
              defaultValue={CourseType}
              onChange={(e) => onAdminChangeHandler(e)}
            >
              <FormControlLabel
                value="video"
                control={<Radio />}
                label="video"
              />
              {/* <FormControlLabel value="Single" control={<Radio />} label="Single" /> */}
              {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" />
                      <FormControlLabel value="Multiple" control={<Radio />} label="Multiple" /> */}
            </RadioGroup>
          )}
          {questiontype === "Pdf" && (
            <RadioGroup
              aria-label="CourseType"
              name="CourseType"
              className="radio-group"
              defaultValue={CourseType}
              onChange={(e) => onAdminChangeHandler(e)}
            >
              {/* <FormControlLabel value="video" control={<Radio />} label="video" /> */}
              {/* <FormControlLabel value="Single" control={<Radio />} label="Single" /> */}
              <FormControlLabel value="pdf" control={<Radio />} label="pdf" />
              {/* <FormControlLabel value="Multiple" control={<Radio />} label="Multiple" /> */}
            </RadioGroup>
          )}
          <div className="col-span-2">
            <TextareaAutosize
              name="question"
              value={formData.question || ""}
              onChange={(e) => onChangeHandler(e)}
              className="w-full"
              id="postname"
              aria-label="Empty"
              placeholder={t('Question')}
              minRows={3}
            />
          </div>
          {QuestionType.SessionType === "Test" ? (
            <>
         
              {/* <div className='col-span-2'> */}
              {/* <TextField
                         className="w-full"
                       id="Option"
                       label="Option"
                       name="Option"
                       value={formData.Option || ''}
                       onChange={(e) => onChangeHandler(e)}
                       minRows={3}
                      /> */}
              {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="first-division">
                    <TextField
                      label={t('Add_Option')}
                      name="links"
                      type="text"
                      id="otherLink"
                      value={singleService.otherLink}
                      onChange={(e) => handleServiceChange(e, index)}
                    />
                    {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_More_Option')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('REMOVED')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {/* </div> */}

              {
              CourseType === "Single" &&
              <div className=" col-span-2">
               

              {/* <TextField
                className="w-full"
                id="Answer"
                label="Answer"
                name="Answer"
                value={formData.Answer || ""}
                onChange={(e) => onChangeHandler(e)}
                minRows={3}
                required
              /> */}
               <FormLabel component="legend">{t('Select_Answer')}</FormLabel>
                <RadioGroup
              aria-label="Answer"
              name="Answer"
              className="radio-group"
              defaultValue={Answer}
              onChange={(e) => onAdminChangeAnswer(e)}
            >
              {
                serviceList.length === 1 ?
                <FormControlLabel value="1" control={<Radio />} label={t('one')} /> :
                serviceList.length === 2 ? 
                <>
                 <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                 <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                </> : serviceList.length === 3 ? 
                <>
              <FormControlLabel value="1" control={<Radio />} label={t('one')} />
              <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
              <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                </> : serviceList .length === 4 ? 
                <>
              <FormControlLabel value="1" control={<Radio />} label={t('one')} />
              <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
              <FormControlLabel value="3" control={<Radio />} label={t('three')} />
              <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                </> : serviceList .length === 5 ? 
                  <>
                  <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                  <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                  <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                  <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                  <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                    </> : serviceList .length === 6 ? 
                      <>
                      <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                      <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                      <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                      <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                      <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                      <FormControlLabel value="6" control={<Radio />}label={t('six')}/>
                        </> : serviceList .length === 7 ? 
                      <>
                      <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                      <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                      <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                      <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                      <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                      <FormControlLabel value="6" control={<Radio />}label={t('six')}/>
                      <FormControlLabel value="7" control={<Radio />}label={t('seven')}/>
                        </> : null
              }
           
            </RadioGroup>
            </div>
            }
             
             {  CourseType === "Multiple" &&
               <>
      <FormControl component="fieldset">
        <FormLabel component="legend">{t('Select_Multiple_Answer')}</FormLabel>
        <FormGroup>
          {
            serviceList.length === 1 ? 
            <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          /> :  serviceList.length === 2 ? 
          <>
            <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          </> : serviceList.length === 3 ? 
          <>
              <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          </> : serviceList.length === 4 ? 
          <>
                <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')} 
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          </> : serviceList.length === 5 ? 
          <>
                  <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          </> : serviceList.length === 6 ? 
          <>
                        <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.F}
                onChange={handleFlavorChange}
                value="6"
              />
            }
            label={t('six')}
          />
          </> : serviceList.length === 7 ? 
          <>
                       <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.F}
                onChange={handleFlavorChange}
                value="6"
              />
            }
            label={t('six')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.G}
                onChange={handleFlavorChange}
                value="7"
              />
            }
            label={t('seven')}
          />
          </> : null
          }
      
        </FormGroup>
        {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
        </FormHelperText> */}
      </FormControl>
               {/* {serviceLists.map((singleService, index) => (
                 <div key={index} className="services">
                   <div className="first-division">
                     <TextField
                       label="Multiple Answer"
                       name="links"
                       type="text"
                       id="otherLinks"
                       value={singleService.otherLinks}
                       onChange={(e) => handleServiceChanges(e, index)}
                       required
                     />
                     {serviceLists.length - 1 === index &&
                       serviceLists.length < 7 && (
                         <button
                           type="button"
                           onClick={handleServiceAdds}
                           className="add-btn"
                         >
                           <span>Add Multiple Answer</span>
                         </button>
                       )}
                   </div>
                   <div className="second-division">
                     {serviceLists.length !== 1 && (
                       <button
                         type="button"
                         onClick={() => handleServiceRemoves(index)}
                         className="remove-btn"
                       >
                         <span>Remove</span>
                       </button>
                     )}
                   </div>
                 </div>
               ))} */}
 
                
              </>
             }
             <div className=" col-span-2">
               

             <TextField
               className="w-full"
               id="Hint"
               label={t('Hint')}
               name="Hint"
               value={formData.Hint || ""}
               onChange={(e) => onChangeHandler(e)}
               minRows={3}
               required
             />
           </div>
            </>
          ) : null}

          {/* <Autocomplete
                       id="sectors"
                       options={Questiontype?.sort(dynamicSort("name"))}
                       getOptionLabel={(option) => option?.value || ""}
                       sx={{ width: 300 }}
                       value={CourseType?.value}
                       onChange={(e, newVal) => onInputSectorsChange(e, newVal, "CourseType")}
                       renderInput={(params) => <TextField {...params} label="CourseType" />}
                     /> */}

          {/* <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label="Tags" />}
                    /> */}
        </div>

        <div className="mt-12 w-full">
          <Grid container alignItems="center" spacing={1}>
            {QuestionType.SessionType === "Video" ||
            QuestionType.SessionType === "Pdf" ? (
              <Grid item md={2}>
                <span className="text-paraText">{t('Add_Media')}</span>
              </Grid>
            ) : null}
            {QuestionType.SessionType === "Video" ? (
              <Grid item md={4}>
                <div className="relative w-full overflow-hidden">
                  <TextField
                    id="profilephoto"
                    label={t('Add_a_Video')}
                    value={VideoFile.name || ""}
                    className="w-full text-xs"
                  />
                  <input
                    disabled={disableVideo}
                    type="file"
                    accept="video/*"
                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                    name="video-file"
                    onChange={(e) => handleVideoFile(e.target.files[0])}
                  />
                  <div className="floating-box">+</div>
                </div>
                {videoValidation && videoValidation && (
                  <p className="text-red-600">{videoValidation}</p>
                )}
                <div className="media-box mt-2">
                  <img src="../../assets/video.svg" alt="" />
                  {t('Video')}
                </div>
                <div className="note">
                  <span> {t('Video_sizeshouldbelessthan200MB')}</span>
                </div>
              </Grid>
            ) : null}

            <Grid item md={2} className="flex items-center justify-center">
              {/* <span className="text-muteText">or</span> */}
            </Grid>
            {QuestionType.SessionType === "Pdf" ? (
              <Grid item md={4}>
                <div className="relative w-auto overflow-hidden">
                  <TextField
                    id="profilephoto"
                    label={t('Add_a_PDF_File')}
                    value={pdfFile.name || ""}
                    className="w-full text-xs"
                  />

                  <input
                    disabled={disablePdf}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                    name="pdf-file"
                    onChange={(e) => handlePdfFile(e.target.files[0])}
                  />
                  <div className="floating-box">+</div>
                </div>
                {pdfValidation && pdfValidation && (
                  <p className="text-red-600">{pdfValidation}</p>
                )}
                <div className="media-box mt-2">
                  <img src="../../assets/photos.svg" alt="" />
                  {t('Files')}
                </div>
                <div className="note">
                  <span> {t('Pdf_sizeshouldbelessthan50MB')}</span>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      {  CourseType === "Multiple" &&
          <div className="flex justify-center mt-10">
          <button className="button-1 mx-auto" 
          disabled={flavors !== undefined  ? false : true  }
          >
           {t('Submit')}
          </button>
        </div>
      }
      {  CourseType === "Single" &&
          <div className="flex justify-center mt-10">
          <button className="button-1 mx-auto" 
          disabled={Answer !== undefined  ? false :  true}
          >
            {t('Submit')}
          </button>
        </div>
      }
      {  questiontype === "Pdf" || questiontype === "Video" ?
          <div className="flex justify-center mt-10">
          <button className="button-1 mx-auto" 
          disabled={loader ? true : false}
          >
             {t('Submit')}
          </button>
        </div> : null
      }
      </form>
    </>
  );
};

export default AddQuestion;
