import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState } from 'react';
import {editNewsFeedPost, getAdminDetails, getTags, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from 'react-i18next';


const EditNewsFeed = ({ forumData, getForumsDataApi ,title }) => {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(forumData?.country);
    const [formData, setFormData] = useState(forumData ? forumData?.title : "");
    const [publisher, setpublisher] = useState(forumData ? forumData?.publisher : "");
    const [formlinks, setformlinks] = useState(forumData ? forumData?.links : "");
    const [adminType, setAdminType] = useState(forumData ? forumData?.category : "");
    const [loader, setLoader] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState(forumData?.tags);


    // const getCountryData = async () => { 
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
   
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => { 
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    const onAdminChangeHandler = e => {
        e.preventDefault()
        setCountry([]);
        const value = e.target.value
        setAdminType(value);
    }
    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    const onChangeHandlers = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setpublisher({ ...publisher, [name]: value })
    }
    const onChangeHandlerss = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setformlinks({ ...formlinks, [name]: value })
    }
    console.log(formData, "Form Data")

    const handlePostSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        let tagsData;
        if (tagsValue?.tags) {
            tagsData = tagsValue?.tags?.map((data) => {
                return data._id
            })
        }
        else {
            tagsData = tagsValue?.map((data) => {
                return data._id
            })
        }
        let countryData;
        if (country?.country) {
            countryData = country?.country?.map((data) => {
                return data._id
            })
        }
        else {
            countryData = country?.map((data) => {
                return data._id
            })
        }
        try {
            const result = await editNewsFeedPost({
                // 'title': formData.title ? formData.title : "",
                // 'tags': tagsData,
                // 'id': forumData?._id,
                // 'country': countryData,
                "title": formData,
                "tag": tagsData,
                "countrys": countryData,
                "category": adminType,
                "publisher": publisher,
                "links": formlinks,
                'id': forumData?._id,
            });
            if (result.res.success) {
                // toast.success(result.res.message);
                toast.success("Newsfeed edited successfully");
                closeModal();
                getForumsDataApi({
                    page: 1,
                    postsPerPage: 10
                });
                setLoader(false);
                console.log(result.res, "This is result response")
            }
            
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
               <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                <div className="mt-4 w-6/12">
                    <RadioGroup aria-label="adminType" name="adminType" className="radio-group" defaultValue={adminType} onChange={e => onAdminChangeHandler(e)}>
                        <FormControlLabel value="All" control={<Radio />} label={t('All')} />
                        <FormControlLabel value="National" control={<Radio />} label={t('National')} />
                        <FormControlLabel value="Regional" control={<Radio />} label={t('Regional')} />
                        <FormControlLabel value="International News" control={<Radio />} label={t('International_News')} />
                    </RadioGroup>
                </div>
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData}
                            onChange={(e) => setFormData(e.target.value)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                 
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        defaultValue={forumData?.country}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        defaultValue={forumData?.tags}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label={t('Tags')} />}
                    />
                </div>
                <div className="mt-12 w-6/6">
                <div className='col-span-2'>
                        <TextareaAutosize
                            name="Publisher"
                            value={publisher}
                            onChange={(e) => setpublisher(e.target.value)}
                            className="w-full"
                            id="postPublisher"
                            aria-label="Empty"
                            placeholder={t('Publisher')}
                            minRows={3}
                        />
                    </div>
                </div>
                <div className="mt-12 w-6/6">
                <div className='col-span-2'>
                        <TextareaAutosize
                            name="previewlinks"
                            value={formlinks}
                            onChange={(e) => setformlinks(e.target.value)}
                            className="w-full"
                            id="postLinks"
                            aria-label="Empty"
                            placeholder={t('Add_Link')}
                            minRows={3}
                        />
                    </div>
                </div>
            
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                    {t('submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditNewsFeed