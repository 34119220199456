import React, { useState, useEffect, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { addReadNotifictaion } from "../../Utils/services";
import { FunctionNotificationContext } from '../../Context';
import { useTranslation } from 'react-i18next';

export default function Contacts({ getTablesData, notificationdata,  currentusername, changeChat ,socket}) { 
  const [currentUserName, setCurrentUserName] = useState();
  const [CurrentUserindex, setCurrentUserindex] = useState();
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [refresh, setrefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const scrollRef = useRef(null);
  const { getNotificationData,contacts } = useContext(FunctionNotificationContext);
  const { t } = useTranslation();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const length = localStorage.getItem("Notificationlength");
  const [unreadMessages, setUnreadMessages] = useState();
  useEffect(() => {
    let filtercontact = contacts?.filter((data, index) => {
      if (data?.fromName === CurrentUserindex?.fromName) { 
        setCurrentSelected(index);
      }
    });
  }, [CurrentUserindex, contacts]);

  const changeCurrentChat = async (index, contact) => { 
    if (contact?.read) {}
    try {
      const result = await addReadNotifictaion({ Id: contact?._id });
      if (result.res.success && contact?.read) {
        setCurrentSelected(index);
        changeChat(contact);
        setCurrentUserindex(contact);
        getNotificationData();
      } else {
        getTablesData();
        setCurrentSelected(index);
        changeChat(contact);
        getNotificationData();
        setCurrentUserindex(contact);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const selectedElement = scrollRef?.current?.childNodes[currentSelected];
    selectedElement?.scrollIntoView({ behavior: 'smooth' });
  });

  const filteredContacts = contacts?.filter(contact => 
    contact?.fromName.toLowerCase().includes(searchTerm.toLowerCase())
  );


  
  // console.log(unreadMessages,"unreadMessages")

  return (
    <>
      <div className="maincontacts">
        {/* <div className="brand relative w-auto overflow-hidden">
          <input 
          className="z-50  "
            type="text" 
            placeholder={t("Search contacts...")} 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div> */}
       <div className="brand relative w-auto overflow-hidden">
  <input 
    className="search-input z-50 px-4 py-2 w-90 rounded-md border-2 border-gray-300 focus:outline-none focus:border-blue-500"
    type="text" 
    placeholder={t("Search user...")} 
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
  />
</div>
        <div className="contacts" ref={scrollRef}>
          {filteredContacts?.map((data, index) => {  
            return (
              <div className={`contact ${
                  index === currentSelected ? "selected" : ""
                }`}
                 key={uuidv4()}
                onClick={() => changeCurrentChat(index, data)}>
                <div className="username">
                  <h3>{data?.fromName}</h3>
                  {/* {unreadMessages > 0 && <span className="unread-count">{unreadMessages}</span>} */}
                  {/* {data?.read === false ? <div className="rounded-full left bg-green h-3 w-3"></div> : null} */}
                  {data?.read === false ? <span className="unread-count">{data?.unreadCount}</span> : null}
                </div>
              </div>
            );
          })}
        </div>
        <div className="current-user">
          <div className="username">
            <h3>{currentusername?.name}</h3>
          </div>
        </div>
      </div>
    </>
  );
}
