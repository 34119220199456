import { useEffect, useState, useContext,useRef } from "react";
import PostDetail from "../Common/PostDetail";
import SimpleListing from "../Common/SimpleListing";
import GridListing from "../Common/GridListing";
import demoData from "./demoData";
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { getHomeApiData } from "../../Utils/services";
import { toast } from "react-toastify";
import ReactChartPage from "./ReactChartPage";
import { ModalContext } from '../../Context'
import PostDetails from "../ModalTemplate/PostDetails"
import { filterPostTypehome } from '../../Homefunction'
import classNames from "classnames";
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import AllEvent from "./Homes";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";

const _chartData = {
    labels: [],
    datasets: [
      {
        label: "Updates",
        data: [],
        fill: true,
        backgroundColor: "rgba(217, 238, 247, 0.8)",
        borderColor: "rgba(20, 114, 249, 0.8)",
      },
    ]
};

const downward = '/assets/icons/downRedArrow.svg'
const upward = '/assets/icons/upward-arrow.png'

const EducationDetail = ({ allHomeData , getHomeAllData, loader }) => { 
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const [popularSearchData, setpopularSearchData] = useState([]);
    const [popularMembersData, setPopularMembersData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [hubData, setHubData] = useState([]);
   // const [allHomeData, setAllHomeData] = useState({});
    const [chartData, setChartData] = useState(_chartData)
    const [recentPost, setRecentPost] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [postData, setPostData] = useState([]);
    //const [loader, setLoader] = useState(true);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date())
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    useEffect(() => {
        getHomeAllData();
    }, []);

    const contentRef = useRef(null);
    const handlePrint = () => {
        const printContents = contentRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
    
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    
        // After printing, reload the page to restore the original content.
        window.location.reload();
      };

    const handleView = () => { 
        // You can add any validation or logic here if needed.
        setShowPrintButton(true);
        getHomeAllData({startDate,endDate});
      };
//    console.log(allHomeData,"allHomeData")
    // const getHomeAllData = async () => {
    //     try {
    //         const result = await getHomeApiData();
    //         // const home_data = result.res;
    //         setLoader(false)
    //         const home_data = result.res;
            
    //         setAllHomeData(home_data)

    //         if (home_data.popularSearch.length > 0) {
    //             setpopularSearchData(home_data.popularSearch)
    //         }

    //         if (home_data.popularMembers.length > 0) {
    //             setPopularMembersData(home_data.popularMembers)
    //         }

    //         if (home_data.party.length > 0) {
    //             setPartyData(home_data.party)
    //         }

    //         if (home_data.hub.length > 0) {
    //             setHubData(home_data.hub)
    //         }

    //         if (home_data.recentPosts.length > 0) {
    //             setRecentPost(home_data.recentPosts)
    //             const _allData = home_data.recentPosts;
    //             const _pollData = _allData.filter(item =>{
    //                 if(item.isPoll == true){
    //                     return item
    //                 }
    //             })
    //             const _postData = _allData.filter(item =>{
    //                 if(item.isPoll == false){
    //                     return item
    //                 }
    //             })
    //             setPollData(_pollData)
    //             setPostData(_postData)
    //         }
    //         const labels = home_data.updates.graph.map(item => item._id)
    //         const data = home_data.updates.graph.map(item => item.count)
    //         _chartData.labels = labels
    //         _chartData.datasets[0].data = data
    //         console.log(_chartData, '_chardata', home_data);
    //         setChartData(_chartData)
    //     }
    //     catch (error) {
    //         setLoader(false)
    //         console.log(error)
    //         // toast.error(error.message);
    //     }
    // }

    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails
            data={data}
            isModalView={true}
            isReportBtn={true}
        />
        handleModalData(POST_MODAL)
    }


    const AdminType = localStorage.getItem("AdminType")
    const region = localStorage.getItem("region")
   

    return (
        <>
          <Loader loader={loader} />
            {
                Object.keys(allHomeData).length > 0 && (
                   <>
                      <div className="flex flex-row items-center mb-4 w-3/5 ml-5">
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                   <div className="">
        {/* <label htmlFor="start-date" className="mb-2">Start Date:</label> */}
        {/* <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
          style={{ width: '350px' }}
        /> */}
         
           <KeyboardDatePicker
             label={'Start Date'}
            //   placeholder={`${start}`}
              value={startDate}
              onChange={(date) => (setStartDate(date))}
              format={"dd-MM-yyyy"}
              
            //   disablePast={true}
              required
            />
        </div>
      <div className="ml-2">
        {/* <label htmlFor="end-date" className="mb-2">End Date:</label> */}
     
              <KeyboardDatePicker
             label={'End Date'}
            //   placeholder={`${end}`}
              value={endDate}
              onChange={(date) => setEndDate(date)}
              format={"dd-MM-yyyy"}
               //   disablePast={true}
               minDate={startDate}
              required
              className=" "
            />
            
        {/* <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
          style={{ width: '350px' }}
        /> */}
        </div>
        <div className="">
        <button onClick={handleView} className="mx-auto mt-5 ml-5 button-1">
        View
      </button>
      </div>
      {showPrintButton && (
        <button onClick={handlePrint} className=" mx-auto ml-2 mt-5 button-1">
          Print PDF
        </button>
      )}
       </MuiPickersUtilsProvider>
      </div>
                    <div className="px-10 homemainresponsive grid grid-cols-12 gap-x-20">

                        <div className="col-span-9" id="contentToPrint" ref={contentRef}>

                          
                        <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('CourseSectionDetail')}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('Total_enrolled_users')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUsers}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('total_course_completed')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.users_completed_more_course}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>

                                {/* <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('two_course_completed')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.usersCompletedTwoCourses}
                                        </h1>

                                        
                                    </div>
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('one_course_completed')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.usersCompletedOneCourse}
                                        </h1>

                                        
                                    </div>
                                </div> */}

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ONGOINGCOURSE')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.ongoingUser                                            }
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('totalenrolledUserslast7days')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUserslast7days
                                            }
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                              
                            </div>
                           
                            {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('COUNTRYWISEONGOINGCOURSE')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHONGOINGCOURSE')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAONGOINGCOURSE')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.ongoingUserCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.ongoingUserThailand}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEONGOINGCOURSE')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHONGOINGCOURSE')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALONGOINGCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.ongoingUserNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEONGOINGCOURSE')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAONGOINGCOURSE')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.ongoingUserCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAONGOINGCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.ongoingUserIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAONGOINGCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.ongoingUserMalaysia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARONGOINGCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.ongoingUserMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDONGOINGCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.ongoingUserThailand}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEONGOINGCOURSE')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('ONGOINGCOURSE')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.ongoingUser}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            }
                             {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('COUNTRYWISECOMPLETEDCOURSE')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMPLETEDCOURSE')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMPLETEDCOURSE')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalcompletededUsersInCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalcompletededUsersInThailand}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDCOURSE')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMPLETEDCOURSE')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcompletededUsersInNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDCOURSE')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMPLETEDCOURSE')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totalcompletededUsersInCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcompletededUsersInIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcompletededUsersInMalaysia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARCOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcompletededUsersInMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcompletededUsersInThailand}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDCOURSE')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALCOURSECOMPLETED')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.users_completed_more_course}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            }

                           {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('COUNTRYWISEENROLLEDUSER')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHENROLLEDUSER')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAENROLLEDUSER')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalenrolledUsersInCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalenrolledUsersInThailand }
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEENROLLEDUSER')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHENROLLEDUSER')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUsersInNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEENROLLEDUSER')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAENROLLEDUSER')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totalenrolledUsersInCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUsersInIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUsersInMalaysia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUsersInMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUsersInThailand}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEENROLLEDUSER')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALENROLLEDUSERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalenrolledUsers}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            }
                             {   AdminType && AdminType === "Platform Admin" && region === "undefined" && showPrintButton === false ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('COUNTRYWISELAST7DAYSENROLLEDUSER')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHLAST7DAYSENROLLEDUSER')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIALAST7DAYSENROLLEDUSER')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalenrolledUserslast7daysCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIALAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIALAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIALAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARLAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALLAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDLAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalenrolledUserslast7daysThailand}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" && showPrintButton === false ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISELAST7DAYSENROLLEDUSER')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHLAST7DAYSENROLLEDUSER')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIALAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALLAST7DAYSENROLLEDUSER')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalenrolledUserslast7daysNepal                                              }
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" && showPrintButton === false ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISELAST7DAYSENROLLEDUSER')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIALAST7DAYSENROLLEDUSER')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totalenrolledUserslast7daysCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIALAST7DAYSENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUserslast7daysIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIALAST7DAYSENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUserslast7daysMalaysia                                             }
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARLAST7DAYSENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUserslast7daysMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDLAST7DAYSENROLLEDUSER')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalenrolledUserslast7daysThailand}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" && showPrintButton === false ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISELAST7DAYSENROLLEDUSER')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('totalenrolledUserslast7days')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalenrolledUserslast7days}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            }
                                  {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('COUNTRYWISECOMPLETEDMORECOURSE')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMPLETEDCOURSE')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMPLETEDCOURSE')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.users_completed_more_courseCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.users_completed_more_courseThailand}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDMORECOURSE')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMPLETEDCOURSE')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMPLETEDCOURSE')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.users_completed_more_courseNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDMORECOURSE')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMPLETEDCOURSE')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.users_completed_more_courseCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.users_completed_more_courseIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.users_completed_more_courseMalaysia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARCOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.users_completed_more_courseMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMPLETEDCOURSE')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.users_completed_more_courseNepal}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISECOMPLETEDMORECOURSE')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALCOURSECOMPLETED')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.users_completed_more_course                                            }
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            }

                        </div>
                     

                    </div>
                    </>
                )
                
            }
        </>
    )
}

export default EducationDetail;