import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { BASE_URL_IMG, deleteCourseSubSessionPostApi, deleteToolkitPerticularData, getResourcesCategory, InviteNotification, ToolkitStatusApi, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate, useMatch } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
// import EditResourcesForm from '../ModalTemplate/EditResourceForm';
import EditToolkitForm from '../ModalTemplate/EditToolkitForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import EducationSubSessiontable from './EducationSubSessiontable';
import EditSubSession from '../ModalTemplate/EditSubSession';


const EducationSubSessionResources = ({ data, getTablesData, adminData, Sessionname,coursename}) => { 
  const [searchValue, setSearchValue] = useState("");
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  let { path } = useMatch();
  
  const handleAdminPush = () => {
    history(-1);
}

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }


  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }

  useEffect(() => {
    if (category?.category?._id) {
      let _result = data?.filter((item) => {
        return item?.category?.id === category?.category?._id
      })
      setShowFilteredData(_result)
    }
    else {
      setShowFilteredData(data)
    }
  });

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  const history = useNavigate();
  const { t } = useTranslation();

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteCourseSubSessionPostApi(id);
      console.log(result)
      if (result.res.success) {
        toast.success(result.res.success)
        getTablesData();
      }

    } catch (error) {
      toast.error(error.message)
    }
  }
  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title="Remove SubSession"
        action={HandleDeleteResource}
        data={modalTextsFile.SubSessionDelete}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  // const handleDeactivate = async (props) => {
  //   const id = props.row.original._id;
  //   const USER_DEACTIVATE = <MaintenanceModeConfirmation
  //     title="Change User Status"
  //     data={modalTextsFile.userActivateDeactivate}
  //     action={() => handleMaintenance(id)}
  //   />
  //   handleModalData(USER_DEACTIVATE, 'sm')
  // }

  const handleEditResource = async (data) => {
    const editResourceForm = <EditSubSession
      title="Edit Subsession"
      data={data}
      getFeedData={getTablesData}
    />
    handleModalData(editResourceForm)
  }

  const showResourceDetail = (item) => { 
    history(`${item._id}`,{ 
    
      state: {
        Sessionname : Sessionname,
        coursename : coursename,
        data: item,
        title: item.SessionType === "Video" ? 'Video Question' : item.SessionType === "Test" ? 'Text Question':item.SessionType === "Pdf" ? 'Pdf Question': null,
        Addhandle: item.SessionType === "Video" ? 'Add Video Question' : item.SessionType === "Test" ? 'Add Text Question':item.SessionType === "Pdf" ? 'Add Pdf Question': null,
        // Addformhandle: item.SessionType === "Video" ? ' Video Question' : item.SessionType === "Text" ? ' Text Question':item.SessionType === "Pdf" ? ' Pdf Question': null
      }
    })
  }

  const handleActiveDeactive = async (props) => { 
    const id = props.row.original._id;
    try {
      const result = await ToolkitStatusApi(id);
      if (result.res.success) {
        toast.success(result.res.message)
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const userColumns = useMemo(
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
      // {
      //   Header: "Media Image",
      //   accessor: data => {
      //     return (
      //       <>
      //         {
      //           // data?.media[0]?.mediaType === "file" ?
      //             <img src={`${BASE_URL_IMG}${data?.image}`} alt="pdfimg" className='w-28' />
      //           //   :
      //           //   <img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
      //         }
      //       </>
      //     )
      //   }
      // },
      {
        Header: "Sub-Topic",
        accessor: data => { 
          return (
            <>
              <span>{data?.title}</span>
            </>
          )
        }
      },
      {
        Header: "Topic Type",
        accessor: data => {
          return (
            <>
              <span>{data?.SessionType}</span>
            </>
          )
        }
      },
      // {
      //   Header: "Country",
      //   accessor: data => { 
      //     return (
      //       <>
      //         <span>{data?.Country[0]?.name}</span>
      //       </>
      //     )
      //   }
      // },
    //   {
    //     Header: 'Option',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.Option?.map(item => { 
    //                     return (
    //                         <p>{`${item}`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
    //   {
    //     Header: 'SessionType',
    //     accessor: data => { 
    //         return (
    //             <>
    //                 {data?.subdata?.map(item => { 
    //                     return (
    //                         <p>{`${item?.SessionType} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
      // {
      //   Header: "Course Type",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.CourseType}</span>
      //       </>
      //     )
      //   }
      // },
      // {
      //   Header: "Course Duration",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.CourseDuretion}</span>
      //       </>
      //     )
      //   }
      // },
    //   {
    //     Header: "Posted On",
    //     accessor: data => {
    //       return (
    //         <>
    //           <span>{formatDate(data?.date)}</span>
    //         </>
    //       )
    //     }
    //   },
      {
        Header: 'Edit',
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => handleEditResource(data)}
            >
              <span className="MuiButton-label">Edit</span>
            </button>
          )
        }
      },
      {
        Header: 'View',
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => { 
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => showResourceDetail(data)}
            >
             { data?.SessionType === "Test" ?
             <span className="MuiButton-label">View Question</span> :
             data?.SessionType === "Video" ?
             <span className="MuiButton-label">View Video</span> :
             data?.SessionType === "Pdf" ?
             <span className="MuiButton-label">View Pdf</span> : null
             }
            </button>
          )
        }
      },
      {
        Header: 'Delete',
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => HandleRemoveEvent(data?._id)}
            >
              <span className="MuiButton-label">Delete</span>
            </button>
          )
        }
      },
    //   {
    //     Header: 'Status',
    //     accessor: "deactivated",
    //     Cell:adminData[0]?.AdminType === "Coordination team" ? "" : props => {
    //       return (
    //         <Grid item>
    //           <AntSwitch
    //             checked={props.row.original.status}
    //             onChange={() => handleActiveDeactive(props)}
    //             name="isDirectory"
    //           />
    //         </Grid>
    //       )
    //     }
    //   }
    ], [adminData])
    console.log(showFilteredData, "showFilteredData")

  return (
    <>
      <div className="flex justify-between items-center">
      {/* <div className="flex items-center">
                <span onClick={handleAdminPush} className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Go Back</span>
            </div> */}
      <div className="flex items-center mb-4">
                <span onClick={handleAdminPush} className="text-sm text-sidenav  cursor-pointer">Course Name :{coursename}</span>
                <span className="px-2 text-sm">/</span>
                <span className="text-sidenav text-minihubPara capitalize text-sm">Session Name : {Sessionname}</span>
            </div>
      {/* <div className="flex items-center">
                <span onClick={handleAdminPush} className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Go Back</span>
            </div>
      <div className="flex items-center">
                <span  className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Course Name : {coursename}/Session Name : {Sessionname}</span>
            </div>
      <div className="flex items-center">
                <span  className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Session Name :{Sessionname}</span>
            </div> */}

        {/* <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder="Search"
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div> */}
        {/* <div>
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label="Filter By Category" />}
          />
        </div> */}
      </div>
      {
        showFilteredData && <EducationSubSessiontable columns={userColumns} data={showFilteredData.filter((val, index) => {
          if (searchValue === "") {
            return val
          }
          else if (val.aliasName && val.aliasName.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.country && val.country.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
            return val
          }
        })} />
      }
    </>
  )
}

export default EducationSubSessionResources;