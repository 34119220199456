import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { ModalContext } from '../../Context';
import AdminDetailEditForm from '../ModalTemplate/AdminDetailEditForm';
import { getAdminDetails } from '../../Utils/services';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { useTranslation } from 'react-i18next';

const AdminViewPage = () => {
    const [adminDetails, setAdminDetails] = useState([]);
    const [newAccessTo, setNewAccessTo] = useState([]);
    const history = useNavigate();
    const location = useLocation();

    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { id } = location.state && location.state;

    const adminId = localStorage.getItem('adminId');
    const AdminType = localStorage.getItem('AdminType');
    const { t } = useTranslation();
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            setAdminDetails(result.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    const handleAdminPush = () => {
        history(-1);
    }

    const reportHandler = () => {
        console.log('reportHandler');
    }
    const showPostDetail = adminDetails => {
        const POST_MODAL = <AdminDetailEditForm
            userAllData={adminDetails}
            reportHandler={reportHandler}
            isReportBtn={true}
            isModalView={true}
            getAdminDetailsApiData={getAdminDetailsApiData}
        />
        handleModalData(POST_MODAL)
    }


    return (
        <div className="container px-8">
            <div className="flex items-center mb-4">
                <span onClick={handleAdminPush} className="text-sm text-minihubPara cursor-pointer">{t("Admin")}</span>
                <span className="px-2 text-sm">/</span>
                <span className="text-sidenav capitalize text-sm">{adminDetails.name}</span>
            </div>
            <div className="user_data_admin">
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className=" h-40 justify-center flex-col items-center">
                                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-shadowImg imageMarginTop">
                                    <img src={DummyDpImg} alt="User-image" className="w-24 h-24 rounded-full" />
                                </div>
                                <h3 className="text-sidenav capitalize mt-2 text-3xl">{adminDetails.name}</h3>
                                {/* <p className="text-2xl text-paraText border-b border-muteText pb-2">{adminDetails.designation}</p> */}
                                {  adminDetails?.country?.length > 1 ?
                                    <p className="text-lg pt-2 capitalize text-muteText">{adminDetails?.country?.map((data) => { 
                                        return (
                                            <span>{`${data?.name},`}</span>
                                        )
                                    })}</p> : <div className="text-lg pt-2 capitalize text-muteText">{adminDetails?.country?.map((data) => { 
                                        return (
                                            <span>{`${data?.name} `}</span>
                                        )
                                    })}</div>
                                }
                            </div>
                            <div className="my-10 detailmove">
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Email")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.email}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Mobile_Number")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.mobile}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Age")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails?.age}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Language")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails?.language?.map((data) => {
                                        return (
                                            <span>{data?.name}</span>
                                        )
                                    })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {   AdminType === "Platform Admin" ? 
                        adminDetails._id === adminId ?
                        <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-end">
                                <div className="mt-9">
                                    <button
                                        className="button-1"
                                        onClick={() => showPostDetail(adminDetails)}
                                    >
                                        {t("Edit")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : adminDetails.AdminType === "Regional Admin" || adminDetails.AdminType === "Country Admin"  || adminDetails.AdminType === "Coordination team"? 
                    <div className="mb-8">
                    <div className="userDataMain px-10">
                        <div className="flex h-40 justify-end">
                            <div className="mt-9">
                                <button
                                    className="button-1"
                                    onClick={() => showPostDetail(adminDetails)}
                                >
                                    {t("Edit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null : AdminType === "Regional Admin" ? 
                   adminDetails._id === adminId ?
                   <div className="mb-8">
                   <div className="userDataMain px-10">
                       <div className="flex h-40 justify-end">
                           <div className="mt-9">
                               <button
                                   className="button-1"
                                   onClick={() => showPostDetail(adminDetails)}
                               >
                                  {t("Edit")}
                               </button>
                           </div>
                       </div>
                   </div>
               </div> : adminDetails.AdminType === "Country Admin"  || adminDetails.AdminType === "Coordination team"? 
               <div className="mb-8">
               <div className="userDataMain px-10">
                   <div className="flex h-40 justify-end">
                       <div className="mt-9">
                           <button
                               className="button-1"
                               onClick={() => showPostDetail(adminDetails)}
                           >
                               {t("Edit")}
                           </button>
                       </div>
                   </div>
               </div>
           </div> : null : AdminType === "Country Admin" ? 
               adminDetails._id === adminId ?
               <div className="mb-8">
               <div className="userDataMain px-10">
                   <div className="flex h-40 justify-end">
                       <div className="mt-9">
                           <button
                               className="button-1"
                               onClick={() => showPostDetail(adminDetails)}
                           >
                               {t("Edit")}
                           </button>
                       </div>
                   </div>
               </div>
           </div> : adminDetails.AdminType === "Coordination team"? 
           <div className="mb-8">
           <div className="userDataMain px-10">
               <div className="flex h-40 justify-end">
                   <div className="mt-9">
                       <button
                           className="button-1"
                           onClick={() => showPostDetail(adminDetails)}
                       >
                           {t("Edit")}
                       </button>
                   </div>
               </div>
           </div>
       </div> : null :  AdminType === "Coordination team" ? 
            adminDetails._id === adminId ?
            <div className="mb-8">
            <div className="userDataMain px-10">
                <div className="flex h-40 justify-end">
                    <div className="mt-9">
                        <button
                            className="button-1"
                            onClick={() => showPostDetail(adminDetails)}
                        >
                           {t("Edit")}
                        </button>
                    </div>
                </div>
            </div>
        </div> : adminDetails.AdminType === "Coordination team"?  null
    //     <div className="mb-8">
    //     <div className="userDataMain px-10">
    //         <div className="flex h-40 justify-end">
    //             <div className="mt-9">
    //                 <button
    //                     className="button-1"
    //                     onClick={() => showPostDetail(adminDetails)}
    //                 >
    //                     Edit
    //                 </button>
    //             </div>
    //         </div>
    //     </div>
    // </div> 
    : null : null
       
                    }
                   
                  
                </div>
            </div>
        </div>
    )
}

export default AdminViewPage;
