import classNames from "classnames"
import ReactPlayer from 'react-player'
import { BASE_URL_IMG } from "../../Utils/services"
import styles from '../../Assets/Comment.module.css'


const VideoPlayer = ({
    data,
    isModalView,
}) => { 
    const renderYoutubeVideo = link => {
        return (
            <ReactPlayer
                url={`${BASE_URL_IMG}${link}`}
                controls={true}
                width="100%"
                loop={true}
            />
        )
    }

    return (
        <div className={classNames({
            'post-detail': true,
            'relative': true,
            'modal-view': isModalView
        })}>
            <>
                <div className="content">
                    {renderYoutubeVideo(data?.media[0]?.mp4link)}

                </div>
                <div className="footer bg-white p-2">
                    <h3 className="title text-sm font-semibold tracking-wider mb-1"> {data.title} </h3>
                </div>
                <div className="flex justify-start sticky top-0">
                <div className="flex pl-2 pt-2 comment-bg rounded-tl">
                    <span className={styles.num}>{data && data?.downloadCount?.length}</span>
                    <img src="../../assets/comment/download.png" className={styles.icon} alt="likes" />
                </div>
                <div className="flex px-2 pt-2 comment-bg">
                   <span className={styles.num}>{data && data?.view?.length}</span>
                    <img src="../../assets/comment/eye.svg" className={styles.icon} alt="comment" />
                </div>
            </div>
            </>
        </div>
    )
}

export default VideoPlayer