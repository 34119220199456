import Table from '../../Common/Table';
import { useMemo } from 'react';
import { Button } from "@material-ui/core";
import { useEffect, useState, useContext } from 'react';
import { getCountriesData } from '../getRequests';
import AddCountry from '../../ModalTemplate/AddCountry'
import { ModalContext } from '../../../Context'
import { BASE_URL_IMG, getSectorList, deleteSector } from '../../../Utils/services';
import { toast } from 'react-toastify';
import DummyDpImg from "../../../Assets/images/DummyDp.png"
import AddSector from '../../ModalTemplate/AddSector';
import { useTranslation } from 'react-i18next';
import RemoveConfirmation from '../../ModalTemplate/RemoveConfirmation';
const Sector = ({ sectorData, getSectorListData,adminData }) => { 
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const editHandler = (data) => {  
        const EDIT_COUNTRY = <AddSector
            title={t('EDIT_SECTOR')}
            id={data.cell.row.original._id}
            name={data.cell.row.original.name}
            sector={data.cell.row.original.sectors}
            langauge={data.cell.row.original.language}
            getSectorListData ={getSectorListData}
            btnText={t('SAVE')}
            edit
        />
        handleModalData(EDIT_COUNTRY, 'md')
    }

    const handledeleteSector = async (item) => {  
        try {
            const result = await deleteSector({
                id: item.cell.row.original._id
            });
            if (result.res.success) {
                // toast.success(result.res.message)
                toast.success("Sector deleted successfully")
                getSectorListData()
            }

        } catch (error) {
            toast.error(error.messages)
        }
    }

    const HandleRemoveEvent = (id) => {
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('Remove_Sector')}
            action={handledeleteSector}
            data={t('sectorRemoveText')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };

    //   let filtercontact =  contacts?.filter((data, index) =>{ 
    //   if(data?.fromName === CurrentUserindex?.fromName){ 
    //     setCurrentSelected(index)
    //     // changeChat(data)
    //     // changeCurrentChat(index, data)
    //   }
     
    //  })

    const sectorfunction = (sector) => { 
        let filtercontact =  sector?.filter((data) =>{ 
            if(data?.language.name == "English" ){ 
               return data?.name
            }
           
           })
    //    console.log(filtercontact,"filtercontact")
        return filtercontact[0]?.name
    }
    const AdminType = localStorage.getItem("AdminType");
    const countryColumns = useMemo(
        () => [
            // {
            //     Header: `${t('Sector')}`,
            //     accessor: 'name',
            //     Cell: ({ cell: { value } }) => <span className="capitalize pl-0">{value}</span>
            // },
            // {
            //     Header: `${t('langauge')}`,
            //     accessor: 'language',
            //     Cell: ({ cell: { value } }) => <span className="capitalize pl-0">{value}</span>
            // },
            {
                Header: `${t("Sector")}`,
                accessor: data => {       

                    return (
                        <>
                            <span className="capitalize pl-0">
                                {/* {data?.sectors} */}
                           {/* {data?.name ? data?.name  : `${sectorfunction(data?.sectors)}` }     */}
                           {  data?.sectors ? `${sectorfunction(data?.sectors)}` :  data?.name}    
                                </span>
                        </>
                    )
                }
            },
            {
                Header: `${t('Added_On')}`,
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                Header: `${t('Added_By')}`,
                accessor: 'createdBy',
                Cell: ({ cell: { value } }) => <div className='flex items-center'><img src={DummyDpImg} className=' w-10 h-10 flex object-cover rounded-full mr-2' />{value.name}</div>
            },
            {
                id: "view",
                Header: `${t('Action')}`,
                accessor: "_id",
                Cell: props => AdminType === "Coordination team" || AdminType === "Regional Admin" || AdminType === "Country Admin" ? "" : <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')} </Button>,
            },
            {
                id: "delete",
                Header: `${t('Action')}`,
                accessor: "_id",
                Cell: props => AdminType === "Coordination team" || AdminType === "Regional Admin" || AdminType === "Country Admin" ? "" : <Button className="btn2" onClick={() => HandleRemoveEvent(props)}> {t('Delete')} </Button>,
            }
        ],
        []
    )

    return (
        <>
            {
                sectorData &&
                <Table columns={countryColumns} data={sectorData.sort(dynamicSort("name"))} />
            }
        </>
    )
}

export default Sector