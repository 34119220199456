import { useState } from "react";
import Header from "./Header";
import MainWrapper from "./MainWrapper"
import SideNav from "./SideNav"
import CommonModal from "../CommonModal";
import { FunctionContext, ModalContext,FunctionGalleryContext,FunctionStoryContext,FunctionNotificationContext } from "../../Context"
import { getForumsApi, getFouramCommentData, getGalleryApi,getGalleryCommentData,getStoriesCommentData,getStoryApi } from "../../Utils/services";



const Layout = ({getNotificationData,contacts}) => {  

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState('');
    const [modalSize, setModalSize] = useState('');
    const [data, setData] = useState({});
    const [FouramData, setFouramData] = useState(null);
    const [postData, setPostData] = useState(null);
    const [paginationpostData, setpaginationPostData] = useState(null);
    const [postDatas, setPostDatas] = useState(null);
    const [GalleryDatas, setGalleryDatas] = useState(null);
    const [paginationGalleryDatas, setpaginationGalleryDatas] = useState(null);
    const [postStoryDatas, setpostStoryDatas] = useState(null);
    const [paginationpostStoryDatas, setpaginationpostStoryDatas] = useState(null);
    const [StoryDatas, setStoryDatas] = useState(null);
    const [isNavopen, setnavopen] = useState(false);
    const [isNavopens, setnavopens] = useState(false);

       const getGalleryDataApi = async (data) => {
        try {
            const result = await getGalleryApi(data);
            
            setPostDatas(result.res.data)
            setpaginationGalleryDatas(result.res.pagination)
        }
        catch (error) {
           
            console.log(error)
          
        }
    }
    // console.log(getNotificationData,"getNotificationData")
       const getGalleryDataApis = async () => {
        const Galleryid = localStorage.getItem("itemss");
        try {
            const result = await getGalleryCommentData({
                id: Galleryid   
            });
            
            setGalleryDatas(result?.data[0])
        }
        catch (error) {
           
            console.log(error)
          
        }
    }

    const getForumsDataApi = async (data) => { 
        try {
            const result = await getForumsApi(data);
            setPostData(result.res.data)
            setpaginationPostData(result.res.pagination)
            
        }
        catch (error) {
            console.log(error)
        }
    }

  
   
    const getForumsDataApis = async () => {
        const item = localStorage.getItem("item");
        try {
            const result = await getFouramCommentData({
                id: item   
            });
            
            setFouramData(result?.data[0])
        }
        catch (error) {
            console.log(error)
        }
    }


    const getStoryDataApi = async ({ page = 1, postsPerPage = 10 } = {}) => {
        try {
            const result = await getStoryApi({page,postsPerPage});
            setpostStoryDatas(result.res.data)
            setpaginationpostStoryDatas(result.res.pagination)
        }
        catch (error) {
            console.log(error)
        }
    }
    const getStoryDataApis = async () => {
        const itemsid = localStorage.getItem("items");
        try {
            const result = await getStoriesCommentData({
                id: itemsid    
            });
            setStoryDatas(result?.data[0])
            
        }
        catch (error) {
            console.log(error)
        }
    }



    const handleModalData = (data, size = 'xl') => {
        setModalData(data);
        setModalSize(size)
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    return (
        <>
            <ModalContext.Provider
                value={{
                    handleModalData,
                    closeModal,
                    setData,
                    data,
                    isModalOpen,
                    modalData
                }}
            >
                <div className="layout">
                    <div className={isNavopen ? " side-navs" : "side-nav"}>
                        <SideNav  setnavopen={setnavopen}  isNavopen={isNavopen}
                        />
                    </div>

                    <div className={isNavopen ? "page-containers" : "page-container"}>
                        <Header setnavopen={setnavopen}  isNavopen={isNavopen} setnavopens={setnavopens}  isNavopens={isNavopens}
                        />

                        <div className="body-container">
                            <FunctionNotificationContext.Provider  value={{getNotificationData,contacts}}>
                            <FunctionStoryContext.Provider  value={{postStoryDatas, getStoryDataApi, StoryDatas, getStoryDataApis,paginationpostStoryDatas}}>
                             <FunctionGalleryContext.Provider value={{postDatas, getGalleryDataApi, GalleryDatas, getGalleryDataApis,paginationGalleryDatas}}>
                              <FunctionContext.Provider value={{postData, getForumsDataApi, FouramData, getForumsDataApis,paginationpostData }}>
                              <MainWrapper
                               />
                              </FunctionContext.Provider>
                             </FunctionGalleryContext.Provider>
                            </FunctionStoryContext.Provider>
                            </FunctionNotificationContext.Provider>
                        </div>
                    </div>
                </div>
                <CommonModal
                    handleModalData={handleModalData}
                    isModalOpen={isModalOpen}
                    modalData={modalData}
                    modalSize={modalSize}
                    closeModal={closeModal}
                />
            </ModalContext.Provider>
        </>
    )
}

export default Layout;