import UsersTable from './UsersTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import UserApproveModal from '../ModalTemplate/UserApproveModal';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const DeclinedUser = ({ adminData, data, getTablesData ,Count,getnewRegistraionTablesData}) => {
    const [searchValue, setSearchValue] = useState("");
    const modalContext = useContext(ModalContext);
    const { closeModal, handleModalData } = modalContext;
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(1);

    const { t } = useTranslation();

    const handlePageChange = (event, value) => {
        setPage(value);
      };
      useEffect(() => {
        if(searchValue!= ''){
        const timeoutId = setTimeout(() =>
        getnewRegistraionTablesData({
              //  page: page,
              //  size: postsPerPage,
               query:searchValue,
        }), 500);
      return () => clearTimeout(timeoutId);
      //   if(searchValue!= ''){
      //   getTablesData({
      //     page: page,
      //     size: postsPerPage,
      //     query:searchValue,
      //   });
      // }
    }}, [searchValue,Count])
      useEffect(() => {
        if(searchValue === ""){
            getnewRegistraionTablesData({
            page: page,
            size: postsPerPage,
         })
        }
    },[Count,page,searchValue])
        useEffect(() => {
            setPageCount(Math.ceil(Count / postsPerPage));
          }, [Count])

    const editHandler = (data) => {
        const EDIT_PARTY = <UserEditForm
            title={t('Edit_user')}
            buttonText={t('Save_Changes')}
            id={data.row.original._id}
            perticularData={data.row.original}
            getnewRegistraionTablesData={getnewRegistraionTablesData}
        />
        handleModalData(EDIT_PARTY);
    }

    const handleApprove = async (data) => {
        const OPEN_APPROVE_MODAL = <UserApproveModal
            id={data.cell.row.original._id}
            getnewRegistraionTablesData={getnewRegistraionTablesData}

        />
        handleModalData(OPEN_APPROVE_MODAL, 'md')
    }

    const userColumns = useMemo(
        () => [
            {
                Header: `${t('Status')}`,
                accessor: 'status', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) => value ?
                    <div className="rounded-full bg-green h-3 w-3"></div>
                    :
                    <div className="rounded-full bg-orange h-3 w-3"></div>,
            },
            {
                Header: `${t('Users')}`,
                accessor: userData => (
                    <div className="flex justify-start items-center">
                        <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
                        <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
                    </div>
                ),
            },
            {
                Header: `${t('Age')}`,
                accessor: Value => {
                    return (
                        <>
                            {
                                <span>{Value && Value.age ? Value.age : " - "}</span>
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Language')}`,
                accessor: Value => {
                    return (
                        <>
                            {
                                <span>{Value?.language ? Value?.language?.name : " - "}</span>
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Country')}`,
                accessor: properties => {
                    return (
                        <>
                            <span className='capitalize'>{`${properties?.country ? properties?.country?.name : ' - '}`}</span>
                        </>
                    )
                }
            },
            {
                Header: `${t('Sector')}`,
                accessor: properties => {
                    return (
                        <>
                            <span className='capitalize'>{`${properties.sector ? properties?.sector?.name : ' - '}`}</span>
                        </>
                    )
                }
            },
            {
                Header: `${t('Declined_By')}`,
                accessor: properties => {
                    return (
                        <>
                            <span className='capitalize'>{`${properties.declinedBy ? properties?.declinedBy?.name : ' - '}`}</span>
                        </>
                    )
                }
            },
            {
                Header: `${t('Decline_Reason')}`,
                accessor: properties => { 
                    return (
                        <>
                            <span className='capitalize'>{`${properties.declineRegion ? properties?.declineRegion : ' - '}`}</span>
                        </>
                    )
                }
            },
            {
                Header: `${t("Action")}`,
                accessor: "_id",
                Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')} </Button>
            },
            {
                Header: `${t("Approve")}`,
                accessor: "",
                Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => handleApprove(props)}> {t('Approve')} </Button>
            },
        ], [])

    return (
        <>
            <div className="flex justify-between flex-wrap items-center">
            <div>
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          />
        </div>
                <div className="bg-white p-2 flex items-center search-wrapper">
                    <span>
                        <img src="/assets/icons/search.svg" />
                    </span>
                    <input
                        type="text"
                        value={searchValue}
                        placeholder={t('Search')}
                        className="w-96 mx-3"
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <span>
                        <img src="/assets/icons/submit-arrow.svg" />
                    </span>
                </div>

            </div>
            {
                data && <UsersTable columns={userColumns} data={data?.filter((val, index) => { 
                    if (searchValue === "") {  
                        return val
                    }
                    else if (val.aliasName && val.aliasName.toLowerCase().includes(searchValue.toLowerCase())) {
                        return val
                    }
                    else if (val.sector?.name && val.sector?.name.toLowerCase().includes(searchValue.toLowerCase())) {
                        return val
                    }
                    // else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
                    //     return val
                    // }
                    // else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
                    //     return val
                    // }
                    // else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
                    //     return val
                    // }
                    // else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
                    //     return val
                    // }
                    // else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
                    //     return val
                    // }
                })} />
            }
        </>
    )
}

export default DeclinedUser;