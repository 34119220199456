import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { BASE_URL_IMG, deleteToolkitPerticularData, getResourcesCategory, InviteNotification, ToolkitStatusApi, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate, useMatch } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import Toolkittable from './Toolkittable';
// import EditResourcesForm from '../ModalTemplate/EditResourceForm';
import EditToolkitForm from '../ModalTemplate/EditToolkitForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import { Pagination } from '@material-ui/lab';


const ToolResource = ({ data, getTablesData, adminData ,totalCount}) => {
  const [searchValue, setSearchValue] = useState("");
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  // let { path } = useMatch();

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  const getCategoryData = async () => {
    try {
      const result = await getResourcesCategory();
      setCategoryData(result?.data)
    }
    catch (error) {
      console.log(error);
      toast.warning("Something went wrong!")
    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);

  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }
  // console.log(totalCount)
  useEffect(()=>{  
    if (totalCount && totalCount > 0) { 
        setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
    }
// if(selectfilter?.Country?.name !== undefined  )

  getTablesData({ 
        page: page,
        size: postsPerPage,
    });

},[page, totalCount])

  useEffect(() => {
    if (category?.category?._id) {
      let _result = data?.filter((item) => {
        return item?.category?.id === category?.category?._id
      })
      setShowFilteredData(_result)
    }
    else {
      setShowFilteredData(data)
    }
  });

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  const history = useNavigate();
  const { t } = useTranslation();

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteToolkitPerticularData(id);
      console.log(result)
      if (result.res.success) {
        toast.success("Toolkits deleted successfully")
        getTablesData({ 
          page: page,
          size: postsPerPage,
      });
      }

    } catch (error) {
      toast.error(error.message)
    }
  }
  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation 
        title={t('Remove_Toolkit')}
        action={HandleDeleteResource}
        data={t('ToolkitRemoveText')}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  // const handleDeactivate = async (props) => {
  //   const id = props.row.original._id;
  //   const USER_DEACTIVATE = <MaintenanceModeConfirmation
  //     title="Change User Status"
  //     data={modalTextsFile.userActivateDeactivate}
  //     action={() => handleMaintenance(id)}
  //   />
  //   handleModalData(USER_DEACTIVATE, 'sm')
  // }

  const handleEditResource = async (data) => {
    const editResourceForm = <EditToolkitForm
      data={data}
      id={data._id}
      getTablesData={getTablesData}
      title={t('Edit_Toolkit')}
    />
    handleModalData(editResourceForm)
  }

  const showResourceDetail = (item) => {  
    history(`${item._id}`,{
    
      state: {
        data: item,
        title: 'Toolkit Details'
      }
    })
  }

  const handleActiveDeactive = async (props) => { 
    const id = props.row.original._id;
    try {
      const result = await ToolkitStatusApi(id);
      if (result.res.success) {
        toast.success(result.res.message)
        getTablesData({ 
          page: page,
          size: postsPerPage,
      });
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const userColumns = useMemo(
    () => [
      {
        Header: `${t('Status')}`,
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },
      {
        Header: `${t('Media_Image')}`,
        accessor: data => {
          return (
            <>
              {
                data?.media[0]?.mediaType === "file" ?
                  <img src={`${BASE_URL_IMG}${data?.pdfimg}`} alt="pdfimg" className='w-28' />
                  :
                  <img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
              }
            </>
          )
        }
      },
      {
        Header: `${t('Title')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.title}</span>
            </>
          )
        }
      },
      // {
      //   Header: "Category",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.category?.name}</span>
      //       </>
      //     )
      //   }
      // },
      // {
      //   Header: "Country",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.countries[0]?.name}</span>
      //       </>
      //     )
      //   }
      // },
      {
        Header: `${t('Country')}`,
        accessor: data => { 
            return (
                <>
                    {data?.countries?.map(item => { 
                        return (
                            <p>{`${item?.name} ,`}</p>
                        )
                    })}
                </>
            )
        }
    },
      {
        Header: `${t('View_Post')}`,
        accessor: data => { 
          return (
            <>
              <span>{data?.view?.length}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Downloads')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.downloadCount?.length}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Posted_On')}`,
        accessor: data => {
          return (
            <>
              <span>{formatDate(data?.date)}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Edit')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => handleEditResource(data)}
            >
              <span className="MuiButton-label">{t('Edit')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('View')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => showResourceDetail(data)}
            >
              <span className="MuiButton-label">{t('View')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('Delete')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => HandleRemoveEvent(data?._id)}
            >
              <span className="MuiButton-label">{t('Delete')}</span>
            </button>
          )
        }
      },
      {
        Header:  `${t('Status')}`,
        accessor: "deactivated",
        Cell:adminData[0]?.AdminType === "Coordination team" ? "" : props => {
          return (
            <Grid item>
              <AntSwitch
                checked={props.row.original.status}
                onChange={() => handleActiveDeactive(props)}
                name="isDirectory"
              />
            </Grid>
          )
        }
      }
    ], [adminData])
    // console.log(showFilteredData, "showFilteredData")
    const handlePageChange = (event, value) => { 
      setPage(value);
  };
  return (
    <>
      <div className="flex justify-between items-center">
      <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        {/* <div>
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label="Filter By Category" />}
          />
        </div> */}
      </div>
      {
        showFilteredData && <Toolkittable columns={userColumns} data={showFilteredData.filter((val, index) => {
          if (searchValue === "") {
            return val
          }
          else if (val.title && val.title.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          // else if (val.country && val.country.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
          //   return val
          // }
        })} />
      }
    </>
  )
}

export default ToolResource;