import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { regionCountryList, addAdmin, getLanguageList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';

const AdminForm = ({
    refreshDataMethod
}) => {
    const [countries, setCountries] = useState([]);
    const [allCountry, setAllCountry] = useState([]);
    const [country, setCountry] = useState([]);
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState("");
    const [formData, setFormData] = useState({});
    const [gender, setGender] = useState("Male");
    const [loader, setLoader] = useState(false);
    const [adminType, setAdminType] = useState("Platform Admin");
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const [ismultipleCountry, setIsMultipleCountry] = useState(true);
    const [isShowCountry, setIsShowCountry] = useState(false);
    const [isShowRegion, setIsShowRegion] = useState(false);
    const [plateform, setplateform] = useState(true);
    const [Regionalbutton, setRegionalbutton] = useState(false);
    const [Coordinationbutton, setCoordinationbutton] = useState(false);
    const [Countrybutton, setCountrybutton] = useState(false);
    const [emailmsg, setemailmsg] = useState(false);

    useEffect(() => {
        getCountryData();
        getLanguageListData();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }
    const { t } = useTranslation();
    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
        validateEmail(value);
    }
console.log(emailmsg,"emailmsg")
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        if (!isValid) {
            setemailmsg("add Correct email address")
        } else {
            setemailmsg("")
        }
      };

    const onAdminChangeHandler = e => {
        e.preventDefault()
        setCountry([]);
        const value = e.target.value
        setAdminType(value);
        if (value === "Platform Admin") {
            setIsMultipleCountry(true)
            setIsShowCountry(false)
            setIsShowRegion(false)
            setplateform(true)
            setRegionalbutton(false)
            setCountrybutton(false)
            setCoordinationbutton(false)
        }
        if (value === "Regional Admin") {
            setIsMultipleCountry(true)
            setIsShowCountry(false)
            setIsShowRegion(true)
            setplateform(false)
            setRegionalbutton(true)
            setCountrybutton(false)
            setCoordinationbutton(false)

        }
        if (value === "Country Admin") {
            setIsMultipleCountry(false)
            setIsShowCountry(true)
            setIsShowRegion(true)
            setplateform(false)
            setRegionalbutton(false)
            setCountrybutton(true)
            setCoordinationbutton(false)
        }
        if (value === "Coordination team") {
            setIsMultipleCountry(false)
            setIsShowCountry(true)
            setIsShowRegion(true)
            setplateform(false)
            setRegionalbutton(false)
            setCountrybutton(false)
            setCoordinationbutton(true)
        }
    }
    const onGenderChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setGender(value)
    }

    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            setRegions(result?.res?.data?.region)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await regionCountryList();
                let CountryData = result?.res?.data?.country?.filter((data) => {
                    return data?.region?.name === region?.region?.name;
                });
                setCountries(CountryData);
                setAllCountry(result?.res?.data?.country);
            } catch (error) {
                console.error(error);
                toast.warning("Something went wrong!");
            }
        };
    
        // Use an IIFE to call the async function directly
        (async () => {
            fetchData();
        })();
    
        // No need for a cleanup function in this case
    
    }, [region]);
    
    // useEffect(async () => {
    //     try {
    //         const result = await regionCountryList();
    //         let CountryData = result?.res?.data?.country?.filter((data) => {
    //             return data?.region?.name === region?.region?.name
    //         })
    //         setCountries(CountryData)
    //         setAllCountry(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }, [region]);
    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    const onInputRegionChange = (event, newInputValue, name) => {
        setRegion({
            ...region,
            [name]: newInputValue
        })
        setCountry([]);
    }
    const onInputLanguageChange = (event, newInputValue, name) => { 
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }
//    console.log(formData?.email,"formData?.email")
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            let countryData;
            if (!isShowCountry && isShowRegion) {

                countryData = countries?.map((data) => {
                    return data?._id
                })

            }
            else if(!isShowRegion){
                countryData = allCountry?.map((data) => {
                    return data?._id
                })
            }
            else {
                countryData = country.country._id;
            }
            console.log(formData?.email,"formData?.email")
            const formDataValue = new FormData();
            formDataValue.append("AdminType", adminType);
            formDataValue.append("name", formData.name);
            formDataValue.append("language", language.language.name);
            formDataValue.append("email", formData?.email?.toLowerCase());
            formDataValue.append("mobile", formData.mobile);
            formDataValue.append("gender", gender);
            formDataValue.append("age", formData.age);
            if(isShowRegion){
                formDataValue.append("region", region?.region?._id);
            }
            formDataValue.append("country", countryData);
            const results = await addAdmin(formDataValue);
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false)
                closeModal()
                console.log('"Admin');
                refreshDataMethod();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('add_new_admin')} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="mt-4 w-6/12">
                    <RadioGroup aria-label="adminType" name="adminType" className="radio-group" defaultValue={adminType} onChange={e => onAdminChangeHandler(e)}>
                        <FormControlLabel value="Platform Admin" control={<Radio />} label="Platform Admin" />
                        <FormControlLabel value="Regional Admin" control={<Radio />} label="Regional Admin" />
                        <FormControlLabel value="Country Admin" control={<Radio />} label="Country Admin" />
                        <FormControlLabel value="Coordination team" control={<Radio />} label="Coordination team" />
                    </RadioGroup>
                </div>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Name')}
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                    />
                    <TextField
                        type={'number'}
                        label={t('Age')}
                        name="age"
                        value={formData.age || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                    />

                    <TextField
                        label={emailmsg ? emailmsg : t('Email')}
                        name="email"
                        value={formData.email || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                        className='emailadmin'
                    />
                   {/* {formData.email && 
                     <span className='errormsg'>{emailmsg}</span>
                   } */}
                       
                    

                    <TextField
                        type={'number'}
                        label={t('Mobile_Number')}
                        name="mobile"
                        value={formData.mobile || ''}
                        onChange={e => onChangeHandler(e)}
                        required
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')} />}
                        required
                    />
                    {
                        isShowRegion &&
                        <Autocomplete
                            id="combo-box-demo2"
                            options={regions?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={region?.name}
                            onChange={(e, newVal) => onInputRegionChange(e, newVal, 'region')}
                            renderInput={(params) => <TextField {...params} label={t('Region')} />}
                            required
                        />
                    }
                    {
                        region?.region?.name && ismultipleCountry && isShowCountry &&
                        <Autocomplete
                            id="combo-box-demo2"
                            multiple
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={country?.name}
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                            required
                        />
                    }
                    {
                        region?.region?.name && !ismultipleCountry &&
                        <Autocomplete
                            id="combo-box-demo2"
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={country?.name}
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                            required
                        />
                    }
                    <div className="mt-4 w-6/12">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Gender</label>
                        <RadioGroup aria-label="gender" name="gender" className="radio-group" defaultValue={gender} onChange={e => onGenderChangeHandler(e)}>
                            <FormControlLabel value="Male" control={<Radio />} label={t('Male')} />
                            <FormControlLabel value="Female" control={<Radio />} label={t('Female')} />
                        </RadioGroup>
                    </div>
                </div>
                {
                    plateform && 
                    <div className="flex justify-center mt-10">
                    <button className={`mx-auto ${language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email  !== undefined  ? "button-1" : "buttons-1"}`}  type="submit" disabled={language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email !== undefined ? false : true}>
                    {t('ADD_ADMIN')}
                    </button>
                  </div>
                }
                {
                    Regionalbutton && 
                    <div className="flex justify-center mt-10">
                    <button className={`mx-auto ${language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id  !== undefined ? "button-1" : "buttons-1"}`}  type="submit" disabled={language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? false : true}>
                    {t('ADD_ADMIN')}
                    </button>
                  </div>
                }
                {
                    Countrybutton && 
                    <div className="flex justify-center mt-10">
                    <button className={`mx-auto ${language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? "button-1" : "buttons-1"}`}  type="submit" disabled={language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? false : true}>
                    {t('ADD_ADMIN')}
                    </button>
                  </div>
                }
                {
                    Coordinationbutton && 
                    <div className="flex justify-center mt-10">
                    <button 
                    className={`mx-auto ${language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? "button-1" : "buttons-1"}`} 
                     type="submit" disabled={language?.language?.name && formData?.name && formData?.age && formData?.mobile && formData?.email && region?.region?._id !== undefined ? false : true}>
                    {t('ADD_ADMIN')}
                    </button>
                  </div>
                }
              
            </form>
        </>
    )
}

export default AdminForm;