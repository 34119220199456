import React, { useState, useContext, useEffect } from 'react';
import { FEEDS_FORM, GALLERY_FORM,POLL_FORM} from '../ModalTemplate';
import { ModalContext } from '../../Context'
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import {allAdminUsers, fetchManageFlag, fetchManagePostFlag, fetchRemovedFlagPost, getGalleryApi, getGalleryPollApi} from '../../Utils/services';
import { toast } from 'react-toastify';
import GallaryPage from './GallaryPage';
import ManageGallery from './ManageGallery';
import RemovedFlagGallery from './RemovedFlagGallery';
import { FunctionGalleryContext } from '../../Context';
import { useTranslation } from 'react-i18next';
import PollPage from './PollPage';


const a11yProps = (index) => {
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Gallery = () => {
    const {t} = useTranslation();
    const {postDatas, getGalleryDataApi,paginationGalleryDatas} = useContext(FunctionGalleryContext)
    const [tabsValue, setTabsValue] = useState(0);
    const [postData, setPostData] = useState(null);
    const [adminData, setAdminData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [totalCount, setotalCount] = useState(0)
    const [flagData, setFlagData] = useState([])
    const [removedData, setRemovedData] = useState([]);
    
    useEffect(() => {
        setotalCount(paginationGalleryDatas && paginationGalleryDatas.totalCount)
    }, [paginationGalleryDatas && paginationGalleryDatas.totalCount])
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }
    const getpollDataApi = async (data) => {
        try {
            const result = await getGalleryPollApi(data);
            setPostData(result.res.data.reverse())
        }
        catch (error) {
            setLoader(false)
            console.log(error)
            toast.error(error.message);
        }
    }

    
    const getManageFlag = async () => {
        try {
            const res = await fetchManagePostFlag();
            let _data = res?.data?.commentdata;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = res?.data?.posts?.concat(result[0]).concat(res?.data?.galaryflaggedReply)
            // _AllData.sort((a, b) => new Date(a.date) - new Date(b.date));
            _AllData.sort((a, b) => new Date(b.flag.report[0].reportedOn) - new Date(a.flag.report[0].reportedOn));
            // console.log(_AllData,"_AllData")
            setFlagData(_AllData);
            
        } catch (error) {
            toast.error(error.message)
        }
    }
    const getRemovedFlag = async () => {
        try {
            const res = await fetchRemovedFlagPost();
            let _data = res?.data?.removedComment;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = res?.data?.removedpost.concat(result[0]).concat(res?.data?.removedReply)
            _AllData.sort((a, b) => new Date(b.removed.removedOn) - new Date(a.removed.removedOn));
            setRemovedData(_AllData);
            // toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() =>{
        getManageFlag();
        getGalleryDataApi();
        getAdminTableData()
    }, []);

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
        var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
        window.history.pushState({ path: newurl }, '', newurl);
    };
    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(()=>{
        var idValue = getParameterByName('id');
        if(idValue){
            setTabsValue(parseInt(idValue))
        }
    },[])
    const tabsData = [
        {
            label: `${t("Gallery")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: <GallaryPage totalCount ={totalCount}  data={postDatas} getGalleryDataApi={getGalleryDataApi} adminData={adminData} />
        },
        {
            label: `${t("Manage_Gallery")}`,
            isNotshowAdd: true,
            data: <ManageGallery flagData={flagData} getManageFlag={getManageFlag}  adminData={adminData}/>
        },
        {
            label: `${t("Removed_Gallery")}`,
            isNotshowAdd: true,
            data: <RemovedFlagGallery flagData={removedData} getManageFlag={getRemovedFlag} adminData={adminData}/>
        },
        // {
        //     label: `${t("Poll")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: <PollPage totalCount ={totalCount}  data={postData} getGalleryDataApi={getpollDataApi} adminData={adminData} />
        // }
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(GALLERY_FORM(true, getGalleryDataApi));
                break;
            // case 3:
            //     handleModalData(POLL_FORM(true, getpollDataApi));
            //     break;
            default:
                handleModalData(GALLERY_FORM(true, getGalleryDataApi));
                break;
        }
    }
    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />
            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}
export default Gallery;

