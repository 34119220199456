import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio, TextareaAutosize } from '@material-ui/core';
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useEffect } from 'react';
import { regionCountryList, addAdmin, getLanguageList,addAbout } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';

const AboutForm = ({
    refreshDataMethod
}) => {
    const [countries, setCountries] = useState([]);
    const [allCountry, setAllCountry] = useState([]);
    const [country, setCountry] = useState([]);
    const [region, setRegion] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState("");
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const context = useContext(ModalContext);
    const { closeModal } = context;
 
    const [article, setArticle] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const { t } = useTranslation();
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }

    useEffect(() => {
        getLanguageListData();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
            try {
                const result = await regionCountryList();
    
                if (isMounted) {
                    let CountryData = result?.res?.data?.country?.filter((data) => {
                        return data?.region === region?.region?.name;
                    });
                    
                    setCountries(CountryData);
                    setAllCountry(result?.res?.data?.country);
                }
            } catch (error) {
                console.error(error);
                toast.warning("Something went wrong!");
            }
        };
    
        fetchData();
    
        return () => {
            // Cleanup function to set isMounted to false when the component unmounts
            isMounted = false;
        };
    
    }, [region]);
    // useEffect(async () => {
    //     try {
    //         const result = await regionCountryList();
    //         let CountryData = result?.res?.data?.country?.filter((data) => {
    //             return data?.region === region?.region?.name
    //         })
    //         setCountries(CountryData)
    //         setAllCountry(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }, [region]);
    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
 
    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            const results = await addAbout({
                "language" : language.language.name,
                // "quation": formData.question ? formData.question : "",
                "about" :  article ? article : ""

            });
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false)
                closeModal()
                console.log('"Admin');
                refreshDataMethod();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title">{t('add_About')}</h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                {/* <div className="mt-4 w-6/12">
                    <RadioGroup aria-label="adminType" name="adminType" className="radio-group" defaultValue={adminType} onChange={e => onAdminChangeHandler(e)}>
                        <FormControlLabel value="Platform Admin" control={<Radio />} label="Platform Admin" />
                        <FormControlLabel value="Regional Admin" control={<Radio />} label="Regional Admin" />
                        <FormControlLabel value="Country Admin" control={<Radio />} label="Country Admin" />
                        <FormControlLabel value="Coordination team" control={<Radio />} label="Coordination team" />
                    </RadioGroup>
                </div> */}
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                {/* <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData.question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder="Question"
                            minRows={3}
                        />
                    </div> */}
                
                    
                      <div className='editorSectionStart col-span-2'>
                        <Editor
                        placeholder={t('About')}
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />
                      </div>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')} />}
                    />
                  
                
                  
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                    {t('Submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default AboutForm;