import { useContext, useEffect, useMemo } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { ModalContext } from '../../Context';
import { useState } from 'react';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { BASE_URL_IMG, deleteNewsFeedPostApi,getResourcesCategory,getTags,StoryStatusApi } from '../../Utils/services';
import NewsFeedTable from './NewsFeedTable';
import { useNavigate, useMatch } from 'react-router-dom';
import EditNewsFeed from '../ModalTemplate/EditNewsFeed';
import { toast } from 'react-toastify';
import { Button, Grid, TextField } from '@material-ui/core';
import AntSwitch from '../Common/AntSwitch'
import { Helmet } from "react-helmet";
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { getCountriesData } from '../Master/getRequests';

const NewsFeedpage = ({ data, getForumsDataApi, totalCount, adminData }) => {
    const [page, setPage] = useState(1);
    const [size] = useState(10);
    const [totalPageToShow, setTotalPageToShow] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [countryData, setCountryData] = useState([]);
    const [selectfilter, setFilter] = useState([]);
    const [CategoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState([]);
    const history = useNavigate();
    // let { path } = useMatch();
    const {t} = useTranslation();
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    const [currentPage, setCurrentPage] = useState(page);
    const onInputCategoryChangeselect = (event, newInputValue, name) => { 
        setFilter({
          ...selectfilter,
          [name]: newInputValue
        })
      }
      const onInputCategoryChange = (event, newInputValue, name) => {
        setCategory({
          ...category,
          [name]: newInputValue
        })
      }
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          return result * sortOrder;
        }
      }

    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const handlePageChange = (event, value) => {
        setPage(value);
        setCurrentPage(value);

    };
    // console.log(totalCount,"totalCount")
    // useEffect(() => { 
    //     if (totalCount && totalCount > 0) {
    //         setTotalPageToShow(Math.ceil((totalCount) / size));
    //     }
    //     getForumsDataApi({ page, size });
    // }, [page, totalCount])
    // useEffect(() => {
    //     const trimmedSearchValue = searchValue.trim();
    //     if (totalCount && totalCount > 0) {
    //       setTotalPageToShow(Math.ceil(totalCount / size));
    //     }
        
    //     if (
    //       (category?.Tag?.name !== undefined && category?.Tag?.name !== "") ||
    //       (selectfilter?.Country?.name !== undefined && selectfilter?.Country?.name !== "") ||
    //       trimmedSearchValue !== ""
    //     ) {
    //       getForumsDataApi({
    //         country: selectfilter?.Country?.name,
    //         tag: category?.Tag?.name,
    //         search: trimmedSearchValue,
    //       });
    //     } else {
    //       getForumsDataApi({
    //         page: page,
    //         size: size,
    //       });
    //     }
    //   }, [page, totalCount, selectfilter?.Country?.name, category?.Tag?.name, searchValue]);
    // Effect to handle page change based on filters or search value
useEffect(() => {
  const trimmedSearchValue = searchValue.trim();
  const countryName = selectfilter?.Country?.name;
  const tagName = category?.Tag?.name;

  const isFilterApplied = 
      (tagName !== undefined && tagName !== "") ||
      (countryName !== undefined && countryName !== "") ||
      trimmedSearchValue !== "";

  if (isFilterApplied) {
      // Reset to page 1 when any filter or search is applied
      setCurrentPage(1);
  }
}, [selectfilter?.Country?.name, category?.Tag?.name, searchValue]);

// Effect to handle fetching data whenever the page or filters change
useEffect(() => {
  const trimmedSearchValue = searchValue.trim();
  const countryName = selectfilter?.Country?.name;
  const tagName = category?.Tag?.name;

  const payload = {
      page: currentPage,
      size: size,
      country: countryName || undefined,
      tag: tagName || undefined,
      search: trimmedSearchValue || undefined,
  };

  getForumsDataApi(payload);

  if (totalCount && totalCount > 0) {
      setTotalPageToShow(Math.ceil(totalCount / size));
  }

}, [currentPage, size, totalCount, selectfilter?.Country?.name, category?.Tag?.name, searchValue]);
  

    // useEffect(() => { 

    //     const trimmedSearchValue = searchValue.trim();
    //     if (totalCount && totalCount > 0) {
    //       setTotalPageToShow(Math.ceil(totalCount / size));
    //     }
    //     // if(selectfilter?.Country?.name !== undefined  )
    //     if (
    //       category?.Tag?.name !== undefined ||
    //       selectfilter?.Country?.name !== undefined || trimmedSearchValue === ""
    //     ) {
    //         getForumsDataApi({
    //         country: selectfilter?.Country?.name,
    //         tag: category?.Tag?.name,
    //       });
    //     }
    //     // else if(category?.Tag?.name !== undefined ){
    //     else if (
    //       category?.Tag?.name == undefined ||
    //       selectfilter?.Country?.name == undefined || trimmedSearchValue === ""
    //     ) {
    //         getForumsDataApi({
    //         page: page,
    //         size: size,
    //       });
    //     } else if (selectfilter?.Country?.name !== undefined) {
    //         getForumsDataApi({
    //         country: selectfilter?.Country?.name,
    //       });
    //     } else if (category?.Tag?.name !== undefined) {
    //         getForumsDataApi({
    //         tag: category?.Tag?.name,
    //       });
    //     }else if (trimmedSearchValue !== "") {
    //         getForumsDataApi({
    //             search: trimmedSearchValue,
    //       });
    //     }
    //   }, [page, totalCount, selectfilter?.Country?.name, category?.Tag?.name,searchValue]);
    

    const showPostDetail = item => {
        history(`${item._id}`,{
          
            state: {
                data: item,
                title: 'Gallery Details'
            }
        })
    }
    const deletePostGallery = async (id) => { 
        try {
            const result = await deleteNewsFeedPostApi({
                id: id
            });
            if (result.res.success) {
                // toast.success(result.res.message)
                toast.success("Newsfeed removed successfully")
                getForumsDataApi({ page, size });
            }

        } catch (error) {
            toast.error(error.messages)
        }
    }
    
    const HandleRemoveEvent = (id) => {
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('Remove_NewsFeed')}
            action={deletePostGallery}
            data={t('NewsFeedRemoveText')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };

    const EditPostDetail = (item) => { 
        const Edit_MODAL = <EditNewsFeed
            forumData={item}
            getForumsDataApi={getForumsDataApi}
            isModalView={true}
            title={t('Edit_NewsFeed')}
        />
        handleModalData(Edit_MODAL)
    }

    // const getCategoryData = async () => {
    //     try {
    //       const result = await getResourcesCategory();
    //       setCategoryData(result?.data)
    //     }
    //     catch (error) {
    //       console.log(error);
    //       toast.warning("Something went wrong!")
    //     }
    //   }
    //   useEffect(() => {
    //     getCategoryData();
    //   }, []);

      const geTagsApi = async () => {
        try {
          const result = await getTags();
          if(result.res.success){
            setCategoryData(result.res.data)
          }
        } catch (error) {
          toast.error(error.message)
        }
      }

      useEffect(() => {
        geTagsApi();
      }, []);

    const getCountryApiData = async () => {
        try {
          const result = await getCountriesData();
         // console.log(result, "Result627");
          setCountryData(result);
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        getCountryApiData();
      }, []);

    const handleActiveDeactive = async (props) => { 
        const id = props.row.original._id;
        try {
          const result = await StoryStatusApi(id);
          if (result.res.success) {
            toast.success(result.res.message)
            getForumsDataApi();
          }
        } catch (error) {
          toast.error(error.message)
        }
      }
      const handleLogin = (link) => { 
        window.open(link, "_blank");
      };
      
    const FeedsColumns = useMemo(
        () => [
            {
                Header: `${t("Post_Photo")}`,
                accessor: data => { 
                    if(data?.image){    
                    return (
                        <>
                       
                       <img  src={data?.image  ? data?.image :data?.image[0]?.link   } alt="Post Image" className='w-28 newsfeed' onClick={() => handleLogin(data?.links[0])}/>
                        
                           
                        </>
                    )
                    }else if(data?.image === ""){
                        return (
                            <>
                           
                           <img src={data?.media[0]?.link  ? data?.media[0]?.link :data?.image   } alt="Post Image" className='w-28 newsfeed' onClick={() => handleLogin(data?.links[0])}/>
                            
                               
                            </>
                        ) 
                    }else {
                        return (
                            <>
                           
                           <img src={data?.links[0]  ? data?.links[0] :data?.image   } alt="Post Image" className='w-28 newsfeed' onClick={() => handleLogin(data?.links[0])}/>
                            
                               
                            </>
                        ) 
                    }
                }
            },
            {
                Header: `${t("Title")}`,
                accessor: data => {
                    return (
                        <>
                            <h4 className='w-60 text-md'>{data?.title}</h4>
                        </>
                    )
                }
            },
            {
                Header:  `${t("Country")}`,
                accessor: data => {
                    return (
                        <>
                            {data?.country?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t("Tags")}`,
                accessor: data => {
                    return (
                        <>
                            {data?.tags?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t("Posted_On")}`,
                accessor: data => {
                    return (
                        <span>{formatDate(data?.date)}</span>
                    )
                }
            },
           
           
            {
                Header: `${t("Publisher")}`,
                accessor: data => {
                    return (
                        <span>{data?.publisher}</span>
                    )
                }
            },
            // {
            //     Header: 'Action',
            //     accessor: (data) => {
            //         return (
            //             <button
            //                 className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
            //                 type="button"
            //                 key={data.id}
            //                 onClick={() => showPostDetail(data)}
            //             >
            //                 <span className="MuiButton-label">View</span>
            //             </button>
            //         )
            //     },
            // },
            {
                Header: `${t("Edit")}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => { 
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => EditPostDetail(data)}
                        >
                            <span className="MuiButton-label">{t("Edit")}</span>
                        </button>
                    )
                },
            },
            {
                Header: `${t("Delete")}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => HandleRemoveEvent(data?._id)}
                        >
                            <span className="MuiButton-label">{t("Delete")}</span>
                        </button>
                    )
                },
            },
            // {
            //     Header: 'Status',
            //     accessor: "deactivated",
            //     Cell: props => { 
            //       return (
            //         <Grid item>
            //           <AntSwitch
            //             checked={props.row.original.status}
            //             onChange={() => handleActiveDeactive(props)}
            //             name="isDirectory"
            //           />
            //         </Grid>
            //       )
            //     }
            //   }
        ], [])

    return (
        <>
            <div className="flex justify-between items-center">
                <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
                 <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
       
        <div className="flex justify-between flex-wrap items-center">
        <Autocomplete
            options={countryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
             value={selectfilter?.name}
            className="w-56 mr-5"
            onChange={(e, newVal) => onInputCategoryChangeselect(e, newVal, 'Country')}
            renderInput={(params) => <TextField {...params} label={t('Filter_By_Country')} />}
          />
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'Tag')}
            renderInput={(params) => <TextField {...params} label={t('Filter_By_Tag')} />}
          />
        </div>
            </div>
            {data &&
                <NewsFeedTable columns={FeedsColumns} data={data} />
            }

        </>
    )
}

export default NewsFeedpage;