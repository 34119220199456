import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState, useRef } from 'react';
import { addForumPost, editForumPost, getAdminDetails, getTags, regionCountryList,BASE_URL_IMG } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState,convertFromHTMLToContentBlocks } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import "cropperjs/dist/cropper.css";
import {Cropper} from 'react-cropper';

const EditForum = ({ forumData, getForumsDataApi,title }) => {  
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(forumData?.country);
    const [formData, setFormData] = useState(forumData ? forumData : {});
    // const [imgFile, setImgFile] = useState(forumData?.media[0]?.mediaType === "photo" ? forumData?.media[0]?.link : '');
    const [imgFile, setImgFile] = useState( '');
    const [VideoFile, setVideoFile] = useState('');
    const [pdfFile, setPdfFile] = useState('');
    const [disableButton, setDisableButton] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo");
    const [youtubelink, setYoutubeLink] = useState([]);
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState(forumData?.tag);
    const [isFeatured, setIsFeatured] = useState(forumData?.isFeatured)
    const [isMediaEdit, setIsMediaEdit] = useState(false)
    const [crop, setCrop] = useState({ aspect: 2 / 1 });
    const [src, setSrc] = useState(null);
    const [image, setImage] = useState(null)
    const [result, setResult] = useState(null)
    const [contentType, setContentType] = useState("application/json")
    const [sendImageFile, setSendImageFile] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(forumData && forumData.content)
    )))
    const [article, setArticle] = useState("");


    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }



    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }


    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }
    const handleImgFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 600 && img.height === 300) {
                    setImgFile(val);
                    setMediaType("photo")
                   // setImgFilecrop("")
                    setSrc(null)
                }
                else {
                    setImgFile("");
                    setContentType("application/json")
                    setMediaType("photo")
                   // setImgFile(e.target.files[0])
                    setSrc(URL.createObjectURL(val))
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    console.log(formData, "Form Data")

    const handlePostSubmit = async (e) => {
       
        e.preventDefault();
        let tagsData;
        if (tagsValue?.tags) {
          tagsData = tagsValue?.tags?.map((data) => {
            return data._id;
          });
        } else {
          tagsData = tagsValue?.map((data) => {
            return data._id;
          });
        }
        let countryData;
        if (country?.country) {
          countryData = country?.country?.map((data) => {
            return data._id;
          });
        } else {
          countryData = country?.map((data) => {
            return data._id;
          });
        }
        if (sendImageFile || imgFile) {
          setLoader(true);
          const postFormData = new FormData();
          postFormData.append(
            "question",
            formData.question ? formData.question : ""
          );
          postFormData.append("tags", tagsData);
          postFormData.append("id", forumData?._id);
          postFormData.append("countrys", countryData);
          postFormData.append("isFeatured", isFeatured);
          postFormData.append("content", article ? article : formData.content);
          // if (isMediaEdit) {
          postFormData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);
          postFormData.append("mediaType", mediaType);
          //   }

          try {
            const result = await editForumPost(postFormData);
            if (result.res.success) {
              // toast.success(result.res.message);
              toast.success("Forum edited successfully");
              closeModal();
              getForumsDataApi();
              setLoader(false);
              console.log(result.res, "This is result response");
            }
          } catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
          }
        }else{
            setLoader(true);
            const postFormData = new FormData();
            postFormData.append(
              "question",
              formData.question ? formData.question : ""
            );
            postFormData.append("tags", tagsData);
            postFormData.append("id", forumData?._id);
            postFormData.append("countrys", countryData);
            postFormData.append("isFeatured", isFeatured);
            postFormData.append("content", article ? article : formData.content);
            // if (isMediaEdit) {
           // postFormData.append("media", sendImageFile && sendImageFile);
           // postFormData.append("mediaType", mediaType);
            //   }
  
            try {
              const result = await editForumPost(postFormData);
              if (result.res.success) {
                // toast.success(result.res.message);
                toast.success("Forum edited successfully");
                closeModal();
                getForumsDataApi();
                setLoader(false);
                console.log(result.res, "This is result response");
              }
            } catch (error) {
              setLoader(false);
              console.log(error);
              toast.error(error.message);
            }
        }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    // const handleImgChangeFile = (e) => {
    //     setContentType("application/json")
    //     setMediaType("photo")
    //     setImgFile(e.target.files[0])
    //     setSrc(URL.createObjectURL(e.target.files[0]))
    //   }
    const cropperRef = useRef(null);
    const getCropData = () => {
      if (typeof cropperRef.current?.cropper !== "undefined") {
        setResult(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
              fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
          .then(res => res.blob())  
          .then(blob => {
            const file = new File([blob], cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
            setSendImageFile(file)
          })
      }
    };
      // function getCroppedImg() {
      //   const canvas = document.createElement("canvas");
      //   const scaleX = image.naturalWidth / image.width;
      //   const scaleY = image.naturalHeight / image.height;
      //   // canvas.width = crop.width;
      //   // canvas.height = crop.height;
    
      //   var originWidth = crop.width * scaleX;
      //   var originHeight = crop.height * scaleY;
    
    
      //   // maximum width/height
      //   var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
      //   var targetWidth = originWidth,
      //     targetHeight = originHeight;
      //   if (originWidth > maxWidth || originHeight > maxHeight) {
      //     if (originWidth / originHeight > maxWidth / maxHeight) {
      //       targetWidth = maxWidth;
      //       targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      //     } else {
      //       targetHeight = maxHeight;
      //       targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      //     }
      //   }
      //        // set canvas size
      //        canvas.width = targetWidth;
      //        canvas.height = targetHeight;
         
      //        const ctx = canvas.getContext("2d");
         
      //        ctx.drawImage(
      //          image,
      //          crop.x * scaleX,
      //          crop.y * scaleY,
      //          crop.width * scaleX,
      //          crop.height * scaleY,
      //          0,
      //          0,
      //          targetWidth,
      //          targetHeight
      //        );
      //        console.log(canvas, "canvas")
      //        const base64Image = canvas.toDataURL("image/jpeg");
      //        var profile = new Image();
      //        profile.src = base64Image;
      //        setResult(profile.src)
         
      //        fetch(profile.src)
      //          .then(res => res.blob())
      //          .then(blob => {
      //            const file = new File([blob], profile.src, { type: "image/jpeg" })
      //            setSendImageFile(file)
      //          })
      //      }
           console.log(sendImageFile,"sendImageFile")
           
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title">{title}</h3>
            </div>
            <div noValidate autoComplete="off" className="w-full" >
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData.question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                    <div className='editorSectionStart col-span-2'>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />
                    </div>
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        defaultValue={forumData?.country}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        defaultValue={forumData?.tag}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label={t('Tags')} />}
                    />
                    <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} defaultChecked color="primary" />} label={t('Featured')} labelPlacement="start" />
                    </div>
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item md={1}>
                            <span className="text-paraText">{t('Add_Media')}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a Photo"
                                    value={imgFile.name ||result ||  ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>

                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                {t('Photo')}
                            </div>
                        </Grid>
                           {/* {
                      <Grid item md={6} container alignItems="center" spacing={1}>
                        <div className="relative w-auto overflow-hidden">
                          <TextField
                            id="profilephoto"
                            label="Add Photo"
                            value={imgFile.name || result || ""}
                            className="w-full text-xs"
                          />
                          <input
                            disabled={disableButton}
                            type="file"
                            accept="image/*"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="image-file"
                            onChange={handleImgFile}
                          />
                          <div className="floating-box" >+</div>
                        </div>
                      </Grid>
                    } */}
                    </Grid>
                   {src ? "" : <p className='mt-4 text-sidenav'>  {t('Imageshouldbe600x300px')}</p>} 
                    <Grid md={6}>
                    {
                      src &&
                      <div className="col-6 cropperclass">
                        {/* <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        /> */}
                                   <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={2/1}
          aspectRatio={2 / 1} 
          //preview=".img-preview"
          src={src}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false}
        />
                        <button
                          variant="contained"
                          onClick={getCropData}
                          className='cropbutton'
                        >
                          Crop Image
                        </button>
                      </div>
                    }

<div className='flex justify-center editimage'>
  <img
  src={`${BASE_URL_IMG}${forumData?.media[0]?.link}`}
  alt="Post Image"
  className="w-80 "
/>
</div>
                  </Grid>
                    {/* <p className='mt-4 text-sidenav'> {t('Imageshouldbe600x300px')}</p> */}
                </div>
                <div className="flex justify-center mt-10">
                    <button onClick={handlePostSubmit} className="button-1 mx-auto" disabled={loader ? true : false}>
                    {t('submit')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default EditForum