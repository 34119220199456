import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loader, showPrintButton }) => { 
  const override = {
    position: "absolute",
    top: showPrintButton ? `${10}%` : "50%",
    left: "50%",
  };
  return (
    <>
      {loader && (
        <ClipLoader
          size={100}
          loading={loader}
          color="#75ea9c"
          cssOverride={override}
        />
      )}
    </>
  );
};

export default Loader;
