export const CountryOPtion = [
    { label: "country", value: "country" },
    { label: "all", value: "all" },
  ];
export const CourseOption = [
    { label: "Beginner", value: "Beginner" },
    { label: "Intermediate", value: "Intermediate" },
    { label: "Advanced", value: "Advanced" },
  ];
export const CourseOptions = [
    { label: "Video", value: "Video" },
    { label: "Test", value: "Test" },
    { label: "Pdf", value: "Pdf" },
  ];
export const Questiontype = [
    { label: "video", value: "video" },
    { label: "Single", value: "Single" },
    { label: "pdf", value: "pdf" },
    { label: "Multiple", value: "Multiple" },
  ];
export const Translatedata = [
    { label: "English", value: "English" },
    { label: "हिंदी", value: "हिंदी" },
    { label: "Melayu", value: "Melayu" },
    { label: "नेपाली", value: "नेपाली" },
    { label: "অসমীয়া", value: "অসমীয়া" },
    { label: "বাংলা", value: "বাংলা" },
    { label: "ไทย", value: "ไทย" },
    { label: "မြန်မာစာ", value: "မြန်မာစာ" },
    { label: "ខ្មែរ", value: "ខ្មែរ" },
    { label: "Bahasa", value: "Bahasa" },
  ];
  export const Filterbydata = [
    { label: "Tag", value: "Tag" },
    { label: "Country", value: "Country" },
  ];
  
 