import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useContext, useEffect, useState, useRef } from "react";
import {
  addCoursePost,
  addFeedPost,
  addForumPost,
  EditCoursePost,
  getAdminDetails,
  getTags,
  regionCountryList,
  BASE_URL_IMG,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { CountryOPtion, CourseOption, CourseType } from "../Common/Option";
import { useTranslation } from "react-i18next";
import { Cropper } from "react-cropper";
const EditCourse = ({ getFeedData, title, data }) => {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(data?.Country);
  const [formData, setFormData] = useState(data ? data : {});
  const [imgFile, setImgFile] = useState("");
  const [imgFile2, setImgFile2] = useState("");
  const [imgFile3, setImgFile3] = useState("");
  const [loader, setLoader] = useState(false);
  const [imgFilecrop, setImgFilecrop] = useState("");
  const [mediaType, setMediaType] = useState("photo");
  const [tags, setTags] = useState([]);
  const [src, setSrc] = useState(null);
  const [sendImageFile, setSendImageFile] = useState("");
  const [result, setResult] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [tagsValue, setTagsValue] = useState([]);
  const [CourseType, setCourseType] = useState(data?.CourseType);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(data?.description))
    )
  );
  const [article, setArticle] = useState("");
  const [showImgError, setshowImgError] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let convertedData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setArticle(convertedData);
  };
  const { t } = useTranslation();
  const embedVideoCallBack = (link) => {
    link = link.replace("watch?v=", "embed/");
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
    return link;
  };

  // const getCountryData = async () => {
  //     try {
  //         const result = await regionCountryList();
  //         setCountries(result?.res?.data?.country)
  //     }
  //     catch (error) {
  //         console.log(error);
  //         toast.warning("Something went wrong!")
  //     }
  // }
  const adminId = localStorage.getItem("adminId");

  const getAdminDetailsApiData = async () => {
    try {
      const result = await getAdminDetails({
        id: adminId,
        type: "admin",
      });
      setCountries(result.data.country);
    } catch (error) {
      console.log(error);
    }
  };
  const geTagsApi = async () => {
    try {
      const result = await getTags();
      if (result.res.success) {
        setTags(result.res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAdminDetailsApiData();
    // getCountryData();
    geTagsApi();
  }, []);

  const onInputCountryChange = (event, newInputValue, name) => {
    setCountry({
      ...country,
      [name]: newInputValue,
    });
  };

  const onInputTagsChange = (event, newInputValue, name) => {
    setTagsValue({
      ...tagsValue,
      [name]: newInputValue,
    });
  };

  const onInputSectorsChange = (event, newInputValue, name) => {
    setCourseType({
      ...CourseType,
      [name]: newInputValue,
    });
  };

  const handleImgFile = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile(val);
          setMediaType("photo");
          setshowImgError(false);
          setImgFilecrop("");
          setSrc(null);
        } else {
          setImgFile("");
          setshowImgError(true);
          setMediaType("photo");
          setImgFilecrop(val);
          setSrc(URL.createObjectURL(val));
        }
      };
    }
    if (val.name !== "") {
      setDisableButton(true);
    }
  };
  const handleImgFile2 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile2(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile2("");
          setshowImgError(true);
        }
      };
    }
  };
  const handleImgFile3 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 400) {
          setImgFile3(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile3("");
          setshowImgError(true);
        }
      };
    }
  };

  const context = useContext(ModalContext);
  const { closeModal } = context;

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handlePostSubmit = async (e) => {
    
    setLoader(true);
    e.preventDefault();
    const tagsData = tagsValue?.tags?.map((data) => {
      return data._id;
    });
    const countryData = country?.country?.map((data) => {
      return data._id;
    });
    const postFormDataedit = new FormData();
    postFormDataedit.append("title", formData.title ? formData.title : "");
    postFormDataedit.append("CourseType", CourseType ? CourseType : "");
    postFormDataedit.append(
      "CourseDuretion",
      formData.CourseDuretion ? formData.CourseDuretion : ""
    );
    postFormDataedit.append("tags", tagsData ? tagsData : data?.tag[0]._id);
    postFormDataedit.append(
      "country",
      countryData ? countryData : data?.Country[0]._id
    );
    postFormDataedit.append("description", article ? article : data?.description);
    postFormDataedit.append("media", sendImageFile && sendImageFile ? sendImageFile :  imgFile && imgFile ? imgFile : "");
    postFormDataedit.append("id", data?._id);

    try {
      const result = await EditCoursePost(postFormDataedit);
      if (result.res.success) {
        // toast.success(result.res.message);
        toast.success("Course edited successfully");
        closeModal();
        getFeedData({
          page: 0,
          postsPerPage: 10,
        });
        setLoader(false);
        console.log(result.res, "This is result response");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const onAdminChangeHandler = (e) => {
    e.preventDefault();
    setCountry([]);
    const value = e.target.value;
    setCourseType(value);
  };

  const cropperRef = useRef(null);

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

      // Resize the canvas to reduce file size
      const resizedCanvas = document.createElement("canvas");
      const maxWidth = 800; // Adjust the maximum width as needed
      const maxHeight = 800; // Adjust the maximum height as needed
      const scale = Math.min(
        maxWidth / croppedCanvas.width,
        maxHeight / croppedCanvas.height
      );

      resizedCanvas.width = croppedCanvas.width * scale;
      resizedCanvas.height = croppedCanvas.height * scale;

      const ctx = resizedCanvas.getContext("2d");
      ctx.drawImage(
        croppedCanvas,
        0,
        0,
        resizedCanvas.width,
        resizedCanvas.height
      );

      // Convert the resized canvas to a data URL
      const resizedDataURL = resizedCanvas.toDataURL("image/jpeg", 0.8); // Adjust quality as needed
      setResult(resizedDataURL);
      // Create a Blob from the data URL and set the compressed file
      fetch(resizedDataURL)
        .then((res) => res.blob())
        .then((blob) => {
          const compressedFile = new File([blob], "compressed_image1.jpg", {
            type: "image/jpeg",
          });

          // Set the compressed file for further use (e.g., sending to the server)
          setSendImageFile(compressedFile);
          //  setImgFile(compressedFile);

          // Optional: Set the compressed image data URL
          console.log(compressedFile, "resizedDataURL");
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  };

  const getCropremove = () => {
    setImgFile("");
    setResult("");
    setMediaType("");
    setshowImgError(false);
    setImgFilecrop("");
    setSrc(null);

    const fileInput = document.querySelector('input[name="image-file"]');
    if (fileInput) {
      fileInput.value = ""; // Clear the value to allow re-selection of the same file
    }
  };

  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>
      <div noValidate autoComplete="off" className="w-full">
        <RadioGroup
          aria-label="CourseType"
          name="CourseType"
          className="radio-group"
          defaultValue={CourseType}
          onChange={(e) => onAdminChangeHandler(e)}
        >
          <FormControlLabel
            value="Beginner"
            control={<Radio />}
            label={t("Beginner")}
          />
          <FormControlLabel
            value="Intermediate"
            control={<Radio />}
            label={t("Intermediate")}
          />
          <FormControlLabel
            value="Advanced"
            control={<Radio />}
            label={t("Advanced")}
          />
          {/* <FormControlLabel value="Coordination team" control={<Radio />} label="Coordination team" /> */}
        </RadioGroup>
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <div className="col-span-2">
            <TextareaAutosize
              name="title"
              value={formData.title || ""}
              onChange={(e) => onChangeHandler(e)}
              className="w-full"
              id="postname"
              aria-label="Empty"
              placeholder={t("Title")}
              minRows={3}
            />
          </div>
          <div className="editorSectionStart col-span-2">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                embedded: {
                  embedCallback: embedVideoCallBack,
                },
              }}
            />
          </div>
          {/* <Autocomplete
                       id="sectors"
                       options={CourseOption?.sort(dynamicSort("name"))}
                       getOptionLabel={(option) => option?.value || ""}
                       sx={{ width: 300 }}
                       value={CourseType?.CourseType ? CourseType?.CourseType : CourseType[0]?.CourseType}
                    //    defaultValue={data?.CourseType}
                       onChange={(e, newVal) => onInputSectorsChange(e, newVal, "CourseType")}
                       renderInput={(params) => <TextField {...params} label="CourseType" />}
                     /> */}
          <TextField
            id="CourseDuretion"
            label={t("Course_Duration")}
            name="CourseDuretion"
            value={formData.CourseDuretion || ""}
            defaultValue={data?.CourseDuretion}
            onChange={(e) => onChangeHandler(e)}
          />
          <Autocomplete
            id="combo-box-demo22"
            multiple
            options={countries?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            sx={{ width: 300 }}
            value={country?.name}
            defaultValue={data?.Country}
            onChange={(e, newVal) => onInputCountryChange(e, newVal, "country")}
            renderInput={(params) => (
              <TextField {...params} label={t("Country")} />
            )}
          />
          <Autocomplete
            id="combo-box-demo2"
            multiple
            options={tags?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            sx={{ width: 300 }}
            value={tagsValue?.name}
            defaultValue={data?.tag}
            onChange={(e, newVal) => onInputTagsChange(e, newVal, "tags")}
            renderInput={(params) => (
              <TextField {...params} label={t("Tags")} />
            )}
          />
        </div>
        <div className="mt-12 w-12/12">
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={1}>
              <span className="text-paraText">{t("Add_Media")}</span>
            </Grid>
            <Grid item md={6}>
              <div className="relative w-auto overflow-hidden">
                <TextField
                  id="profilephoto"
                  label={t("Add_Photo")}
                  value={(imgFile.name || result || "").slice(0, 25)}
                  className="w-full text-xs"
                />
                <input
                  type="file"
                  accept="image/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="image-file"
                  onChange={(e) => handleImgFile(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
              <div className="media-box mt-2">
                <img src="../../assets/photos.svg" alt="" />
                {t("Photo")}
              </div>
            </Grid>
            <Grid md={6}>
              {src && (
                <div className="col-3 cropperclass">
                  {/* <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        /> */}
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={2 / 1}
                    aspectRatio={2 / 1}
                    //preview=".img-preview"
                    src={src}
                    ref={cropperRef}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                  />
                  <div className="Zoombtn">
                    <button
                      variant="contained"
                      onClick={getCropData}
                      className="cropbutton"
                    >
                      Crop Image
                    </button>
                    <button
                      variant="contained"
                      onClick={getCropremove}
                      className="cropbutton"
                    >
                      Remove Crop
                    </button>
                  </div>
                </div>
              )}
            </Grid>
            {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 2"
                                    value={imgFile2.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile2(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
            {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 3"
                                    value={imgFile3.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile3(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
            <p className="mt-4 text-sidenav"> {t("Image_shouldbe400x400px")}</p>
          </Grid>
        </div>
        <div className="flex  editimage">
          {data?.image && src === null && (
            <img
              src={`${BASE_URL_IMG}${data?.image}`}
              alt="Post Image"
              className="w-80 "
            />
          )}
        </div>
        <div className="flex justify-center mt-10">
          <button
            className="button-1 mx-auto"
            disabled={loader ? true : false}
            onClick={handlePostSubmit}
          >
            {t("Submit")}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditCourse;
