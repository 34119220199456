import styles from "../../Assets/Comment.module.css";
import {
 addCommentStory,
  BASE_URL_IMG,
  deleteCommentForum,
  deleteCommentStory,
} from "../../Utils/services";
import React from 'react'
import avtarImg from "../../Assets/images/DummyDp.png";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FunctionStoryContext } from "../../Context";
import { useEffect } from "react";
import CommentGallerydatabox from "./CommentStorydatabox";
import CommentStorydatabox from "./CommentStorydatabox";

const CommentGalleryBox = ({commentBoxDataProps, singlePostData, postId,  }) => { 
  const { postStoryDatas, getStoryDataApi, getStoryDataApis,StoryDatas } = useContext(FunctionStoryContext);
  const [showReplyInput, setShowReplyInput] = useState({});
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [showPerticularData, setShowPerticularData] = useState(singlePostData.reverse());
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

 

  const handleSubmitComment = async (e) => {
    e.preventDefault();
    try {
      const result = await addCommentStory({
        postId: postId,
        comment : comment,
      });
      if (result.res.success) {
        // toast.success(result.res.message);
        toast.success("Commented successfully");
        setComment("");
        getStoryDataApi();
        getStoryDataApis();
      }
      console.log(result);
    } catch (error) {
      toast.error(error.message);
    }
  };



  useEffect(() => {
    // let _result = postStoryDatas?.filter((items) => {
    //   return items?._id === postId;
    // });
    // setShowPerticularData(_result && _result[0]?.comments);
    setShowPerticularData(StoryDatas?.comments.reverse());
  });
  useEffect(() => {
    getStoryDataApis();
  }, [])

  
  return (
    <>
      {showPerticularData &&
        showPerticularData.map((data,index) => {
          return (
          <React.Fragment key={index}>
            <CommentStorydatabox data={data} postId={postId} commentBoxDataProps={commentBoxDataProps}/>
          </React.Fragment>
          );
        })}
      <div className="grid grid-cols-8 my-6">
        <div className="col-span-1 flex justify-center items-center">
          <img src={avtarImg} className=" w-12 rounded-full" alt="" />
        </div>
        <div className="col-span-5">
          <form
            onSubmit={handleSubmitComment}
            className="flex justify-center items-center"
          >
            <input
              type="text"
              name="comment"
              className="commentSectionInput"
              placeholder="Comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CommentGalleryBox;
