// import './messaging_init_in_sw';
import Auth from "./Auth";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import Layout from "./Layout";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../Utils/auth-context";
import { lazy, useContext } from "react";
import ForgotPassword from "./Auth/ForgotPassword";
import ChangePassword from "./Auth/ChangePassword";
import OtpVerification from "./Auth/OtpVerification";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import LoginOtpVerification from "./Auth/LoginOtpVerification";
import { onMessageListener } from "./firebaseInit";
import React, { useState, useEffect } from "react";
import Notifications from "./Notifications/Notifications";
import ReactNotificationComponent from "./Notifications/ReactNotification";
import { allUsersdata, NewRegistrationUsers } from "../Utils/services";
import UIkit from "./100ms";
import Appfirebasereflected from "./Pushnotification/Appfirebasereflected";
// const AgoraUI = lazy(() => import("./AgoraMeeting"));

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({
    title: "",
    body: "",
    setShow: false,
  });
  const [notificationLength, setNotificationLength] = useState([]);
  const [newRegistrationData, setNewRegistrationData] = useState([]);
  const [Counts, setCounts] = useState();
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [contacts, setContacts] = useState([]);
  const [contactsfilter, setContactsfilter] = useState([]);
  const [reload, setreload] = useState(false);
  const [isServiceWorkerActive, setIsServiceWorkerActive] = useState(false);

  if (process.env.NODE_ENV !== 'development') {
    console.info = () => {};
    console.log = () => {};
    console.error = () => {};
  }
  onMessageListener()
    .then((payload) => {
      console.log(payload, "payload");
      toast.info(
        // <Link to={{pathname: 'Chat', state: payload?.notification?.body}} >
        <Display
          title={payload?.notification?.title}
          body={payload?.notification?.body}
        />
        // </Link>
      );

      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        setShow: true,
      });
      // console.log(payload,"payload");
      // if(payload.notification.title === "chat notify"){
      // setNotificationLength(prev => [...prev, payload])
      // let lastElement = notificationLength?.length + 1;
      // localStorage.setItem("Notificationlength", lastElement);
      // }
    })
    .catch((err) => console.log("failed: ", err));

  const Display = ({ title, body }) => {
    return (
      <div>
        <h4>{title}</h4>
        {/* <p>{body}</p> */}
      </div>
    );
  };
  const getnewRegistraionTablesData = async (sendData) => {
    try {
      const result = await NewRegistrationUsers(sendData);
      // console.log(result, "This is results user All Data");

      setLoader(false);
      setNewRegistrationData(result.res?.data?.user);
      let registration = result.res?.data?.user?.length;
      localStorage.setItem("registration", registration);
      setCounts(result.res?.data?.userCount);
    } catch (error) {
      setLoader(false);
      console.log(error, "Error");
      toast.error(error.message);
    }
  };
  useEffect(() => {
    {
      authCtx.isLoggedIn &&
        getnewRegistraionTablesData({
          page: page,
          size: postsPerPage,
        });
    }
  }, [page, notification]);

  const getNotificationData = async () => {
    try {
      const result = await allUsersdata();
      // console.log(result.res?.falseNotify, "chatNotification");

      if (result?.res?.message) {
        setContacts(result.res?.falseNotify);
      }
      let _UserData = result.res?.falseNotify.filter((data) => {
        return data?.read === false;
      });

      localStorage.setItem("Notificationlength", _UserData?.length);
      setContactsfilter(_UserData?.length);
    } catch (error) {
      // setLoader(false)
      console.log(error, "Error");
    }
  };
  useEffect(() => {
    {
      authCtx.isLoggedIn && getNotificationData();
    }
  }, [notification, contactsfilter]);

  localStorage.setItem("getNotificationData", getNotificationData.toString());
  // localStorage.setItem('getNotificationData', JSON.stringify(getNotificationData));
  // localStorage.setItem('getNotificationData', JSON.stringify(getNotificationData));

  // const history = useNavigate();
  const authCtx = useContext(AuthContext);
  // const initialToken = localStorage.getItem("token");
  // if(!!initialToken){
  //   history.push("/login")
  // }
  // console.log(notification?.setShow, "show");
  // console.log(show, "show");
  // console.log(contactsfilter, "contactsfilter");

    useEffect(() => {
    if (window.navigator.serviceWorker) {
      setIsServiceWorkerActive(true);
    }
  }, []);
  return (
    <Router>
      {/* <Appfirebasereflected /> */}
      <Notifications />
      <ToastContainer position="top-right" autoClose={3000} />
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/changepassword" element={<ChangePassword />} />
        <Route path="/otp" element={<OtpVerification />} />
        <Route path="/loginOtp" element={<LoginOtpVerification />} />
        {authCtx.isLoggedIn ? (
          <>
            <Route
              path="/*"
              element={<Layout getNotificationData={getNotificationData} contacts={contacts} />}
            />
            <Route path="/glpmeeting/:slug" element={<UIkit />} />
          </>
        ) : (
          <Route path="/login" element={<Auth />} />
        )}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
