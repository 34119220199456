import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { regionCountryList, getCategoryList, editDirectory, getSectorList, getSegmentApi, editCategory, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import AntSwitch from '../Common/AntSwitch';
import { useTranslation } from 'react-i18next';

const EditCategoryForm = ({
    title,
    buttonText,
    getTablesData,
    id,
    perticularData
}) => { 
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState(perticularData?.country?.name || []);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState(perticularData?.category?.name || []);
    const [formData, setFormData] = useState(perticularData);
    const [gender, setGender] = useState("Male");
    const [loader, setLoader] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const { t } = useTranslation();
    useEffect(() => {
        getAdminDetailsApiData();
        getLanguageListData();
        getSectorListData();
        getSegmentData();
    }, [])

  


    const getSectorListData = async () => {
        try {
            const result = await getSectorList();
            setSectorData(result.res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const getSegmentData = async () => {
        try {
            if (country?.country?._id) {
                const result = await getSegmentApi();
               let _resultData = result?.res?.data?.filter((data) => {
                return data?.country?.name === country?.country?.name
               })
                setSegmentData(_resultData)
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, [country]);

    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }



    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }

    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    const getLanguageListData = async () => {
        try {
            const result = await getCategoryList();
            setLanguageList(result?.res?.data)
            
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    const onChangeHandler = e => { 
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {  
        console.log(formData, "formData")
        e.preventDefault();
        setLoader(true);
        try {
            const results = await editCategory({ 
                "name": formData.name ? formData.name : perticularData?.name,
                // "description": formData.description ?  formData.description : perticularData?.description,
                // "category": language?.Category?._id ?  language?.Category?._id : perticularData?.language?.name,
                // "Phone_No": formData.Phone_No ? formData.Phone_No : perticularData?.Phone_No,
                "country": country?.country?._id ?  country?.country?._id : perticularData?.country?._id,
                 "id": id,
            });
            
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("Category edited successfully");
                setLoader(false)
                closeModal()
                getTablesData();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
  

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title}</h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Name')} 
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    {/* <TextField
                        label="Description"
                        name="description"
                        value={formData.description || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                 
                    <TextField
                        label="Mobile No"
                        name="Phone_No"
                        value={formData.Phone_No || ''}
                        onChange={e => onChangeHandler(e)}
                    /> */}
                    {/* <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        defaultValue={perticularData?.category}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'Category')}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                    /> */}
                    <Autocomplete
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        defaultValue={perticularData?.country}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                     {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditCategoryForm;