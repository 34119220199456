import { FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import { EditAdmin, getLanguageList, getSectorList, regionCountryList } from '../../Utils/services';
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';

const AdminDetailEditForm = ({ userAllData, getAdminDetailsApiData }) => {
    const [name, setName] = useState(userAllData.name);
    const [email, setEmail] = useState(userAllData.email);
    const [number, setNumber] = useState(userAllData.mobile);
    const [designation, setDesignation] = useState(userAllData.designation);
    const [loader, setLoader] = useState(false);
    const [ismultipleCountry, setIsMultipleCountry] = useState(true);
    const [languageList, setLanguageList] = useState(userAllData?.language);
    const [language, setLanguage] = useState(userAllData?.language);
    const [gender, setGender] = useState("Male");
    const [adminType, setAdminType] = useState(userAllData?.AdminType);
    const [countries, setCountries] = useState(userAllData?.country);
    const [country, setCountry] = useState(userAllData?.country);
    const [age, setAge] = useState(userAllData?.age);
    const [regions, setRegions] = useState([]);
    const [region, setRegion] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;

    let AdminType = localStorage.getItem('AdminType');
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const {t} = useTranslation();

    const onAdminChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setAdminType(value);
        if (value === "Platform Admin") {
            setIsMultipleCountry(true)
        }
        if (value === "Regional Admin") {
            setIsMultipleCountry(true)
        }
        if (value === "Country Admin") {
            setIsMultipleCountry(false)
        }
        if (value === "Coordination Team Admin") {
            setIsMultipleCountry(false)
        }
    }
    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }
    const onGenderChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setGender(value)
    }

    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }

    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            setRegions(result?.res?.data?.region)
            
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    // useEffect(async () => {
    //     try {
    //         const result = await regionCountryList();
    //         let CountryData = result?.res?.data?.country?.filter((data) => {
    //             return data?.region?.name === region?.region?.name
    //         })
    //         setCountries(CountryData)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }, [region]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await regionCountryList();
                let CountryData = result?.res?.data?.country?.filter((data) => {
                    return data?.region?.name === region?.region?.name
                })
                setCountries(CountryData)
            } catch (error) {
                console.log(error);
                toast.warning("Something went wrong!")
            }
        };
    
        fetchData();
    
    }, [region]);
    

    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getCountryData();
        getLanguageListData();
    }, []);

    const onInputRegionChange = (event, newInputValue, name) => {
        setRegion({
            ...region,
            [name]: newInputValue
        })
        setCountry([]);
    }

    const handleEditSubmit = async (e) => { 
        setLoader(true)
        e.preventDefault();
        try {
            let countryData;
            if (country && country.country) {
                if (ismultipleCountry) {
                    countryData = country && country.country !== undefined ? country && country.country && country.country.map((data) => {
                        return data._id
                    })
                        :
                        country?.map((data) => {
                            return data._id
                        })
                }
                else {
                    countryData = country.country._id;
                }
            }
            else{
                if (ismultipleCountry) {
                    countryData = userAllData?.country !== undefined ? userAllData?.country.map((data) => {
                        return data._id
                    })
                        :
                        country?.map((data) => {
                            return data._id
                        })
                }
                else {
                    countryData = userAllData?.country._id;
                }
            }
            let lang = language.language !== undefined ? language.language.name : language[0].name
            if (lang == 'हिंदी') {
                localStorage.setItem('lng', 'hi');
                localStorage.setItem('language',lang)
            }
            else if (lang == 'বাংলা') {
                localStorage.setItem('lng', 'bn');
                localStorage.setItem('language',lang)
            }
            else if (lang == 'မြန်မာစာ') {
                localStorage.setItem('lng', 'my');
                localStorage.setItem('language',lang)
            }
            else if (lang == 'Bahasa') {
                localStorage.setItem('lng', 'id');
                localStorage.setItem('language',lang)

            }
            else if (lang == 'অসমীয়া') {
                localStorage.setItem('lng', 'as');
                localStorage.setItem('language',lang)
            }
            else if (lang == 'Melayu') {
                localStorage.setItem('lng', 'ms');
                localStorage.setItem('language',lang)

            }
            else if (lang == 'नेपाली') {
                localStorage.setItem('lng', 'ne');
                localStorage.setItem('language',lang)

            }
            else if (lang == 'ไทย') {
                localStorage.setItem('lng', 'th');
                localStorage.setItem('language',lang)

            }
            else if (lang == 'ខ្មែរ') {
                localStorage.setItem('lng', 'km');
                localStorage.setItem('language',lang)
            }
            else if (lang == 'English') {
                localStorage.setItem('lng', 'en');
                localStorage.setItem('language',lang)

            }
            else {
                localStorage.setItem('lng', 'en');
                localStorage.setItem('language',lang)
            }
            const formDataValue = new FormData();
            if(AdminType === "Platform Admin"){
               
            formDataValue.append("AdminType", adminType);
            formDataValue.append("name", name);
            formDataValue.append("language", language.language !== undefined ? language.language.name : language[0].name);
            formDataValue.append("email", email?.toLowerCase());
            formDataValue.append("mobile", number);
            formDataValue.append("designation", designation);
            formDataValue.append("gender", gender);
            formDataValue.append("age", age);
            formDataValue.append("country", countryData);
            // formDataValue.append("region", region?.region?._id ? region?.region?._id : userAllData?.region?.id);
            formDataValue.append("id", userAllData?._id);
            } else{
                // const formDataValue = new FormData();
            formDataValue.append("AdminType", adminType);
            formDataValue.append("name", name);
            formDataValue.append("language", language.language !== undefined ? language.language.name : language[0].name);
            formDataValue.append("email", email?.toLowerCase());
            formDataValue.append("mobile", number);
            formDataValue.append("designation", designation);
            formDataValue.append("gender", gender);
            formDataValue.append("age", age);
            formDataValue.append("country", countryData);
            formDataValue.append("region", region?.region?._id ? region?.region?._id : userAllData?.region?.id);
            formDataValue.append("id", userAllData?._id);
            }
         
            const result = await EditAdmin(formDataValue);
            if (result.res.success) {
                setLoader(true)
                toast.success(result.res.message);
                setLoader(false)
                closeModal()
                getAdminDetailsApiData();
               // console.log(result)
                window.location.reload();
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('EDIT_ADMIN')} </h3>
            </div>
            <form className="w-full" onSubmit={handleEditSubmit}>
                <div className="mt-4 w-6/12">
                    {/* <RadioGroup aria-label="adminType" name="adminType" className="radio-group" defaultValue={adminType} onChange={e => onAdminChangeHandler(e)}>
                        <FormControlLabel value="Platform Admin" control={<Radio />} label={t('Platform_Admin')} />
                        <FormControlLabel value="Regional Admin" control={<Radio />} label={t('Regional_Admin')}  />
                        <FormControlLabel value="Country Admin" control={<Radio />} label={t('Country_Admin')} />
                        <FormControlLabel value="Coordination team" control={<Radio />} label={t('Coordination_team')} />
                    </RadioGroup> */}
                </div>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        name="name"
                        id="name"
                        label={t('Name')}
                        className="w-full"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        type={'number'}
                        label={t('Age')}
                        name="age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                    />
                    <TextField
                        name="email"
                        type="email"
                        id="email"
                        label={t('Email')}
                        className="w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextField
                        name="number"
                        id="number"
                        label={t('Mobile_Number')}
                        className="w-full"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        sx={{ width: 300 }}
                        defaultValue={userAllData && userAllData.language && userAllData.language[0]}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')} />}
                    />
                 
                         <Autocomplete
                         id="combo-box-demo2"
                         options={regions?.sort(dynamicSort("name"))}
                         getOptionLabel={(option) => option?.name}
                         sx={{ width: 300 }}
                         value={region?.name}
                         defaultValue={userAllData?.region}
                         onChange={(e, newVal) => onInputRegionChange(e, newVal, 'region')}
                         renderInput={(params) => <TextField {...params} label={t('Region')} />}
                     />
                    
                  

                    {
                        region?.region?.name && ismultipleCountry &&
                        <Autocomplete
                            id="combo-box-demo2"
                            multiple
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={country?.name}
                            defaultValue={userAllData.country}
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                        />
                    }
                    {
                        region?.region?.name && !ismultipleCountry &&
                        <Autocomplete
                            id="combo-box-demo2"
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={country?.name}
                            defaultValue={userAllData.country}
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                        />
                    }
                    <div className="mt-4 w-6/12">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"> {t('Gender')}</label>
                        <RadioGroup aria-label="gender" name="gender" className="radio-group" defaultValue={gender} onChange={e => onGenderChangeHandler(e)}>
                            <FormControlLabel value="Male" control={<Radio />} label={t('Male')} />
                            <FormControlLabel value="Female" control={<Radio />} label={t('Female')} />
                        </RadioGroup>
                    </div>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type='submit'>
                        {t('SAVE')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default AdminDetailEditForm
