import { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button} from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { deleteCategoryPostApi, deleteDirectoryPostApi, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import DummyDpImg from "../../Assets/images/DummyDp.png"
import UserApproveModal from '../ModalTemplate/UserApproveModal';
import UserDeclineModal from '../ModalTemplate/UserDeclineModal';
import CategoryTable from './CategoryTable';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import EditDirectoryForm from '../ModalTemplate/EditDirectoryForm';
import EditCategoryForm from '../ModalTemplate/EditCategoryForm';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const CategoryList = ({ data, getcategoryData, adminData }) => {
  const [searchValue, setSearchValue] = useState("");
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  useEffect(() => {
    getcategoryData();
  }, [])

  const history = useNavigate();
  const editHandler = (data) => {
    const EDIT_PARTY = <UserEditForm
      title="Edit user"
      buttonText="Save Changes"
      id={data.row.original._id}
      perticularData={data.row.original}
      getcategoryData={getcategoryData}
    />
    handleModalData(EDIT_PARTY);
  }
  const { t } = useTranslation();
  const handleApprove = async (data) => {
    const OPEN_APPROVE_MODAL = <UserApproveModal
      id={data.cell.row.original._id}
      getcategoryData={getcategoryData}
   
    />
    handleModalData(OPEN_APPROVE_MODAL, 'md')
  }

  const handleDecline = async (data) => {
    const OPEN_DECLINE_MODAL = <UserDeclineModal
      id={data.cell.row.original._id}
      getcategoryData={getcategoryData}
   
    />
    handleModalData(OPEN_DECLINE_MODAL, 'md')
  }


  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
         getcategoryData();
      }
    } catch (error) {
      toast.error(error.message)
    }

  }
  const EditPostDetail = (data) => { 
    const EDIT_PARTY = <EditCategoryForm
      title={t('Edit_Category')}
      buttonText={t('Submit')}
      id={data._id}
      perticularData={data}
      getTablesData={getcategoryData}
    />
    handleModalData(EDIT_PARTY);
  }

  const deletePostGallery = async (id) => {  
    try {
        const result = await deleteCategoryPostApi({
                id: id
        });
        if (result.res.success) {
            // toast.success(result.res.message)
            toast.success("Category deleted successfully")
            getcategoryData();
        }

    } catch (error) {
        toast.error(error.messages)
    }
}
  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t('Remove_Category')}
        action={deletePostGallery}
        data={t('categoryRemoveText')}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };
  const userColumns = useMemo(
    () => [
        {
            Header: `${t('Category')}`,
            accessor: data => {  
                return (
                    <span>{data?.name}</span>
                )
            }
        },
        {
            Header: `${t('Country')}`,
            accessor: data => { 
                return (
                    <span>{data?.country?.name}</span>
                )
            }
        },
        {
          Header: `${t('Delete')}`,
          accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => {
              return (
                  <button
                      className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                      type="button"
                      key={data._id}
                      onClick={() => HandleRemoveEvent(data?._id)}
                  >
                      <span className="MuiButton-label">{t('Delete')}</span>
                  </button>
              )
          },
      },
      {
        Header: `${t('Edit')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : (data) => { 
            return (
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={data.id}
                    onClick={() => EditPostDetail(data)}
                >
                    <span className="MuiButton-label">{t('Edit')}</span>
                </button>
            )
        },
    },

    //         {
    //     Header: 'Organisation Name',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.OrganisationName} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
    //         {
    //     Header: 'Phone No.',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.Phone_No} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
    //         {
    //     Header: 'Country',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.country} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
    //         {
    //     Header: 'Description',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.description} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
    //   {
    //     Header: "Action",
    //     accessor: "_id",
    //     Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
    //   },
    //   {
    //     Header: "Approve",
    //     accessor: "",
    //     Cell: props => <Button className="btn2" onClick={() => handleApprove(props)}> Approve </Button>
    //   },
    //   {
    //     Header: "Decline",
    //     accessor: "",
    //     Cell: props => <Button className="btn2" onClick={() => handleDecline(props)}> Decline </Button>
    //   },
    ], [])

  return (
    <>
      <div className="flex justify-between items-center">
      </div>
      {
        data && <CategoryTable columns={userColumns} data={data.filter((val, index) => { 
          if (searchValue === "") {
            return val
          }
          else if (val.aliasName && val.aliasName.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.segment && val.segment.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
            return val
          }
        })} />
      }
    </>
  )
}

export default CategoryList;