import React, { useState, useEffect, useRef } from "react";
import ChatInput from "./ChatInput";
import Message from "./Message";
import { Sendmessage, getmessage, BASE_URL_IMG,deleteMessage } from "../../Utils/services";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import vmsg from "vmsg"
import moment from "moment";
import adminlogo from "../../Assets/chatimage/app_icon.png";
//import  from "../../Utils/services"


// const recorder = new vmsg.Recorder({
//   wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
// });
export default function ChatContainer({ currentChat, currentusername, socket }) {
  const [messages, setMessages] = useState([]);
  const [Voices, setVoicedata] = useState();
  const [messagess, setMessagess] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isRecording, setisRecording] = useState(false);
  const [recordings, setrecordings] = useState();
  const scrollRef = useRef();
  const [arrivalMessage, setArrivalMessage] = useState(null);
  // const [timer, setTimer] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isToggled, setIsToggled] = useState({});

  const requestRef = useRef();
  const previousTimeRef = useRef();

  const getmessageDataApi = async () => {
    try {
      const result = await getmessage({
        // senderId: currentusername._id,
        targetId: currentChat._id,
      });
      setMessages(result.res.projectedMessages);
      // setMessagess(result.res.projectedMessages)
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getmessageDataApi();
  }, [currentChat]);
  const AdminType = localStorage.getItem("AdminsName")
  // console.log(recordings, "recordings&&recordings")
  const handleSendMsg = async (msg) => {
    if (msg !== "" || recordings !== undefined) {
      try {
        // console.log(recordings && recordings, "Voices && Voices ")
        if (msg) {
          socket.emit("message", {
            "message": msg ? msg : "",
            "senderId": currentusername._id,
            "targetId": currentChat._id,
            "isAudio": false,
            "adminmsg": "adminmsg",
            "adminName": AdminTypenames,
            "path": ""
            // senderId: currentusername._id,
            // targetId: currentChat._id,
            // msg,
          })
        }
        const formDatamsg = new FormData();
        formDatamsg.append("senderId", currentusername._id);
        formDatamsg.append("targetId", currentChat._id);
        formDatamsg.append("roomId", currentChat._id);
        formDatamsg.append("message", msg ? msg : "");
        formDatamsg.append("adminmsg", "adminmsg");
        formDatamsg.append("adminName", AdminTypenames);
        formDatamsg.append("media", null);

        const formDataaudio = new FormData();
        formDataaudio.append("senderId", currentusername._id);
        formDataaudio.append("targetId", currentChat._id);
        formDataaudio.append("roomId", currentChat._id);
        formDataaudio.append("message", "");
        formDataaudio.append("adminmsg", "adminmsg");
        formDataaudio.append("adminName", AdminTypenames);
        formDataaudio.append("media", recordings && recordings);


        const result = await Sendmessage(msg ? formDatamsg : formDataaudio);
        getmessageDataApi()
        if (result.res.path) {
          socket.emit("message", {
            "message": msg ? msg : "",
            "senderId": currentusername._id,
            "targetId": currentChat._id,
            "isAudio": true,
            "path": result.res.path,
            "adminmsg": "adminmsg",
            "adminName": AdminType,
            // senderId: currentusername._id,
            // targetId: currentChat._id,
            // msg,
          })
          setrecordings()
         
          // const msgs = [...messages];
          // msgs.push({ fromSelf: currentusername._id, voice:result.res.path});
          // setMessages(msgs);
          // console.log(msgs,"msgssssss")
        }

        // const msgs = [...messages];
        // msgs.push({ fromSelf: currentusername._id, message: msg, voice:result.res.path});
        // setMessages(msgs);
        // console.log(msgs,"msgssssss")

      } catch (error) {
        toast.error(error.message);
      }
    }
  };






  // Update the timer every second
  // useEffect(() => {
  //   let interval;
  //   if (isRecording) {
  //     interval = setInterval(() => {
  //       setTimer(timer => timer + 1);
  //     }, 1000);
  //   }
  //   return () => clearInterval(interval);
  // }, [isRecording]);

  // const minutes = Math.floor(timer / 60);
  // const seconds = timer % 60;
  // const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  // useEffect(() => {
  //   if(recordings){ 
  //     handleSendMsg()
  //   }

  // },[recordings])

  // useEffect(() => {
  //   if (recordings) {
  //     console.log(recordings,"recd")
  //     const recd = [];
  //     recd.push([{fromSelf: true, message: recordings}]);

  //     let segmentData = recd[0]?.map((item) => {
  //       return item;
  //     });
  //     setVoicedata(segmentData[0].message);
  //     // setMessages(segmentData[0].message);
  //   }
  // }, [recordings]);
  const AdminTypenames = localStorage.getItem("AdminType");
  useEffect(() => {
    if (socket) {
      socket.on("message", (msg) => {
        setArrivalMessage({ message: msg.message, fromSelf: msg.senderId, voice: msg.path, time: new Date(), adminmsg: msg.adminmsg, adminName: msg?.adminName });
        // console.log( msg.message, "message");
        // console.log( msg.isAudio ,"isAudio");

      });

    }
  }, [socket]);
  //     socket.on('message1', (msg) => {
  //     setMessages({fromSelf: true, ...messages, msg});
  //     console.log(msg.user, msg.message, msg.id);
  // })

  // console.log(arrivalMessage, "arrivalMessage");
  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // console.log(messages, "messagessssssss");
  const handleToggle = (id) => {
    setIsToggled({
      [id]: !isToggled[id]
    });
  };

  const getpollDataApi = async (id) => {
    getmessageDataApi();
    if(id){
    try {
        const result = await deleteMessage(id);
        // console.log(result)
        
        if (result.res.success) {
          getmessageDataApi();
          // toast.success(result.res.success)
          toast.success("Message deleted successfully")
         
      }
    }
  
  
    catch (error) {
       // setLoader(false)
        console.log(error)
        toast.error(error.message);
    }
  }
}
  return (
    <>
      <div className="ChatContainer">
        <div className="chat-header">
          <div className="userdetails">
            <div className="usernames">
              <h3>{currentChat?.fromName}</h3>
              <img src={`${BASE_URL_IMG}/user/${currentChat?._id}/${currentChat?._id}.jpeg`}></img>
            </div>
          </div>
        </div>
        <div className="chat-messages">
          {messages && messages?.map((message) => {
            //  console.log(message,"message")
            //  console.log(message?.isAudio,"isAudio")
            //  console.log(messages,"messages")
            return (
              <div
                ref={scrollRef} key={uuidv4()}
              >
                <div
                  className={`message ${
                    //  message.fromSelf ? "sended" : "recieved"
                    message.fromSelf === currentusername._id ? "sended" : "recieved"
                    }`}
                >
                  <div className={` ${message.fromSelf === currentusername._id ? "adminreply" : "userreply"}`}>
                    <div className="time">
                      {`${moment(message.time).format('MMMM Do YYYY, h:mm:a')} `}
                    </div>
                    {
                      message.adminmsg === "adminmsg" ?
                        <div className="time">
                          <p>{message.adminName}</p>
                          {/* <p>{AdminTypenames}</p> */}
                        </div> : null
                    }
                    {
                      message.fromSelf === currentusername._id ?
                        <div className="adminimage">
                          <img src={adminlogo}></img>
                        </div> :
                        message.adminmsg === "adminmsg" ?
                          <div className="adminimage">
                            <img src={adminlogo}></img>

                          </div> :
                          <div className="adminimage">
                            <img src={`${BASE_URL_IMG}/user/${message?.fromSelf}/${message?.fromSelf}.jpeg`}></img>
                          </div>
                    }

                  </div>
                  <div className={` ${message.fromSelf === currentusername._id ? "content" : message.adminmsg === "adminmsg" ? "content" : "usecontent"}`}>
                    {message.message ?
                      <div class="test">
                        <p>
                          {message.message}
                        </p>
                        {message.fromSelf === currentusername._id ?
                          <button class="threedot" onClick={() => handleToggle(message?.id)}>...</button> : null
                        }
                        {isToggled[message?.id] ?
                          <div id="" class="dropdown-contents">
                            <p onClick={() => getpollDataApi(message?.id)} >Delete</p>
                           
                          </div> : null
                        }
                      </div>
                      :  <div class="tests">
                      <audio src={`${BASE_URL_IMG}${message?.voice}`} controls />
                      {message.fromSelf === currentusername._id ?
                          <button class="threedotaudio" onClick={() => handleToggle(message?.id)}>...</button> : null
                        }
                        {isToggled[message?.id] ?
                          <div id="" class="dropdown-contents">
                            <p onClick={() => getpollDataApi(message?.id)}>Delete</p>
                           
                          </div> : null
                        }
                      </div>
                    }


                  </div>

                </div>
              </div>
            );
          })}
        </div>
        <ChatInput seconds={seconds} minutes={minutes} handleSendMsg={handleSendMsg} getmessageDataApi={getmessageDataApi} isLoading={isLoading} isRecording={isRecording} recordings={recordings} setrecordings={setrecordings} />
      </div>
    </>
  );
}
