import icon1 from "../../Assets/icoonsss/people.png"

import icon4 from "../../Assets/icoonsss/master.png"
import icon3 from "../../Assets/images/ICONS GLP/Fouram.png"
import icon5 from "../../Assets/images/ICONS GLP/GALLERY.png"
import icon6 from "../../Assets/images/ICONS GLP/EVENTS.png"
import icon7 from "../../Assets/images/ICONS GLP/HELP (2).png"
import icon8 from "../../Assets/images/ICONS GLP/NOTIFICATION.png"
import icon9 from "../../Assets/images/ICONS GLP/NEWS.png"
import icon10 from "../../Assets/images/ICONS GLP/RESOURCES.png"
import icon11 from "../../Assets/images/ICONS GLP/STORIES (2).png"
import icon12 from "../../Assets/images/ICONS GLP/TOOLKIT.png"


export const navlist = [
    {
        label: "Home",
        path: "/",
        ref: "Home",
        icon: icon1
    },
    {
        label: "People",
        path: "/users",
        ref: "people",
        icon: icon7
    },

    {
        label: "Forum",
        path: "/forum",
        ref: "forum",
        icon: icon3
    },
    {
        label: "Gallery",
        path: "/gallery",
        ref: "gallery",
        icon: icon5
    },
    {
        label: "Master",
        path: "/master",
        ref: "master",
        icon: icon4
    },

    {
        label: "Events",
        path: "/events",
        ref: "events",
        icon: icon6
    },
    {
        label: "Resources",
        path: "/resources",
        ref: "resources",
        icon: icon10
    },
     {
        label: "SKILLS TRAINING",
        path: "/Education",
        ref: "Education",
        icon: icon10
    },
    {
        label: "Toolkits",
        path: "/Toolkits",
        ref: "Toolkits",
        icon: icon12
    },
    {
        label: "STORIES",
        path: "/Story",
        ref: "Story",
        icon: icon11
    },
    {
        label: "NewsFeed",
        path: "/NewsFeed",
        ref: "NewsFeed",
        icon: icon9
    },
    {
        label: "NOTIFICATION",
        path: "/notification",
        ref: "notification",
        icon: icon8
    },
    // {
    //     label: "CHAT",
    //     path: "/join",
    //     ref: "chat",
    //     icon: icon7
    // },
    {
        label: "CHAT",
        path: "/chat",
        ref: "chat",
        icon: icon7
    },
    {
        label: "DIRECTORY",
        path: "/directory",
        ref: "directory",
        icon: icon7
    },
   
]