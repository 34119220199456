import React, { useState, useContext, useEffect, createContext } from 'react';
import { FEEDS_FORM, POLL_FORM } from '../ModalTemplate';
import { ModalContext } from '../../Context'
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { allAdminUsers, fetchManageFlag, fetchRemovedFlag, getForumsApi,getGalleryPollApi,GetSuggestdata } from '../../Utils/services';
import { toast } from 'react-toastify';
import PostFeed from './PostFeed';
import ManageForum from './ManageForum';
import RemovedFlag from './RemovedFlagg';
import { FunctionContext } from '../../Context';
import Suggestead from './Suggestead';
import { useTranslation } from 'react-i18next';
import PollPage from '../Gallary/PollPage';

const a11yProps = (index) => {
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Feeds = () => {
    const {t} = useTranslation();
    const {postData, getForumsDataApi,paginationpostData} = useContext(FunctionContext)
    const [adminData, setAdminData] = useState([]);
    const [tabsValue, setTabsValue] = useState(0);
    const [getsuggestdata, setgetsuggestdata] = useState([]);
    const [loader, setLoader] = useState(true);
    const [flagData, setFlagData] = useState([])
    const [removedData, setRemovedData] = useState([]);
    const [postId, setPostId] = useState("");
    const [totalCount, setotalCount] = useState()
    const [postDatas, setPostData] = useState(null);
    const CommentContext = createContext()


    const context = useContext(ModalContext)
    const { handleModalData } = context;

    useEffect(() => {
        setotalCount(paginationpostData && paginationpostData.totalCount)
    }, [paginationpostData && paginationpostData.totalCount])
    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }


  const getSuggestdata = async () => { 
        try {
            const result = await GetSuggestdata();
            const reversedata = result?.data?.suggention.reverse()
            setgetsuggestdata(reversedata)
            
        }
        catch (error) {
            setLoader(false)
            console.log(error)
            toast.error(error.message);
        }
    }

    useEffect(() => {
        getAdminTableData()
        getManageFlag()
    }, [])



    const getManageFlag = async () => {
        try {
            const res = await fetchManageFlag()
            let _data = res?.data?.commentdata;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            
            let _AllData = result[0] ? result[0]?.concat(res?.data?.flaggedReply) : res?.data?.flaggedReply
            
            // console.log(_AllData,"_AllData")
            _AllData.sort((a, b) => new Date(b.flag.report[0].reportedOn) - new Date(a.flag.report[0].reportedOn));
            setFlagData(_AllData);
        } catch (error) {
            toast.error(error.message)
        }
    }
    const getRemovedFlag = async () => {
        try {
            const res = await fetchRemovedFlag();
            let _data = res?.data?.removedComment;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = result[0].concat(res?.data?.flaggedReply)
           // console.log(_AllData,"_AllData")
            _AllData.sort((a, b) => new Date(b.removed.removedOn) - new Date(a.removed.removedOn));
            setRemovedData(_AllData);
        } catch (error) {
             toast.error(error.message)
        }
    }

    const getpollDataApi = async (data) => {
        try {
            const result = await getGalleryPollApi(data);
            setPostData(result.res.data)
        }
        catch (error) {
            setLoader(false)
            console.log(error)
            toast.error(error.message);
        }
    }

    useEffect(() => {
        // getForumsDataApi();
        getRemovedFlag();
        // getpollDataApi();
    }, []);

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
        var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
        window.history.pushState({ path: newurl }, '', newurl);
    };

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(()=>{
        
        var idValue = getParameterByName('id');
        if(idValue){
            console.log(idValue,"idValue")
            setTabsValue(parseInt(idValue))
        }
    },[])
    
    const tabsData = [
        {
            label: `${t("Forum")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: <PostFeed totalCount={totalCount} data={postData} getForumsDataApi={getForumsDataApi} adminData={adminData} paginationpostData={paginationpostData}/>
        },
        {
            label: `${t("Manage_Items")}`,
            isNotshowAdd: true,
            data: <ManageForum flagData={flagData} getManageFlag={getManageFlag} postId={postId} adminData={adminData}/>
        },
        {
            label: `${t("Removed_Items")}`,
            isNotshowAdd: true,
            data: <RemovedFlag flagData={removedData} getManageFlag={getRemovedFlag} adminData={adminData} />
        },
        {
            label: `${t("suggested_Topic")}`,
            isNotshowAdd: true,
            data: <Suggestead flagData={getsuggestdata} getManageFlag={getSuggestdata} adminData={adminData} />
        },
        {
            label: `${t("Poll")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: <PollPage totalCount ={paginationpostData?.totalCount}  data={postDatas} getGalleryDataApi={getpollDataApi} adminData={adminData} />
        }
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(FEEDS_FORM(true, getForumsDataApi));
                break;
            case 4:
                handleModalData(POLL_FORM(true, getpollDataApi));
                break;

            default:
                handleModalData(FEEDS_FORM(true, getForumsDataApi));
                break;
        }
    }
    return (
       <>
       <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />
            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
     
       </>
    );
}
export default Feeds;

