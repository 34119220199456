import logo from "../../Assets/images/glp-logo-colour.png";
import { loginServiceFirst } from "../../Utils/services";
import { useState, useContext} from "react";
import { toast } from "react-toastify";
import AuthContext from '../../Utils/auth-context'
import { useNavigate  } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getToken } from "../firebaseInit.js";
import { useEffect } from "react";
const Login = () => {
    const { t } = useTranslation(["home"]);
    const authCtx = useContext(AuthContext);
    const history = useNavigate();
    const [deviceToken, setDeviceToken] = useState("");
    const [isForgetActive, setForgetActive] = useState(false)
    const [userData, setUserData] = useState({
        email: "",
        password: ""
    })
    const [loading, setLoading] = useState(false);
    // const [isTokenFound, setTokenFound] = useState("");

    // console.log("Token found", deviceToken);
    
    const loginSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            if (userData.email && userData.password) {
                const data = {
                    email: userData.email,
                    password: userData.password,
                    fcmToken:deviceToken
                }
                const result = await loginServiceFirst(data);
                if (result.res.success) {
                    setLoading(false);
                    localStorage.setItem('deviceToken', deviceToken)
                    history("/loginOtp",{
                                   state: {
                            fcmToken: deviceToken,
                            email: userData.email,
                        },
                    })
                }

            }
        }
        catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }
    useEffect(() => {
        let data;
    
        async function tokenFunc() {
          data = await getToken(setDeviceToken);
          if (data) {
            // console.log("Token is", data);
            setDeviceToken(data)
          }
          return data;
        }
    
        tokenFunc();
      }, [setDeviceToken]);
    // New
    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = (e) => {
        loginSubmit(e)
    }

    return (
        <div className="w-2/5  logincontainer mx-auto auth-container">
            <div className="w-1/2 mx-auto text-center">
                <img
                    src={logo}
                    className="mx-auto mb-5"
                    alt="logo"
                />

                <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                    {isForgetActive ? 'FORGET PASSWORD' : 'SIGN IN'}
                </h2>

                <form className="auth-form" onSubmit={e => handleSubmit(e)}>
                    <input
                        type="email"
                        placeholder={t("EmailID")}
                        name="email"
                        value={userData.email}
                        onChange={(e) => handleChange(e)}
                        required
                    />
                    <input
                        type="password"
                        placeholder={t("Password")}
                        name="password"
                        value={userData.password}
                        onChange={(e) => handleChange(e)}
                        required
                    />

                    {
                        loading ? <p>{t("Loading...")}</p> :
                            <button
                                className="primary-btn"
                                type="submit"
                            >
                                {isForgetActive ? 'submit' : 'sign in'}
                            </button>
                    }
                    <span
                        className="text-btn"
                        onClick={() => setForgetActive(true)}
                    >
                        <Link to="/forgotpassword">{t("forgot_password")}</Link>
                    </span>
                </form>
            </div>
        </div>
    )
}

export default Login;