import { useMemo } from "react"
import { BASE_URL_IMG, deleteGalleryFlag, deleteManageFlag, galleryKeepPost, getGalleryCommentData, keepPost, singleForumApi } from "../../Utils/services"
import { useNavigate } from "react-router-dom"
import { Button } from "@material-ui/core";
import { toast } from "react-toastify"
import GallaryTable from "./GallaryTable"
import { useTranslation } from 'react-i18next';


const ManageGallery = ({ flagData, getManageFlag, postId,adminData }) => {
    console.log(postId, "postId")
    console.log(flagData, "flagData")
    const history = useNavigate();
    const { t } = useTranslation();
    // const showPostDetail = async (item, data) => {
    //     try {
    //         const result = await singleForumApi(item)
    //         let sendData = result?.res?.thread[0];
    //         history.push({
    //             pathname: `forum/${item}`,
    //             state: {
    //                 data: sendData,
    //                 title: 'Forum Details'
    //             }
    //         })
    //     } catch (error) {
    //         console.log(error.message)
    //     }

    // }
    const showPostDetail = async (item,data) => {   
        localStorage.setItem('itemss', item);
        try {
            const result = await getGalleryCommentData({
                id: item
            });
            if (result.success) {
                // toast.success(result.res.message)
                // getGalleryDataApi({ page, postsPerPage });
                 history(`${item}`,{
          
            state: {
                data: result?.data[0],
                title: 'Gallery Details',
                // count: totalCount,
                mediadata:data,

            }
        })
            }

        } catch (error) {
            toast.error(error.messages)
        }
       
    }
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }

    const keepPostHandler = async (postId, DataId, commentId) => {
        try {

            if (commentId !== undefined) {
                const res = await galleryKeepPost({
                    id: postId,
                    commentId: commentId,
                    replyId: DataId
                })
                toast.success("Post kept successfully")
                getManageFlag();
            }
            else {
                const res = await galleryKeepPost({
                    id: postId,
                    commentId: DataId,
                })
                toast.success(res.message)
                getManageFlag();
            }
        } catch (error) {
            toast.error(error.message)
        }
    }
    const RemovePostHandler = async (postId, DataId, commentId) => {
        try {
            if (commentId !== undefined) {
                const res = await deleteGalleryFlag({
                    id: postId,
                    commentId: commentId,
                    replyId: DataId
                })
                toast.success(res.message)
                getManageFlag();
            }
            else {
                const res = await deleteGalleryFlag({
                    id: postId,
                    commentId: DataId,
                })
                toast.success(res.message)
                getManageFlag();
            }

        } catch (error) {
            toast.error(error.message)
        }
    }

    const FeedsColumns = useMemo(
        () => [
            {
                Header: `${t('Post/Comment/Reply')}`,
                accessor: data => {
                    return (
                        <div className="my-12 ml-4 flex justify-center flex-col">
                            {
                                data && data.media && data.media[0] &&
                                <img src={`${BASE_URL_IMG}${data?.media[0]?.link}`} alt="" className="w-72" />
                            }
                            <span className="mt-2 max-w-xs">{data?.content ? data?.content : data?.title}</span>
                        </div>
                    )
                }
            },
            {
                Header: `${t('Reported_By')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{item?.reportedByName}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Reported_On')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{formatDate(item?.reportedOn)}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Issue')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{item?.issue}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Comments')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{item?.comments}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Type')}`,
                accessor: data => {
                    return (
                        <>
                            <span>{data?._id && data.postId && data.commentId ? "Reply" : data?._id && data?.postId ? "Comment" : "Post"}</span>
                        </>
                    )
                }
            },

            {
                Header: `${t('Action')}`,
                accessor: adminData[0]?.AdminType==="Coordination team"? "" : data => {
                    return (
                        <>
                            <div className="footer -ml-2 my-6">
                                <Button
                                    color="primary"
                                    className="primary-color"
                                    onClick={() => keepPostHandler(data?.postId, data?._id, data?.commentId)}
                                >
                                    {t('keep_post')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    className="approve_decline_padding ml-10"
                                    onClick={() => RemovePostHandler(data?.postId, data?._id, data?.commentId)}
                                >
                                    <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                    <span className="pl-1"> {t('Remove')}</span>
                                </Button>
                            </div>
                        </>
                    )
                }
            },
            {
                Header: `${t('View_Post')}`,
                accessor: adminData[0]?.AdminType==="Coordination team"? "" : data => { 
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={postId}
                            onClick={() => showPostDetail(data._id, data)}
                        >
                            <span className="MuiButton-label">{t('View')}</span>
                        </button>
                    )
                }
            },


        ], [])

    return (
        <>
            {flagData &&
                <GallaryTable columns={FeedsColumns} data={flagData} />
            }

        </>
    )
}

export default ManageGallery