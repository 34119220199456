// Import the functions you need from the SDKs you need
// import { FCM_DATA } from 'Constant';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
// import { CrmPostMethodFunction } from '../Function/CommonApifunction.tsx';
// import { ADD_FCM_TOKEM } from '../Function/CrmApiRoutes';
// import { firebaseConfig } from "../../Constant";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOK1OhtdI9d9uqLC6sOz1eIepCRhb3nzw",
  authDomain: "glp-power.firebaseapp.com",
  projectId: "glp-power",
  storageBucket: "glp-power.appspot.com",
  messagingSenderId: "433940137992",
  appId: "1:433940137992:web:cbf74863d495e5322469bd",
  measurementId: "G-QW2C1ZJ0Z1"
};
// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);

export const generateToken = async (UserId) => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: `BJDSLQdJjUYY4lJmHHrSInPbc4r95AihOZ0wGlHPihlE3Lrmzxg_gCGijHeUfUjg1nznNviLnr8yMS21V5Jqeu4`,
      });
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);
      const isWeb = !isMobile;

      // const resutt = await CrmPostMethodFunction({
      //   UrlPath: ADD_FCM_TOKEM,
      //   TYPE: 'body',
      //   DATA: {
      //     userId: UserId,
      //     fcmToken: token,
      //     isMobile: isMobile,
      //     isWeb: isWeb,
      //   },
      // });
      // localStorage.setItem('fmctoken', token);
    }
  } catch (err) {
    console.error(err);
  }
};
