import { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import {
  addSectorApi,
  editSectorApi,
  getLanguageList,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";

const AddSector = ({
  title,
  name,
  langauge,
  btnText,
  edit,
  getSectorListData,
  id,
  sector,
}) => {
  const [loader, setLoader] = useState(false);
  const [sectorName, setSectorName] = useState(
    sector
      ? {
          field1: sector[0].name,
          field2: sector[1].name,
          field3: sector[2].name,
          field4: sector[3].name,
          field5: sector[4].name,
          field6: sector[5].name,
          field7: sector[6].name,
          field8: sector[7].name,
          field9: sector[8].name,
          field10: sector[9].name,
        }
      : {
          field1: "",
          field2: "",
          field3: "",
          field4: "",
          field5: "",
          field6: "",
          field7: "",
          field8: "",
          field9: "",
          field10: "",
        }
  );
  const [languageList, setLanguageList] = useState([]);
  const [language, setLanguage] = useState(sector ? sector[0].sectorName : []);
  const [language1, setLanguage1] = useState(
    sector ? sector[1].language.name : []
  );
  const [language2, setLanguage2] = useState(
    sector ? sector[2].language.name : []
  );
  const [language3, setLanguage3] = useState(
    sector ? sector[3].language.name : []
  );
  const [language4, setLanguage4] = useState(
    sector ? sector[4].language.name : []
  );
  const [language5, setLanguage5] = useState(
    sector ? sector[5].language.name : []
  );
  const [language6, setLanguage6] = useState(
    sector ? sector[6].language.name : []
  );
  const [language7, setLanguage7] = useState(
    sector ? sector[7].language.name : []
  );
  const [language8, setLanguage8] = useState(
    sector ? sector[8].language.name : []
  );
  const [language9, setLanguage9] = useState(
    sector ? sector[9].language.name : []
  );

  const { t } = useTranslation();
  const context = useContext(ModalContext);
  const { closeModal } = context;

  const handleAdd = async () => {
    setLoader(true);
    try {
      const result = await addSectorApi({
        // name: sectorName,
        // language:language.language.name,
        sector: arrayOfObjects,
      });
      if (result.res.success) { 
        setLoader(false);
        // toast.success(result.res.message)
        toast.success("Sector added successfully");
        closeModal();
        getSectorListData();
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
    }
  };

  const handleEdit = async () => {
    setLoader(true);
    try {
      const result = await editSectorApi({
        // name: sectorName,
        id: id,
        // language:language.language.name,
        sector: arrayOfObjects,
      });
      if (result.res.success) {
        setLoader(false);
        toast.success(result.res.success);
        getSectorListData();
        closeModal();
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSectorName((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getLanguageListData = async () => {
    try {
      const result = await getLanguageList();

      setLanguageList(result?.res?.data?.languages);
    } catch (error) {
      console.log(error);
      toast.warning("Something went wrong!");
    }
  };
  useEffect(() => {
    getLanguageListData();
  }, []);

  const onInputLanguageChange = (event, newInputValue, name) => {
    setLanguage({
      ...language,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange1 = (event, newInputValue, name) => {
    setLanguage1({
      ...language1,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange2 = (event, newInputValue, name) => {
    setLanguage2({
      ...language2,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange3 = (event, newInputValue, name) => {
    setLanguage3({
      ...language3,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange4 = (event, newInputValue, name) => {
    setLanguage4({
      ...language4,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange5 = (event, newInputValue, name) => {
    setLanguage5({
      ...language5,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange6 = (event, newInputValue, name) => {
    setLanguage6({
      ...language6,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange7 = (event, newInputValue, name) => {
    setLanguage7({
      ...language7,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange8 = (event, newInputValue, name) => {
    setLanguage8({
      ...language8,
      [name]: newInputValue,
    });
  };
  const onInputLanguageChange9 = (event, newInputValue, name) => {
    setLanguage9({
      ...language9,
      [name]: newInputValue,
    });
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  // const { field1, field2, field3 } = formData;
  let arr = [];
  console.log(sectorName, "formData");
  // console.log(language.language,"language")
  arr.push(
    language?.language?.name
      ? language?.language?.name
      : languageList && languageList?.[0]?.name
  );
  arr.push(
    language1?.language?.name
      ? language1?.language?.name
      : languageList && languageList?.[1]?.name
  );
  arr.push(
    language2?.language?.name
      ? language2?.language?.name
      : languageList && languageList?.[2]?.name
  );
  arr.push(
    language3?.language?.name
      ? language3?.language?.name
      : languageList && languageList?.[3]?.name
  );
  arr.push(
    language4?.language?.name
      ? language4?.language?.name
      : languageList && languageList?.[4]?.name
  );
  arr.push(
    language5?.language?.name
      ? language5?.language?.name
      : languageList && languageList?.[5]?.name
  );
  arr.push(
    language6?.language?.name
      ? language6?.language?.name
      : languageList && languageList?.[6]?.name
  );
  arr.push(
    language7?.language?.name
      ? language7?.language?.name
      : languageList && languageList?.[7]?.name
  );
  arr.push(
    language8?.language?.name
      ? language8?.language?.name
      : languageList && languageList?.[8]?.name
  );
  arr.push(
    language9?.language?.name
      ? language9?.language?.name
      : languageList && languageList?.[9]?.name
  );
  // console.log(language1.language,"language1")
  console.log(language, language?.language?.name, "language2");
  console.log(arr);
  let arr1 = [];
  arr1.push(sectorName?.field1);
  arr1.push(sectorName?.field2);
  arr1.push(sectorName?.field3);
  arr1.push(sectorName?.field4);
  arr1.push(sectorName?.field5);
  arr1.push(sectorName?.field6);
  arr1.push(sectorName?.field7);
  arr1.push(sectorName?.field8);
  arr1.push(sectorName?.field9);
  arr1.push(sectorName?.field10);
  const currentUTCTime = new Date().toISOString();
  const arrayOfObjects = arr1.map((value, index) => ({
    language: arr[index],
    sectorName: value,
    createdOn:currentUTCTime
  }));
  
  console.log(arrayOfObjects, "language2");
  
  // const concatenatedArray = language.concat(language1, language2);
  console.log(sectorName?.field1, "languageList");
  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>

      <form noValidate autoComplete="off" className="w-full">
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            label={t("Name")}
            name="field1"
            value={sectorName.field1}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />

          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option._id}
            // defaultValue={sector ? sector[0]?.language : ""}
            value={{
              name: languageList && languageList?.[0]?.name,
              _id: languageList && languageList?.[0]?._id,
            }}
            disabled
            sx={{ width: 300 }}
            //   value={}
            // value={language?.name}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field2"
            value={sectorName.field2}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[1]?.language : ""}
            sx={{ width: 300 }}
            value={{
                name: languageList && languageList?.[1]?.name,
                _id: languageList && languageList?.[1]?._id,
              }}
              disabled
            // value={language1?.name}
            // value={languageList?.[1].name}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange1(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field3"
            value={sectorName.field3}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[2]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language2?.name}
            value={{
                name: languageList && languageList?.[2]?.name,
                _id: languageList && languageList?.[2]?._id,
              }}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange2(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field4"
            value={sectorName.field4}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[3]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language3?.name}
            value={{
                name: languageList && languageList?.[3]?.name,
                _id: languageList && languageList?.[3]?._id,
              }}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange3(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field5"
            value={sectorName.field5}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[4]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language4?.name}
            value={{
                name: languageList && languageList?.[4]?.name,
                _id: languageList && languageList?.[4]?._id,
              }}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange4(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field6"
            value={sectorName.field6}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[5]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language5?.name}
            value={{
                name: languageList && languageList?.[5]?.name,
                _id: languageList && languageList?.[5]?._id,
              }}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange5(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field7"
            value={sectorName.field7}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[6]?.language : ""}
            sx={{ width: 300 }}
            disabled
            value={{
                name: languageList && languageList?.[6]?.name,
                _id: languageList && languageList?.[6]?._id,
              }}
            // value={language6?.name}
            onChange={(e, newVal) =>
              onInputLanguageChange6(e, newVal, "language")
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field8"
            value={sectorName.field8}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[7]?.language : ""}
            sx={{ width: 300 }}
            disabled
            value={{
                name: languageList && languageList?.[7]?.name,
                _id: languageList && languageList?.[7]?._id,
              }}
            // value={language7?.name}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange7(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field9"
            value={sectorName.field9}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[8]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language8?.name}
            value={{
                name: languageList && languageList?.[8]?.name,
                _id: languageList && languageList?.[8]?._id,
              }}
            // onChange={(e, newVal) =>
            //   onInputLanguageChange8(e, newVal, "language")
            // }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
          <TextField
            label={t("Name")}
            name="field10"
            value={sectorName.field10}
            // onChange={e => setSectorName(e.target.value)}
            onChange={handleInputChange}
            required
          />
          <Autocomplete
            id="combo-box-demo2"
            options={languageList?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            defaultValue={sector ? sector[9]?.language : ""}
            sx={{ width: 300 }}
            disabled
            // value={language9?.name}
            value={{
                name: languageList && languageList?.[9]?.name,
                _id: languageList && languageList?.[9]?._id,
              }}
            onChange={(e, newVal) =>
              onInputLanguageChange9(e, newVal, "language")
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Language")} />
            )}
            required
          />
        </div>

        <div className="flex justify-center mt-10">
          <button
            className={`mx-auto ${sectorName.field1.trim() && sectorName.field2.trim() && sectorName.field3.trim() && sectorName.field4.trim() && sectorName.field5.trim() && sectorName.field6.trim() && sectorName.field7.trim() &&  sectorName.field8.trim() && sectorName.field9.trim() && sectorName.field10.trim() !== '' ? "button-1" : "buttons-1"}`}
            //className="button-1 mx-auto"
            type="button"
            disabled={ sectorName.field1.trim() && sectorName.field2.trim() && sectorName.field3.trim() && sectorName.field4.trim() && sectorName.field5.trim() && sectorName.field6.trim() && sectorName.field7.trim() &&  sectorName.field8.trim() && sectorName.field9.trim() && sectorName.field10.trim() !== '' ? false : true}
            onClick={edit ? handleEdit : handleAdd}
          >
            {btnText}
          </button>
        </div>

        {/* {
                    edit && (
                        <div className="flex justify-center mt-5">
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="approve_decline_padding"
                                onClick={() => handleModalData(REMOVE_CONFIRMATION, 'sm')}
                            >
                                <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                <span className="pl-1">Remove</span>
                            </Button>
                        </div>
                    )
                } */}
      </form>
    </>
  );
};

export default AddSector;
