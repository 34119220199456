import { Button } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import { DeleteEvent, BASE_URL_IMG, GetMeetingCode } from "../../Utils/services";
import EditEventForm from "./EditEventForm";
import modalTextsFile from "./modalTextsFile";
import RemoveConfirmation from "./RemoveConfirmation";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useNavigate } from 'react-router-dom';

const EventEditDeletePopUp = ({
  title,
  buttonText,
  getEventApiData,
  eventDetails,
  eventId,
  adminData,
  eventIds
}) => {
  const context = useContext(ModalContext);
  const { handleModalData, closeModal } = context;
  const [year, setYear] = useState(new Date());
  const [month, setMonth] = useState(new Date());
  const [yearMonthData, setYearMonthData] = useState(
    new Date(eventDetails.current)
  );
  const history = useNavigate();
  const { t } = useTranslation();
  const removeEvent = async () => {
    const _yearMonth = yearMonthData;
    const year = _yearMonth.getFullYear();
    const month = _yearMonth.getMonth() + 1;
    setYear(year);
    setMonth(month);
    try {
      const result = await DeleteEvent(eventIds);
      if (result.res.success) {
        // toast.success(result.res.message);
        toast.success("Event removed successfully");

        setTimeout(() => {
          
          getEventApiData(year, month);
          // setLoader(false);
          closeModal();
        }, 2000);

        setTimeout(() => {
          
          getEventApiData(year, month);
          // setLoader(false);
          closeModal();
        }, 3000);
        //   setTimeout(() => {
        //   getEventApiData(year, month);
        // }, 500); // 1000 milliseconds = 1 second
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditEvent = () => {
    const edit_Form_Modal = (
      <EditEventForm
        title={title}
        buttonText={buttonText}
        getEventApiData={getEventApiData}
        eventDetails={eventDetails}
        eventId={eventId}
        eventIds={eventIds}
      />
    );
    handleModalData(edit_Form_Modal);
  };

  const HandleRemoveEvent = () => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t("Remove_Event")}
        action={removeEvent}
        data={t("EventRemoveText")}
        // id={eventId}
        id={eventIds}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const Meetingopen =async (currentURL, data) => { 
    console.log(data, "item");
    
    const endTimeStr = data.end;
    const endTime = new Date(endTimeStr);
    const currentTime = new Date();
    if (false) {
      toast.info("The Link has been expired");
      return null;
    }
    const parsedUrl = new URL(currentURL);
    const domain = parsedUrl.origin;

    if (domain === "https://admin.glp-connect.org" || domain === "https://glpadmins.netlify.app" ) {
      const RoomCode = await GetMeetingCode({
        roomId: data.roomDetails[0].id.replace(/\\/g, "").replace(/"/g, ""),
      });
      const jsonData = RoomCode.res.split("Room Detail: ")[1];

      // Parse JSON
      const parsedData = JSON.parse(jsonData);

      // Access the data
      const roomDetails = parsedData.data;
      console.log(roomDetails, "data");

      function openNewTab(slugCode) {
    

        const url = `${window.location.origin}/glpmeeting/${slugCode}`; // Replace with your actual URL
        window.open(url, "_blank");
      }

      // Check the role and open a new tab if it's "new-role-6288"
      roomDetails.forEach((room) => {
        if (room.role === "host") {
          openNewTab(room.code);
        }
      });

      // const urlParts = currentURL.split("?");
      // const key = urlParts[1].split("/")[0].split("=")[1];
      // const channelid = urlParts[2].split("=")[1];
      // navigate("/glpmeeting", {
      //   state: {
      //     _token: localStorage.getItem("token"),
      //     token: channelid,
      //     channel_id: key,
      //     userid: localStorage.getItem("adminId"),
      //     roomid: data.eventId,
      //     name: localStorage.getItem("adminName"),
      //   },
      // });
    } else {
      window.open(currentURL, "_blank");
    }
  };

  const formateTime = (time) => {
    const _Time = new Date(time);
    const hours = _Time.getHours();
    const minutes = _Time.getMinutes();
    const minutess = minutes < 10 ? `0${minutes}` : minutes;
    const finalhours = hours > 12 ? hours - 12 : hours;
    const ampm = hours >= 12 ? "pm" : "am";
    const FullTime = `${finalhours}: ${minutess} ${ampm}`;
    return FullTime;
  };
  return (
    <div className="warning-modal">
      <div className="flex items-center justify-center">
        <h3 className="title"> {t("PLEASE_SELECT_WHAT_DO_YOU_WANT")}</h3>
      </div>
      <div className="flex justify-between mx-auto">
        <div
          className={`p-5 cursor-pointer mb-5 innerbox ${
            eventDetails.postTo == "offline" ? "bg-sidenav" : "bg-bgOrange"
          }`}
          key={eventDetails._id}
          // onClick={() => handleEditEventShow(item, item._id)}
        >
          {eventDetails.postTo == "offline" ? (
            <h1
              className={`text-xl flex items-center justify-center ${
                eventDetails.postTo == "offline" ? "text-white" : "text-white"
              }`}
            >
              {" "}
              {t("Ofline_Event")}
            </h1>
          ) : eventDetails.postTo == "hybrid" ? (
            <h1
              className={`text-xl flex items-center justify-center ${
                eventDetails.postTo == "offline" ? "text-white" : "text-white"
              }`}
            >
              {" "}
              {t("Hybrid_event")}{" "}
            </h1>
          ) : (
            <h1
              className={`text-xl flex items-center justify-center ${
                eventDetails.postTo == "offline" ? "text-white" : "text-white"
              }`}
            >
              {" "}
              {t("Online_event")}{" "}
            </h1>
          )}

          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Event_Name")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {eventDetails.name}
              </h3>
            </div>
          </div>
          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Organiser")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {eventDetails.organiser}
              </h3>
            </div>
          </div>

          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Start_Time")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {/* {new Date(eventDetails.start).toDateString()} <br />  {new Date(eventDetails.startTime).toLocaleTimeString()} */}
                {`${formatDate(eventDetails.start)} ${formateTime(
                  eventDetails.startTime
                )}`}
              </h3>
            </div>
          </div>
          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("End_Time")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {/* {new Date(eventDetails.start).toDateString()} <br />  {new Date(eventDetails.startTime).toLocaleTimeString()} */}
                {/* {`${new Date(eventDetails.start).toDateString()} ${new Date(
                  eventDetails.endTime
                ).toLocaleTimeString()}`} */}
                {`${formatDate(eventDetails.end)} ${formateTime(
                  eventDetails.endTime
                )}`}
              </h3>
            </div>
          </div>
          {/* <p
            className={`text-sm py-1 ${
              eventDetails.postTo == "offline" ? "text-white" : "text-white"
            }`}
          >
            {" "}
            {`${formatDate(eventDetails.start)} to ${formatDate(
              eventDetails.end
            )}`}{" "}
          </p> */}
          {/* <p
            className={`text-sm ${
              eventDetails.postTo == "offline" ? "text-white" : "text-white"
            }`}
          >
            {`${formateTime(eventDetails.startTime)} to ${formateTime(
              eventDetails.endTime
            )}`}
          </p> */}
          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Sector")} :
              </h3>
            </div>
            {eventDetails &&
              eventDetails.sector &&
              eventDetails.sector.map((item2, index) => {
                // const urllinks =   JSON.stringify(item2);
                return (
                  <>
                    <div className="righteventsector">
                      <span
                        className={`text-xl flex   ${
                          eventDetails.postTo == "offline"
                            ? "text-white"
                            : "text-white"
                        }`}
                      >
                        {item2?.language == "English" ? `${item2?.name},  ` : null}
                      </span>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Description")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {eventDetails.description}
              </h3>
            </div>
          </div>
          <p
            className={`text_tosmall_10 ${
              eventDetails.postTo == "offline" ? "text-white" : "text-white"
            }`}
          >
            {eventDetails &&
              eventDetails.links &&
              eventDetails.links.map((item2, index) => {
                // const urllinks =   JSON.stringify(item2);
                return (
                  <>
                    {/* <div key={index}>
                      <p>{item2}</p>
                    </div> */}
                    <div className="mainevent pt-4" key={index}>
                      <div className="leftevent">
                        <h3
                          className={`text-xl flex items-center justify-center ${
                            eventDetails.postTo == "offline"
                              ? "text-white"
                              : "text-white"
                          }`}
                        >
                          {t("Links")} :
                        </h3>
                      </div>
                      <div className="rightevent">
                        {/* <h3
                          className={`text-xl flex items-center justify-center ${
                            eventDetails.postTo == "offline"
                              ? "text-white"
                              : "text-white"
                          }`}
                        >
                          {item2}
                  
                        </h3>  */}
                        
                        <Link
                          className={`text-xl flex items-center justify-center ${
                            eventDetails.postTo == "offline"
                              ? "text-white"
                              : "text-white"
                          }`}
                          onClick={(e) => {
                            Meetingopen(item2, eventDetails);
                          }}
                        >
                          {" "}
                          {item2}
                        </Link>
                           {/* <Link
                         className={`text-xl flex items-center justify-center ${
                            eventDetails.postTo == "offline"
                              ? "text-white"
                              : "text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(item2, "_blank");
                            }}
                        > {item2}</Link> */}
                      </div>
                    </div>
                  </>
                );
              })}
            {eventDetails && eventDetails.location && (
              <div className="mainevent pt-4">
                <div className="leftevent">
                  <h3
                    className={`text-xl flex items-center justify-center ${
                      eventDetails.postTo == "offline"
                        ? "text-white"
                        : "text-white"
                    }`}
                  >
                    {t("Location")} :
                  </h3>
                </div>
                <div className="rightevent">
                  <h3
                    className={`text-xl flex items-center justify-center ${
                      eventDetails.postTo == "offline"
                        ? "text-white"
                        : "text-white"
                    }`}
                  >
                    {eventDetails.location}
                  </h3>
                </div>
              </div>
            )}
          </p>
          <div className="mainevent pt-4">
            <div className="leftevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                {t("Event Image")} :
              </h3>
            </div>
            <div className="rightevent">
              <h3
                className={`text-xl flex items-center justify-center ${
                  eventDetails.postTo == "offline" ? "text-white" : "text-white"
                }`}
              >
                <img
                  src={`${BASE_URL_IMG}${eventDetails?.image}`}
                  alt="Post Image"
                  className="w-80"
                />
                {}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button
          disabled={adminData[0]?.AdminType === "Coordination team"}
          className="btn2"
          onClick={handleEditEvent}
        >
          {t("Edit")}
        </Button>
        <Button
          disabled={adminData[0]?.AdminType === "Coordination team"}
          variant="outlined"
          color="secondary"
          className="approve_decline_padding danger-bg"
          onClick={HandleRemoveEvent}
        >
          <span className="cross-icon" />
          <span className="pl-1">{t("Remove")} </span>
        </Button>
      </div>
    </div>
  );
};

export default EventEditDeletePopUp;
