import React, { useState, useContext } from 'react';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import Country from './Country/Country'
import Regions from './Regions/Regions';
import { ModalContext } from '../../Context'
import AddRegion from '../ModalTemplate/AddRegion'
import AddCountry from '../ModalTemplate/AddCountry'
import Loader from "../Common/Loader"
import Sector from './sector/Sector';
import AddSector from '../ModalTemplate/AddSector';
import { allAdminUsers, getSectorList, getSegmentApi, getTags } from '../../Utils/services';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Tag from './tags/Tag';
import AddTags from '../ModalTemplate/AddTags';
import Segments from './segments/Segments';
import AddSegments from '../ModalTemplate/AddSegments';
import { useTranslation } from 'react-i18next';



const a11yProps = (index) => {
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const Master = () => {
  const {t} = useTranslation();
  const [tabsValue, setTabsValue] = useState(0);
  const [countryData, setCountryData] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(false);
  const [segments, setSegment] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const context = useContext(ModalContext)
  const { handleModalData } = context;


  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }

  const getSectorListData = async () => {
    try {
      const result = await getSectorList();
      setSectorData(result.res.data)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const geTagsApi = async () => {
    try {
      const result = await getTags();
      if(result.res.success){
        setTags(result.res.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const geSegmentApiData = async () => {
    try {
      const result = await getSegmentApi();
      if(result.res.success){
        setSegment(result.res.data)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getSectorListData();
    geTagsApi();
    geSegmentApiData();
    getAdminTableData()
  }, []);


  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
    var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
    window.history.pushState({ path: newurl }, '', newurl);
  };
  function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
useEffect(()=>{
    var idValue = getParameterByName('id');
    if(idValue){
        setTabsValue(parseInt(idValue))
    }
},[])

  const ADD_REGION = <AddRegion
    setRegionData={setRegionData}
    title="Add Region"
    btnText="ADD REGION"
  />

  const ADD_COUNTRY = <AddCountry
    setCountryData={setCountryData}
    title="Add Country"
    btnText="ADD COUNTRY"
  />

  const ADD_SECTOR = <AddSector
    sectorData={sectorData}
    getSectorListData={getSectorListData}
    title={t('ADD_SECTOR')}
    btnText={t('ADD_SECTOR')}
  />
  const ADD_Tags = <AddTags
    tags={tags}
    geTagsApi={geTagsApi}
    title="Add Tag"
    btnText="ADD TAG"
  />
  const ADD_Segment = <AddSegments
    geSegmentApiData={geSegmentApiData}
    title="Add Segment"
    btnText="ADD Segment"
  />


  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(ADD_REGION, 'md');
        break;

      case 1:
        handleModalData(ADD_COUNTRY, 'md');
        break;
      case 2:
        handleModalData(ADD_SECTOR, 'md');
        break;
      case 3:
        handleModalData(ADD_Tags, 'md');
        break;
      case 4:
        handleModalData(ADD_Segment, 'md');
        break;
      default:
        handleModalData(ADD_Segment, 'md');
        break;
    }
  }




  const tabsData = [
    {
      label: `${t("Regions")}`,
      isNotshowAdd: true,
      data: <Regions
        regionData={regionData}
        setRegionData={setRegionData}
      />
    },

    {
      label: `${t("Country")}`,
      isNotshowAdd: true,
      data: <Country
        setCountryData={setCountryData}
        countryData={countryData}
        adminData={adminData}
      />
    },
    {
      label: `${t("Sector")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: <Sector
        sectorData={sectorData}
        getSectorListData ={getSectorListData}
        adminData={adminData}
      />
    },
    {
      label: `${t("Tags")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: <Tag
        tags={tags}
        geTagsApi={geTagsApi}
        adminData={adminData}
      />
    },
    {
      label: `${t("Segment")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: <Segments
        segments={segments}
        geSegmentApiData={geSegmentApiData}
        adminData={adminData}
      />
    },
  ]





  return (
    <>
      <Loader loader={loader} />
      <div className="relative">
        <InnerHeader
          tabsData={tabsData}
          tabsValue={tabsValue}
          handlerTabs={handlerTabs}
          a11yProps={a11yProps}
          handleModalForm={handleModalForm}
          formBtn
        />

        {
          tabsData.map((obj, index) => (
            <TabPanel
              className="px-10 homemainresponsive"
              value={tabsValue}
              index={index}
            >
              {obj.data}
            </TabPanel>
          ))
        }
      </div>
    </>
  );
}
export default Master;

