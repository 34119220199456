import { TextField } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { addUser, getExpertise, getHubs, partyList, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { ModalContext } from '../../Context';
import { useNavigate, useLocation } from 'react-router-dom';


const bulkUploadHeader = [
    {
        id: 1,
        head: "Name",
    },
    {
        id: 2,
        head: "Email",
    },
    {
        id: 3,
        head: "Title",
    },
    {
        id: 4,
        head: "Add a Photo",
    },
    {
        id: 5,
        head: "Region",
    },
    {
        id: 6,
        head: "Country",
    },
    {
        id: 7,
        head: "Name of the Party/Organisation/Thinktank",
    },
    {
        id: 8,
        head: "Add a Badge",
    },
    {
        id: 9,
        head: "Add Expertise",
    },
    {
        id: 10,
        head: "Access to Directory",
    },
    {
        id: 10,
        head: "Submit",
    },
]

const UserBulkUpload = () => {

    const [loader, setLoader] = useState(false);
    const [partyListData, setPartyListData] = useState([]);
    const [badges, setBadges] = useState([]);
    const [formData, setFormData] = useState({});
    const [isInternational, setIsInternational] = useState(false)
    const [expertiseList, setExpertiseList] = useState([]);
    
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    // New Field Start
    const [userName, setUserName] = useState({});
    const [designation, setDesignation] = useState({});
    const [email, setEmail] = useState({})
    // const [choices, setChoices] = useState(['', ''])
    const [choices, setChoices] = useState([''])
    const [userImg, setUserImg] = useState({});
    const [regionValue, setRegionValue] = useState({});
    const [countryValue, setCountryValue] = useState({});
    const [partyValue, setPartyValue] = useState({});
    const [badgeValue, setBadgeValue] = useState({});
    const [expertiseValue, setExpertiseValue] = useState({});
    const [rowId, setRowId] = useState(null);
    const [isDirectoryaccess, setIsDirectoryAccess] = useState({});

    const location = useLocation();
    const history = useNavigate();

    useEffect(() =>{
        const {isInterNationalStatus} = location.state;
        setIsInternational(isInterNationalStatus)
    }, []);
    // New Field End

    // const handleChoices = () => {
    //     if (choices.length < 10) {
    //         let newArray = [...choices];
    //         newArray = [...newArray, ""];
    //         setChoices(newArray);
    //     }
    // }

    const handleNameChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserName({
            ...userName,
            // [name]: {
            //     title: value
            // }
            [name]: value
        })
    }
    const handleEmailChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEmail({
            ...email,
            [name]: value
        })
    }
    const handleDesignationChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setDesignation({
            ...designation,
            [name]: value
        })
    }

    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            if (result.res.data.country.length > 0 && result.res.data.region.length > 0) {
                console.log(result)
                setRegions(result.res.data.region);
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getCountryData();
    }, []);

    const handleRegionData = async (e, val, name) => {
        setRegionValue({
            ...regionValue,
            [name]: val
        })
        console.log(regionValue, "regionValue")
        try {
            const result = await regionCountryList();
            const _allCountry = result.res.data.country;
            const countriesData = _allCountry.filter(obj => {
                return obj.region.toLowerCase() == val.toLowerCase()
            }
            )
            setCountries(countriesData);
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    const handleCountryChange = async (e, val, name) => {
        setCountryValue({
            ...countryValue,
            [name]: val
        })
    }

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        // if (name == 'members') {
        //     setAllFieldsData({
        //         ...allFieldsData,
        //         [name]: newInputValue
        //     })
        // }
        // else {
        //     setAllFieldsData({
        //         ...allFieldsData,
        //         [_name]: _value
        //     })
        // }
        setBadgeValue({
            ...badgeValue,
            [name]: newInputValue
        })
    }
    const onInputPartyChange = (event, newInputValue, name) => {
        setPartyValue({
            ...partyValue, 
            [name]: newInputValue
        })
    }
    const onInputExpertiseChange = (event, newInputValue, name) => {
        setExpertiseValue({
            ...expertiseValue,
            [name]: newInputValue
        })
    }

    const handleChange = (event) => {
        const _name = event.target.name
        const _value = event.target.checked
        setIsDirectoryAccess({
            ...isDirectoryaccess,
            [_name]: _value
        })
    };

    const  handleUserImgFile = (e) => {
        const name = e.target.name;
        const value = e.target.files[0]
        setUserImg({
            ...userImg,
            [name]: value
        })
        // if (!!value) {
        //     let img = new Image();
        //     img.src = window.URL.createObjectURL(value);

        //     img.onload = () => {
        //         if (img.width === 400 && img.height === 400) {
        //             setUserImg({
        //                 ...userImg,
        //                 [name] : value
        //             })
        //         }
        //         else {
        //             setUserImg({})
        //         }
        //     }
        // }
    }


    const getData = async () => {
        try {
            const result2 = await getHubs();
            const _HubList = result2.res;
            setBadges(_HubList)
        }
        catch (error) {
            toast.warning("Something went wrong!")
        }
    }
    const getExpertiseData = async () => {
        try {
            const result2 = await getExpertise();
            const _ExpertiseList = result2.res;
            setExpertiseList(_ExpertiseList)
        }
        catch (error) {
            toast.warning("Something went wrong!")
        }
    }
    const getParty = async () => {
        try {
            const response = await partyList();
            const _partyList = response.res.data.party;
            setPartyListData(_partyList)

        }
        catch (error) {
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getData();
        getExpertiseData();
    }, [])

    useEffect(() => {
        getParty();
    }, [])

    // useEffect(() => {
    //     if (title === "add new international Officer") {
    //         setIsInternational(true)
    //     }
    // }, [title])

    const handleSubmit = async (e, id) => {
        console.log(expertiseValue, "expertiseValue")
        e.preventDefault();
        setLoader(true);
        let _userNameData = []
        for (const x in userName) {
            _userNameData.push(userName[x])
        }
        let _emailData = [];
        for (const x in email) {
            _emailData.push(email[x])
        }
        let _designationData = [];
        for (const x in designation) {
            _designationData.push(designation[x])
        }
        let _userImgData = [];
        for (const x in userImg) {
            _userImgData.push(userImg[x])
        }
        let _regionData = [];
        for (const x in regionValue) {
            _regionData.push(regionValue[x])
        }
        let _countryData = [];
        for (const x in countryValue) {
            _countryData.push(countryValue[x])
        }
        let _partyData = [];
        for (const x in partyValue) {
            _partyData.push(partyValue[x])
        }
        let _isDirectoryaccessValue = [];
        for (const x in isDirectoryaccess) {
            _isDirectoryaccessValue.push(isDirectoryaccess[x])
        }
        let _badgeData = [];
        for (const x in badgeValue) {
            _badgeData.push(badgeValue[x])
        }
        let _expertiseData = [];
        for (const x in expertiseValue) {
            _expertiseData.push(expertiseValue[x])
        }

        const sendUserName = _userNameData && _userNameData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendUserEmail = _emailData && _emailData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendDesignation = _designationData && _designationData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendUserImage = _userImgData && _userImgData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendRegion = _regionData && _regionData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendCountry = _countryData && _countryData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendParty = _partyData && _partyData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendIsDirectoryAccess = _isDirectoryaccessValue && _isDirectoryaccessValue.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendBadgeData = _badgeData && _badgeData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })
        const sendExpertiseData = _expertiseData && _expertiseData.filter((val, index) => {
            if (index === id) {
                return val
            }
        })

        const addFormData = new FormData();
        // if (allFieldsData.members) {
        //     const badgesValueSend = allFieldsData && allFieldsData.members.map(data => {
        //         return data._id
        //     })
        //     addFormData.append("badge", badgesValueSend);
        // }
        // if (expertise.expertise) {
        //     const ExpertiseValueSend = expertise && expertise.expertise.map(data => {
        //         return data.area
        //     })
        //     addFormData.append("expertise", ExpertiseValueSend);
        // }

        console.log(sendParty, "sendParty")
        addFormData.append("name", sendUserName && sendUserName[0]);
        addFormData.append("email", sendUserEmail && sendUserEmail[0] && sendUserEmail[0].toLowerCase());
        addFormData.append("designation", sendDesignation && sendDesignation[0]);
        addFormData.append("media", sendUserImage && sendUserImage[0]);
        addFormData.append("region", sendRegion && sendRegion[0]);
        addFormData.append("country", sendCountry && sendCountry[0]);
        if (sendParty.length > 0) {
            addFormData.append("party", sendParty && sendParty[0]._id);
        }
        if (sendBadgeData.length > 0) {
            addFormData.append("badge", sendBadgeData && sendBadgeData[0]._id);
        }
        if (sendExpertiseData.length > 0) {
            addFormData.append("expertise", sendExpertiseData && sendExpertiseData[0].area);
        }
        addFormData.append("isInternational", isInternational);

        addFormData.append("access", sendIsDirectoryAccess.length > 0  ? sendIsDirectoryAccess : false);
        try {
            const results = await addUser(addFormData);
            if (results.res.success) {
                toast.success(results.res.message);
                setRowId({
                    id
                })
                setLoader(false);
                let newArray = [...choices];
                newArray = [...newArray, ""];
                setChoices(newArray);
            }
        }
        catch (e) {
            setLoader(false);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const handleGoBack =() =>{
        history(-1);
    }

    return (
        <div className='px-4'>
        <span className="text-sm text-sidenav border-b border-solid border-sidenav cursor-pointer" onClick={handleGoBack}>Back</span>
            <form noValidate autoComplete="off" className="bulkUploadOverFlow">
                <table
                    className="w-full mt-5 table-theme-1"
                    cellSpacing={5}
                >
                    <thead>
                        <tr>
                            {bulkUploadHeader.map((column, id) => (
                                <th key={id}>
                                    {column.head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            choices.map((choice, id) => (
                                <tr>
                                    <td className='table_column_min_width'>
                                        <TextField
                                            id="name"
                                            key={id}
                                            name={`choices${id + 1}`}
                                            value={userName[`choices${id + 1}`] ? userName[`choices${id + 1}`].title : ""}
                                            onChange={(e) => handleNameChange(e)}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <TextField
                                            id="email"
                                            key={id}
                                            name={`email${id + 1}`}
                                            value={email[`email${id + 1}`] ? email[`email${id + 1}`].title : ""}
                                            onChange={(e) => handleEmailChange(e)}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <TextField
                                            id="designation"
                                            key={id}
                                            name={`designation${id + 1}`}
                                            value={designation[`designation${id + 1}`] ? designation[`designation${id + 1}`].title : ""}
                                            onChange={(e) => handleDesignationChange(e)}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <div>
                                            <div className="relative w-auto overflow-hidden">
                                                <TextField
                                                    id="profilephoto"
                                                    value={userImg[`userImg${id + 1}`] ? userImg[`userImg${id + 1}`].name : ""}
                                                    className="w-full text-xs"
                                                /> 

                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                                    name={`userImg${id + 1}`}
                                                    onChange={(e) => handleUserImgFile(e)}
                                                />
                                                <div className="floating-box" >+</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='table_column_min_width'>
                                        <Autocomplete
                                            id="combo-box-demo1"
                                            options={regions && regions.sort(dynamicSort("name"))}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            name={`regionValue${id + 1}`}
                                            inputValue={regionValue[`regionValue${id + 1}`] ? regionValue[`regionValue${id + 1}`] : ""}
                                            onInputChange={(event, newInputValue) => handleRegionData(event, newInputValue, `regionValue${id + 1}`)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <Autocomplete
                                            id="combo-box-demo2"
                                            options={countries && countries.sort(dynamicSort("name"))}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            name={`countryValue${id + 1}`}
                                            inputValue={countryValue[`countryValue${id + 1}`] ? countryValue[`countryValue${id + 1}`] : ""}
                                            onInputChange={(e, newInputValue) => handleCountryChange(e, newInputValue, `countryValue${id + 1}`)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <Autocomplete
                                            name={`partyValue${id + 1}`}
                                            options={partyListData && partyListData.sort(dynamicSort("name"))}
                                            getOptionLabel={(option) => option.name}
                                            value={`partyValue${id + 1}` ? `partyValue${id + 1}`.name : ""}
                                            onChange={(e, newVal) => onInputPartyChange(e, newVal, `partyValue${id + 1}`)}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <Autocomplete
                                            // multiple
                                            // limitTags={2}
                                            name={`badgeValue${id + 1}`}
                                            options={badges && badges.sort(dynamicSort("name"))}
                                            getOptionLabel={(option) => option.name}
                                            // value={allFieldsData.members}
                                            value={`badgeValue${id + 1}` ? `badgeValue${id + 1}`.name : ""}
                                            onChange={(e, newVal) => onInputChange(e, newVal, `badgeValue${id + 1}`)}
                                            renderInput={(params) => <TextField {...params} id="badge" />}
                                        />
                                    </td>
                                    <td className='table_column_min_width'>
                                        <Autocomplete
                                            // multiple
                                            // limitTags={2}
                                            name={`expertiseValue${id + 1}`}
                                            options={expertiseList && expertiseList.sort(dynamicSort("area"))}
                                            getOptionLabel={(option) => option.area}
                                            value={`expertiseValue${id + 1}` ? `expertiseValue${id + 1}`.area : ""}
                                            onChange={(e, newVal) => onInputExpertiseChange(e, newVal, `expertiseValue${id + 1}`)}
                                            renderInput={(params) => <TextField {...params} id="expertise" />}
                                        />
                                    </td>
                                    <td className='table_column_min_width ml-5'>
                                        <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item>
                                                <AntSwitch
                                                    name={`isDirectoryaccess${id + 1}`}
                                                    checked={isDirectoryaccess[`isDirectoryaccess${id + 1}`] ? isDirectoryaccess[`isDirectoryaccess${id + 1}`] : ''}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </td>
                                    <td className='table_column_min_width'>
                                        <button className="button-1" onClick={(e) => handleSubmit(e, id)} disabled={rowId && rowId.id === id ? true : false}>
                                            Submit
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    {/* <p className="cursor-pointer text-sidenav font-bold my-4" ><span onClick={() => handleChoices()}>+ Add Field</span></p> */}
                </table>
            </form>
        </div>
    )
}

export default UserBulkUpload