import { toast } from "react-toastify";
import {
    getExpertise,
    getCountries,
    getRegions,
    addHub,
    addMiniHub,
    getMembers,
    fetchSingleHub,
    getParties,
    getMembersById,
    fetchSingleMiniHub,
    addSection,
    allAdminUsers,
    fetchSingleSection,
    editHubApi
} from '../../Utils/services';


export const getExpertiseData = async () => {
    try {
        const result = await getExpertise();
        const expertise = result.res;
        return expertise
    }
    catch (error) {
        toast.error(error.message);
    }
}

export  const getCountriesData = async () => {
    try {
        const result = await getCountries();
        const { countries } = result.res;
        if (countries.length > 0) return countries
    }
    catch (error) {
        console.log(error);
        toast.error(error.message);
    }
}

export  const getRegionData = async () => {
    try {
        const result = await getRegions();
        const regions = result.res;
        if (regions.length > 0) return regions;
    }
    catch (error) {
        toast.error(error.message);
    }
}


export const submitHub = async (data) => {
    const formdata = new FormData()

    for (const x in data) {

        if (x == 'file') {
            formdata.append('media', data[x])
        }
        else {
            formdata.append(x, data[x])
        }
    }

    try {
        const _res = await addHub(formdata)
        return _res
    } catch (error) {
        toast.error(error.message);
    }
}

export const submitMiniHub = async (data) => {
    const formdata = new FormData()

    for (const x in data) {
        if (x == 'file') {
            formdata.append('media', data[x])
        }
        else {
            formdata.append(x, data[x])
        }
    }

    try {
        const _res = await addMiniHub(formdata)
        return _res
    } catch (error) {
        toast.error(error.message);
    }
}
export const editHubApiHandler = async (data) => {
    const formdata = new FormData()

    for (const x in data) {
        if (x == 'file') {
            formdata.append('media', data[x])
        }
        else {
            formdata.append(x, data[x])
        }
    }

    try {
        const _res = await editHubApi(formdata)
        return _res
    } catch (error) {
        toast.error(error.message);
    }
}

export const submitSection = async (data) => {
    try {
        const _res = await addSection(data)
        return _res
    } catch (error) {
        toast.error(error.message);
    }
}



export const fetchMembers = async () => {
    try {
        const res = await getMembers()
        const data = res.data.members;
        toast.success(res.message);
        return data;
    } catch (error) {
        toast.error(error.message);        
    }
}

export const fetchMembersById = async (id) => {
    try {
        const res = await getMembersById(id)
        const data = res.data.hubMembers;
        toast.success(res.message);
        return data;
    } catch (error) {
        toast.error(error.message);        
    }
}




export const getSingleHub = async (id) => {
    try {
        const res = await fetchSingleHub(id)
        const data = res.data;
        toast.success(res.message);
        return data;
    } catch (error) {
        toast.error(error.message);        
    }
}

export  const getPartyData = async () => {
    try {
        const result = await getParties();
        const parties = result.res;
        if (parties.length > 0) return parties;
    }
    catch (error) {
        toast.error(error.message);
    }
}


export const getSingleMiniHub = async (id) => {
    try {
        const res = await fetchSingleMiniHub(id)
        const data = res.data;
        toast.success(res.message);
        return data;
    } catch (error) {
        toast.error(error.message);        
    }
}


export const getSingleSection = async (id) => {
    try {
        const res = await fetchSingleSection(id)
        const data = res.data;
        toast.success(res.message);
        return data;
    } catch (error) {
        toast.error(error.message);        
    }
}




export const getAdminTableData = async () =>{
    try{
        const result = await allAdminUsers();
        toast.success(result.message);
        return result.data
    }
    catch(error){
      console.log(error, "Error");
      toast.error(error.message);
    }
  }