import { useState, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { addSegmentApi, addTagsApi, editSegmentApi, getAdminDetails, getCountries, getLanguageList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Autocomplete } from '@material-ui/lab';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';



const AddSegments = ({
    title,
    name,
    btnText,
    edit,
    geSegmentApiData,
    countryName,
    id,
}) => {
    const [loader, setLoader] = useState(false);
    const [segmentName, setSegmentName] = useState(name ? name : "");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState("");

    const context = useContext(ModalContext);
    const { closeModal } = context;
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    // const getCountryData = async () => {
    //     try {
    //         const result = await getCountries();
    //         setCountries(result?.res?.countries)

    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }

    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }


    const handleAdd = async () => {
        setLoader(true)
        const currentUTCTime = new Date().toISOString();
        try {
            const result = await addSegmentApi({
                name: segmentName,
                country : country?.country?._id,
                createdOn:currentUTCTime
            });
            if (result.res.success) {
                setLoader(false);
                toast.success(result.res.message)
                closeModal();
                geSegmentApiData();
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.message)
        }
    }

    const handleEdit = async () => {
        setLoader(true);
        try {
            const result = await editSegmentApi({
                id : id,
                name: segmentName,
                countrys : country?.country?._id
            });
            if (result.res.success) {
                setLoader(false);
                toast.success(result.res.success);
                geSegmentApiData();
                closeModal();
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.message)
        }
    }
    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    // useEffect(() => {
    //     getLanguageListData();
    // }, [])

    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" >
                <div className="flex flex-col w-full">
                    <TextField
                        label={t('Name')}
                        name="name"
                        value={segmentName}
                        onChange={e => setSegmentName(e.target.value)}
                    />
                </div>
                <div className="mt-5">
                    <Autocomplete
                        id="combo-box-demo"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        sx={{ width: 300 }}
                        value={country?.name }
                        defaultChecked ={countryName}
                        defaultValue ={countryName}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                    
                    {/* <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')}/>}
                        required
                    /> */}
                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="button" disabled={loader ? true : false} onClick={edit ? handleEdit : handleAdd}>
                        {btnText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddSegments;