import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState } from 'react';
import { addFeedPost, addForumPost, getTags, regionCountryList,addStoryPost,addNewsFeedPost, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { RadioGroup, FormControlLabel, Radio,Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NewsFeedForm = ({ getFeedData, title }) => {
    const [isFeatured, setIsFeatured] = useState(false)
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState({});
    const [publisher, setpublisher] = useState({});
    const [formlinks, setformlinks] = useState({});
    const [isNotification, setIsNotification] = useState(false)
    const [loader, setLoader] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
    const [showImgError, setshowImgError] = useState(false);
    const [adminType, setAdminType] = useState("All");
    const [pages, setPage] = useState(1);
    const [size] = useState(10);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const { t } = useTranslation();
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }

    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    const onAdminChangeHandler = e => {
        e.preventDefault()
        setCountry([]);
        const value = e.target.value
        setAdminType(value);
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    const onChangeHandlers = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setpublisher({ ...formData, [name]: value })
    }
    const onChangeHandlerss = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setformlinks({ ...formData, [name]: value })
    }

    const handlePostSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        const tagsData = tagsValue?.tags?.map((data) => {
            return data._id
        })
        const countryData = country?.country?.map((data) => {
            return data._id
        })
        const currentUTCTime = new Date().toISOString();
        const postFormData = new FormData();
        postFormData.append("title", formData.question ? formData.question : "");
        postFormData.append("tags", tagsData);
        postFormData.append("target", isNotification);
        postFormData.append("countrys", countryData);
        postFormData.append("category", adminType);
        postFormData.append("publisher", publisher.Publisher ? publisher.Publisher : "");
        postFormData.append("links", formlinks.previewlinks ? formlinks.previewlinks : "");
        try {
            const result = await addNewsFeedPost({
                "title": formData.question ? formData.question : "",
                "tags": tagsData,
                "countrys": countryData,
                "category": adminType,
                "publisher": publisher.Publisher ? publisher.Publisher : "",
                "links": formlinks.previewlinks ? formlinks.previewlinks : "",
                "date" : currentUTCTime,
                "target" : isNotification
            });
            if (result.res.success) {
                toast.success(result.res.message);
                setIsFeatured(true)
                setTimeout(() => {
                    getFeedData({
                        page: pages,
                        size: size
                    });
                }, 5000);
                setLoader(false);
                closeModal();
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }

    // useEffect(() => {
    //     // if (totalCount && totalCount > 0) {
    //     //     setTotalPageToShow(Math.ceil((totalCount) / size));
    //     // }
    //     getFeedData({  page: pages,
    //                 postsPerPage: size });
    // }, [])
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('add_new_NEWSFEED')} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                <div className="mt-4 w-6/12">
                    <RadioGroup aria-label="adminType" name="adminType" className="radio-group" defaultValue={adminType} onChange={e => onAdminChangeHandler(e)}>
                        <FormControlLabel value="All" control={<Radio />} label={t('All')}/>
                        <FormControlLabel value="National" control={<Radio />} label={t('National')} />
                        <FormControlLabel value="Regional" control={<Radio />} label={t('Regional')}/>
                        <FormControlLabel value="International News" control={<Radio />} label={t('International_News')} />
                    </RadioGroup>
                </div>
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData.question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                 
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label={t('Tags')} />}
                    />
                </div>
                <div className="mt-12 w-6/6">
                <div className='col-span-2'>
                        <TextareaAutosize
                            name="Publisher"
                            value={publisher.Publisher || ''}
                            onChange={(e) => onChangeHandlers(e)}
                            className="w-full"
                            id="postPublisher"
                            aria-label="Empty"
                            placeholder={t('Publisher')}
                            minRows={3}
                        />
                    </div>
                </div>
                <div className="mt-12 w-6/6">
                <div className='col-span-2'>
                        <TextareaAutosize
                            name="previewlinks"
                            value={formlinks.previewlinks || ''}
                            onChange={(e) => onChangeHandlerss(e)}
                            className="w-full"
                            id="postLinks"
                            aria-label="Empty"
                            placeholder={t('Add_Link')}
                            minRows={3}
                        />
                    </div>
                </div>
                <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={isNotification} onChange={(e) => setIsNotification(e.target.checked)} defaultChecked color="primary" />} label={t('Send_Push_Notification')} labelPlacement="start" />
                    </div>
            
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                    {t('submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default NewsFeedForm