import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { getSegmentApi, userApproved } from '../../Utils/services';
import { useTranslation } from 'react-i18next';
const UserApproveModal = ({
    getnewRegistraionTablesData,
    id
}) => {
    const [loader, setLoader] = useState(false);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const { t } = useTranslation();
    const getSegmentData = async () => {
        try {
            const result = await getSegmentApi({
                id : id
            });
            setSegmentData(result?.res?.data)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, []);
    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        try {
            const result = await userApproved({
                id: id,
                segmentId : segment?.segment?._id
            });
            if (result.res.success) {
                setLoader(false)
                toast.success(result.res.message)
                closeModal();
                getnewRegistraionTablesData({
                    page: page,
                    size: postsPerPage,
                 });
            }
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('Approve_User')}</h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <Autocomplete
                    id="combo-box-demo"
                    options={segmentData?.sort(dynamicSort("name"))}
                    getOptionLabel={(option) => option?.name || ""}
                    value={segment?.name}
                    onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                    renderInput={(params) => <TextField {...params} label={t('Segment')} />}
                />
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                    {t('Approve')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default UserApproveModal;