import { useContext, useEffect, useMemo } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { ModalContext } from "../../Context";
import { useState } from "react";
import DummyDpImg from "../../Assets/images/DummyDp.png";
import {
  BASE_URL_IMG,
  deleteGalleryPostApi,
  GalleryStatusApi,
  getTags,
  getGalleryCommentData,
} from "../../Utils/services";
import GallaryTable from "./GallaryTable";
import { useNavigate, useMatch, useParams } from "react-router-dom";
import EditGallery from "../ModalTemplate/EditGallery";
import { toast } from "react-toastify";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button, Grid, TextField } from "@material-ui/core";
import AntSwitch from "../Common/AntSwitch";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { useTranslation } from "react-i18next";
import { getCountriesData } from "../Master/getRequests";

const GallaryPage = ({ data, getGalleryDataApi, totalCount, adminData }) => {
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const [category, setCategory] = useState([]);
  const [selectfilter, setFilter] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const history = useNavigate();
  let { path } = useParams();
  const { t } = useTranslation();
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const context = useContext(ModalContext);
  const { handleModalData } = context;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // useEffect(() => {
  //   if (totalCount && totalCount > 0) {
  //     setTotalPageToShow(Math.ceil(totalCount / postsPerPage));
  //   }
  //   // if(selectfilter?.Country?.name !== undefined  )
  //   if (
  //     category?.Tag?.name !== undefined ||
  //     selectfilter?.Country?.name !== undefined
  //   ) {
  //     getGalleryDataApi({
  //       country: selectfilter?.Country?.name,
  //       tag: category?.Tag?.name,
  //     });
  //   }
  //   // else if(category?.Tag?.name !== undefined ){
  //   else if (
  //     category?.Tag?.name == undefined ||
  //     selectfilter?.Country?.name == undefined
  //   ) {
  //     getGalleryDataApi({
  //       page: page,
  //       size: postsPerPage,
  //     });
  //   } else if (selectfilter?.Country?.name !== undefined) {
  //     getGalleryDataApi({
  //       country: selectfilter?.Country?.name,
  //     });
  //   } else if (category?.Tag?.name !== undefined) {
  //     getGalleryDataApi({
  //       tag: category?.Tag?.name,
  //     });
  //   }
  // }, [page, totalCount, selectfilter?.Country?.name, category?.Tag?.name]);
  // Reset page to 1 when filters change
useEffect(() => {
  if (selectfilter?.Country?.name !== undefined || category?.Tag?.name !== undefined) {
    setPage(1);  // Page ko 1 pe reset karna
  }
}, [selectfilter?.Country?.name, category?.Tag?.name]);

// Fetch data when page or filters change
useEffect(() => {
  if (totalCount && totalCount > 0) {
    setTotalPageToShow(Math.ceil(totalCount / postsPerPage));
  }

  let payload = {
    page: page,
    size: postsPerPage,
  };

  if (selectfilter?.Country?.name !== undefined) {
    payload.country = selectfilter?.Country?.name;
  }

  if (category?.Tag?.name !== undefined) {
    payload.tag = category?.Tag?.name;
  }

  getGalleryDataApi(payload);

}, [page, totalCount, selectfilter?.Country?.name, category?.Tag?.name]);

  const showPostDetail = async (item) => {
    localStorage.setItem("itemss", item?._id);
    try {
      const result = await getGalleryCommentData({
        id: item._id,
      });
      if (result.success) {
        // toast.success(result.res.message)
        // getGalleryDataApi({ page, postsPerPage });
        history(`${item._id}`, {
          state: {
            data: result?.data[0],
            title: "Gallery Details",
            count: totalCount,
            mediadata: item,
          },
        });
      }
    } catch (error) {
      toast.error(error.messages);
    }
  };
  const deletePostGallery = async (id) => {
    try {
      const result = await deleteGalleryPostApi({
        id: id,
      });
      if (result.res.success) {
        toast.success("Post deleted successfully");
        getGalleryDataApi({ page, postsPerPage });
      }
    } catch (error) {
      toast.error(error.messages);
    }
  };

  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t("Remove_Gallery")}
        action={deletePostGallery}
        data={t("galleryRemoveText")}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  const EditPostDetail = (item) => {
    const Edit_MODAL = (
      <EditGallery
        forumData={item}
        getGalleryDataApi={getGalleryDataApi}
        isModalView={true}
        title={t("Edit_Gallery")}
      />
    );
    handleModalData(Edit_MODAL);
  };

  const handleActiveDeactive = async (props) => {
    const id = props.row.original._id;
    try {
      const result = await GalleryStatusApi(id);
      if (result.res.success) {
        toast.success(result.res.message);
        getGalleryDataApi({ page, postsPerPage });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const FeedsColumns = useMemo(
    () => [
      {
        Header: `${t("Post_Photo")}`,
        accessor: (data) => {
          return (
            <>
              <img
                src={`${BASE_URL_IMG}${data?.media[0]?.link}`}
                alt="Post Image"
                className="w-28"
              />
            </>
          );
        },
      },
      {
        Header: `${t("Title")}`,
        accessor: (data) => {
          return (
            <>
              <h4 className="w-60 text-md">{data?.title}</h4>
            </>
          );
        },
      },
      {
        Header: `${t("Country")}`,
        accessor: (data) => {
          return (
            <>
              {data?.country?.map((item) => {
                return <span>{`${item?.name} , `}</span>;
              })}
            </>
          );
        },
      },
      {
        Header: `${t("Tags")}`,
        accessor: (data) => {
          return (
            <>
              {data?.tag?.map((item) => {
                return <span>{`${item?.name} , `}</span>;
              })}
            </>
          );
        },
      },
      {
        Header: `${t("Posted_On")}`,
        accessor: (data) => {
          return <span>{formatDate(data?.date)}</span>;
        },
      },
      {
        Header: `${t("Likes")}`,
        accessor: (data) => {
          return <span>{data?.likes?.length}</span>;
        },
      },
      {
        Header: `${t("Comments")}`,
        accessor: (data) => {
          return <span>{data?.comments?.length}</span>;
        },
      },
      // {
      //     Header: `${t('View')}`,
      //     accessor: data => {
      //         return (
      //             <span>{data?.views}</span>
      //         )
      //     }
      // },
      {
        Header: `${t("Action")}`,
        accessor:
          adminData[0]?.AdminType === "Coordination team"
            ? ""
            : (data) => {
                return (
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={data.id}
                    onClick={() => showPostDetail(data)}
                  >
                    <span className="MuiButton-label">{t("View")}</span>
                  </button>
                );
              },
      },
      {
        Header: `${t("Edit")}`,
        accessor:
          adminData[0]?.AdminType === "Coordination team"
            ? ""
            : (data) => {
                return (
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={data.id}
                    onClick={() => EditPostDetail(data)}
                  >
                    <span className="MuiButton-label">{t("Edit")}</span>
                  </button>
                );
              },
      },
      {
        Header: `${t("Delete")}`,
        accessor:
          adminData[0]?.AdminType === "Coordination team"
            ? ""
            : (data) => {
                return (
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={data.id}
                    onClick={() => HandleRemoveEvent(data._id)}
                  >
                    <span className="MuiButton-label">{t("Delete")}</span>
                  </button>
                );
              },
      },
      {
        Header: `${t("Status")}`,
        accessor: "deactivated",
        Cell:
          adminData[0]?.AdminType === "Coordination team"
            ? ""
            : (props) => {
                return (
                  <Grid item>
                    <AntSwitch
                      checked={props.row.original.status}
                      onChange={() => handleActiveDeactive(props)}
                      name="isDirectory"
                    />
                  </Grid>
                );
              },
      },
    ],
    [adminData]
  );

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  const onInputCategoryChangeselect = (event, newInputValue, name) => {
    setFilter({
      ...selectfilter,
      [name]: newInputValue,
    });
  };
  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue,
    });
  };

  const getCountryApiData = async () => {
    try {
      const result = await getCountriesData();
      // console.log(result, "Result627");
      setCountryData(result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountryApiData();
  }, []);

  const geTagsApi = async () => {
    try {
      const result = await getTags();
      if (result.res.success) {
        setCategoryData(result.res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    geTagsApi();
  }, []);

  return (
    <>
      <div className="flex justify-between flex-wrap items-center">
        <Pagination
          count={totalPageToShow ? totalPageToShow : 10}
          variant="outlined"
          shape="rounded"
          className="flex items-center"
          onChange={handlePageChange}
          page={page}
        />
        <div></div>

        <div className="flex justify-between flex-wrap items-center">
          <Autocomplete
            options={countryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={selectfilter?.name}
            className="w-56 mr-5"
            onChange={(e, newVal) =>
              onInputCategoryChangeselect(e, newVal, "Country")
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Filter_By_Country")} />
            )}
          />
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, "Tag")}
            renderInput={(params) => (
              <TextField {...params} label={t("Filter_By_Tag")} />
            )}
          />
        </div>
      </div>
      {data && <GallaryTable columns={FeedsColumns} data={data} />}
    </>
  );
};

export default GallaryPage;
