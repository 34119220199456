import { useEffect, useState, useContext } from "react";
import PostDetail from "../Common/PostDetail";
import SimpleListing from "../Common/SimpleListing";
import GridListing from "../Common/GridListing";
import demoData from "./demoData";
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { getHomeApiData,getFouramHomedetail,
    getGalleryHomedetail,getResourcesHomedetail,
    getToolkitHomedetail,getStoryHomedetail,getNewsfeedHomedetail,
    getHomedetail, 
    getCourseHomedetail} from "../../Utils/services";
import { toast } from "react-toastify";
import ReactChartPage from "./ReactChartPage";
import { ModalContext } from '../../Context'
import PostDetails from "../ModalTemplate/PostDetails"
import { filterPostTypehome } from '../../Homefunction'
import classNames from "classnames";
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import Homes from "./Homes";
import HomesFouam from "./HomesFouam";
import HomesGallery from "./HomesGallery";
import HomesResources from "./HomesResources";
import HomesToolkit from "./HomesToolkit";
import HomesStory from "./HomesStory";
import HomesNewsFeed from "./HomesNewsFeed";    
import InnerHeader from '../Common/InnerHeader';
import { TabPanel } from "../Common/TabPanel"
import { BrowserRouter as Router, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import Education from "../Education";
import EducationDetail from "./EducationDetail";

const _chartData = {
    labels: [],
    datasets: [
      {
        label: "Updates",
        data: [],
        fill: true,
        backgroundColor: "rgba(217, 238, 247, 0.8)",
        borderColor: "rgba(20, 114, 249, 0.8)",
      },
    ]
};

const downward = '/assets/icons/downRedArrow.svg'
const upward = '/assets/icons/upward-arrow.png'

const Home = () => {
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const [popularSearchData, setpopularSearchData] = useState([]);
    const [popularMembersData, setPopularMembersData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [hubData, setHubData] = useState([]);
    const [allHomeData, setAllHomeData] = useState({});
    const [allHomeFouramData, setAllHomeFouramData] = useState({});
    const [allHomeGalleryData, setAllHomeGalleryData] = useState({});
    const [allHomeResourcesData, setAllHomeResourcesData] = useState({});
    const [allHometoolkitData, setAllHometoolkitData] = useState({});
    const [allHomestoryData, setAllHomestoryData] = useState({});
    const [allHomenewsfeedData, setAllHomeNewsfeedData] = useState({});
    const [allHomecourseData, setAllHomeCourseData] = useState({});
    const [allHomesData, setAllHomesData] = useState({});
    const [chartData, setChartData] = useState(_chartData)
    const [recentPost, setRecentPost] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [postData, setPostData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loader2, setLoader2] = useState(true);
    const [loader3, setLoader3] = useState(true);
    const [loader4, setLoader4] = useState(true);
    const [loader5, setLoader5] = useState(true);
    const [loader6, setLoader6] = useState(true);
    const [loader7, setLoader7] = useState(true);
    const [loader8, setLoader8] = useState(true);


    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const location = useLocation();
     const navigate  = useNavigate();
    // useEffect(() => {
    //     getHomeAllData();
    // }, []);

    const a11yProps = (index) => {
        return {
            id: `flagged-tab-${index}`,
            'aria-controls': `flagged-tabpanel-${index}`,
        };
    }

   console.log(allHomeData,"allHomeData")
    // const getHomeAllData = async () => {
    //     try {
    //         const result = await getHomeApiData();
    //         // const home_data = result.res;
    //         setLoader(false)
    //         const home_data = result.res;
            
    //         setAllHomeData(home_data)
           
    //         if (home_data.popularSearch.length > 0) {
    //             setpopularSearchData(home_data.popularSearch)
    //         }

    //         if (home_data.popularMembers.length > 0) {
    //             setPopularMembersData(home_data.popularMembers)
    //         }

    //         if (home_data.party.length > 0) {
    //             setPartyData(home_data.party)
    //         }

    //         if (home_data.hub.length > 0) {
    //             setHubData(home_data.hub)
    //         }

    //         if (home_data.recentPosts.length > 0) {
    //             setRecentPost(home_data.recentPosts)
    //             const _allData = home_data.recentPosts;
    //             const _pollData = _allData.filter(item =>{
    //                 if(item.isPoll == true){
    //                     return item
    //                 }
    //             })
    //             const _postData = _allData.filter(item =>{
    //                 if(item.isPoll == false){
    //                     return item
    //                 }
    //             })
    //             setPollData(_pollData)
    //             setPostData(_postData)
    //         }
    //         const labels = home_data.updates.graph.map(item => item._id)
    //         const data = home_data.updates.graph.map(item => item.count)
    //         _chartData.labels = labels
    //         _chartData.datasets[0].data = data
    //         console.log(_chartData, '_chardata', home_data);
    //         setChartData(_chartData)
    //     }
    //     catch (error) {
    //         setLoader(false)
    //         console.log(error)
    //         // toast.error(error.message);
    //     }
    // }

    const getHomeFouramAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getFouramHomedetail({ startDate, endDate });
           
            setLoader2(false)
            const home_data = result?.data;
            
            setAllHomeFouramData(home_data)
        }
        catch (error) {
            setLoader2(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeGalleryAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getGalleryHomedetail({ startDate, endDate });
           
            setLoader3(false)
            const home_data = result?.data;
            
            setAllHomeGalleryData(home_data)
        }
        catch (error) {
            setLoader3(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeResourcesAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getResourcesHomedetail({ startDate, endDate });
           
            setLoader4(false)
            const home_data = result?.data;
            
            setAllHomeResourcesData(home_data)
        }
        catch (error) {
            setLoader4(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeToolkitAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getToolkitHomedetail({ startDate, endDate });
           
            setLoader5(false)
            const home_data = result?.data;
            
            setAllHometoolkitData(home_data)
        }
        catch (error) {
            setLoader5(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeStoryAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getStoryHomedetail({ startDate, endDate });
           
            setLoader6(false)
            const home_data = result?.data;
            
            setAllHomestoryData(home_data)
        }
        catch (error) {
            setLoader6(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeNewsfeedAllData = async ({ startDate = '', endDate = '' } = {}) => {
        try {
            const result = await getNewsfeedHomedetail({ startDate, endDate });
           
            setLoader7(false)
            const home_data = result?.data;
            
            setAllHomeNewsfeedData(home_data)
        }
        catch (error) { 
            setLoader7(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    const getHomeCourseAllData = async ({ startDate = '', endDate = '' } = {}) => { 
        try {
            const result = await getCourseHomedetail({ startDate, endDate });
           
            setLoader8(false)
            const home_data = result?.data;
            
            setAllHomeCourseData(home_data)
        }
        catch (error) {
            setLoader8(false)
            console.log(error)
            // toast.error(error.message);
        }
    }
    // const getHomesAllData = async ({ startDate = '', endDate = '' } = {}) => {
    //     try {
    //         const result = await getHomedetail({ startDate, endDate });
           
    //         setLoader(false);
    //         const home_data = result?.data;
            
    //         setAllHomesData(home_data);
    //         if(showPrintButton === false){
    //         localStorage.setItem('homesData', JSON.stringify(home_data));
    //         }
    //     } catch (error) {
    //         setLoader(false);
    //         console.log(error);
    //         // toast.error(error.message);
    //     }
    // }
    

    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails
            data={data}
            isModalView={true}
            isReportBtn={true}
        />
        handleModalData(POST_MODAL)
    }

    // console.log(allHomeData, "allHomeData")
    const AdminType = localStorage.getItem("AdminType")
    const region = localStorage.getItem("region")
    // console.log(region,"region")
    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
        const params = new URLSearchParams(location.search);
        var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
        window.history.pushState({ path: newurl }, '', newurl);

        params.set('newValue', newValue);
        console.log(params,"params")
     
    };
  
  
    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(()=>{
        var idValue = getParameterByName('id');
        if(idValue){
            setTabsValue(parseInt(idValue))
        }
    },[])




    const tabsData = [
        {
            label: `${t("Home")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader} />
                    <Homes  setLoader={setLoader} loader={loader} allHomeData={allHomesData}   AdminType={AdminType} setAllHomesData={setAllHomesData}/>
                </>
            ) 
        },
        {
            label: `${t("Forum")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader2} />
                    <HomesFouam  loader={loader2} allHomeData={allHomeFouramData} getHomeAllData={getHomeFouramAllData}   />
                </>
            )
        },
        {
            label: `${t("Gallery")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader3} />
                    <HomesGallery loader={loader3} allHomeData={allHomeGalleryData} getHomeAllData={getHomeGalleryAllData}   />
                </>
            )
        },
        {
            label: `${t("Resources")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader4} />
                    <HomesResources  loader={loader4} allHomeData={allHomeResourcesData} getHomeAllData={getHomeResourcesAllData}   />
                </>
            )
        },
        {
            label: `${t("Toolkits")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader5} />
                    <HomesToolkit loader={loader5} allHomeData={allHometoolkitData} getHomeAllData={getHomeToolkitAllData}   />
                </>
            )
        },
        {
            label: `${t("Story")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader6} />
                    <HomesStory loader={loader6} allHomeData={allHomestoryData} getHomeAllData={getHomeStoryAllData}   />
                </>
            )
        },
        {
            label: `${t("NewsFeed")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader7} />
                    <HomesNewsFeed  loader={loader7} allHomeData={allHomenewsfeedData} getHomeAllData={getHomeNewsfeedAllData}   />
                </>
            )
        },
        {
            label: `${t("Course Detail")}`,
           // isNotshowAdd : AdminType  === "Coordination team" ? true : false,
            isNotshowAdd : true,
            data: (
                <>
                    <Loader loader={loader8} />
                    <EducationDetail  loader={loader8} allHomeData={allHomecourseData} getHomeAllData={getHomeCourseAllData}   />
                </>
            )
        },

        // {
        //     label: `${t("OFFLINE_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: liData && <LiEventData getEventApiData={getEventApiData} handleModalData={handleModalData} liData={liData} adminData={adminData} />
        // },

        // {
        //     label: `${t("DIGITAL_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: minihubData && <MinihubEvent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={minihubData} adminData={adminData} />
        // },
        // {
        //     label: `${t("HYBRID_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: minihubData && <Hybridevent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={hybrid} adminData={adminData} />
        // }
    ]

    return (
        <div className="relative">
        <InnerHeader
            tabsData={tabsData}
            // handleModalForm={handleModalForm}
            tabsValue={tabsValue}
            handlerTabs={handlerTabs}
            a11yProps={a11yProps}
            formBtn
        />

        {
            tabsData.map((obj, index) => (
                <TabPanel
                    className="px-10 homemainresponsive"
                    value={tabsValue}
                    index={index}
                    key = {index}
                >
                    {obj.data}
                </TabPanel>
            ))
        }
    </div>
    )
}

export default Home;