import TextField from '@material-ui/core/TextField';
import { MenuItem, Select } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addGalleryPOll, getAdminDetails, getTags } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader";
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));

const Pollform = ({ getFeedData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [choices, setChoices] = useState(['', '']);
    const [loader, setLoader] = useState(false);
    const [length, setLength] = useState(1);
    const [question, setQuestion] = useState("");
    const [location, setLocation] = useState("");
    const [choicesData, setChoicesData] = useState({});
    const [newChoiceData, setNewChoiceData] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const [countries, setCountries] = useState([]);
    const [tags, setTags] = useState([]);
    const [country, setCountry] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);

    const handleChange = (event) => {
        setLength(event.target.value);
    };

    const handleChoices = () => {
        if (choices.length < 8) {
            let newArray = [...choices];
            newArray = [...newArray, ""];
            setChoices(newArray);
        }
    };

    const handleChoicesData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setChoicesData({
            ...choicesData,
            [name]: {
                title: value
            }
        });
    };

    const handleFormSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        const _choicesData = [];

        for (const key in choicesData) {
            _choicesData.push(choicesData[key]);
        }

        const tagsData = tagsValue?.tags?.map((data) => {
            return data._id;
        });
        const countryData = country?.country?.map((data) => {
            return data._id;
        });

        try {
            const result = await addGalleryPOll({
                'question': question,
                'choices': _choicesData,
                'length': length * 24 * 60,
                "isPoll": true,
                "Tags": tagsData,
                "countrys": countryData,
            });

            if (result.res.success) {
                getFeedData({
                    page: 0,
                    postsPerPage: 10
                });
                const translatedMessage = t("Poll_added_successfully");
                toast.success(translatedMessage);
                closeModal();
                setLoader(false);
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    };

    const adminId = localStorage.getItem('adminId');
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            });
            setCountries(result.data.country);
        } catch (error) {
            console.log(error);
        }
    };

    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data);
            }
        } catch (error) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        getAdminDetailsApiData();
        geTagsApi();
    }, []);

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        });
    };

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        });
    };

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const handleDeleteChoice = (index) => {
        const newChoices = choices.filter((_, i) => i !== index);
        setChoices(newChoices);

        const newChoicesData = {};
        newChoices.forEach((choice, i) => {
            newChoicesData[`choices${i + 1}`] = choicesData[`choices${i + 1}`] || { title: "" };
        });
        setChoicesData(newChoicesData);
    };

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('Add_Poll')} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handleFormSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div>
                        <TextareaAutosize
                            name="question"
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Ask_Question')}
                            minRows={3}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <Autocomplete
                            id="combo-box-demo22"
                            multiple
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={country?.name}
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                        />
                        <Autocomplete
                            id="combo-box-demo2"
                            multiple
                            options={tags?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name}
                            sx={{ width: 300 }}
                            value={tagsValue?.name}
                            onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                            renderInput={(params) => <TextField {...params} label={t('Tags')} />}
                        />
                    </div>
                    <div>
                        {
                            choices.map((choice, id) => (
                                <>
                                <div key={id} className="flex items-center">
                                    <TextField
                                        name={`choices${id + 1}`}
                                        id={`outlined-basic${id + 1}`}
                                        label={`${t('Choice')} ${id + 1}`}
                                        className={classes.textField}
                                        variant="outlined"
                                        value={choicesData[`choices${id + 1}`] ? choicesData[`choices${id + 1}`].title : ""}
                                        onChange={(e) => handleChoicesData(e)}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                   
                                </div>
                                 <p className="cursor-pointer primary-color font-bold  mb-4" ><span onClick={() => handleDeleteChoice(id)}>{t('+_Poll_Delete')}</span></p>
                                 </>
                            ))
                        }
                        <p className="cursor-pointer primary-color font-bold text-right mb-4">
                            <span onClick={() => handleChoices()}>{t('+_Add_Poll')}</span>
                        </p>
                    </div>
                </div>
                <div className="flex justify-between items-center w-full">
                    <p className="primary-color font-bold"> {t('Poll_Length')}</p>
                    <Select
                        name="length"
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={length}
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>{t('1day')}</MenuItem>
                        <MenuItem value={2}>{t('2day')}</MenuItem>
                        <MenuItem value={3}>{t('3day')}</MenuItem>
                        <MenuItem value={4}>{t('4day')}</MenuItem>
                        <MenuItem value={5}>{t('5day')}</MenuItem>
                        <MenuItem value={6}>{t('6day')}</MenuItem>
                        <MenuItem value={7}>{t('7day')}</MenuItem>
                    </Select>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        {t('Add_Poll')}
                    </button>
                </div>
            </form>
        </>
    );
}

export default Pollform;
