import UsersTable from './UsersTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button} from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import DummyDpImg from "../../Assets/images/DummyDp.png"
import UserApproveModal from '../ModalTemplate/UserApproveModal';
import UserDeclineModal from '../ModalTemplate/UserDeclineModal';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const NewRegistration = ({ data,adminData, getTablesData,Counts,getnewRegistraionTablesData }) => { 
  const [searchValue, setSearchValue] = useState("");
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const { t } = useTranslation();
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    if(searchValue!= ''){
    const timeoutId = setTimeout(() =>
    getnewRegistraionTablesData({
          //  page: page,
          //  size: postsPerPage,
           query:searchValue,
    }), 500);
  return () => clearTimeout(timeoutId);
  //   if(searchValue!= ''){
  //   getTablesData({
  //     page: page,
  //     size: postsPerPage,
  //     query:searchValue,
  //   });
  // }
}}, [searchValue,Counts])

  useEffect(() => {
    if(searchValue === ""){
      getnewRegistraionTablesData({
        page: page,
        size: postsPerPage,
     })
    }
},[Counts,page,searchValue])

  useEffect(() => {
    setPageCount(Math.ceil(Counts / postsPerPage));
  }, [Counts])

  const history = useNavigate();
  const editHandler = (data) => {
    const EDIT_PARTY = <UserEditForm
      title={t('Edit_user')}
      buttonText={t('Save_Changes')}
      id={data.row.original._id}
      perticularData={data.row.original}
      getnewRegistraionTablesData={getnewRegistraionTablesData}
    />
    handleModalData(EDIT_PARTY);
  }

  const handleApprove = async (data) => {
    const OPEN_APPROVE_MODAL = <UserApproveModal
      id={data.cell.row.original._id}
      getnewRegistraionTablesData={getnewRegistraionTablesData}
   
    />
    handleModalData(OPEN_APPROVE_MODAL, 'md')
  }

  const handleDecline = async (data) => {
    const OPEN_DECLINE_MODAL = <UserDeclineModal
      id={data.cell.row.original._id}
      getnewRegistraionTablesData={getnewRegistraionTablesData}
   
    />
    handleModalData(OPEN_DECLINE_MODAL, 'md')
  }
 

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getnewRegistraionTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }

  }

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
}
  const userColumns = useMemo(
    () => [
      {
        Header: `${t('Status')}`,
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },
      {
        Header: `${t('Alias_Name')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
            <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
          </div>
        ),
      },
      {
        Header: `${t('Mobile_No.')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            <span className="ml-2">{userData.phone ? userData.phone : " - "}</span>
          </div>
        ),
      },
      {
        Header: `${t('Age')}`,
        accessor: Value => {
          return (
            <>
              {
                <span>{Value && Value.age ? Value.age : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Language')}`,
        accessor: Value => {
          return (
            <>
              {
                <span>{Value?.language ? Value?.language?.name : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Country')}`,
        accessor: properties => {
          return (
            <>
              <span className='capitalize'>{`${properties?.country ? properties?.country?.name : ' - '}`}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Sector')}`,
        accessor: properties => {
          return (
            <>
              <span className='capitalize'>{`${properties.sector ? properties?.sector?.name : ' - '}`}</span>
            </>
          )
        }
      },
      {
        Header: `${t('AppliedOn')}`,
        accessor: properties => {
          return (
            <>
              <span>{formatDate(properties?.addedOn)}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Action')}`,
        accessor: "_id",
        Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => editHandler(props)}>{t('Edit')}  </Button>
      },
      {
        Header: `${t('Approve')}`,
        accessor: "",
        Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => handleApprove(props)}> {t('Approve')} </Button>
      },
      {
        Header: `${t('Decline')}`,
        accessor: "",
        Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => handleDecline(props)}> {t('Decline')} </Button>
      },
    ], [])

  return (
    <>
      <div className="flex justify-between flex-wrap items-center">
      <div>
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          />
        </div>
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>

      </div>
      {
        data && <UsersTable columns={userColumns} data={data} />
      }
    </>
  )
}

export default NewRegistration;