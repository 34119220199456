import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';
import { regionCountryList, addUser, getResourcesCategory, addResources, editResources,editToolkit, BASE_URL_IMG, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';


const EditResourcesForm = ({
    getTablesData,
    id,
    data,
    title
}) => {  
    const {t} = useTranslation();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [formData, setFormData] = useState(data);
    const [loader, setLoader] = useState(false);
    const [CategoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState([]);
    const [pdfFile, setPdfFile] = useState('');
    const [mediaType, setMediaType] = useState("photo")
    const [VideoFile, setVideoFile] = useState("");
    const [disableButton, setDisableButton] = useState(false);
    const [isFileChange, setIsFileChange] = useState(false);
    const [isCountryChange, setIsCountryChange] = useState(false);
    const [isCategoryChange, setIsCategoryChange] = useState(false)
    const [videoValidation, setVideoValidation] = useState("");
    const [disablePdf, setDisablePdf] = useState(false);
    const [disableVideo, setDisableVideo] = useState(false);
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);

    const context = useContext(ModalContext);
    const { closeModal } = context;

    useEffect(() => {
        getCategoryData();
        getAdminDetailsApiData();
    }, [])

    // const handleVideoFile = (val) => {
    //     setIsFileChange(true)
    //     setVideoFile(val);
    //     setMediaType("video")
    //     if (val.name !== "") {
    //         setDisableButton(true)
    //     }
    // }
    const handleVideoFile = async (val) => {
        setMediaType("video")
        if (val && val.size > 0) {
            console.log(val, "val");
            if (val?.size && val?.size > 0) {
                if (Math.round(val.size / 1048576) > 300) {
                    setVideoValidation(`${t("Videosizeshouldbelessthan300MB")}`);
                    setVideoFile('');
                }
                const duration = await getVideoDuration(val);
                let durationSecond = Math.round(duration);

                if (durationSecond && durationSecond > 120) {
                    setVideoValidation(`${t("Videodurationshouldbelessthan2minutes")}`);
                    setVideoFile('');
                }
                else {
                    setVideoFile(val);
                    setVideoValidation("");
                    if (duration && duration > 120) {
                        setVideoValidation(`${t("Videodurationshouldbelessthan2minutes")}`);
                        setVideoFile("")
                    }
                    else {
                        setVideoFile(val);
                        setVideoValidation("");
                    }
                }
                console.log(Math.round(val.size / 1048576), "Val")
            }
        }
        if (val?.name !== "") {
            setDisablePdf(true)
        }
    }
    
    const getVideoDuration = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const media = new Audio(reader.result);
                media.onloadedmetadata = () => resolve(media.duration);
            };
            reader.readAsDataURL(file);
            reader.onerror = (error) => reject(error);
        });
    // const handlePdfFile = (val) => {
    //     setIsFileChange(true)
    //     setPdfFile(val);
    //     setMediaType("file")
    //     if (val.name !== "") {
    //         setDisableButton(true)
    //     }
    // }

    const handlePdfFile = (val) => {
        setPdfFile(val);
        setMediaType("file")
        if (val.name !== "") {
            setDisableVideo(true)
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setIsCountryChange(true);
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const getCategoryData = async () => {
        try {
            const result = await getResourcesCategory();
            setCategoryData(result?.data)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getCategoryData();
    }, [country]);
    const onInputCategoryChange = (event, newInputValue, name) => {
        setIsCategoryChange(true)
        setCategory({
            ...category,
            [name]: newInputValue
        })
    }

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }
    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmit = async (e) => {  
        e.preventDefault();
        setLoader(true);
        try {
            let countryData = country?.country?.map((item) => { 
                return item?._id;
            });
    
            let countryDatas = data && data.countries.map((item) => { 
                return item?._id;
            });
    
            const postFormData = new FormData();
            postFormData.append("id", id);
            postFormData.append("title", formData.title ? formData.title : "");
    
            postFormData.append("country", countryData ? countryData : countryDatas);
    
            // Handle pdfFile if it exists
            if (pdfFile?.name) {
                
                postFormData.append("media", pdfFile);
                postFormData.append("mediaType", mediaType);
            }
    
            // Handle VideoFile if it exists
            if (VideoFile !== "")  {
                
                postFormData.append("media", VideoFile);
                postFormData.append("mediaType", mediaType);
            }
    
            // postFormData.append("mediaType", mediaType);
    
            const results = await editToolkit(postFormData);
            if (results.res.success) {
                toast.success("Toolkit edited successfully");
                setLoader(false);
                closeModal();
                getTablesData({ 
                    page: page,
                    size: postsPerPage,
                });
            }
        } catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message || "An error occurred");
        }
    }
    

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t("Title")}
                        name="title"
                        value={formData.title || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <Autocomplete
                        multiple
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        defaultValue={data && data?.countries}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t("Country")} />}
                    />
                    
                    {/* <Autocomplete
                        id="combo-box-demo"
                        options={CategoryData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        value={category?.name}
                        defaultValue={data?.category}
                        onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                    /> */}
                    <br />
                </div>
                {/* <div className="mt-12 w-full">
                    <Grid container alignItems="center" spacing={1}>

                        <Grid item md={2}>
                            <span className="text-paraText">Add Media</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a Video"
                                    value={VideoFile.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="video/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="video-file"
                                    onChange={(e) => handleVideoFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/video.svg" alt="" />
                                Video
                            </div>
                        </Grid>
                        <Grid item md={2} className="flex items-center justify-center">
                            <span className="text-muteText">or</span>
                        </Grid>
                        <Grid item md={4}>

                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add a PDF File"
                                    value={pdfFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="pdf-file"
                                    onChange={(e) => handlePdfFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/photos.svg" alt="" />
                                Files
                            </div>
                        </Grid>
                    </Grid>
                </div> */}
                 <div className="mt-12 w-full">
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item md={2}>
                            <span className="text-paraText">{t("Add_Media")}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t("Add_a_Video")}
                                    value={VideoFile.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    disabled={disableVideo}
                                    type="file"
                                    accept="video/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="video-file"
                                    onChange={(e) => handleVideoFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            {
                                videoValidation && videoValidation && <p className="text-red-600">{videoValidation}</p>
                            }
                            <div className="media-box mt-2">
                                <img src="../../assets/video.svg" alt="" />
                                {t("Video")}
                            </div>

                        </Grid>
                        <Grid item md={2} className="flex items-center justify-center">
                            <span className="text-muteText"> {t("or")}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t("Add_a_PDF_File")}
                                    value={pdfFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disablePdf}
                                    type="file"
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="pdf-file"
                                    onChange={(e) => handlePdfFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/photos.svg" alt="" />
                                {t("Files")}
                            </div>
                         
                         
                            {/* <div className='flex justify-center editimage'>
  <img
  src={`${BASE_URL_IMG}${eventDetails?.image}`}
  alt="Post Image"
  className="w-80 "
/>
</div> */}
                        </Grid>
                        {

data?.media[0]?.mediaType === "file" ?
<div className='flex justify-center editimage'>
<img src={`${BASE_URL_IMG}${data?.pdfimg}`} alt="pdfimg" className='w-28' />
</div>
:
<div className='flex justify-center editimage'>
<img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
</div>
                         }
                    </Grid>
                </div>
                <div className="flex justify-center mt-10">
                    <button 
                    className={`mx-auto ${data?.videoimg || VideoFile.name !== undefined  || data?.pdfimg || pdfFile.name !== undefined ? "button-1" : "buttons-1"}`}
                    type="submit" 
                    
                    disabled={data?.videoimg || VideoFile.name !== undefined || data?.pdfimg || pdfFile.name !== undefined ? false : true}
                    
                    >
                    {t("Submit")}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditResourcesForm;