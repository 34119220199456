import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState } from 'react';
import { addCoursePost, addFeedPost, addForumPost, addSessionPost, EditSessionPost, getAdminDetails, getTags, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { CountryOPtion, CourseOption, CourseType } from '../Common/Option';
import { useTranslation } from 'react-i18next';

const EditSession = ({data, getTablesData, title,courseidid }) => {   
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState(data ? data : {});
    const [imgFile, setImgFile] = useState('');
    const [imgFile2, setImgFile2] = useState('');
    const [imgFile3, setImgFile3] = useState('');
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo")
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [CourseType, setCourseType] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
    const [showImgError, setshowImgError] = useState(false);
    const {t} = useTranslation();

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }

    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }

    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData()
        // getCountryData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    const onInputSectorsChange = (event, newInputValue, name) => {
        setCourseType({
          ...CourseType,
          [name]: newInputValue,
        });
      };

    const handleImgFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile2 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile2(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile2("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile3 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile3(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile3("");
                    setshowImgError(true)
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handlePostSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        // const tagsData = tagsValue?.tags?.map((data) => {
        //     return data._id
        // })
        // const countryData = country?.country?.map((data) => {
        //     return data._id
        // })
        // console.log(data,"data")
        
        const postFormData = new FormData();
        postFormData.append("session", formData.session ? formData.session : data?.session);
        postFormData.append("CourseId", data?._id);
        // postFormData.append("CourseType", CourseType?.CourseType?.value ? CourseType?.CourseType?.value : "");
        // postFormData.append("CourseDuretion", formData.CourseDuration ? formData.CourseDuration : "");
        // postFormData.append("tags", tagsData);
        // postFormData.append("country", countryData);
        // postFormData.append("description", article ? article : "");
        // postFormData.append("media", imgFile && imgFile);
        // postFormData.append("mediaType", mediaType)

        try {
            const result = await EditSessionPost({
                session : formData.session ? formData.session : data?.session,
                id : data?._id,
            });
            if (result.res.success) {
                // toast.success(result.res.message);
                toast.success("Session edited sucessfully");
                closeModal();
                getTablesData(courseidid);
                setLoader(false);
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="session"
                            value={formData.session || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                    {/* <div className='editorSectionStart col-span-2'>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />
                    </div> */}
                    {/* <Autocomplete
                       id="sectors"
                       options={CourseOption?.sort(dynamicSort("name"))}
                       getOptionLabel={(option) => option?.value || ""}
                       sx={{ width: 300 }}
                       value={CourseType?.value}
                       onChange={(e, newVal) => onInputSectorsChange(e, newVal, "CourseType")}
                       renderInput={(params) => <TextField {...params} label="CourseType" />}
                     />
                       <TextField
                       id="CourseDuration"
                       label="Course Duration"
                       name="CourseDuration"
                       value={formData.CourseDuration || ''}
                       onChange={(e) => onChangeHandler(e)}
                      />
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label="Tags" />}
                    /> */}
                     
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={4}>
                        {/* <Grid item md={1}>
                            <span className="text-paraText">Add Media</span>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 1"
                                    value={imgFile.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 2"
                                    value={imgFile2.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile2(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <Grid item md={3}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label="Add Photo 3"
                                    value={imgFile3.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile3(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                Photo
                            </div>
                        </Grid> */}
                        {/* <p className='mt-4 text-sidenav'> Note : Image should be 400 x 400px</p> */}
                    </Grid>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                    {t('Submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditSession