import classNames from "classnames"
import { BASE_URL_IMG } from "../../Utils/services";
import { useTranslation } from "react-i18next";


const ArticlePost = ({
    avatarClass,
    data,
    onClickHandler,
    isModalView,
    isReportBtn
}) => {
    const {t} = useTranslation();
     return (
        <div className={classNames({
            'post-detail pt-4': true,
            'relative': true,
            'modal-view': isModalView,
        })}>
            <div className="header">
                <img alt="" src={ data && data.postedBy && data.postedBy.image && `${BASE_URL_IMG}${data.postedBy.image}`} className={avatarClass} />
                <span className="pt-1 text-sm primary-color font-semibold tracking-wider">
                    {data && data.postedBy && data.postedBy.name && data.postedBy.name}
                </span>
            </div>

            <div className="w-full">
                <div className="p-4 pt-10 bg-1">
                <h3 className="text-sm font-semibold primary-color tracking-wider mb-1">
                    { data && data.title }
                </h3>

                <p className="text-xs text-paraText">
                    { data && data.caption }
                </p>
                {
                    !isModalView && (
                        <span className="text-btn2" onClick={() => onClickHandler(data)}>
                          {t("ReadArticle")}
                        </span>
                    ) 
                }
                </div>
                {
                    isReportBtn && (
                        <div className="flex mt-2">
                            <button
                                className="button-1"
                                type="button"
                                // disabled ={loader ? true : false}
                                // onClick={edit ? handleEdit : handleAdd}
                            >
                                 {t("Report")}
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ArticlePost;