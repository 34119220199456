import React from 'react'
import styles from "../../Assets/Comment.module.css";
import { useTranslation } from 'react-i18next';
// import inputbtn from "../Assets/images/Social/inputbtn.png"
import avtarImg from "../../Assets/images/DummyDp.png";
import moment from 'moment';
import { v4 as uuidv4  } from "uuid";
import { useState } from 'react';
import { useContext } from "react";
import { FunctionGalleryContext } from "../../Context";
import { addCommentForum,deleteCommentGallery, addGalleryCommentForum, deleteCommentForum, deleteReplyCommentForum, getAdminDetails, TRANSLATE_URL} from '../../Utils/services';
import { toast } from 'react-toastify';
import InputText from './InputText';
import { useEffect } from 'react';
//const TRANSLATE_URL =`https://translation.googleapis.com/language/translate/v2?key=AIzaSyC2SUlb9pljbtvOW5w_iqJK0GM_DVCzLGk`;

const Commentdatabox = ({data,postId,commentBoxDataProps, totalCount}) => {
    const [reply, setReply] = useState("");
    const { postDatas, getGalleryDataApi, GalleryDatas, getGalleryDataApis } = useContext(FunctionGalleryContext);
    const [showReplyInput, setShowReplyInput] = useState({});
    const [translateLanguage, settranslateLanguage] = useState();
    const [comment, setComment] = useState("");
    const [qusTions,setQustions] =useState()
    const [contents,setContents] =useState()
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [totalPageToShow, setTotalPageToShow] = useState(1);
    const [commenttrans,setcommenttrans] =useState("")
    const [replytrans,setreplytrans] =useState("")
    const [Id,setId] =useState()
    const [Ids,setIds] =useState()

  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };
  const {t} = useTranslation();
  const handleReplyClick = (id) => {
    setShowReplyInput({
      [id]: !showReplyInput[id],
    });
  };
  useEffect(() => {
    if (totalCount && totalCount > 0) {
      setTotalPageToShow(Math.ceil(totalCount / postsPerPage));
    }
    getGalleryDataApi({ page, postsPerPage });
  }, [page, totalCount]);

  const handleReplySubmit = async (e, data) => {
    e.preventDefault();
    try {
      const result = await addGalleryCommentForum({
        postId: postId,
        comment: reply,
        commentId: data._id,
      });
      if (result.res.success) {
        toast.success(result.res.message);
        setReply("");
        getGalleryDataApi({ page, postsPerPage });
        getGalleryDataApis()
      }
      console.log(result);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteComment = async (id) => {
    try {
      const result = await deleteCommentGallery({
        commentId: id,
        postId: postId,
      });
      toast.success(result.res.message);
      getGalleryDataApi({ page, postsPerPage });
      getGalleryDataApis()
      console.log(result, "result");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleReplyDeleteComment = async (id, commentsid) => {
    try {
      const result = await deleteCommentGallery({
        commentId: commentsid,
        postId: postId,
        replyId: id,
      });
      toast.success(result.res.message);
      getGalleryDataApi({ page, postsPerPage });
      getGalleryDataApis()
      console.log(result, "result");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const userNameHandler = (item) => {
    let result = commentBoxDataProps.users.filter(
      (items) => items._id === item.user
    );
    //  console.log(result,"sandeep data check")
    let AlternateName = result[0];
    return AlternateName?.aliasName;
  };
  const userNameHandlers = (item) => {
    let result = commentBoxDataProps.user_reply.filter(
      (items) => items._id === item.user
    );
    //  console.log(result,"sandeep data check")
    let AlternateName = result[0];
    return AlternateName?.aliasName;
  };
  useEffect(() => {
    getAdminDetailsApiData();
  }, [])
  const adminId = localStorage.getItem('adminId');
     
  const getAdminDetailsApiData = async () => {
      try {
          const result = await getAdminDetails({
              id: adminId,
              "type": "admin"
          })
          settranslateLanguage(result.data.language[0].name)
      } catch (error) {
          console.log(error)
      }
  }
  const handleChangeButton =()=>{ 
    let toLang = translateLanguage; // translate to norwegian
    let temp;
    // let text =`${qusTion}`; 
    let textContent =`${data?.content}`
    if(toLang === "English"){
      temp = "en";
    }else if(toLang === "हिंदी"){
      temp = "hi"
    }
    else if(toLang === "বাংলা"){
      temp = "bn"
    }
    else if(toLang === "မြန်မာစာ"){
      temp = "my"
    }
    else if(toLang === "Bahasa"){
      temp = "id"
    }
    else if(toLang === "অসমীয়া"){
      temp = "as"
    }
    else if(toLang === "Melayu"){
      temp = "ms"
    }
    else if(toLang === "नेपाली"){
      temp = "ne"
    }
    else if(toLang === "ไทย"){
      temp = "th"
    }
    else if(toLang === "ខ្មែរ"){
      temp = "km"
    }
    let url =TRANSLATE_URL;
    // url += '&q=' + encodeURI(text);
    url += '&q=' + encodeURI(textContent);
    url += `&target=${temp}`;
    const token = localStorage.getItem("token");
    
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      }
    })
    .then(res => res.json())
    .then((response) => {
       setQustions()
       setcommenttrans(response.data.translations[0].translatedText)
    //    setId(index)
     
    })
    .catch(error => {
      console.log("There was an error with the translation request: ", error);
    });
  
  }
  const handleChangeButtons =({index, items})=>{   
    let toLang = translateLanguage; // translate to norwegian
    let temp;
    // let text =`${qusTion}`; 
    let textContent =`${items?.content}`
    if(toLang === "English"){
      temp = "en";
    }else if(toLang === "हिंदी"){
      temp = "hi"
    }
    else if(toLang === "বাংলা"){
      temp = "bn"
    }
    else if(toLang === "မြန်မာစာ"){
      temp = "my"
    }
    else if(toLang === "Bahasa"){
      temp = "id"
    }
    else if(toLang === "অসমীয়া"){
      temp = "as"
    }
    else if(toLang === "Melayu"){
      temp = "ms"
    }
    else if(toLang === "नेपाली"){
      temp = "ne"
    }
    else if(toLang === "ไทย"){
      temp = "th"
    }
    else if(toLang === "ខ្មែរ"){
      temp = "km"
    }
    let url =TRANSLATE_URL;
    // url += '&q=' + encodeURI(text);
    url += '&q=' + encodeURI(textContent);
    url += `&target=${temp}`;
    const token = localStorage.getItem("token");
    fetch(url, { 
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`, // notice the Bearer before your token
      }
    })
    .then(res => res.json())
    .then((response) => {
       setQustions()
       setreplytrans(response.data.translations[0].translatedText)
       setIds(index)
    })
    .catch(error => {
      console.log("There was an error with the translation request: ", error);
    });
  
  }

  return (
    <>
    <div className="comment-bg border-b p-3">
              <div className="grid grid-cols-6">
                <div className="col-span-1">
                  <img src={avtarImg} className=" w-8 rounded-full" alt="" />
                </div>
                <div className="pl-2 col-span-5">
                  <p className={styles.comment}>
                    <span className={styles.name}> {data.isAdmin ? <>{data.name} </> : userNameHandler(data)}{" "}</span>
                    <span className="pr-2 text-red-500">
                      {data.isAdmin ? "Moderator" : ""}
                    </span>
                     
                    {data.content}
                       {
                        <p className="text-justify text-sm">{commenttrans}</p>
                        }
                    
                  </p>
                  <div className="flex justify-start items-center pt-2">
                    {/* <div className={styles.num}>{formatDate(data.date)}</div> */}
                    <div className={styles.num}>{moment(data.date).fromNow(true)}</div>
                    
                    <div className="flex pl-4">
                      <span className={styles.num}>
                        {data.likes && data.likes.length}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.icon}
                        width="21.144"
                        height="18.658"
                        viewBox="0 0 21.144 18.658"
                      >
                        <g transform="translate(0 0)">
                          <g transform="translate(0 0)">
                            <path
                              className="a"
                              d="M19.479,1.715a5.681,5.681,0,0,0-8.086,0l-.8.8-.8-.8A5.718,5.718,0,0,0,1.715,9.8L10.6,18.683,19.479,9.8a5.681,5.681,0,0,0,0-8.086"
                              transform="translate(-0.025 -0.025)"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="flex pl-4">
                      <span className={styles.num}>
                        {data.replies && data.replies.length}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.icon}
                        width="17.622"
                        height="18.659"
                        viewBox="0 0 17.622 18.659"
                      >
                        <path
                          className="a"
                          d="M15.2-1.332H2.423A2.628,2.628,0,0,0,0,1.456v9a2.629,2.629,0,0,0,2.414,2.788v4.084l5.1-4.083H15.2a2.628,2.628,0,0,0,2.423-2.789v-9A2.628,2.628,0,0,0,15.2-1.332ZM12.906,9.126H4.717V7.937h8.189Zm0-2.535H4.717V5.4h8.189Zm0-2.535H4.717V2.867h8.189Zm0,0"
                          transform="translate(0 1.332)"
                        />
                      </svg>
                    </div>
                    <button
                      className={`pl-4 ${styles.num}`}
                      onClick={() => handleReplyClick(data?._id)}
                    >
                      {t("Reply")}
                    </button>
                    <button
                      onClick={() => handleDeleteComment(data?._id)}
                      className={`pl-2 text-red-500 ${styles.num}`}
                    >
                      {t("Delete")}
                    </button>
                    <button
                      // onClick={() => handleChangeButton(data.content)}
                      onClick={()=>handleChangeButton({data})}
                      className={`pl-2 text-600 ${styles.num}`}
                    >
                    {t("Translate")}
                    </button>
                  </div>
                  {data?.replies?.map((items, index) => { 
                    return (
                      <React.Fragment key={index}>
                        <InputText index={index} items={items} replytrans={replytrans} Ids={Ids} handleChangeButtons={handleChangeButtons} handleReplyDeleteComment={handleReplyDeleteComment} userNameHandlers={userNameHandlers}/>
                      </React.Fragment>
                     
                    );
                  })}
                </div>
              </div>
              {showReplyInput[data._id] && (
                <div className="grid grid-cols-8 my-6">
                  <div className="col-span-1 flex justify-center items-center">
                    <img src={avtarImg} className=" w-12 rounded-full" alt="" />
                  </div>
                  <div className="col-span-5">
                    <form
                      className="flex justify-center items-center"
                      onSubmit={(e) => handleReplySubmit(e, data)}
                    >
                      <input
                        type="text"
                        name="reply"
                        className="commentSectionInput"
                        value={reply}
                        placeholder= {t("Reply")}
                        onChange={(e) => setReply(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
              )}
            </div>

    </> 
  )
}

export default Commentdatabox