import React, { useContext, useEffect, useState } from 'react'
import { ModalContext } from '../../Context';
import EditEventForm from '../ModalTemplate/EditEventForm';
import EventEditDeletePopUp from '../ModalTemplate/EventEditDeletePopUp';
import ReactCalendarPage from './ReactCalendarPage'
import { useTranslation } from 'react-i18next';
const Hybridevent = ({ minihubData, getEventApiData,adminData }) => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [showEvent, setShowEvent] = useState([]);
    const [year, setYear] = useState(new Date().getUTCFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { t } = useTranslation();
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    const formateTime = (time) => {
        const _Time = new Date(time);
        const hours = _Time.getUTCHours();
        const minutes = _Time.getUTCMinutes();
        const minutess =  minutes <10 ? `0${minutes}`: minutes
        const finalhours = hours > 12 ? hours-12 : hours
        const ampm = hours >= 12 ? 'pm' : 'am';
        const FullTime = `${finalhours}: ${minutess} ${ampm}`
        return FullTime
    }

    const getAllApiDataOnLoad = () => {
        setShowEvent([])
        let array = [];
        minihubData && minihubData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(currentDate)
            if (_d1 === _d2) {
                array.push(item)
            }
            else {
                console.log(false);
                // setShowEvent([])
            }
            setShowEvent(array);
        })
    }


    useEffect(() => {
        getAllApiDataOnLoad();
    }, [minihubData])


    const dateHandler = (date) => {
        setCurrentDate(date)
        setShowEvent([])
        let array = [];

        minihubData.map((item) => {
            const _date = new Date(item.current)
            const _d1 = formatDate(_date)
            const _d2 = formatDate(date)
            if (_d1 === _d2) {
                array.push(item)
            }
            else {
                console.log(false);
            }
            setShowEvent(array);
        })
    }

    const handleActiveStartDate = (value) => {
        const _yearMonth = value.activeStartDate;
        const year = _yearMonth.getFullYear();
        const month = _yearMonth.getMonth() + 1;
        setYear(year)
        setMonth(month)
    }
    useEffect(() => {
        getEventApiData(year, month);
    }, [year, month]);


    const handleEditEventShow = (data, id) => {  
        const edit_Form_Modal =
            <EventEditDeletePopUp
                title= {t('EDIT_HYBRID_EVENT')}
                buttonText={t('Save')}
                getEventApiData={getEventApiData}
                eventDetails={data}
                eventId={id}
                adminData={adminData}
            />
        handleModalData(edit_Form_Modal,)
    }

    return (
        <div className="grid xl:grid-cols-4 md:grid-cols-3">
            <div className="col-span-3">
                <ReactCalendarPage minihubData={minihubData} value={currentDate} onChange={dateHandler} activeStartDateChange={handleActiveStartDate} />
                <div className="my-8 flex items-center">
                    <div className="flex items-center mr-4">
                        <span className="w-4 h-4 rounded-full bg-sidenav" />
                        <span className="text-paraText ml-2">{t('Offline_Calendar')}</span>
                    </div>

                    <div className="flex items-center mx-4">
                        <span className="w-4 h-4 rounded-full bg-bgOrange" />
                        <span className="text-paraText ml-2">{t('Digital_Calendar')}</span>
                    </div>
                </div>
            </div>
            <div className="details_main">
                <div className="sticky top-0 sticky-sidebar">
                    <h2 className="text-sidenav text-2xl pb-2 pl-4">{t('Details')}</h2>
                    {
                        showEvent.length > 0 ?
                            (
                                showEvent.map(item => (
                                    <div className={`p-5 mb-5 cursor-pointer bg-bgOrange`} key={item._id} onClick={() => handleEditEventShow(item, item._id)}>
                                        <h3 className={`text-xl text-white pb-3`}>{t('Event_Name')} : {item.name} </h3>
                                        <h3 className={`text-md text-white pb-3`}>{t('Event_Organiser')}: {item.organiser} </h3>
                                        <h3 className={`text-white`}>{t('Event_Description')}: {item.description} </h3>
                                        <p className={`text-sm text-white py-1`}>{t('Event_Date')}: {`${formatDate(item.start)} to ${formatDate(item.end)}`} </p>
                                        <p className={`text-sm text-white`}>{t('Event_Time')}: {`${formateTime(item.startTime)} to ${formateTime(item.endTime)}`} </p>

                                        <p
                                            className={`text-sm py-1 text-white`}
                                        >
                                            {item.timezone}
                                        </p>
                                        <p className={`text_tosmall_10 text-white`}>  {item && item.links && item.links.map(data => {
                                            return (
                                                <>
                                                    <p>{data}</p>
                                                </>
                                            )
                                        })} </p>
                                    </div>

                                ))

                            )
                            : (
                                <div className="ml-5">
                                    <p>No Events</p>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    )
}

export default Hybridevent
