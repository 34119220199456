import { useState } from 'react';
import FeedsPoll from './FeedsPoll';
import FeedsPost from './FeedsPost';



const FeedsForm = ({
    title,
    post,
    getFeedData
}) => {

    const [postVal, setPostVal] = useState(post);

    return (
        <>
            <div className="flex items-center justify-center">
                <h3 className="title">{title}</h3>
            </div>
            <div className="my-12 flex">
                <div className="flex items-baseline">
                    <img
                        src={
                            postVal ?
                                "../../assets/Compose-active.svg" :
                                "../../assets/Compose.svg"
                        }
                        onClick={() => setPostVal(true)} alt="" className="cursor-pointer" />
                    {postVal && <span className="primary-color font-bold">POST</span>}
                </div>
            </div>
            <FeedsPost getFeedData ={getFeedData} /> 
        </>
    )
}

export default FeedsForm;