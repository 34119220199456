import { useMemo } from "react"
import FeedsTable from "../Feeds/FeedsTable"
import { BASE_URL_IMG, deleteSuggesteadPostApi, flagReinstate, singleForumApi } from "../../Utils/services"
import { useNavigate } from "react-router-dom"
import { Button } from "@material-ui/core";
import { toast } from "react-toastify"
import { useEffect } from "react";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { ModalContext } from "../../Context";
import { useContext } from "react";
import { useTranslation } from 'react-i18next';



const Suggestead = ({ flagData, getManageFlag, postId, adminData}) => { 
    // console.log(postId, "postId")
    // console.log(flagData, "flagData")
    const history = useNavigate();
    const { t } = useTranslation();
    useEffect(() => {
        getManageFlag();
    }, [])
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const showPostDetail = async (item, data) => {
        try {
            const result = await singleForumApi(item)
            let sendData = result?.res?.thread[0];
            history(`${item}`,{
               
                state: {
                    data: sendData,
                    title: 'Forum Details'
                }
            })
        } catch (error) {
            console.log(error.message)
        }
    }
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }


    const reinstateHandler = async (id, DataId, commentId) => {
        try {
            if (commentId !== undefined) {
                const res = await flagReinstate({
                    id: id,
                    commentId: commentId,
                    replyId: DataId
                })
                toast.success(res.message)
                getManageFlag();
            }
            else{
                const res = await flagReinstate({
                    id: id,
                    commentId: DataId,
                })
                toast.success(res.message)
                getManageFlag();
            }

        } catch (error) {
            toast.error(error.message)
        }
    }
    const HandleDeleteResource = async (id) => {
        try {
            const result = await deleteSuggesteadPostApi({id:id});
            console.log(result)
            if (result.res.success) {
                toast.success("Suggested Topic Deleted")
                getManageFlag();
            }

        } catch (error) {
            toast.error(error.message)
        }
    }
    const HandleRemoveEvent = (id) => { 
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('suggested_Topic')}
            action={HandleDeleteResource}
            data={t('SuggestedTopicDelete')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };

    const FeedsColumns = useMemo(
        () => [
            {
                Header: `${t('Suggested_By')}`,
                accessor: data => {   
                    return (
                        <div className="my-12 flex justify-center flex-col">
                            <span className="mt-2 max-w-xs">{data?.suggestedBy?.name}</span>
                        </div>
                    )
                }
            },
            {
                Header: `${t('suggested_Topic')}` ,
                accessor: data => {   
                    return (
                        <div className="my-12 flex justify-center flex-col">
                            <span className="mt-2 max-w-xs">{data?.suggested_topic}</span>
                        </div>
                    )
                }
            },
            {
                Header: `${t('Description')}`,
                accessor: data => {   
                    return (
                        <div className="my-12 flex justify-center flex-col">
                            <span className="mt-2 max-w-xs">{data?.description}</span>
                        </div>
                    )
                }
            },
            {
                Header: `${t('Posted_On')}`,
                accessor: data => {
                    return (
                        <span>{formatDate(data?.date)}</span>
                    )
                }
            },
            {
                Header:  `${t('Delete')}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : data => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            onClick={() => HandleRemoveEvent(data?._id)}
                        >
                            <span className="MuiButton-label">{t('Delete')}</span>
                        </button>
                    )
                }
            },
           

        ], [])

    return (
        <>
            {flagData &&
                <FeedsTable columns={FeedsColumns} data={flagData} />

            }

        </>
    )
}

export default Suggestead