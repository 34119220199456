import React, { useState, useEffect ,useContext} from 'react';
import UsersTable from './UsersTable';
import { useMemo } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate, useMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import { BASE_URL_IMG, InviteAllPeopleNotification, InviteNotification,adminDeactivate } from '../../Utils/services';
import { Button, Grid } from '@material-ui/core';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
const Admin = ({ data, getAdminTableData,adminData }) => {
  const modalContext = useContext(ModalContext);
  const [searchValue, setSearchValue] = useState("");
  const [cordinater, setcordinater] = useState(data[0]?.AdminType);
  const { closeModal, handleModalData } = modalContext;
  let { path } = useParams();
  const history = useNavigate();
  const param = useParams();
  // console.log(param, "Param")

  useEffect(() => {
    getAdminTableData()
  }, [])
  const { t } = useTranslation();
  const showPostDetail = item => { 
    
    history(`${item._id}`,{ 
    
      state: {
        id: item._id,
        TypeOfAdmin : item.AdminType
      }

    })
  }
  const adminId = localStorage.getItem('adminId');
  const AdminType = localStorage.getItem('AdminType');
  const handleInvite = async (data) => {
    console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "admin",
        id: data.row.original._id
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleMaintenance = async (id) => {
    try {
      const _result = await adminDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getAdminTableData();
      }
    } catch (error) {
      toast.error(error.message)
    }

  }

  const handleDeactivate = async (props) => {
    const id = props.row.original._id;
    const USER_DEACTIVATE = <MaintenanceModeConfirmation
      title="Change Admin Status"
      data={modalTextsFile.AdminActivateDeactivate}
      action={() => handleMaintenance(id)}
    />
    handleModalData(USER_DEACTIVATE, 'sm')
  }
  const adminColumns = useMemo(
    () => [
      {
        Header: `${t("Status")}`,
        accessor: 'status',
        Cell: ({ cell: { value } }) => value ?
        <div className="rounded-full bg-orange h-3 w-3"></div>
          :
          <div className="rounded-full bg-green h-3 w-3"></div>
      },
      {
        Header: `${t('Users')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            {
              userData.image && <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
            }
            <span className="ml-2">{userData.name}</span>
          </div>
        ),
      },
      {
        Header: `${t('Role')}`,
        accessor: 'AdminType',
      },
      {
        Header: 'Age',
        accessor: Value => {
          return (
            <>
              {
                <span>{Value && Value.age ? Value.age : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Language')}`,
        accessor: Value => {
          return (
            <>
              {
                <span>{Value && Value.language ? Value.language[0]?.name : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Country')}`,
        accessor: properties => <span className='capitalize'>{
          properties?.country?.map((data, index) => {
            return (
              <p>{`${data?.name}`}</p>
            )
          })
        }</span>
      },
      {
        id: "questionID",
        Header: `${t("Action")}`,
        accessor: (data) => { 
          return (
            cordinater === "Coordination team" ? "" :
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              key={data.id}
              onClick={() => showPostDetail(data)}
            >
              <span className="MuiButton-label">{t("View")}</span>
            </button>
          )
        },
      },
      {
        Header: `${t("Status")}`,
        accessor: "deactivated",

         Cell: cordinater === "Coordination team" ? "" :props =>{  
         
          return (
             AdminType === "Platform Admin" ?
             props?.row?.original?._id === adminId ?
            <>
         <Grid item>
          <AntSwitch
            checked={!props.row.original.status}
            onChange={() => handleDeactivate(props)}
            name="isDirectory"
          />
        </Grid>
        </> :  props?.row?.original?.AdminType === "Regional Admin" || props?.row?.original?.AdminType === "Country Admin"  || props?.row?.original?.AdminType === "Coordination team"? 
           <>
           <Grid item>
            <AntSwitch
              checked={!props.row.original.status}
              onChange={() => handleDeactivate(props)}
              name="isDirectory"
            />
          </Grid>
          </> : null : AdminType === "Regional Admin" ? 
          props?.row?.original?._id === adminId ? 
          <>
          <Grid item>
           <AntSwitch
             checked={!props.row.original.status}
             onChange={() => handleDeactivate(props)}
             name="isDirectory"
           />
         </Grid>
         </> : props?.row?.original?.AdminType === "Country Admin" || props?.row?.original?.AdminType === "Coordination team" ?
         <>
         <Grid item>
          <AntSwitch
            checked={!props.row.original.status}
            onChange={() => handleDeactivate(props)}
            name="isDirectory"
          />
        </Grid>
        </> : null :  AdminType === "Country Admin" ? 
                props?.row?.original?._id === adminId ?
                <>
         <Grid item>
          <AntSwitch
            checked={!props.row.original.status}
            onChange={() => handleDeactivate(props)}
            name="isDirectory"
          />
        </Grid>
        </> : props?.row?.original?.AdminType === "Coordination team" ?
           <>
           <Grid item>
            <AntSwitch
              checked={!props.row.original.status}
              onChange={() => handleDeactivate(props)}
              name="isDirectory"
            />
          </Grid>
          </> : null : AdminType === "Coordination team" ? 
            props?.row?.original?._id === adminId ?
            <>
            <Grid item>
             <AntSwitch
               checked={!props.row.original.status}
               onChange={() => handleDeactivate(props)}
               name="isDirectory"
             />
           </Grid>
           </> : props?.row?.original?.AdminType === "Coordination team" ? null : null : null
          ) 
        }
      }
      // {
      //   Header: "Send SMS",
      //   accessor: "",
      //   Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Send </Button>
      // }
    ], [])

  return (
    <>

      <div className="flex justify-between flex-wrap items-center">
        {/* <div>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </div> */}
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
      </div>
      {
        data && <UsersTable columns={adminColumns} data={data.filter((val) => {
          if (searchValue === "") { 
            return val
          }
          else if (val?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
            return val
          }
          else if (val?.age?.toString().toLowerCase()?.includes(searchValue?.toLowerCase())) {
            return val
          }
          else if (val?.language[0]?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
            return val
          }
          // else if (val?.country && val?.country?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
          //   return val
          // }
          // else if (val?.email && val?.email?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
          //   return val
          // }
          // else if (val?.designation && val?.designation?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
          //   return val
          // }
        })} />
      }
    </>
  )
}

export default Admin;