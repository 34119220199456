import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { regionCountryList, getLanguageList, addUser, getSectorList, getSegmentApi, getSectorLists } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import AntSwitch from '../Common/AntSwitch';


const UserForm = ({
    getTablesData
}) => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState("");
    const [formData, setFormData] = useState({});
    const [gender, setGender] = useState("Male");
    const [loader, setLoader] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;

    useEffect(() => {
        getCountryData();
        getLanguageListData();
        getSectorListData();
        getSegmentData();
    }, [])




    const getSectorListData = async () => {
        try {
            const result = await getSectorLists();
            setSectorData(result.res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const getSegmentData = async () => {
        try {
            if (country?.country?._id) {
                const result = await getSegmentApi();
               let _resultData = result?.res?.data?.filter((data) => {
                return data?.country?.name === country?.country?.name
               })
                setSegmentData(_resultData)
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, [country]);
    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }
    const onInputSectorChange = (event, newInputValue, name) => {
        setSector({
            ...sector,
            [name]: newInputValue
        })
    }
    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }

    const handleApprove = (e) => {
        setIsApprove(e.target.checked)
    }
    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }
    const onGenderChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setGender(value)
    }
    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            setCountries(result?.res?.data?.country)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    const handleSubmit = async (e) => {
        console.log(formData, "formData")
        e.preventDefault();
        setLoader(true);
        try {
            const results = await addUser({
                "name": formData.name,
                "aliasName": formData.aliasName,
                "language": language?.language?.name,
                "phone": formData.mobile,
                "designation": formData.designation,
                "gender": gender,
                "age": formData.age,
                "country": country?.country?.name,
                "sector": sector?.sector?.name,
                "segment": segment?.segment?.name,
                "isApproved": isApprove
            });
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false)
                closeModal()
                getTablesData();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> add new User </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label="Alias Name"
                        name="aliasName"
                        value={formData.aliasName || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label="Age"
                        name="age"
                        value={formData.age || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label="Mobile No"
                        name="mobile"
                        value={formData.mobile || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label="Language" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    {
                        country?.country?.name &&
                        <Autocomplete
                            id="combo-box-demo"
                            options={segmentData?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name || ""}
                            value={segment?.name}
                            onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                            renderInput={(params) => <TextField {...params} label="Segment" />}
                        />
                    }
                    <Autocomplete
                        id="sector"
                        options={sectorData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={sector?.name}
                        onChange={(e, newVal) => onInputSectorChange(e, newVal, 'sector')}
                        renderInput={(params) => <TextField {...params} label="Sector" />}
                    />
                    <div className="mt-4 w-6/12">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Gender</label>
                        <RadioGroup aria-label="gender" name="gender" className="radio-group" defaultValue={gender} onChange={e => onGenderChangeHandler(e)}>
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </div>
                </div>
                <div className="mt-4 w-6/12 flex items-center">
                    <span className='text-minihubPara'>Approved</span>
                    <AntSwitch
                        checked={isApprove}
                        name="isApproved"
                        className='ml-5'
                        onChange={handleApprove}
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                        add User
                    </button>
                </div>
            </form>
        </>
    )
}

export default UserForm;