import classNames from "classnames"
import { BASE_URL_IMG } from "../../Utils/services"

const YoutubeVideoPost = ({
    avatarClass,
    data,
    onClickHandler,
    isModalView,
    isReportBtn
 }) => {

    const renderYoutubeVideo = link => {
        console.log(link,'link')
        // const splitLink = link.split('/').slice(-1)[0]
        const splitLink = link.split('=').slice(-1)[0]
        console.log(splitLink,"splitLink" )
        return (
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${splitLink}`}
                title="YouTube video player"
                frameborder="0"
                style={{minHeight: 300}}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            />
        )
    }

    return (
        <div className={classNames({
            'post-detail': true,
            'relative': true,
            'modal-view': isModalView
        })}>
            <>
                <div className="header">
                    <img alt="" src={`${BASE_URL_IMG}${data.postedBy.image}`} className={avatarClass} />
                    <span className="pt-1 text-sm primary-color font-semibold tracking-wider">
                        { data.postedBy.name || '' }
                    </span>
                </div>

                <div className="content">
                    { renderYoutubeVideo(data.media[0].link) }
                    
                    {
                        isReportBtn && (
                            <div className="flex">
                                <button
                                    className="button-1"
                                    type="button"
                                    // disabled ={loader ? true : false}
                                    // onClick={edit ? handleEdit : handleAdd}
                                >
                                    Report
                                </button>
                            </div>
                        )
                    }
                </div>

                <div className="footer bg-white p-2">
                    <h3 className="title text-sm font-semibold tracking-wider mb-1"> { data.title } </h3>
                    <p className="text text-sm">
                        { data.caption }
                        {
                            !isModalView && (
                                <span className="text-btn2" onClick={() => onClickHandler(data)}>
                                    Read Article
                                </span>
                            ) 
                        }
                    </p>
                </div>
            </>
        </div>
    )
}

export default YoutubeVideoPost