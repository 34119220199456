import { useEffect, useState, useContext,useRef  } from "react";
import PostDetail from "../Common/PostDetail";
import SimpleListing from "../Common/SimpleListing";
import GridListing from "../Common/GridListing";
import demoData from "./demoData";
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { getHomeApiData, getHomedetail } from "../../Utils/services";
import { toast } from "react-toastify";
import ReactChartPage from "./ReactChartPage";
import { ModalContext } from '../../Context'
import PostDetails from "../ModalTemplate/PostDetails"
import { filterPostTypehome } from '../../Homefunction'
import classNames from "classnames";
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import AllEvent from "./Homes";
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";


const _chartData = {
    labels: [],
    datasets: [
      {
        label: "Updates",
        data: [],
        fill: true,
        backgroundColor: "rgba(217, 238, 247, 0.8)",
        borderColor: "rgba(20, 114, 249, 0.8)",
      },
    ]
};

const downward = '/assets/icons/downRedArrow.svg'
const upward = '/assets/icons/upward-arrow.png'

const Homes = ({ allHomeData , getHomeAllData ,loader,setAllHomesData,setLoader}) => { 
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const contentRef = useRef(null);

   
    const handlePrint = () => {
        const printContents = contentRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
    
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    
        // After printing, reload the page to restore the original content.
        window.location.reload();
      };
    const {t} = useTranslation();
//     const [tabsValue, setTabsValue] = useState(0);
//     const [popularSearchData, setpopularSearchData] = useState([]);
//     const [popularMembersData, setPopularMembersData] = useState([]);
//     const [partyData, setPartyData] = useState([]);
//     const [hubData, setHubData] = useState([]);
//    // const [allHomeData, setAllHomeData] = useState({});
//     const [chartData, setChartData] = useState(_chartData)
//     const [recentPost, setRecentPost] = useState([]);
//     const [pollData, setPollData] = useState([]);
//     const [postData, setPostData] = useState([]);
    //const [loader, setLoader] = useState(true);

    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    const getHomesAllData = async ({ startDate = '', endDate = '' } = {}) => {
        //console.log(loader,"setLoader")
        
        try {
            const result = await getHomedetail({ startDate, endDate });
           
            setLoader(false);
            const home_data = result?.data;
        
            setAllHomesData(home_data);
           
        } catch (error) {
            setLoader(false);
            console.log(error);
            // toast.error(error.message);
        }
    }
    // const TEN_MINUTES = 10 * 60 * 1000;
    // const getHomesAllData = async ({ startDate = '', endDate = '' } = {}) => {
    //     const now = new Date().getTime();
    //     const storedData = localStorage.getItem('homeData');
    //     const storedTime = localStorage.getItem('homeDataTime');
        
    //     if (storedData && storedTime && (now - storedTime) < TEN_MINUTES) {
    //         const home_data = JSON.parse(storedData);
    //         setAllHomesData(home_data);
    //         setLoader(false);
    //         return;
    //     }

    //     try {
    //         const result = await getHomedetail({ startDate, endDate });
    //         setLoader(false);
    //         const home_data = result?.data;
    //         setAllHomesData(home_data);

    //         localStorage.setItem('homeData', JSON.stringify(home_data));
    //         localStorage.setItem('homeDataTime', now.toString());
    //     } catch (error) {
    //         setLoader(false);
    //         console.log(error);
    //         // toast.error(error.message);
    //     }
    // };

    // useEffect(() => {
    //     getHomeAllData();
    // }, []);
    useEffect(() => {
        if(showPrintButton){
            getHomesAllData({ startDate, endDate })
        } else{
            getHomesAllData()
        }
       
        
    }, []);
    const handleView = () => {
        
        setLoader(true);
        // You can add any validation or logic here if needed.
        setShowPrintButton(true);
        getHomesAllData({startDate,endDate});
      };
   console.log(loader,"allHomeData")
    // const getHomeAllData = async () => {
    //     try {
    //         const result = await getHomeApiData();
    //         // const home_data = result.res;
    //         setLoader(false)
    //         const home_data = result.res;
            
    //         setAllHomeData(home_data)

    //         if (home_data.popularSearch.length > 0) {
    //             setpopularSearchData(home_data.popularSearch)
    //         }

    //         if (home_data.popularMembers.length > 0) {
    //             setPopularMembersData(home_data.popularMembers)
    //         }

    //         if (home_data.party.length > 0) {
    //             setPartyData(home_data.party)
    //         }

    //         if (home_data.hub.length > 0) {
    //             setHubData(home_data.hub)
    //         }

    //         if (home_data.recentPosts.length > 0) {
    //             setRecentPost(home_data.recentPosts)
    //             const _allData = home_data.recentPosts;
    //             const _pollData = _allData.filter(item =>{
    //                 if(item.isPoll == true){
    //                     return item
    //                 }
    //             })
    //             const _postData = _allData.filter(item =>{
    //                 if(item.isPoll == false){
    //                     return item
    //                 }
    //             })
    //             setPollData(_pollData)
    //             setPostData(_postData)
    //         }
    //         const labels = home_data.updates.graph.map(item => item._id)
    //         const data = home_data.updates.graph.map(item => item.count)
    //         _chartData.labels = labels
    //         _chartData.datasets[0].data = data
    //         console.log(_chartData, '_chardata', home_data);
    //         setChartData(_chartData)
    //     }
    //     catch (error) {
    //         setLoader(false)
    //         console.log(error)
    //         // toast.error(error.message);
    //     }
    // }

    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails
            data={data}
            isModalView={true}
            isReportBtn={true}
        />
        handleModalData(POST_MODAL)
    }

    // console.log(allHomeData, "allHomeData")
    const AdminType = localStorage.getItem("AdminType")
    const region = localStorage.getItem("region")
    // console.log(region,"region")


        // {
        //     label: `${t("OFFLINE_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: liData && <LiEventData getEventApiData={getEventApiData} handleModalData={handleModalData} liData={liData} adminData={adminData} />
        // },

        // {
        //     label: `${t("DIGITAL_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: minihubData && <MinihubEvent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={minihubData} adminData={adminData} />
        // },
        // {
        //     label: `${t("HYBRID_EVENTS")}`,
        //     isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        //     data: minihubData && <Hybridevent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={hybrid} adminData={adminData} />
        // }
    // ]

    return (
        <>
          <Loader loader={loader} showPrintButton={showPrintButton} />
            {
                Object.keys(allHomeData).length > 0 && (
                   <>
                   <div className="flex justify-around ">
                   <div className="flex flex-row items-center mb-4 w-3/5 ml-5">
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                   <div className="">
       
         
           <KeyboardDatePicker
              label={'Start Date'}
            
              value={startDate}
              onChange={(date) => (setStartDate(date))}
              format={"dd-MM-yyyy"}
              
            
              required
            />
        </div>
      <div className="ml-2">
        
     
              <KeyboardDatePicker
              label={'End Date'}
           
              value={endDate}
              onChange={(date) => setEndDate(date)}
              format={"dd-MM-yyyy"}
            //   disablePast={true}
              minDate={startDate}
              required
              className=" "
            />
        </div>
        <div className="">
        <button onClick={handleView} className="mx-auto mt-5 ml-5 button-1">
        View
      </button>
      </div>
      {showPrintButton && (
        <button onClick={handlePrint} className=" mx-auto ml-2 mt-5 button-1">
          Print PDF
        </button>
      )}
       </MuiPickersUtilsProvider>
     
      </div>
      <div className="minutes">
       <h1 className="text-xl primary-color mr-2">
       The data refreshes every 15 minutes.
       </h1>
       
       </div>
       </div>
    
                    <div className="px-10 homemainresponsive  grid grid-cols-9 gap-x-20" id="contentToPrint" ref={contentRef}>
                       {/* <button onClick={handlePrint}>Print PDF</button> */}
                       
                        <div className="col-span-9">

                            {/* <div className="grid grid-cols-12 gap-x-10">
                                <div className="col-span-3 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <h1 className="text-4xl primary-color mb-1">
                                        { allHomeData.engagement.count }
                                    </h1>

                                    <p className="text-muteText text-xs font-semibold mb-2"> ENGAGEMENT </p>
                                    
                                    {
                                        allHomeData.engagement.percent && 
                                        <div
                                            className={classNames({
                                                "flex mb-2": true,
                                                "items-center": true,
                                                "upward": (allHomeData.engagement.percent > 0),
                                                "downward": !(allHomeData.engagement.percent > 0)
                                            })}
                                        >
                                            <img
                                                src={allHomeData.engagement.percent > 0 ? upward : downward}
                                                className="mr-2"
                                            />

                                            { `${allHomeData.engagement.percent}%` }
                                        </div>
                                    }

                                </div>

                                <div className="col-span-6 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <ReactChartPage data={chartData} />
                                </div>
                                
                                <div className="col-span-3 px-4 py-16 flex items-center justify-center flex-col bg-white">
                                    <h1 className="text-4xl primary-color mb-1">
                                        { allHomeData.views.count }
                                    </h1>

                                    <p className="text-muteText text-xs font-semibold mb-2"> VIEWS </p>

                                    {
                                        allHomeData.views.percent != null && 
                                        <div
                                            className={classNames({
                                                "flex mb-2": true,
                                                "items-center": true,
                                                "upward": (allHomeData.views.percent > 0),
                                                "downward": !(allHomeData.views.percent > 0)
                                            })}
                                        >
                                            <img
                                                src={allHomeData.views.percent > 0 ? upward : downward}
                                                className="mr-2"
                                            />

                                            { `${allHomeData.views.percent}%` }
                                        </div>
                                    }
                                </div>
                            </div> */}
                           {
                           AdminType && AdminType === "Platform Admin" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                               </div>
                               <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.TotalUsers?.count }
                                       </h1>
                                       <span className="new-added-users">
                                           { showPrintButton ? "" : `+${allHomeData.TotalUsers.change } users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.TotalactiveUsers?.count }
                                       </h1>

                                       {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                       { `${allHomeData?.activeUsers?.change} %` } */}
                                       <span className="new-added-users">
                                           {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData.TotalactiveUsers.change} users` } */}
                                           { showPrintButton ? "" : `+${allHomeData.TotalactiveUsers.change } users` }

                                       </span>
                                   </div>
                               </div>

                               <div className="text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.totalinactiveUsers?.count}
                                       </h1>
                                       {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                       { `${allHomeData?.inactiveUsers?.change} %` } */}
                                        <span className="new-added-users">
                                           {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData?.totalinactiveUsers.change} users` } */}
                                           { showPrintButton ? "" : `+${allHomeData.totalinactiveUsers.change } users` }
                                       </span>
                                   </div>
                               </div>
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                               </div>
                               <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.TotalUsers?.count }
                                       </h1>
                                       <span className="new-added-users">
                                           { `+${allHomeData.TotalUsers.change} users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")}</p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.TotalactiveUsers?.count }
                                       </h1>

                                       {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                       { `${allHomeData?.activeUsers?.change} %` } */}
                                       <span className="new-added-users">
                                       {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData?.totalinactiveUsers.change} users` } */}
                                       { showPrintButton ? "" : `+${allHomeData.TotalactiveUsers.change } users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.totalinactiveUsers?.count}
                                       </h1>
                                       {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                       { `${allHomeData?.inactiveUsers?.change} %` } */}
                                        <span className="new-added-users">
                                        {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData?.totalinactiveUsers.change} users` } */}
                                        { showPrintButton ? "" : `+${allHomeData.totalinactiveUsers.change } users` }
                                       </span>
                                   </div>
                               </div>
                           </div> : 
                              AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.TotalUsers?.count }
                                        </h1>
                                        <span className="new-added-users">
                                            {/* { `+${allHomeData.TotalUsers.change} users` } */}
                                            { showPrintButton ? "" : `+${allHomeData.TotalUsers.change } users` }
                                        </span>
                                    </div>
                                </div>
 
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">  {t("ACTIVEUSERS")}</p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalactiveUsers?.count }
                                        </h1>
 
                                        {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.activeUsers?.change} %` } */}
                                        <span className="new-added-users">
                                        {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData.TotalactiveUsers.change} users` } */}
                                        { showPrintButton ? "" : `+${allHomeData.TotalactiveUsers.change } users` }
                                        </span>
                                    </div>
                                </div>
 
                                <div className="text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalinactiveUsers?.count}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData.totalinactiveUsers.change } users` }
                                         {/* {allHomeData.TotalactiveUsers.change === "NaN" ? `${0} users` : `+${allHomeData?.totalinactiveUsers.change} users` } */}
                                        </span>
                                    </div>
                                </div>
                            </div> : 
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                  {/* <p>Country Wise Users</p> */}
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                              </div>
                              <div className="Country">
                                  {/* <p>Country Wise Users</p> */}
                              </div>
                              <div className="border-r-2 text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.TotalUsers?.count }
                                      </h1>
                                      <span className="new-added-users">
                                          {/* { `+${allHomeData.TotalUsers.change} users` } */}
                                          { showPrintButton ? "" : `+${allHomeData.TotalUsers.change } users` }
                                      </span>
                                  </div>
                              </div>

                              <div className="border-r-2 text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")} </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData?.TotalactiveUsers?.count }
                                      </h1>

                                      {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                      { `${allHomeData?.activeUsers?.change} %` } */}
                                      <span className="new-added-users">
                                      { showPrintButton ? "" : `+${allHomeData.TotalactiveUsers.change } users` }
                                      </span>
                                  </div>
                              </div>

                              <div className="text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t("INACTIVEUSERS")}</p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData?.totalinactiveUsers?.count}
                                      </h1>
                                      {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                      { `${allHomeData?.inactiveUsers?.change} %` } */}
                                       <span className="new-added-users">
                                          {/* { `+${allHomeData?.totalinactiveUsers.change} users` } */}
                                          { showPrintButton ? "" : `+${allHomeData.totalinactiveUsers.change } users` }
                                      </span>
                                  </div>
                              </div>
                          </div> : null
                           }
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('GENDERWISEUSERS')}</p>
                               </div>
                               <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               {/* { allHomeData?.genderCount.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2">{item?.gender}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         {item?.Users}
                                     </h1>
                                  
                                 </div>
                             </div>
                             </>
                              )})
                              } */}
                              {allHomeData?.genderCount.map((item, i) => {
    if (i !== 0) {
        return (
            <div className="border-r-2 mt-5 text-center" key={i}>
                <p className="text-muteText text-xs font-semibold mb-2">{item?.gender}</p>
                <div className="flex items-center justify-center">
                    <h1 className="text-2xl primary-color mr-2">
                        {item?.Users}
                    </h1>
                    {/* <span className="new-added-users">
                        {`+${allHomeData.TotalUsers.change} users`}
                    </span> */}
                </div>
            </div>
        );
    }
    return null; // Render nothing for the item at index zero
})}
                              

                              

                              
                           </div>
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('SECTORWISEUSERS')}</p>
                               </div>
                               <div className="Country">
                                   {/* <p>Country Wise Users</p> */}
                               </div>
                              { allHomeData?.sectorWiseUsers.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {item.SectorName}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { item?.Users}
                                     </h1>
                                     {/* <span className="new-added-users">
                                         { `+${allHomeData.TotalUsers.change} users` }
                                     </span> */}
                                 </div>
                             </div>
                             </>
                              )})
                              }

                               

                            
                           </div>
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('NEWREGISTRATIONUSERS')}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('PENDINGREGISTRATION')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl downward mr-2">
                                            { allHomeData.totalPendingUsers }
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEWREGISTRATIONINLAST1WEEK')}</p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl  mr-2">
                                            {showPrintButton? "" : allHomeData?.TotalUsers?.change}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('PENDINGREVIEW/REPORTEDUSERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl downward mr-2">
                                            { allHomeData?.totalReportedUsers}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('VERIFIEDUSERS')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalVerifyUsers }
                                        </h1>

                                        {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.activeUsers?.change} %` } */}
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.TotalactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>

                                <div className=" border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('DELETEDUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totaldeletedUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                
                                <div className=" border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('DECLINEDUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalDeclinedUsers}
                                        </h1>                           
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                           { AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 {/* <p>Country Wise Users</p> */}
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                             </div>
                             <div className="Country">
                                 {/* <p>Country Wise Users</p> */}
                             </div>
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHUSERS')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.count}
                                     </h1>
                                     <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
  
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAUSERS')} </p>
                                 
                                 <div 
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                         "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                     </h1>
  
                                    
                                      <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
  
                             <div className="text-center ">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { showPrintButton ? "" : `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         {showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALUSERS')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         {showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDUSERS')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                     </h1>
                                      <span className="new-added-users">
                                         {showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                         </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 {/* <p>Country Wise Users</p> */}
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                             </div>
                             <div className="Country">
                                 {/* <p>Country Wise Users</p> */}
                             </div>
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHUSERS')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.count}
                                     </h1>
                                     <span className="new-added-users">
                                         { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>

                             {/* <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> CAMBODIA USERS </p>
                                 
                                 <div 
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                         "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                     </h1>

                                    
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div> */}

                             <div className="text-center border-r-2 ">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                     </h1>
                                     {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                     { `${allHomeData?.inactiveUsers?.change} %` } */}
                                      <span className="new-added-users">
                                         { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             {/* <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> INDONESIA USERS </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div> */}
                             {/* <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> MALAYSIA USERS </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div> */}
                             {/* <div className="text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> MYAMMAR USERS </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                     </span>
                                 </div>
                             </div> */}
                             <div className="text-center  ">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         {showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             {/* <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> THAILAND USERS </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                     </h1>
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                     </span>
                                 </div>
                             </div> */}
                         </div> :
                           AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                               {/* <p>Country Wise Users</p> */}
                           </div>
                           <div className="Country text-center mb-5">
                               <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                           </div>
                           <div className="Country">
                               {/* <p>Country Wise Users</p> */}
                           </div>
                           {/* <div className="border-r-2 text-center">
                               <p className="text-muteText text-xs font-semibold mb-2">BANGLADESH USERS </p>
                               <div className="flex items-center justify-center">
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.count}
                                   </h1>
                                   <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.change} users` }
                                   </span>
                               </div>
                           </div> */}

                           <div className="border-r-2 text-center">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAUSERS')} </p>
                               
                               <div 
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                       "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                   </h1>

                                  
                                    <span className="new-added-users">
                                       {showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>

                           {/* <div className="text-center ">
                               <p className="text-muteText text-xs font-semibold mb-2"> INDIA USERS </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                   </h1>
                                  
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div> */}
                           <div className="text-center border-r-2 ">
                               <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAUSERS')}</p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                           <div className="text-center">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                           <div className="text-center border-r-2 mt-5">
                               <p className="text-muteText text-xs font-semibold mb-2">  {t('MYAMMARUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                           {/* <div className="text-center border-r-2 mt-5">
                               <p className="text-muteText text-xs font-semibold mb-2"> NEPAL USERS </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                   </h1>
                                  
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                   </span>
                               </div>
                           </div> */}
                           <div className="text-center border-r-2 mt-5">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                   </h1>
                                    <span className="new-added-users">
                                       { showPrintButton ? "" :  `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                       </div>  :  AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYUSERS')}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('USERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalUsers?.count}
                                        </h1>
                                        <span className="new-added-users">
                                            { showPrintButton ? "" :  `+${allHomeData?.TotalUsers?.change} users` }
                                        </span>
                                    </div>
                                </div>
     
                                {/* <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> CAMBODIA USERS </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                            "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                        </h1>
     
                                       
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
     
                                {/* <div className="text-center ">
                                    <p className="text-muteText text-xs font-semibold mb-2"> INDIA USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                        </h1>
                                       
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                                {/* <div className="text-center border-r-2 ">
                                    <p className="text-muteText text-xs font-semibold mb-2"> INDONESIA USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                        </h1>
                                        
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                                {/* <div className="text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> MALAYSIA USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                        </h1>
                                        
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                                {/* <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> MYAMMAR USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                        </h1>
                                        
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                                {/* <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> NEPAL USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                        </h1>
                                       
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                                {/* <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> THAILAND USERS </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                        </h1>
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                        </span>
                                    </div>
                                </div> */}
                            </div> : null
                           }
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                    
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALRESOURCESDETAIL')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWRESOURCES')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalViewResourcesInLast7Days}
                                        </h1>
                                        
                                    </div>
                                </div>

                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALDOWNLOADRESOURCES')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                            "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedResources}
                                        </h1>

                                      
                                    </div>
                                </div>

                                <div className="text-center ">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('LAST7DAYSDOWNLOADRESOURCES')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedResourcesInLast7Days}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALVIEWRESOURCES')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalviewResources}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTFORUM')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALCOMMENTFORUM')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInForum}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTFORUMLAST7DAYS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInForumInLast7Days}
                                        </h1>
                                   
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTGALLERY')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALCOMMENTGALLERY')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInGallery}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTGALLERYLAST7DAYS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInGalleryInLast7Days}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                    
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTSTORY')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                               
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('COMMENTSTORY')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInStory}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTSTORYLAST7DAYS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInStoryInLast7Days}
                                        </h1>
                                      
                                    </div>
                                </div>
                               
                            </div> */}
                           
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('7DAYSVIEWNEWSFEEDSANDTOOLKITS')}</p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWNEWSFEEDS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalViewNewsFeedInLast7Days}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWTOOLKITS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalViewToolkitsInLast7Days}
                                        </h1>
                                     
                                    </div>
                                </div>
                              
                               
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('TOOLKITSDOWNLOAD')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                              
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALDOWNLOADTOOLKITS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedToolkits}
                                        </h1>
                                      
                                    </div>
                                </div>
                               
                            </div> */}
                           {/* {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                               
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInBangladesh}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInCambodia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndonesia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMalaysia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMyammar}
                                     </h1>
                                 
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInNepal}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInThailand}
                                     </h1>
                               
                                 </div>
                             </div>
                            
                            
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                              
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                               
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInBangladesh}
                                     </h1>
                                   
                                 </div>
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             
                            
                            
                             <div className="text-center  mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInNepal}
                                     </h1>
                                  
                                 </div>
                             </div>
                           
                            
                            
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                    
                                 </div>
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInCambodia}
                                     </h1>
                                   
                                 </div>
                             </div>
                            
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndonesia}
                                     </h1>
                                    
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMalaysia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARACTIVEFOURAMCOUNT')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMyammar}
                                     </h1>
                                    
                                 </div>
                             </div>
                            
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInThailand}
                                     </h1>
                                   
                                 </div>
                             </div>
                            
                            
                            </div> :
                             AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                  
                                 </div>
                             </div>
                            
                            
                            
                            </div> : null
                           } */}
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOP15SEARCHKEYWORDS')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                              { allHomeData?.SearchData.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('KEYWORD')}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         {item}
                                     </h1>
                                  
                                 </div>
                             </div>
                             </>
                              )})
                              }
                           </div>
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2"> {t('TOP5TAGSUSEDINSEARCH')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                              { allHomeData?.tagdata.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('TAGS')}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         {item}
                                     </h1>
                                  
                                 </div>
                             </div>
                             </>
                              )})
                              }
                           </div>
                         
          
                      
                          
                            
                       

                       
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('LANGUAGEWISE/USERS')}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ASSAMESELANGUAGEUSERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalAssameseUsers }
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLALANGUAGEUSERS')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalBanglaUsers }
                                        </h1>

                                        {/* <img src={allHomeData?.activeUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.activeUsers?.change} %` } */}
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.TotalactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ENGLISHLANGUAGEUSERS')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalEnglishUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('HINDILANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalHindiUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BAHASALANGUAGEUSERS')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalIndonessiaUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('KHMERLANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalKhemarUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARLANGUAGEUSERS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalMyanmarUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALILANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalNepaliUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MELAYULANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalMelayuUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDLANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalThailandUsers}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('CourseSectionDetail')}</p>
                                </div>
                                <div className="Country">
                                    {/* <p>Country Wise Users</p> */}
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('Total_enrolled_users')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUsers}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('total_course_completed')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.users_completed_more_course}
                                        </h1>
                                        {/* <span className="new-added-users">
                                            { `+${allHomeData.} users` }
                                        </span> */}
                                    </div>
                                </div>

                                {/* <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('two_course_completed')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.users_completed_two_course}
                                        </h1>

                                        
                                    </div>
                                </div> */}

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ONGOINGCOURSE')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.ongoingUser}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('totalenrolledUserslast7days')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUserslast7days}
                                        </h1>
                                        {/* <img src={allHomeData?.inactiveUsers?.change > 0 ? upward : downward} className="mr-2" />
                                        { `${allHomeData?.inactiveUsers?.change} %` } */}
                                         {/* <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span> */}
                                    </div>
                                </div>
                              
                            </div>
                       
                        


                        
                           
             
                       
                           
                          
                         
                   
                          
                        
                       
                       
                          
                            {/* <div className="mt-10">
                                <h1 className="text-xl tracking-wider primary-color font-semibold mb-2">Trending Feeds</h1>

                                <div className="grid grid-cols-2 py-16 bg-white">
                                    <div className="px-16 border-r-2">
                                        { filterPostTypehome(allHomeData.trendingPosts[0], showPostDetail) }
                                    </div>

                                    <div className="px-16">
                                        { filterPostTypehome(allHomeData.trendingPosts[1], showPostDetail) }
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 gap-x-10 mt-10">
                                {popularSearchData && <SimpleListing
                                    title="Popular Search Items"
                                    list={popularSearchData}
                                />}

                                {popularMembersData && <GridListing
                                    title="Popular Members"
                                    list={popularMembersData}
                                />}
                            </div>

                            <div className="grid grid-cols-2 gap-x-10 mt-10">
                                <GridListing
                                    title="Popular Parties"
                                    list={partyData}
                                />

                                <GridListing
                                    title="Popular Hubs"
                                    list={hubData}
                                />
                            </div> */}

                        </div>
                        {/* <div className="col-span-3 ">
                            <div className="recent-posts sticky-sidebar sticky top-0"> */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14">  {t('PopularForum')} </h1>
                                {
                                    allHomeData.popularForum && allHomeData.popularForum.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumBangladesh')} </h1>
                                {
                                    allHomeData.popularForumInBangladesh && allHomeData.popularForumInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumCambodia')} </h1>
                                {
                                    allHomeData.popularForumInCambodia && allHomeData.popularForumInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumIndia')} </h1>
                                {
                                    allHomeData.popularForumInIndia && allHomeData.popularForumInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumIndonesia')} </h1>
                                {
                                    allHomeData.popularForumInIndonesia && allHomeData.popularForumInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumMalaysia')} </h1>
                                {
                                    allHomeData.popularForumInMalaysia && allHomeData.popularForumInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumMyammar')}</h1>
                                {
                                    allHomeData.popularForumInMyammar && allHomeData.popularForumInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumNepal')} </h1>
                                {
                                    allHomeData.popularForumInNepal && allHomeData.popularForumInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumThailand')} </h1>
                                {
                                    allHomeData.popularForumInThailand && allHomeData.popularForumInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeed')}  </h1>
                                {
                                    allHomeData.popularNewsfeed && allHomeData.popularNewsfeed.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedBangladesh')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInBangladesh && allHomeData.popularNewsfeedInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedCambodia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInCambodia && allHomeData.popularNewsfeedInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14">{t('PopularNewsFeedIndia')} </h1>
                                {
                                    allHomeData.popularNewsfeedInIndia && allHomeData.popularNewsfeedInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedIndonesia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInIndonesia && allHomeData.popularNewsfeedInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedMalaysia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInMalaysia && allHomeData.popularNewsfeedInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedMyammar')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInMyammar && allHomeData.popularNewsfeedInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedNepal')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInNepal && allHomeData.popularNewsfeedInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedThailand')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInThailand && allHomeData.popularNewsfeedInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPosts')} </h1>
                                {
                                    allHomeData.popularNewsfeed && allHomeData.popularNewsfeed.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsBangladesh')} </h1>
                                {
                                    allHomeData.popularPostsInBangladesh && allHomeData.popularPostsInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsCambodia')} </h1>
                                {
                                    allHomeData.popularPostsInCambodia && allHomeData.popularPostsInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsIndia')} </h1>
                                {
                                    allHomeData.popularPostsInIndia && allHomeData.popularPostsInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsIndonesia')} </h1>
                                {
                                    allHomeData.popularPostsInIndonesia && allHomeData.popularPostsInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsMalaysia')}</h1>
                                {
                                    allHomeData.popularPostsInMalaysia && allHomeData.popularPostsInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsMyammar')} </h1>
                                {
                                    allHomeData.popularPostsInMyammar && allHomeData.popularPostsInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsNepal')} </h1>
                                {
                                    allHomeData.popularPostsInNepal && allHomeData.popularPostsInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsThailand')} </h1>
                                {
                                    allHomeData.popularPostsInThailand && allHomeData.popularPostsInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('Popularresources')}  </h1>
                                {
                                    allHomeData.popularResources && allHomeData.popularResources.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesBangladesh')} </h1>
                                {
                                    allHomeData.popularResourcesInBangladesh && allHomeData.popularResourcesInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesCambodia')} </h1>
                                {
                                    allHomeData.popularResourcesInCambodia && allHomeData.popularResourcesInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesIndia')} </h1>
                                {
                                    allHomeData.popularResourcesInIndia && allHomeData.popularResourcesInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesIndonesia')} </h1>
                                {
                                    allHomeData.popularResourcesInIndonesia && allHomeData.popularResourcesInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesMalaysia')} </h1>
                                {
                                    allHomeData.popularResourcesInMalaysia && allHomeData.popularResourcesInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesMyammar')} </h1>
                                {
                                    allHomeData.popularResourcesInMyammar && allHomeData.popularResourcesInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesNepal')} </h1>
                                {
                                    allHomeData.popularResourcesInNepal && allHomeData.popularResourcesInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesThailand')}</h1>
                                {
                                    allHomeData.popularResourcesInThailand && allHomeData.popularResourcesInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkits')}  </h1>
                                {
                                    allHomeData.popularToolkits && allHomeData.popularToolkits.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsBangladesh')} </h1>
                                {
                                    allHomeData.popularToolkitsInBangladesh && allHomeData.popularToolkitsInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsCambodia')} </h1>
                                {
                                    allHomeData.popularToolkitsInCambodia && allHomeData.popularToolkitsInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsIndia')} </h1>
                                {
                                    allHomeData.popularToolkitsInIndia && allHomeData.popularToolkitsInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsIndonesia')} </h1>
                                {
                                    allHomeData.popularToolkitsInIndonesia && allHomeData.popularToolkitsInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsMalaysia')} </h1>
                                {
                                    allHomeData.popularToolkitsInMalaysia && allHomeData.popularToolkitsInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsMyammar')} </h1>
                                {
                                    allHomeData.popularToolkitsInMyammar && allHomeData.popularToolkitsInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsNepal')} </h1>
                                {
                                    allHomeData.popularToolkitsInNepal && allHomeData.popularToolkitsInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsThailand')} </h1>
                                {
                                    allHomeData.popularToolkitsInThailand && allHomeData.popularToolkitsInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStory')}  </h1>
                                {
                                    allHomeData.popularstories && allHomeData.popularstories.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryBangladesh')} </h1>
                                {
                                    allHomeData.popularstoriesInBangladesh && allHomeData.popularstoriesInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryCambodia')} </h1>
                                {
                                    allHomeData.popularstoriesInCambodia && allHomeData.popularstoriesInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryIndia')} </h1>
                                {
                                    allHomeData.popularstoriesInIndia && allHomeData.popularstoriesInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryIndonesia')} </h1>
                                {
                                    allHomeData.popularstoriesInIndonesia && allHomeData.popularstoriesInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryMalaysia')} </h1>
                                {
                                    allHomeData.popularstoriesInMalaysia && allHomeData.popularstoriesInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryMyammar')} </h1>
                                {
                                    allHomeData.popularstoriesInMyammar && allHomeData.popularstoriesInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryNepal')} </h1>
                                {
                                    allHomeData.popularstoriesInNepal && allHomeData.popularstoriesInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryThailand')} </h1>
                                {
                                    allHomeData.popularstoriesInThailand && allHomeData.popularstoriesInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                
                            {/* </div>

                        </div> */}

                    </div>
                    </>
                )
                
            }
        </>
    )
}

export default Homes;