import logo from "../../Assets/images/glp-logo-colour.png";
import { loginServiceFirst, otpVerificationApi, otpVerificationLoginApi } from "../../Utils/services";
import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import authBg from "../../Assets/images/glp_banner.png"
import OtpInput from "react-otp-input";
import AuthContext from "../../Utils/auth-context";
import { useTranslation } from "react-i18next";


const LoginOtpVerification = () => {
    const {t} = useTranslation();
    const [otp, setOtp] = useState();
    const location = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const authCtx = useContext(AuthContext);


    useEffect(() => {
        if (location.state !== undefined) {
            const { fcmToken, email } = location.state;
            setEmailValue(email)
            localStorage.setItem("emailValue", email)
            setVerificationCode(fcmToken)
            // console.log(fcmToken, email, "fcmToken, email,")
        }
        if(location.state === undefined){
            history("/login")
        }
    }, []);

    const handleOtpChange = (e) => {
        // console.log(e)
        setOtp(e);
    }
    const handleOtpSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const result = await otpVerificationLoginApi({
                "email": emailValue,
                "otp": parseInt(otp),
                "fcmToken":verificationCode,
            });
            if (result.res.success) {
                localStorage.setItem("AdminType", result.res.data.AdminType)
                
                localStorage.setItem("region", result.res.data.region)
                localStorage.setItem("AdminsName", result.res.data.Name)
                localStorage.setItem('adminId', result.res.data.id)
                localStorage.setItem('language',result.res.data.language[0].name)
                toast.success(result.res.message || "User Authenticated!");
                authCtx.login(result.res.data.accessToken, result.res.data.refreshToken, result.res.data.isSuperAdmin);
                setLoading(false)
                toast.success(result.res.message)
                let lang = result.res.data.language[0].name
                if (lang == 'हिंदी') {
                    localStorage.setItem('lng', 'hi');
                }
                else if (lang == 'বাংলা') {
                    localStorage.setItem('lng', 'bn');
                }
                else if (lang == 'မြန်မာစာ') {
                    localStorage.setItem('lng', 'my');
                }
                else if (lang == 'Bahasa') {
                    localStorage.setItem('lng', 'id');

                }
                else if (lang == 'অসমীয়া') {
                    localStorage.setItem('lng', 'as');
                }
                else if (lang == 'Melayu') {
                    localStorage.setItem('lng', 'ms');

                }
                else if (lang == 'नेपाली') {
                    localStorage.setItem('lng', 'ne');

                }
                else if (lang == 'ไทย') {
                    localStorage.setItem('lng', 'th');

                }
                else if (lang == 'ខ្មែរ') {
                    localStorage.setItem('lng', 'km');
                }
                else if (lang == 'English') {
                    localStorage.setItem('lng', 'en');

                }
                else {
                    localStorage.setItem('lng', 'en');
                }
                
                history("/");
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
        }
    }


    return (
        <div className="w-full relative">
            <img src={authBg} alt="" />
            <div className="flex absolute top-1/2 left-0 w-full">
                <div className="w-2/5 logincontainer mx-auto auth-container">
                    <div className="w-1/2 mx-auto text-center">
                        <img
                            src={logo}
                            className="mx-auto mb-10"
                            alt="logo"
                        />
                        <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                         {t("VERIFYOTP")}
                        </h2>
                        <form className="flex flex-col items-center" onSubmit={handleOtpSubmit}>
                            <div className="mt-2 flex otpSection justify-center items-center">
                                <OtpInput
                                    value={otp}
                                    onChange={handleOtpChange}
                                    numInputs={4}
                                    isInputNum={true}
                                    renderInput={(props) => <input {...props} />}
                                />
                            </div>
                            {
                                loading ? <p>{t("Loading...")}</p> :
                                    <button
                                        className="primary-btn mt-10"
                                        type="submit"
                                    >
                                        {t("VERIFYOTP")}
                                    </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginOtpVerification;