import React, { useEffect, useState } from "react";
import { BsEmojiSmileFill } from "react-icons/bs";
import { IoMdSend } from "react-icons/io";
import Picker from "emoji-picker-react";
import unmute from "../../Assets/chatimage/microphone.png";
import mute from "../../Assets/chatimage/mute.png";
import RecorderTimer from './RecorderTimer';
import vmsg from "vmsg"
import { useTranslation } from 'react-i18next';
const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm"
});

export default function ChatInput({ handleSendMsg ,getmessageDataApi,recordings,setrecordings}) {
  const [msg, setMsg] = useState("");
  const [AUD, setAUD] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [isRecording, setisRecording] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const handleEmojiPickerhideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const { t } = useTranslation();
  const handleEmojiClick = (event, emojiObject) => {
    let message = msg;
    message += event.emoji;
    setMsg(message);
  };

  const sendChat = (event) => {
    event.preventDefault();
    if (msg.length > 0) {
      handleSendMsg(msg);
      setMsg("");
      // getmessageDataApi();
    } else{
      handleSendMsg(msg);
    }
  };
  const showPostDetail = (record) => { 
    record()
}
const handleFileSelect = () => {
  setrecordings();
};

const record = async () => {
  setisLoading(true);
    if (isRecording) {
      const blob = await recorder.stopRecording();
      const msgurl = blob
      setisLoading(false)
      setisRecording(false)
      // setTimer(0);
      setSeconds(0);
      setMinutes(0);
      setrecordings(msgurl)
      // audiodatsend()
      // setRecordingStartTime(null);
      
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        setisLoading(false)
        setisRecording(true)
        // setTimer(0);
      
      } catch (e) {
        console.error(e);
        setisLoading(false)
      
      }
    }
  };
  useEffect(() => {
    let timeoutId;

    const tick = () => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => prevMinutes + 1);
          return 0;
        } else {
          return prevSeconds + 1;
        }
      });
      timeoutId = setTimeout(tick, 1000);
    };

    if (isRecording) {
      tick();
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRecording]);

  return (
    <div className="chatinput">
      <div className="button-container">
        <div className="emoji">
          <BsEmojiSmileFill onClick={handleEmojiPickerhideShow} />
          {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
        </div>
      </div>
      <form className="input-container" onSubmit={(event) => sendChat(event)}>
        {
           isRecording ?
          <div className="testMessageShowAudio">
            <span>{t("RecordingStarted...")}{minutes.toString().padStart(2, '0')}:
          {seconds.toString().padStart(2, '0')}</span>
          </div>
          : recordings ?
            <div className="testMessageShowAudio">
            <span>{t("SendRecordedMessage")}</span>
            <span onClick={handleFileSelect} className="crossbtn">X</span>
          </div> :
          <input
          type="text"
          placeholder={t('Type_your_message_here')}
          onChange={(e) => setMsg(e.target.value)}
          value={msg}
        />
        }
       <button type="submit">
          <IoMdSend />
        </button>
     
        
       
      </form>
      <div className="button-container">
      <button className="muteunmute" disabled={isLoading} onClick={() => showPostDetail(record)}>
          {isRecording ?  <img src={unmute}></img> :  <img src={mute}></img>}
        </button>
        </div>
    </div>
  );
}
