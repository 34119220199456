import React, { useState, useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { useNavigate, useMatch } from 'react-router-dom';
import { useParams } from 'react-router';
import UsersTable from './UserTable';
// import crossRedIcon from "../../Assets/images/icons/cross-red.svg";
import { BASE_URL_IMG ,deleteNotificationPostApi} from '../../Utils/services';
import { toast } from "react-toastify"
import { useTranslation } from 'react-i18next';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import { ModalContext } from '../../Context';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const GeneralNotification = ({ data, getTablesData,Count, adminData }) => { 
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const {t} = useTranslation();
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;

  useEffect(() => {
    if(searchValue === ""){
    getTablesData({
      page: page,
      size: postsPerPage
    });
  }
  }, [Count,page,searchValue])


  useEffect(() => {
    setPageCount(Math.ceil(Count / postsPerPage));
  }, [Count])

  useEffect(() => {
    if (typeof searchValue === "string" && searchValue.match(/^\d+/)) {
      let intValue = parseInt(searchValue, 10);
      //console.log("intValue:", intValue);
      // if (typeof searchValue === "string") {
        if(intValue!= ''){
        const timeoutId = setTimeout(() =>
        getTablesData({
              //  page: page,
              //  size: postsPerPage,
              number:intValue,
        }), 500);
      return () => clearTimeout(timeoutId);
    }
    // }
    } else {
      //console.log("searchValue is not a string starting with a number");
      if(searchValue!= ''){
        const timeoutId = setTimeout(() =>
        getTablesData({
              //  page: page,
              //  size: postsPerPage,
              query :searchValue,
        }), 500);
      return () => clearTimeout(timeoutId);
      }
    }

  }, [searchValue])

  // let { path } = useMatch();
  const history = useNavigate();
  const param = useParams();
  // console.log(param, "Param")


  const deletePostGallery = async (item) => {   
    const deltid = item._id
    try {
        const result = await deleteNotificationPostApi(deltid);
        if (result.res.success) {
            // toast.success(result.res.message)
            toast.success("General notification deleted")
            getTablesData({
              page: page,
              size: postsPerPage
            });
        }

    } catch (error) {
        toast.error(error.messages)
    }
}

const HandleRemoveEvent = (id) => { 
  const REMOVE_CONFIRMATION = (
    <RemoveConfirmation
      title={t("General_Notification_Delete")}
      action={deletePostGallery}
      data={t("remove_this_Notification?")}
      id={id}
    />
  );
  handleModalData(REMOVE_CONFIRMATION, "sm");
};

  const adminColumns = useMemo(
    () => [
      {
        Header: `${t("Title")}`,
        accessor: 'title',
      },
      {
        Header: `${t("Description")}`,
        accessor: 'description',
      },
      {
        Header: <div className='flex items-center'><span>{t("Target_Group")}</span> </div>,
        accessor: 'generalDetails',
        Cell: ({ cell: { value } }) => (
          <>
            {  
              <div>
                <span>{value && value.target && value.target} {value && value.target !== "All" ? " : " : " "} </span>
                {
                  value && value.party && value.party.map((item) => {   
                    return (
                      <span>{`${item.name} , `}</span>
                    )
                  })
                }
                {
                  value && value.country &&
                  <span>{value.country.join(" , ")}</span>
                }
                {
                  value && value.organization && value.organization.map((item) => {
                    return (
                      <span>{`${item.name} , `}</span>
                    )
                  })
                }
                {
                  value && value.region &&
                  <span>{value.region.join(" , ")}</span>
                }
                {
                  value && value.thinktank && value.thinktank.map((item) => {
                    return (
                      <span>{`${item.name} , `}</span>
                    )
                  })
                }
              </div>
            }
          </>
        )
      },
      {
        Header: `${t("Image")}`,
        accessor: 'image',
        Cell: ({ cell: { value } }) => (
          <>
            <img src={`${BASE_URL_IMG}${value}`} alt="" className='w-20' />
          </>
        )
      },
      // {
      //   Header: "Actions",
      //   accessor: 'badge',
      //   Cell: ({ cell: { value } }) => (
      //     <div className='flex items-center'>
      //       <span className='px-2'>
          
      //         <button
      //           className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
      //           type="button"
      //           onClick={() => deletePostGallery(value._id)}
      //         >
      //           <span className="MuiButton-label">DELETE</span>
      //         </button>
      //       </span>
        
      //     </div>
          
      //   )
      // }
      {
        Header: `${t("Action")}`,
        accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => {
            return (
                <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                    type="button"
                    key={data.id}
                    onClick={() => HandleRemoveEvent(data)}
                >
                    <span className="MuiButton-label">{t("Delete")}</span>
                </button>
            )
        },
    },
    ], [adminData])

  return (
    <>
      <div className="flex justify-between flex-wrap items-center">
      <div>
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          />
        </div>
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input type="text"  value={searchValue} placeholder={t("Search")} className="w-96 mx-3" onChange={(e) => setSearchValue(e.target.value)} />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
      </div>
      {
        data && (
          <UsersTable columns={adminColumns} data={data} />
        )
      }
    </>
  )
}

export default GeneralNotification;