import React, { useEffect, useState, useRef ,useContext} from "react";
import { io } from "socket.io-client";
import socketIo from "socket.io-client";
import { getAdminDetails,allUsers , host, chatuserdata, allUsersdata } from "../../Utils/services";
import ChatContainer from "./ChatContainer";
import Contacts from "./Contacts";
import Welcome from "./Welcome";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";



// import ChatContainer from "../components/ChatContainer";
// import Contacts from "../components/Contacts";
// import Welcome from "../components/Welcome";

export default function Chat() {
  const [adminData, setAdminData] = useState();
  const [stateusersdata, setstateusersdata] = useState();
  const [notificationdata, setnotificationdata] = useState();
  const socket = useRef();
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState(undefined);
  const [id, setid] = useState("");
  const [socketfunction, setsocketfunction] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
 // const [socketfunction, setSocketfunction] = useState(false);
 // const sockets = useContext(SocketContext);
 const [arrivalMessage, setArrivalMessage] = useState(null);

  // let socket ;
// console.log(sockets,"socketssockets")//
  const ENDPOINT = "https://api.glp-connect.org";  // production
  //  const ENDPOINT = "https://glp.techtonic.asia"  //Develop

  const getAdminDetailsApiData = async () => {
    const id = localStorage.getItem("adminId")
    try {
        const result = await getAdminDetails({
            id: id,
            "type": "admin"
        })
        if (result.success) {
            setAdminData(result.data)
        }

    } catch (error) {
        console.log(error)
    }
}

useEffect(() => {
    getAdminDetailsApiData();
    // sockets.getAdminDetailsApiDatas()
}, []);
// useEffect(() => { 
//     if(adminData){ 
//     // socket.current = io(host)
//     console.log(stateusersdata ,"stateusersdata")
//     const admin = stateusersdata ? stateusersdata : null
//     //  const socket = socketIo(ENDPOINT, { transports: ['websocket'] });
//      // localStorage.setItem('socket', JSON.stringify(socket));
//      console.log(sockets,"sockets")  
//       setsocketfunction(sockets)
//       sockets.on('connect', () => { debugger
//             console.log("connected chang")    
//             setIsConnected(true);
//         })
//         // socket.emit('adminUserJoin',{ 
//         //   username :adminData._id,
//         //   roomname :admin,
         
//         // })
//         console.log("adminUserJoin")
//         // {"username": user!.name,"roomname":"admin"}
// //     console.log(socket,"socket connected")

//    }
// }, [adminData]);
// useEffect(() => {
//   socket = socketIo(ENDPOINT, { transports: ['websocket'] });

  // socket.on('connect', () => {
  //     alert('Connected');
  //     setid(socket.id);
 
  // })
  // console.log(socket);
  // socket.emit('join', adminData?._id )

  // socket.on('welcome', (data) => {
  //     setMessages([...messages, data]);
  //     console.log(data.user, data.message);
  // })

  // socket.on('userJoined', (data) => {
  //     setMessages([...messages, data]);
  //     console.log(data.user, data.message);
  // })

  // socket.on('leave', (data) => {
  //     setMessages([...messages, data]);
  //     console.log(data.user, data.message)
  // })

//   return () => {
//       socket.emit('disconnect');
//       socket.off();
//   }
// }, [])
useEffect(() => {
  if (socketfunction) {
    socketfunction.on("message", (msg) => { 
      setArrivalMessage({ message: msg.message, fromSelf: msg.senderId, voice: msg.path, time: new Date(), adminmsg: msg.adminmsg, adminName: msg?.adminName });
      // console.log( msg.message, "message");
      // console.log( msg.isAudio ,"isAudio");
       
    });

  }
}, [socketfunction]);

const length = localStorage.getItem("Notificationlength");
const getTablesData = async () => {
  try {
    const result = await allUsersdata();
    // console.log(result.res?.falseNotify,"chatNotification")
    
    if(result?.res?.message){
      // localStorage.removeItem("Notificationlength");
      setContacts(result.res?.falseNotify);
    }
    // if (result.res.user.length > 0) {
    //   // setLoader(false)
    //   const _resData = result.res.user;

    //  let _UserData=  result.res.filter(data =>{
    //   return data?._id?.read === false
    //  })
    //  let _NewRegData=  _resData.filter(data =>{
      
    //   return data.isApproved === false && data?.isDeclined === false
    //  })
    //  let _DeclinedData=  _resData.filter(data =>{
    //   return data.isDeclined
    //  })
  //  console.log(_UserData.length,"_UserData")
 
 
    // }
  }
  catch (error) {
    // setLoader(false)
    console.log(error, "Error");
  
  }
}

useEffect(() => {
  getTablesData();
}, [length])

// console.log(contacts,"contacts")
  const handleChatChange = (chat) => { 
    

    if (isConnected) { 
      socketfunction.disconnect(true);
    }
      setTimeout(() => {
        setCurrentChat(chat);
        const socket = socketIo(ENDPOINT, { transports: ['websocket'] });
      //  console.log(socket,"sockets.socketsssss")
          setsocketfunction(socket)
          socket?.on('connect', () => { 
              // console.log("connectedinsidethesecondtime")
              setIsConnected(true);
          })
          socket.emit('adminUserJoin',{ 
          username :adminData?._id,
          roomname :chat?._id,
        })
        // console.log("join")
      }, 1000);
   
    
  };

  useEffect(() => {
   
    return () => {
    
      if (isConnected && socketfunction) {
        socketfunction.disconnect(true);
      }
    };
  }, [isConnected, socketfunction]);

  const location = useLocation();
  const data = location?.state
  // console.log(data,"datass")
  useEffect(() => {
    setstateusersdata(data)
  }, [])

  const handlecurrentchat = async (e) => {
    setLoader(true);
   
    try {
        const result = await chatuserdata({id :data });

        // console.log(result.res.data)
        if(result.res.data){
          setCurrentChat(result.res.data)
          setnotificationdata(result.res.data)
        }
    }
    catch (error) {
        setLoader(false);
        console.log(error);
        toast.error(error.message);
    }
  
}
useEffect(() => {
  handlecurrentchat()
}, [data])

// console.log(currentChat?._id,"currentChat")
  return (
    <>
      <div className="chatcontainer">
        <div className="innearcontainer">
        <Contacts contacts={contacts}  getTablesData={getTablesData} notificationdata={notificationdata} currentusername={adminData}  changeChat={handleChatChange} socket={socketfunction} unreadMessages={unreadMessages}/>
        {
          currentChat === undefined ? (
          <Welcome currentusername={adminData} /> ) :
          <ChatContainer currentChat={currentChat} currentusername={adminData} socket={socketfunction} setUnreadMessages={setUnreadMessages} unreadMessages={unreadMessages}/>

        }
       
        </div>
      </div>
    </>
  );
}

