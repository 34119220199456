import Grid from '@material-ui/core/Grid';
import { filterPostType } from "../../functions"
import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import CommentGallerySection from '../Common/CommentGallerySection';

const PostGalleryDetails = ({ reportHandler, isModalView, isReportBtn }) => {
    const location = useLocation();
    const history = useNavigate();
    const data = location?.state?.data
    const Title = location?.state?.title
    const mediasdata = location?.state?.mediadata
    const totalCount = location?.state.count
    const [singlePostData] = useState(data);
    const [singlesPostData] = useState(mediasdata);
    const handleAdminPush = () => {
        history(-1);
    }

    return (
        <div className='container relative px-8'>
            <div className="flex items-center mb-20">
                <span onClick={handleAdminPush} className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Go Back</span>
            </div>
            <div className="ForumDetailsHead">
                <h2> {Title} </h2>
            </div>
            <Grid container spacing={2}>
                <Grid item md="6">
                    {filterPostType(singlesPostData, reportHandler, isModalView, isReportBtn, Title)}
                </Grid>
                <Grid item md="6">
                    <CommentGallerySection commentSectionData={data} singlePostData={singlePostData} totalCount={totalCount} Likes={singlesPostData} />
                </Grid>
            </Grid>
        </div>
    )
}

export default PostGalleryDetails