import React, { useState, useContext} from "react";
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import { allAdminUsers, getEventData,getagoratoken ,get100mstoken,get100msrecordinglisttoken} from "../../Utils/services";
import LiEventData from "./LiEvent";
import MinihubEvent from "./MinihubEvent";
import Hybridevent from "./Hybridevent";
import AllEvent from "./AllEvent";
import AddEventForm from "../ModalTemplate/AddEventForm";
import Loader from "../Common/Loader"
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import RecordEvent from "./RecordEvent";

const a11yProps = (index) => {
    return {
        id: `flagged-tab-${index}`,
        'aria-controls': `flagged-tabpanel-${index}`,
    };
}

const Events = () => {
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const [eventAllData, setEventAllData] = useState([]);
    const [adminData, setAdminData] = useState([]);
    const [ms100tokenData, set100mstokenData] = useState([]);
    const [liData, setLiData] = useState([]);
    const [minihubData, setMinihubData] = useState([]);
    const [hybrid, sethybrid] = useState([]);

    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        getAdminTableData()
        get100mstokenData()
      }, [])

    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }

    const get100mstokenData = async () => {
        try {
          const result = await get100msrecordinglisttoken();
          
          if (result?.data?.dataRecording?.data) {
            setLoader(false)
            set100mstokenData(result?.data?.dataRecording?.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }

  
    const getEventApiData = async (year, month) => {
        // console.log(year, month, "YearOnt")
        try {
            const result = await getEventData({
                year,
                month
            });
            setLoader(false)
            const _data = result.res.data;
            // console.log(_data, "_data")
            if (_data.digital.length || _data.offline.length || _data.hybrid.length >= 0) {
                setEventAllData(_data.digital.concat(_data.offline,_data.hybrid))
            }
            if (_data.offline.length >= 0) {
                setLiData(_data.offline)
            }
            if (_data.digital.length >= 0) {
                setMinihubData(_data.digital)
            }
            if (_data.hybrid.length >= 0) {
                sethybrid(_data.hybrid)
            }
        }
        catch (error) {
            setLoader(false)
            console.log(error)
        }
    }
    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
        var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
        window.history.pushState({ path: newurl }, '', newurl);
    };
    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
    useEffect(()=>{
        var idValue = getParameterByName('id');
        if(idValue){
            setTabsValue(parseInt(idValue))
        }
    },[])
    const tabsData = [
        {
            label: `${t("Event")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: (
                <>
                    <Loader loader={loader} />
                    <AllEvent getEventApiData={getEventApiData} handleModalData={handleModalData} eventAllData={eventAllData} adminData={adminData} />
                </>
            )
        },

        {
            label: `${t("OFFLINE_EVENTS")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: liData && <LiEventData getEventApiData={getEventApiData} handleModalData={handleModalData} liData={liData} adminData={adminData} />
        },

        {
            label: `${t("DIGITAL_EVENTS")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: minihubData && <MinihubEvent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={minihubData} adminData={adminData}  />
        },
        {
            label: `${t("HYBRID_EVENTS")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: minihubData && <Hybridevent getEventApiData={getEventApiData} handleModalData={handleModalData} minihubData={hybrid} adminData={adminData}  />
        },
        {
            label: `${t("Meeting Recording")}`,
            isNotshowAdd :true,
            data: (
              <>
                <Loader loader={loader} />
                <RecordEvent data={ms100tokenData}  getTablesData={get100mstokenData} adminData={adminData} />
              </>
            )
          },
        
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title={t('ADD_EVENT')} buttonText="Add Event" eventAllData ={eventAllData} />);
                break;

            case 1:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title={t('ADD_OFFLINE_EVENT')} buttonText="Add Offline Event" eventAllData ={eventAllData} />);
                break;

            case 2:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title={t('ADD_DIGITAL_EVENT')} buttonText="Add Digital Event" eventAllData ={eventAllData} />);
                break;
            default:
                handleModalData(<AddEventForm getEventApiData={getEventApiData} title={t('ADD_HYBRID_EVENT')} buttonText="Add Digital Event" eventAllData ={eventAllData} />);
                break;
        }
    }


    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />

            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                        key = {index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
};

export default Events;
