import React, { useState, useContext, useEffect } from 'react';
import { USER_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import { allAdminUsers, allUsers, getEducationData, GetEducationQuestiondata, GetEducationSubSessiondata, getResources ,gettoolkitsCategory } from '../../Utils/services';
import { toast } from 'react-toastify';
import AdminForm from '../ModalTemplate/AdminForm';
import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
// import ToolResource from './ToolResource';
// import AddResourcesForm from '../ModalTemplate/AddResourcesForm';
import AddToolkitform from '../ModalTemplate/AddToolkitform';
import EducationSessionResources from './EducationSessionResources';
import AddCourse from '../ModalTemplate/AddCourse';
import EducationSubSessionResources from './EducationSubSessionResources';
import { useNavigate, useLocation } from 'react-router-dom';
import EducationQuestionDetail from './EducationQuestionDetail';
import AddQuestion from '../ModalTemplate/AddQuestion';


const a11yProps = (index) => {
  console.log(index, "index, ")
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const EducationQuestion = ({match}) => {

  const {t} = useTranslation();

  const [tabsValue, setTabsValue] = useState(0);
  const [ResourcesData, setResourcesData] = useState([]);
  const context = useContext(ModalContext)
  const { handleModalData } = context;
  const [loader, setLoader] = useState(true);
  const [adminData, setAdminData] = useState([]);

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }
  const location = useLocation();
  const CourseId = location?.state?.data
  const Addhandle = location?.state?.Addhandle
  const SessionType = location?.state?.data?.SessionType
  const coursename = location?.state?.coursename
  const Sessionname = location?.state?.Sessionname
  
//   const SessionId = location?.state?.sessionid
  
  const getTablesData = async () => {
    try {
      const result = await GetEducationQuestiondata({
        courseId: CourseId.courseId,
        sessionId: CourseId.sessionId,
        sub_SessionId:CourseId._id

      });
      console.log(result,"result")
      setResourcesData(result?.res?.question.reverse())
     
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getTablesData();
    getAdminTableData();
  }, [])
  
  const tabsData = [
    {
      label: SessionType === "Pdf" ? `${t("Pdf ")}` : SessionType === "Video" ? `${t("Video ")}` : SessionType === "Test" ? `${t("Question")}`: null,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <EducationQuestionDetail data={ResourcesData} getTablesData={getTablesData} adminData={adminData} CourseId={CourseId} coursename={coursename} Sessionname={Sessionname}/>
        </>
      )
    },
  ]

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(<AddQuestion questiontype={CourseId?.SessionType} QuestionType={CourseId} getFeedData={getTablesData} title={Addhandle} buttonText={Addhandle} />);
        break;
      default:
        handleModalData(<AddQuestion questiontype={CourseId?.SessionType} QuestionType={CourseId} getFeedData={getTablesData} title={Addhandle} buttonText={Addhandle}  />);
        break;
    }
  }
  // console.log(tabsData, "tabsData")

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            className="px-10"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
}


export default EducationQuestion;

