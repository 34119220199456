import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { BASE_URL_IMG, deleteCourseSessionPostApi, deleteCourseSubSessionPostApi, deleteQuestionPostApi, deleteToolkitPerticularData, GetEducationSubSessiondata, getResourcesCategory, InviteNotification, ToolkitStatusApi, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate, useLocation, useMatch } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
// import EditResourcesForm from '../ModalTemplate/EditResourceForm';
import EditToolkitForm from '../ModalTemplate/EditToolkitForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import EducationSessiontable from './EducationSessiontable';
import EditSession from '../ModalTemplate/EditSession';
import EducationSidebar from './EducationSidebar';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AddQuestion from "../ModalTemplate/AddQuestion";
import EditQuestion from "../ModalTemplate/EditQuestion";
import ReactPlayer from 'react-player';
import AddSubSession from '../ModalTemplate/AddSubSession';
import EditSubSession from '../ModalTemplate/EditSubSession';



const EducationSessionResources = ({ data, getTablesData, adminData, }) => { 
  const [singlePostData, setsinglePostData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState([]);
  const [ResourcesData, setResourcesData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [courseid, setCourseid] = useState();
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [expanded, setExpanded] = useState(false);
  // let { path } = useMatch();

  const location = useLocation();
  const coursename = location?.state?.data
  
   const handleAdminPush = () => {
        history(-1);
    }

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  // const getCategoryData = async () => {
  //   try {
  //     const result = await getResourcesCategory();
  //     setCategoryData(result?.data)
  //   }
  //   catch (error) {
  //     console.log(error);
  //     toast.warning("Something went wrong!")
  //   }
  // }
  // useEffect(() => {
  //   getCategoryData();
  // }, []);

  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }

  useEffect(() => {
    if (category?.category?._id) {
      let _result = data?.filter((item) => {
        return item?.category?.id === category?.category?._id
      })
      setShowFilteredData(_result)
    }
    else {
      setShowFilteredData(data)
    }
  });

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  const history = useNavigate();
  const { t } = useTranslation();

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteCourseSessionPostApi(id);
      console.log(result)
      if (result.res.success) {
        toast.success(result.res.success)
        getTablesData();
      }

    } catch (error) {
      toast.error(error.message)
    }
  }
  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t('Remove_Session')}
        action={HandleDeleteResource}
        data={t('remove_this_Session?')}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  // const handleDeactivate = async (props) => {
  //   const id = props.row.original._id;
  //   const USER_DEACTIVATE = <MaintenanceModeConfirmation
  //     title="Change User Status"
  //     data={modalTextsFile.userActivateDeactivate}
  //     action={() => handleMaintenance(id)}
  //   />
  //   handleModalData(USER_DEACTIVATE, 'sm')
  // }

  const handleEditResource = async (data) => {
    const editResourceForm = <EditSession
      data={data}
      getTablesData={getTablesData}
      title= {t('Edit_Session')}
    />
    handleModalData(editResourceForm)
  }
  const handleClose = () => {
    setOpen(false);
  };
  const showResourceDetail = async (item) => {  

      try {
      setCourseid(item)
      const result = await GetEducationSubSessiondata({
        courseId: item?.courseId ? item?.courseId : item?._id,
        sessionId: item?.sessionId ? item?.sessionId : item?._id,
      });
      console.log(result.res,"result")
      setResourcesData(result?.res?.sub_Session?.reverse())
      setOpen(true); 
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }

   
  }

  const handleActiveDeactive = async (props) => { 
    const id = props.row.original._id;
    try {
      const result = await ToolkitStatusApi(id);
      if (result.res.success) {
        toast.success(result.res.message)
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleAddQuestion = async (data) => {  
    const editResourceForm = (
      <AddQuestion
       questiontype={data?.SessionType} 
       QuestionType={data}
        getFeedData={showResourceDetail} 
        courseidid={data}
        // title={Addhandle} 
        // buttonText={Addhandle} 
        
        />
      
    );
    handleModalData(editResourceForm);
  };

  const handleEditQuestion = async (data) => { 
    const editResourceForm = <EditQuestion
      title={t('Edit_Question')}
      data={data}
      QuestionType={data}
      getFeedData={showResourceDetail}
      courseid={courseid}
    />
    handleModalData(editResourceForm)
  }
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(singlePostData);
    const [reorderData] = items.splice(result?.source?.index, 1);
    items.splice(result.destination.index, 0, reorderData);
    console.log(items);
    setsinglePostData(items);
  };
  const HandleDeleteQuestion = async (id) => { 
    try {
      const result = await deleteQuestionPostApi(id);
      console.log(result);
      if (result.res.success) {
        toast.success(result.res.success);
        showResourceDetail(courseid);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const checkLink = (url) => {
    return (
      <ReactPlayer
        url={`${BASE_URL_IMG}${url}`}
        controls={true}
        width="75%"
        loop={true}
      />
    );
  };
  const checkpdflink = (url) => {
    window.open(`${BASE_URL_IMG}${url}`, "_blank");
  };

  const HandleRemoveQuestion = (id) => { 
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t('Question_Delete')}
        action={HandleDeleteQuestion}
        data={t('remove_this_Question?')}
        id={id?._id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  const HandleAddSessionForm = async (data) => {
    const AddsessionForm = (
      <AddSubSession
      SessionId={courseid?._id}
      CourseId={courseid?.courseId}
        getFeedData={showResourceDetail}
        getdataid={courseid}
        title={t('Add_SubSession')}
        buttonText={t('Add_SubSession')}
      />
    );
    handleModalData(AddsessionForm);
  };

  const handleEditSubsession = async (data) => { 
    const editResourceForm = (
      <EditSubSession
        data={data}
        courseidid={courseid}
        getFeedData={showResourceDetail}
        title={t('Edit_Subsession')}
      />
    );
    handleModalData(editResourceForm);
  };


  const HandleDeleteResourcesubsession = async (id) => {
    try {
      const result = await deleteCourseSubSessionPostApi(id);
      console.log(result);
      if (result.res.success) {
        toast.success(result.res.success);
        showResourceDetail(courseid);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const HandleRemoveEventsubsession = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t('Remove_SubSession')}
        action={HandleDeleteResourcesubsession}
        data={t('remove_this_Sub-session?')}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };
  const userColumns = useMemo(
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
    //   {
    //     Header: "Media Image",
    //     accessor: data => {
    //       return (
    //         <>
    //           {
    //             // data?.media[0]?.mediaType === "file" ?
    //               <img src={`${BASE_URL_IMG}${data?.image}`} alt="pdfimg" className='w-28' />
    //             //   :
    //             //   <img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
    //           }
    //         </>
    //       )
    //     }
    //   },
 
      {
        Header: `${t('Course_Topic')}`,
        accessor: data => { 
          return (
            <>
              <span>{data?.session}</span>
            </>
          )
        }
      },
    //   {
    //     Header: 'Course Topic',
    //     accessor: data => { 
    //         return (
    //             <>
    //                 {data?.sessiondata?.map(item => { 
    //                     return (
    //                         <p>{`${item?.session} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
      // {
      //   Header: "Category",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.category?.name}</span>
      //       </>
      //     )
      //   }
      // },
    //   {
    //     Header: "Country",
    //     accessor: data => { 
    //       return (
    //         <>
    //           <span>{data?.Country[0]?.name}</span>
    //         </>
    //       )
    //     }
    //   },
     
    //   {
    //     Header: "Course Type",
    //     accessor: data => {
    //       return (
    //         <>
    //           <span>{data?.CourseType}</span>
    //         </>
    //       )
    //     }
    //   },
    //   {
    //     Header: "Course Duration",
    //     accessor: data => {
    //       return (
    //         <>
    //           <span>{data?.CourseDuretion}</span>
    //         </>
    //       )
    //     }
    //   },
    //   {
    //     Header: "Posted On",
    //     accessor: data => {
    //       return (
    //         <>
    //           <span>{formatDate(data?.date)}</span>
    //         </>
    //       )
    //     }
    //   },
      {
        Header: `${t('Edit')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => handleEditResource(data)}
            >
              <span className="MuiButton-label">{t('Edit')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('View')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => showResourceDetail(data)}
            >
              <span className="MuiButton-label">{t('View_SubSession')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('Delete')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => HandleRemoveEvent(data?._id)}
            >
              <span className="MuiButton-label">{t('Delete')}</span>
            </button>
          )
        }
      },
    //   {
    //     Header: 'Status',
    //     accessor: "deactivated",
    //     Cell:adminData[0]?.AdminType === "Coordination team" ? "" : props => {
    //       return (
    //         <Grid item>
    //           <AntSwitch
    //             checked={props.row.original.status}
    //             onChange={() => handleActiveDeactive(props)}
    //             name="isDirectory"
    //           />
    //         </Grid>
    //       )
    //     }
    //   }
    ], [adminData])
    console.log(showFilteredData, "showFilteredData")
    const handleExpand = () => {
      setExpanded(!expanded);
    };
  return (
    <>
      <div className=" justify-between items-center">
      <div className="flex items-center mb-4">
                <span onClick={handleAdminPush} className="text-sm text-sidenav  cursor-pointer">{t('Course_Name')} : </span>
                {/* <span className="px-2 text-sm">/</span> */}
                <span className=" text-minihubPara capitalize text-sm">{coursename?.title}</span>
            </div>
        {
          showFilteredData?.length > 0 && 
          showFilteredData?.map((item,i) => { 
            return (
              <>
                <Accordion onClick={() => showResourceDetail(item)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon onClick={handleExpand} />}
                  >
                    <div className="Questionbox border border-primary mt-5"  >
                      <div  key={i} className="questionsection" >
                        <h6
                         
                          className="questionID d-flex justify-content-first align-items-center"
                        >
                          {i + 1} {t('Session')} 
                        </h6>
                        <p>{item.session}</p>
                      
                      </div>
                      <div className="actionsection">
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                    type="button"
                                     
                                      onClick={() => handleEditResource(item)}
                                  >
                                    <span className="MuiButton-label">
                                    {t('Edit')}
                                    </span>
                                  </button>
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                    type="button"
                                    onClick={() => HandleRemoveEvent(item?._id)}
                                  >
                                    <span className="MuiButton-label">
                                    {t('Delete')}
                                    </span>
                                  </button>
                                </div>
                    </div>
                  </AccordionSummary>
                    
                  <AccordionDetails>
                  <div className="Sessionbody">
                  <div className="flex items-center mb-4">
                <span  className="text-md SubSessionhead ">{i + 1} {t('SubSession')} </span>
            
            <div className="AddSession">
              <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                type="button"
                onClick={() => HandleAddSessionForm()}
              >
                <span className="MuiButton-label">{t('Add_SubSession')}</span>
              </button>
            </div>
            </div>
              {ResourcesData?.map((item, i) => { 
                return (
                  <>
                   <Accordion >
                      <AccordionSummary className="p-0" expandIcon={<ExpandMoreIcon onClick={handleExpand} />} >
                         {/* <Typography>Accordion Title</Typography> */}
                         <div className="listsession border border-primary  mt-5">
                      <h6
                        key={i}
                        className="questionID SubSession d-flex justify-content-first align-items-center"
                      >
                        {i + 1}
                      </h6>
                      <div className="listcontent ">
                       <div className= "subsessiontitle">
                       <p>{item.title}</p>
                       </div>
                        <div className="subsessiontype">
                        <p>{item.SessionType}</p>
                        </div>
                        <div className="buttonsection">
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            onClick={() => handleEditSubsession(item)}
                          >
                            <span className="MuiButton-label">{t('Edit')}</span>
                          </button>
                         
                          <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            onClick={() => HandleRemoveEventsubsession(item?._id)}
                          >
                            <span className="MuiButton-label">{t('Delete')}</span>
                          </button>
                          {
                            item.SessionType === "Test" && 
                            <>
                             <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 samebutton"
                            type="button"
                            onClick={() => handleAddQuestion(item)}
                           >
                            <span className="MuiButton-label">
                            {t('Add_Question')}
                            </span> 
                          </button>
                          </>
                           }
                        </div>
                      </div>
                    </div>
                          </AccordionSummary>
                             <AccordionDetails>
                             <Grid container spacing={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <Grid
                {...provided.droppableProps}
                ref={provided.innerRef}
                item
                md="12"
              >
                {item?.QuestionData?.length > 0 &&
                  item?.QuestionData?.map((item, i) => {  
                    return (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id?.toString()}
                        index={i}
                      >
                        {(provided) =>
                          item.QuestionType === "Single" || item.QuestionType === "Multiple"  ? (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="Questionbox border border-primary mt-5"
                              >
                                <div className="questionsection">
                                  <h6
                                  key={i}
                                    {...provided.dragHandleProps}
                                    className="questionID d-flex justify-content-first align-items-center"
                                  >
                                    {t('Question')} {i + 1}
                                  </h6>
                                  <p>{item.Question}</p>
                                  {item.Option.map((option, i) => (
                                    <>
                                      <div className="option">
                                        <h6 className="questionID d-flex justify-content-first align-items-center">
                                          {/* {i === 0 ? "A" : i === 1 ? "B" : i === 2 ? "C" : i === 3 ? "D" :i === 4 ? "E" : i === 5 ? "F" : i === 6 ? "G" : null} */}
                                          {i + 1}
                                        </h6>
                                        <li>{option}</li>
                                      </div>
                                    </>
                                  ))}
                                </div>
                                {  
                                    item?.QuestionType === "Single" ?
                                    <>
                                     <div className="answersection">
                                     <h6
                                    
                                      className="questionID d-flex justify-content-first align-items-center"
                                     >
                                    {t('Answer')}
                                  </h6>
                                       {
                                        item.Answer.map((option, i) => ( 
                                          <>
                                            <div className="options">
                                              {/* <h6 className="questionID d-flex justify-content-first align-items-center">
                                                {" "}
                                                {i + 1}
                                              </h6> */}
                                              <li>{option}</li>
                                            </div>
                                          </>
                                        ))
                                       }
                                     </div>
                                    </> : null

                                  }
                                {  
                                    item?.QuestionType === "Multiple" ?
                                    <>
                                     <div className="answersection">
                                     <h6
                                    
                                      className="questionID d-flex justify-content-first align-items-center"
                                     >
                                     {t('Answer')}
                                  </h6>
                                       {
                                        item.Answer.map((option, i) => ( 
                                          <>
                                            <div className="options">
                                              {/* <h6 className="questionID d-flex justify-content-first align-items-center">
                                                {" "}
                                                {i + 1}
                                              </h6> */}
                                              <li>{option}</li>
                                            </div>
                                          </>
                                        ))
                                       }
                                     </div>
                                    </> : null

                                  }
                               
                                
                                  <div className="actionsection">
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                    type="button"
                                      onClick={() => handleEditQuestion(item)}
                                  >
                                    <span className="MuiButton-label">
                                    {t('Edit')}
                                    </span>
                                  </button>
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                    type="button"
                                    onClick={() => HandleRemoveQuestion(item)}
                                  >
                                    <span className="MuiButton-label">
                                    {t('Delete')}
                                    </span>
                                  </button>
                                </div>
                           
                              
                              </div>
                            </>
                          ) : item.QuestionType === "video" ? (
                            <div className="Questionbox border border-primary mt-5">
                              <div className="questionsection">
                              <div className = "pdfbox">
                                <h6 className="questionID d-flex justify-content-first align-items-center">
                                  {/* Question {i + 1} */}
                                  {t('Video_Title_Name')} : 
                                </h6>
                                <p className="pb-5">{item.Question}</p>
                                </div>
                                <div className="videodiv">
                                  {checkLink(item.media?.mp4link)}
                                </div>
                                {/* {item.Option.map((option,i)=>
                                                    <>
                                                   <div className='option'>
                                                   <h6 className="questionID d-flex justify-content-first align-items-center"> {i + 1}</h6>
                                                    <li>{option}</li>
                                                   </div>
                                                    </>
                                               )} */}
                              </div>
                              {/* <div className="actionsection">
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                  type="button"
                                    onClick={() => handleEditResource(item)}
                                >
                                  <span className="MuiButton-label">Edit</span>
                                </button>
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                  type="button"
                                  onClick={() => HandleRemoveEvent(item?._id)}
                                >
                                  <span className="MuiButton-label">
                                    Delete
                                  </span>
                                </button>
                              </div> */}
                            </div>
                          ) : item.QuestionType === "pdf" ? (
                            <div className="Questionbox border border-primary mt-5">
                              <div className="questionsection">
                                <div className = "pdfbox">
                                <h6 className="questionID d-flex justify-content-first align-items-center">
                                  {/* Question {i + 1} */}
                                  {t('Pdf_Title_Name')} : 
                                </h6>
                                <p>{item.Question}</p>
                                </div>
                                {/* {checkpdflink(item.media)} */}
                                <img
                                  className="toc-icon2"
                                  src={`${BASE_URL_IMG}${item.pdfimg}`}
                                  alt="pdfimg"
                                  onClick={() =>
                                    checkpdflink(item.media.link)
                                  }
                                />
                              </div>

                              {/* <div className="actionsection">
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                  type="button"
                                    onClick={() => handleEditResource(item)}
                                >
                                  <span className="MuiButton-label">Edit</span>
                                </button>
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                  type="button"
                                  onClick={() => HandleRemoveEvent(item?._id)}
                                >
                                  <span className="MuiButton-label">
                                    Delete
                                  </span>
                                </button>
                              </div> */}
                            </div>
                          ) : null
                        }
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
                          </AccordionDetails>
                    </Accordion>
                   
                  </>
                );
              })}
            </div>
                  </AccordionDetails>
                </Accordion>
              </>
            );
          })
        }
      </div>
      {/* {
        showFilteredData && <EducationSessiontable columns={userColumns} data={showFilteredData.filter((val, index) => {
          if (searchValue === "") {
            return val
          }
          else if (val.aliasName && val.aliasName.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.country && val.country.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
            return val
          }
        })} />
      } */}
      {/* <EducationSidebar open={open} onClose={handleClose} adminData={adminData} ResourcesData={ResourcesData} getFeedData={showResourceDetail} courseid={courseid} /> */}

    </>
  )
}

export default EducationSessionResources;