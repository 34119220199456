import { Button } from "@material-ui/core";
import { ModalContext } from '../../Context'
import { useContext, useState } from "react";



const MaintenanceModeConfirmation = ({ data, action, title }) => {
    const context = useContext(ModalContext)
    const { closeModal } = context;
    const clickHandler = async () => {
        const res = await action();
    }

    return (
        <div className="warning-modal">
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: data }} />

            <div className="flex justify-between mx-auto mt-10">
                <Button className="btn2" onClick={closeModal}>
                    Cancel
                </Button>

                <Button
                    color="secondary"
                    className="approve_maintenace_padding  btn2 approve_decline_padding"
                    onClick={clickHandler}
                >
                    <span className="pl-1"> Save </span>
                </Button>
            </div>
        </div>
    )
}

export default MaintenanceModeConfirmation;