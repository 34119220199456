import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCOK1OhtdI9d9uqLC6sOz1eIepCRhb3nzw",
    authDomain: "glp-power.firebaseapp.com",
    projectId: "glp-power",
    storageBucket: "glp-power.appspot.com",
    messagingSenderId: "433940137992",
    appId: "1:433940137992:web:cbf74863d495e5322469bd",
    measurementId: "G-QW2C1ZJ0Z1"
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

// const { REACT_APP_VAPID_KEY } = process.env;
// const publicKey = REACT_APP_VAPID_KEY;
export const getToken = async (setTokenFound) => { 
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: `BJDSLQdJjUYY4lJmHHrSInPbc4r95AihOZ0wGlHPihlE3Lrmzxg_gCGijHeUfUjg1nznNviLnr8yMS21V5Jqeu4` });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });