import { useContext, useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Loader from "../Common/Loader";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@material-ui/core";
import "date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import { addEvent, getAdminDetails, getCountries, getSectorList, getSectorLists, getSegments,
  GetyAgoralink,getagoratoken,BASE_URL } from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import timezoneList from "./timezone.json";
import { CountryOPtion } from "../Common/Option";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Grid from '@material-ui/core/Grid';
import {Cropper} from 'react-cropper';
import "cropperjs/dist/cropper.css";

const AddEventForm = ({ title, buttonText, getEventApiData, eventAllData }) => { 
  const [loader, setLoader] = useState(false);
  const [showLinkInput, setShowLinkInput] = useState("");
  const [isInviteOnly, setIsInviteOnly] = useState(false);
  const [isGetHelp, setisGetHelp] = useState(false);
  const [displayOnHomepage, setdisplayOnHomepage] = useState(false);
  const [agoradata, setagoradata] = useState(false);
  const [countryNotification, setcountryNotification] = useState(false);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState([]);
  const [segs, setsegs] = useState();
  const [segmentData, setSegmentData] = useState([]);
  const [segment, setSegment] = useState([]);
  const [sector, setSector] = useState([]);
  const [sectorData, setSectorData] = useState([]);
  const [countrydata, setcountrydata] = useState([]);
  const [imgFile, setImgFile] = useState("");
  const [mediaType, setMediaType] = useState("photo");
  const [showImgError, setshowImgError] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [article, setArticle] = useState("");
  const [serviceList, setServiceList] = useState([{ service: "" }]);
  const [disableButton, setDisableButton] = useState(false);
  const [imgFilecrop, setImgFilecrop] = useState('');
  const [crop, setCrop] = useState({ aspect: 2 / 1 });
  const [agoratoken, setagoratoken] = useState();
    const [src, setSrc] = useState(null);
    const [image, setImage] = useState(null)
    const [result, setResult] = useState(null)
    const [contentType, setContentType] = useState("application/json")
    const [sendImageFile, setSendImageFile] = useState('');
    const [agoratokenurl, setagoratokenurl] = useState(agoratoken);
  const [allFieldData, setAllFieldData] = useState({
    name: "",
    postTo: title === "Add Offline Event" ? "offline" : title === "ADD DIGITAL EVENT" ? "digital" : "hybrid" || "",
    repeat: "",
    description: "",
    organizer: "",
    address: "",
  });
  const [links, setLinks] = useState([]);
  const [AgoraLinks, setAgoraLinks] = useState();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [allTimezone, setallTimezone] = useState({
    timezone: [],
  });

  // console.log(allFieldData, "allFieldData");
  const { t } = useTranslation();

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };
  // console.log(serviceList, "serviceList");
  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { service: "" }]);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let convertedData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setArticle(convertedData);
  };

  const embedVideoCallBack = (link) => {
    link = link.replace("watch?v=", "embed/");
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
    return link;
  };

 
  const context = useContext(ModalContext);
  const { closeModal } = context;
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const formateTime = (time) => {
    const _Time = new Date(time);
    const hours = _Time.getHours();
    const minutes = _Time.getMinutes();
    const FullTime = `${hours}:${minutes}`;
    return FullTime;
  };

  // console.log(eventAllData, "eventAllData");

  const onInputSectorChange = (event, newInputValue, name) => {
    setSector({
      ...sector,
      [name]: newInputValue,
    });
  };
  const onInputSectorsChange = (event, newInputValue, name) => {
    setcountrydata({
      ...countrydata,
      [name]: newInputValue,
    });
  };

//   const getCountryData = async () => {
//     try {
//       const result = await getCountries();
//       setCountries(result?.res?.countries);
//     } catch (error) {
//       console.log(error);
//       toast.warning("Something went wrong!");
//     }
//   };
const adminId = localStorage.getItem('adminId');
     
const getAdminDetailsApiData = async () => {
    try {
        const result = await getAdminDetails({
            id: adminId,
            "type": "admin"
        })
        setCountries(result.data.country)
    } catch (error) {
        console.log(error)
    }
}

    const getSegmentApiData = async () => {
        if(segs){ 
        try {
          const result = await getSegments({
           "id": segs
          });
          
        setSegmentData(result?.res?.data);
        } catch (error) {
          console.log(error);
          toast.warning("Something went wrong! test");
        }
    }
      };

  const getSectorListData = async () => {
    try {
      const result = await getSectorLists();
      
      setSectorData(result.res.data);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getAdminDetailsApiData();
   
  }, [country]);

  useEffect(() => {
    getSectorListData();
  }, []);

  useEffect(() => {
    getSegmentApiData()
    // let _segmentData = countries?.filter((data) => {
    //   return data?.country_code === country?.country?.country_code;
    // });
    // setSegmentData(_segmentData && _segmentData[0]?.segments);
  }, [country]);

  const onInputCountryChange = (event, newInputValue, name) => { 
    let newInputValues = newInputValue?.map((item) => {
        return item._id
      });
      setsegs(newInputValues)
      
    setCountry({
      ...country,
      [name]: newInputValue,
    });
    
  };

  const onInputSegmentChange = (event, newInputValue, name) => {
    setSegment({
      ...segment,
      [name]: newInputValue,
    });
  };

  const onInputTimezoneChange = (event, newInputValue, name) => {
    const _name = event.target.name;
    const _value = event.target.value;

    if (name == "timezone") {
      setallTimezone({
        ...allTimezone,
        [name]: newInputValue,
      });
    } else {
      setallTimezone({
        ...allTimezone,
        [_name]: _value,
      });
    }
  };

  const handleChange = (event, newInputValue, name) => {
    const _name = event.target.name;
    const _value = event.target.value;
    if (name == "repeat") {
      setAllFieldData({
        ...allFieldData,
        [name]: newInputValue,
      });
    } else {
      setAllFieldData({
        ...allFieldData,
        [_name]: _value,
      });
    }
  };

  useEffect(() => {
    if (allFieldData.postTo === "offline") { 
      setShowLinkInput("offline");
    } else if (allFieldData.postTo === "digital") {
      setShowLinkInput("digital");
    } else if (allFieldData.postTo === "hybrid"){
      setShowLinkInput("hybrid");
    }
  }, [allFieldData]);
// console.log(showLinkInput,"showLinkInput")
  const handleImgFile = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 200) {
          setImgFile(val);
          setMediaType("photo");
          setshowImgError(false);
          setImgFilecrop("")
          setSrc(null)
        } else {
          setImgFile("");
          setshowImgError(true);
          setMediaType("photo")
          setImgFilecrop(val)
          setSrc(URL.createObjectURL(val))
        }
      };
    }
  };
  let linksdata = serviceList.map((item) => {
    return item.links;
  });
  // console.log(linksdata, "linksdata");

  const handleSubmit = async (e) => { 
    setLoader(true);
    const year = start.getFullYear();
    const month = start.getMonth() + 1;
    e.preventDefault();
    const nowutc = new Date(startTime); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    const Starttime = new Date(
      nowutc.getTime() - nowutc.getTimezoneOffset() * 60000
    ).toISOString();
    const nowsutc = new Date(endTime); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    const Endtime = new Date(
      nowsutc.getTime() - nowsutc.getTimezoneOffset() * 60000
    ).toISOString();
    console.log(Starttime, "Starttime");
    console.log(Endtime, "Endtime");
    
    try {
      let segmentData = segment?.segment?.map((item) => {
        return item?._id;
      });
      let sectorData = sector?.sector?.map((item) => {
        return item?.name;
      });
      let countryData = country?.country?.map((item) => { 
        return item?.name;
        
      });
      const _NewLinks = links.length > 0 && links.split(",");
     
      const postFormData = new FormData();
      postFormData.append("name", allFieldData.name);
      postFormData.append("organiser", allFieldData.organizer);
      postFormData.append("description", allFieldData.description);
      postFormData.append("postTo", allFieldData.postTo);
      postFormData.append("startTime", startTime);
      postFormData.append("endTime", endTime);
      postFormData.append("Mobile_startTime", Starttime);
      postFormData.append("Mobile_endTime", Endtime);
      postFormData.append("start", start);
      postFormData.append("end", end);
      postFormData.append("timezone", allTimezone.timezone.text);
      postFormData.append("location", allFieldData.address);
      postFormData.append("country", countryData);
      postFormData.append("InviteOnly", isInviteOnly);
      postFormData.append("isGethelp", isGetHelp);
      postFormData.append("displayOnHomepage", displayOnHomepage);
      postFormData.append("segment", isInviteOnly ? segmentData : "");
      postFormData.append(
        "sector", sectorData ? sectorData : null
      );
    //   postFormData.append("target", countrydata?.Target.value);
      postFormData.append("target", countryNotification);
      postFormData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);
      const postOnlineData = new FormData();
      postOnlineData.append("name", allFieldData.name);
      postOnlineData.append("organiser", allFieldData.organizer);
      postOnlineData.append("description", allFieldData.description);
      postOnlineData.append("postTo", allFieldData.postTo);
      postOnlineData.append("startTime", startTime);
      postOnlineData.append("endTime", endTime);
      postOnlineData.append("Mobile_startTime", Starttime);
      postOnlineData.append("Mobile_endTime", Endtime);
      postOnlineData.append("start", start);
      postOnlineData.append("end", end);
      postOnlineData.append("timezone", allTimezone.timezone.text);
      postOnlineData.append("links", _NewLinks.length > 0 ? _NewLinks : links);
      //   postOnlineData.append("links", linksdata );
      postOnlineData.append("country", countryData);
      postOnlineData.append("InviteOnly", isInviteOnly);
      postOnlineData.append("isGethelp", isGetHelp);
      postOnlineData.append("displayOnHomepage", displayOnHomepage);
      postOnlineData.append("chatInvite", agoradata);
      postOnlineData.append("segment", isInviteOnly ? segmentData : "");
      postOnlineData.append(
        "sector",
        sectorData ? sectorData : null
      );
    //   postOnlineData.append("target", countrydata?.Target.value);
      postOnlineData.append("target", countryNotification);
      postOnlineData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);
      postOnlineData.append("otherlinks", linksdata && linksdata);

      const postHybridData = new FormData();
      postHybridData.append("name", allFieldData.name);
      postHybridData.append("organiser", allFieldData.organizer);
      postHybridData.append("description", allFieldData.description);
      postHybridData.append("postTo", allFieldData.postTo);
      postHybridData.append("startTime", startTime);
      postHybridData.append("endTime", endTime);
      postHybridData.append("Mobile_startTime", Starttime);
      postHybridData.append("Mobile_endTime", Endtime);
      postHybridData.append("start", start);
      postHybridData.append("end", end);
      postHybridData.append("timezone", allTimezone.timezone.text);
      postHybridData.append("links", _NewLinks.length > 0 ? _NewLinks : links);
      //   postOnlineData.append("links", linksdata );
      postHybridData.append("country", countryData);
      postHybridData.append("InviteOnly", isInviteOnly);
      postHybridData.append("isGethelp", isGetHelp);
      postHybridData.append("displayOnHomepage", displayOnHomepage);
      postHybridData.append("segment", isInviteOnly ? segmentData : "");
      postHybridData.append("chatInvite", agoradata);
      postHybridData.append(
        "sector",
        sectorData ? sectorData : null
      );
    //   postOnlineData.append("target", countrydata?.Target.value);
      postHybridData.append("target", countryNotification);
      postHybridData.append("media", sendImageFile && sendImageFile ? sendImageFile :imgFile);
      postHybridData.append("otherlinks", linksdata && linksdata);
      postHybridData.append("location", allFieldData.address);
      const results = await addEvent(allFieldData.postTo === "offline" ? postFormData: allFieldData.postTo === "hybrid" ? postHybridData : postOnlineData);
      if (results.res.success) {
        // toast.success(results.res.message);
        toast.success("Event added successfully");
        closeModal();
        getEventApiData(year, month);
        setTimeout(() => {
          setLoader(false);
      }, 5000); // 5-second delay
      }
    } catch (error) {
      toast.error(e.messages);
      setLoader(false);
    }
  };
  // console.log(allFieldData?.address,"allFieldData.address")
  function getCroppedImg() { 
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas.width = crop.width;
    // canvas.height = crop.height;

    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;
    

    // maximum width/height
    var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }

    // set canvas size
    canvas.width = targetWidth; 
    canvas.height = targetHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
   
    const base64Image = canvas.toDataURL("image/jpeg");
    var profile = new Image();
    profile.src = base64Image;
    setResult(profile.src)

    fetch(profile.src)
      .then(res => res.blob())  
      .then(blob => {
        const file = new File([blob], profile.src, { type: "image/jpeg" })
        setSendImageFile(file)
      })
  }
  // console.log(agoradata, "agoradata")

  const handlePostSubmit = async (e) => { 
    setLoader(true);
   // setAgoraLinks(e.target.value)
   
   setagoradata(e.target.checked)
    e.preventDefault();
    if(agoradata === false){
    try {
      function generateRandomCode() {
        // Generate a random number between 100000 and 999999 (inclusive)
        const randomCode = Math.floor(100000 + Math.random() * 900000);
      
        return randomCode;
      }
      const sixDigitCode = generateRandomCode();
        // const result = await GetyAgoralink({
        //   token_key : agoratoken?.token,  
        //   channel_id : agoratoken?.channelName,
            
        // });
        // if (result.res.success) { 
          const currentOrigin = window.location.origin;
          const linkWithCode = `${currentOrigin}/${sixDigitCode}`;
          setLinks(linkWithCode)
            setLoader(false);
        // }
    }
  
    catch (error) {
        setLoader(false);
        console.log(error);
        toast.error(error.message);
    }
  } else {
    setLinks("")
    setLoader(false);
  }
}


// const getAgoraApiData = async () => {
//   // console.log(year, month, "YearOnt")
//   try {
//       const result = await getagoratoken();
//       setLoader(false)
//       const _data = result.res.data;
     
//      setagoratoken(_data)
    
//   }
//   catch (error) {
//       setLoader(false)
//       console.log(error)
//   }
// }
// useEffect(()=>{
// getAgoraApiData()
// },[])
// console.log(segmentData.length,"segmentData")
const cropperRef = useRef(null);
// const getCropData = () => {
//     if (typeof cropperRef.current?.cropper !== "undefined") {
//       setResult(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
//             fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
//         .then(res => res.blob())  
//         .then(blob => {
//           const file = new File([blob], cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
//           setSendImageFile(file)
//         })
//     }
//   };
const getCropData = () => {
  if (typeof cropperRef.current?.cropper !== 'undefined') {
    const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

    // Resize the canvas to reduce file size
    const resizedCanvas = document.createElement('canvas');
    const maxWidth = 800; // Adjust the maximum width as needed
    const maxHeight = 800; // Adjust the maximum height as needed
    const scale = Math.min(maxWidth / croppedCanvas.width, maxHeight / croppedCanvas.height);
    
    resizedCanvas.width = croppedCanvas.width * scale;
    resizedCanvas.height = croppedCanvas.height * scale;

    const ctx = resizedCanvas.getContext('2d');
    ctx.drawImage(croppedCanvas, 0, 0, resizedCanvas.width, resizedCanvas.height);

    // Convert the resized canvas to a data URL
    const resizedDataURL = resizedCanvas.toDataURL('image/jpeg', 0.8); // Adjust quality as needed
    setResult(resizedDataURL);
    // Create a Blob from the data URL and set the compressed file
    fetch(resizedDataURL)
      .then(res => res.blob())
      .then(blob => {
        const compressedFile = new File([blob], 'compressed_image1.jpg', { type: 'image/jpeg' });

        // Set the compressed file for further use (e.g., sending to the server)
        setSendImageFile(compressedFile);
      //  setImgFile(compressedFile);

        // Optional: Set the compressed image data URL
        console.log(resizedDataURL,"resizedDataURL")
        
      })
      .catch(error => {
        console.error('Error resizing image:', error);
      });
  }
};

  // const handleCancelSelection = () => {
  //   const cropper = cropperRef.current;
  //   if (cropper) {
  //     // Reset the cropper to its initial state
  //     cropper.setCropBoxData({ width: 0, height: 0 });
  //   }
  // };
  const getCropremove2 = () => {
    setImgFile("");
    setResult("");
    setMediaType("");
    setshowImgError(false)
    setImgFilecrop("");
    setSrc(null); 

    const fileInput = document.querySelector('input[name="image-file"]');
    if (fileInput) {
        fileInput.value = ''; // Clear the value to allow re-selection of the same file
    }
  };

  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>
      <div noValidate autoComplete="off" className="w-full">
        <div className="flex responsiveclass items-center mt-5 customeCheckbox InviteOnlyCheckbox">
          <FormControlLabel
            control={
              <Checkbox
                name="All"
                checked={isInviteOnly}
                onChange={(e) => setIsInviteOnly(e.target.checked)}
                defaultChecked
                color="primary"
              />
            }
            label= {t('Invite_Only')}
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="All"
                checked={isGetHelp}
                onChange={(e) => setisGetHelp(e.target.checked)}
                defaultChecked
                color="primary"
              />
            }
            label={t('Get_Help')}
            labelPlacement="end"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="All"
                checked={displayOnHomepage}
                onChange={(e) => setdisplayOnHomepage(e.target.checked)}
                defaultChecked
                color="primary"
              />
            }
            label={t('displayOnHomepage')}
            labelPlacement="end"
          />
        </div>
        {title === "ADD EVENT" && (
          <div className="mt-4 w-6/12 flex responsiveclass flex-row items-center">
            <h1 className="text-paraText">{t('Post_To')}</h1>
            <RadioGroup
              aria-label="event"
              name="postTo"
              className="radio-group"
              defaultChecked="digital"
              value={allFieldData.postTo}
              onChange={handleChange}
            >
              <span className="ml-8">
                <FormControlLabel
                  value="offline"
                  className="text-muteText radio-label"
                  control={<Radio color="default" />}
                  label={t('Offline_Event')}
                />
                <FormControlLabel
                  value="digital"
                  className="text-muteText radio-label"
                  control={<Radio color="default" />}
                  label={t('Digital_Event')}
                />
                <FormControlLabel
                  value="hybrid"
                  className="text-muteText radio-label"
                  control={<Radio color="default" />}
                  label={t('Hybrid_Event')}
                />
              </span>
            </RadioGroup>
          </div>
        )}
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <TextField
            id="eventName"
            label={t('Event_Name')}
            name="name"
            value={allFieldData.name}
            onChange={handleChange}
            required
          />
          <TextField
            id="organiser"
            label={t('Organizer_Name')}
            name="organizer"
            value={allFieldData.organizer}
            onChange={handleChange}
            required
          />
          <Autocomplete
            name="timezone"
            options={timezoneList}
            getOptionLabel={(option) => option.text}
            value={allTimezone.timezone}
            onChange={(e, newVal) =>
              onInputTimezoneChange(e, newVal, "timezone")
            }
            renderInput={(params) => (
              <TextField {...params} id="timezone" label={t('Select_Timezone')} required />
            )}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              showTodayButton
              todayLabel="now"
              label={t('Start_Time')}
              name="time"
              value={startTime}
              minutesStep={5}
              ampm={false}
              onChange={setStartTime}
              required
            />
            <TimePicker
              showTodayButton
              todayLabel="now"
              label={t('End_Time')}
              name="time"
              value={endTime}
              minutesStep={5}
              ampm={false}
              onChange={setEndTime}
              required
            />

            <KeyboardDatePicker
              label={t('Event_Start_Date')}
              placeholder={`${start}`}
              value={start}
              onChange={(date) => (setStart(date), setEnd(date))}
              format={"dd-MM-yyyy"}
              disablePast={true}
              required
            />
            <KeyboardDatePicker
              label={t('Event_End_Date')}
              placeholder={`${end}`}
              value={end}
              onChange={(date) => setEnd(date)}
              format={"dd-MM-yyyy"}
              disablePast={true}
              minDate={start}
              required
            />
            <Autocomplete
              multiple
              id="combo-box-demo"
              options={countries?.sort(dynamicSort("name"))}
              getOptionLabel={(option) => option?.name || ""}
              sx={{ width: 300 }}
              value={country?.name}
              onChange={(e, newVal) =>
                onInputCountryChange(e, newVal, "country")
              }
              renderInput={(params) => (
                <TextField {...params} label={t('Country')} required />
              )}
            />
            {segmentData?.length > 0 &&
            //   country?.country?.name &&
              isInviteOnly && (
                <Autocomplete
                  multiple
                  options={segmentData?.sort(dynamicSort("name"))}
                  getOptionLabel={(option) => option?.name || ""}
                  sx={{ width: 300 }}
                  value={segment?.name}
                  onChange={(e, newVal) =>
                    onInputSegmentChange(e, newVal, "segment")
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={t('Segment')} required/>
                  )}
                />
              )}
          </MuiPickersUtilsProvider>
          {showLinkInput === "digital" ? (
            <>
      <FormControlLabel
            control={
              <Checkbox
                name="Agroa"
                checked={agoradata}
               // onChange={(e) => setagoradata(e.target.checked)}
                onChange={(e) => handlePostSubmit(e)}
                defaultChecked
                color="primary"
              />
              
            }
            label={t('Internal Link (100ms)')}
            labelPlacement="end"

            
          />
          {/* {agoradata && 
            agoradata  === true &&
            <TextField
            id="otherLink"
            label={t('Agroa Link')}
            name="AgoraLinks"
            value={AgoraLinks}
            placeholder="Agora Urls"
            onChange={(e) => setAgoraLinks(e.target.value)}
            required
           />
          } */}
             {/* {
              agoradata && 
              agoradata  === false &&
             } */}
              <TextField
                id="otherLink"
                label={t('Add_Link')}
                name="links"
                value={links}
                placeholder="Event Urls"
                onChange={(e) => setLinks(e.target.value)}
                required
                disabled
              />
              {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="first-division">
                    <TextField
                      label={t('Add_Other_Link')}
                      name="links"
                      type="text"
                      id="otherLink"
                      value={singleService.otherLink}
                      onChange={(e) => handleServiceChange(e, index)}
                      
                    />
                    {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_Other_Link')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('Remove')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}

              {/* <div className='editorSectionStart col-span-2'>
                             <Editor
                             placeholder='Add Other Links'
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                              />
                         </div> */}
            </>
          ) : showLinkInput === "hybrid" ? (
            <>

        <FormControlLabel
            control={
              <Checkbox
                name="Agroa"
                checked={agoradata}
               // onChange={(e) => setagoradata(e.target.checked)}
                onChange={(e) => handlePostSubmit(e)}
                defaultChecked
                color="primary"
              />
              
            }
            label={t('Internal Link (100ms)')}
            labelPlacement="end"

            
          />
              <TextField
                id="otherLink"
                label={t('Add_Link')}
                name="links"
                value={links}
                placeholder="Event Urls"
                onChange={(e) => setLinks(e.target.value)}
                required
                disabled
              />
              {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="first-division">
                    <TextField
                      label={t('Add_Other_Link')}
                      name="links"
                      type="text"
                      id="otherLink"
                      value={singleService.otherLink}
                      onChange={(e) => handleServiceChange(e, index)}
                      
                    />
                    {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_Other_Link')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('Remove')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}

              {/* <div className='editorSectionStart col-span-2'>
                             <Editor
                             placeholder='Add Other Links'
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                              />
                         </div> */}
                          <TextField
              id="address"
              label={t('Address')}
              name="address"
              value={allFieldData.address}
              placeholder="Event address"
              onChange={handleChange}
              required
            />
            </>
          ) :(
            <TextField
              id="address"
              label={t('Address')}
              name="address"
              value={allFieldData.address}
              placeholder="Event address"
              onChange={handleChange}
              required
            />
          )}
          <Autocomplete
            multiple
            id="sector"
            options={sectorData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            sx={{ width: 300 }}
            value={sector?.name}
            onChange={(e, newVal) => onInputSectorChange(e, newVal, "sector")}
            renderInput={(params) => <TextField {...params} label={t('Sector')} required />}
          />
          <TextareaAutosize
            name="description"
            id="eventDescription"
            aria-label="Empty"
            placeholder={t('Enter_Event_Description')}
            minRows={5}
            value={allFieldData.description}
            onChange={handleChange}
            required
          />
          {/* <Autocomplete
            id="sectors"
            options={CountryOPtion?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.value || ""}
            sx={{ width: 300 }}
            value={countrydata?.value}
            onChange={(e, newVal) => onInputSectorsChange(e, newVal, "Target")}
            renderInput={(params) => <TextField {...params} label="Target" />}
          /> */}
           <div className="flex items-center mt-5 customeCheckbox InviteOnlyCheckbox">
          <FormControlLabel
            control={
              <Checkbox
                name="ALL"
                checked={countryNotification}
                onChange={(e) => { 
                    setcountryNotification(e.target.checked)
                }}
                defaultChecked
                color="primary"
              />
            }
            label={t('Send_Push_Notification')}
            labelPlacement="end"
          />
        </div>
          <div className="d-flex">
            <div className="relative w-auto overflow-hidden">
              <TextareaAutosize
                id="profilephoto"
                label={t('Add_Photo')}
                value={(imgFile.name || result || "").slice(0,65)}
                className="w-full text-xs"
                placeholder={t('Add_Photo')}
                required
                minRows={3} 
              />
              <input
               disabled={disableButton}
                type="file"
                accept="image/*"
                className="z-50 opacity-0 absolute bottom-0 right-0 w-50 mt-5"
                name="image-file"
                onChange={(e) => handleImgFile(e.target.files[0])}
              />
              <div className="floating-box">+</div>
            </div>
            <div className="media-box mt-2">
              <img src="../../assets/photos.svg" alt="" />
              {t('Photo')}
            </div>
           {src ? "" :<p className="mt-4 text-sidenav">
              {" "}
              {t('Note_Imageshouldbe400x200px')}
            </p>} 
            
                    {
                      src &&
                      <div className="col-6 cropperclass">
                        {/* <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        /> */}
                        <Cropper
          // style={{ height: 400, width: "100%" }}
          // initialAspectRatio={2/1}
          // aspectRatio={2 / 1} 
          //preview=".img-preview"
          src={src}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          // minCropBoxHeight={10}
          // minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false}
        />
        <div className='Zoombtn'>
                        <button
                          variant="contained"
                          onClick={getCropData}
                          className='cropbutton'
                        >
                          Crop Image
                        </button>
                        <button
                          variant="contained"
                          onClick={getCropremove2}
                          className='cropbutton'
                        >
                          Remove Crop 
                        </button>
                        </div>
                      </div>
                    }
                
          </div>
          {/* <button onClick={handleCancelSelection}>Cancel Selection</button> */}
        </div>
        {
          showLinkInput ? 
          <div className="flex justify-center mt-10">
         <button
  className={`mx-auto ${loader ? "buttons-1" : (allFieldData?.address && allFieldData.name && allFieldData.organizer && allFieldData.description && start && end !== undefined || result !== null ? "button-1" : "buttons-1")}`}
  onClick={handleSubmit}
  disabled={
    loader 
      ? true 
      : !(allFieldData.address && allFieldData.name && allFieldData.organizer && allFieldData.description && start && end !== undefined)
  }
>
  {t('Submit')}
</button>
        </div> : 
        <div className="flex justify-center mt-10">
      <button
  className={`mx-auto ${loader ? "buttons-1" : (allFieldData?.address && allFieldData.name && allFieldData.organizer && allFieldData.description && start && end !== undefined || result !== null ? "button-1" : "buttons-1")}`}
  onClick={handleSubmit}
  disabled={
    loader 
      ? true 
      : !(allFieldData.address && allFieldData.name && allFieldData.organizer && allFieldData.description && start && end !== undefined)
  }
>
  {t('Submit')}
</button>
      </div>
        }
        
      </div>
    </>
  );
};

export default AddEventForm;
