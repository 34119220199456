// import UsersTable from '../Users/UsersTable';
import DirectoryTable from './DirectoryTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { InviteAllPeopleNotification, InviteNotification, userDeactivate,deleteDirectoryPostApi } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import EditDirectoryForm from '../ModalTemplate/EditDirectoryForm';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { getCountriesData } from '../Master/getRequests';

const AllDirectory = ({ data, getTablesData, adminData,Count }) => { 
  const [searchValue, setSearchValue] = useState("");
  const modalContext = useContext(ModalContext);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const { closeModal, handleModalData } = modalContext;
  const [countryData, setCountryData] = useState([]);
  const [selectfilter, setFilter] = useState([]);
  // useEffect(() => {
  //   if(searchValue == "") {
  //   getTablesData({
  //     query : ''

  //   });
  // }
  // }, [Count,searchValue])
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
  const handlePageChange = (event, value) => {
    setPage(value);
};
//   useEffect(() => {
//     if (Count && Count.totalCount > 0) {
//       setTotalPageToShow(Math.ceil((Count && Count.totalCount) / postsPerPage));
//   }
//     if (typeof searchValue === "string" && searchValue.match(/^\d+/)) {
//       let intValue = parseInt(searchValue, 10);
//       //console.log("intValue:", intValue);
//       // if (typeof searchValue === "string") {
//         if(intValue!= ''){
//         const timeoutId = setTimeout(() =>
//         getTablesData({
//               //  page: page,
//               //  size: postsPerPage,
//               number:intValue,
//         }), 500);
//       return () => clearTimeout(timeoutId);
//     }
//     // }
//     } else {
//       //console.log("searchValue is not a string starting with a number");
//       if(searchValue!= ''){
//         const timeoutId = setTimeout(() =>
//         getTablesData({
//               //  page: page,
//               //  size: postsPerPage,
//               query :searchValue,
//         }), 500);
//       return () => clearTimeout(timeoutId);
//       }
//     }
//     getTablesData({
//        page: page,
//        size: postsPerPage,
// })
//   }, [page,searchValue,Count && Count.totalCount])

useEffect(() => {
      if (Count && Count.totalCount > 0) {
      setTotalPageToShow(Math.ceil((Count && Count.totalCount) / postsPerPage));
  }
  const fetchData = () => {
    if (typeof searchValue === "string" && searchValue.match(/^\d+/)) {
      const intValue = parseInt(searchValue, 10);
      if (!isNaN(intValue)) {
        getTablesData({ number: intValue });
      }
    } else if (selectfilter?.Country?.name) {
      getTablesData({ country: selectfilter.Country.name ,page, size: postsPerPage});
    } else if (searchValue) {
      getTablesData({ query: searchValue });
    } else {
      getTablesData({ page, size: postsPerPage });
    }
  };

  const timeoutId = setTimeout(fetchData, 200);
  return () => clearTimeout(timeoutId);
}, [page, searchValue, Count?.totalCount, selectfilter?.Country?.name]);




  const history = useNavigate();
  const { t } = useTranslation();

  const EditPostDetail = (data) => { 
    const EDIT_PARTY = <EditDirectoryForm
      title={t('Edit_Directory')}
      buttonText={t('Save_Changes')}
      id={data._id}
      perticularData={data}
      getTablesData={getTablesData}
    />
    handleModalData(EDIT_PARTY);
  }
  const deletePostGallery = async (id) => {  
    try {
        const result = await deleteDirectoryPostApi({
                id: id
        });
        if (result.res.success) {
            // toast.success(result.res.message)
            toast.success("Directory removed successfully")
            getTablesData();
        }

    } catch (error) {
        toast.error(error.messages)
    }
}

const HandleRemoveEvent = (id) => {
  const REMOVE_CONFIRMATION = (
    <RemoveConfirmation
      title={t('Remove_Directory')}
      action={deletePostGallery}
      data={t('directoryRemoveText')}
      id={id}
    />
  );
  handleModalData(REMOVE_CONFIRMATION, "sm");
};

  // console.log(data,"userData")
  let AdminType = localStorage.getItem('AdminType');
  const userColumns = useMemo(
    
    () => [
    //   {
    //     Header: `${t('Status')}`,
    //     accessor: 'status', // accessor is the "key" in the data
    //     Cell: ({ cell: { value } }) => value ?
    //       <div className="rounded-full bg-green h-3 w-3"></div>
    //       :
    //       <div className="rounded-full bg-orange h-3 w-3"></div>,
    //   },
    //   {
    //     Header: `${t('Users')}`,
    //     accessor: userData => (  
         
    //       <div className="flex justify-start items-center">
    //         <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
    //         <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
    //       </div>
    //     ),
    //   },
      {
        Header: `${t('Organisation_Name')}`,
        accessor: data => { 
            return (
                <span>{data?.OrganisationName}</span>
            )
        }
    },
      {
        Header: `${t('Phone_No.')}`,
        accessor: data => { 
            return (
                <span>{data?.Phone_No}</span>
            )
        }
    },
      {
        Header: `${t('Category')}`,
        accessor: data => { 
            return (
                <span>{data?.category?.name}</span>
            )
        }
    },
      {
        Header: `${t('Country')}`,
        accessor: data => { 
            return (
                <span>{data?.country.name}</span>
            )
        }
    },
    
      {
        Header: `${t('Description')}`,
        accessor: data => { 
            return (
                <span>{data?.description}</span>
            )
        }
    },
    
    {
      Header: `${t('Delete')}`,
      accessor: AdminType === "Coordination team" ? "" : (data) => {
          return (
              <button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                  type="button"
                  key={data._id}
                  onClick={() => HandleRemoveEvent(data?._id)}
              >
                  <span className="MuiButton-label">{t('Delete')}</span>
              </button>
          )
      },
  },
  {
    Header: `${t('Edit')}`,
    accessor: AdminType === "Coordination team" ? "" : (data) => { 
        return (
            <button
                className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                type="button"
                key={data.id}
                onClick={() => EditPostDetail(data)}
            >
                <span className="MuiButton-label">{t('Edit')}</span>
            </button>
        )
    },
},
  // {
  //   Header: `${t("Action")}`,
  //   accessor: "_id",
  //   Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
  // },
    //   {
    //     Header: 'Name',
    //     accessor: data => {  
    //         return (
    //             <>
    //                 {data?.directoryDetails?.map(item => { 
    //                     return (
    //                         <p>{`${item?.OrganisationName} ,`}</p>
    //                     )
    //                 })}
    //             </>
    //         )
    //     }
    // },
      
     
      
      
      
     
      
    
    
    //   {
    //     Header: `${t("Action")}`,
    //     accessor: "_id",
    //     Cell: props => <Button className="btn2" onClick={() => editHandler(props)}> Edit </Button>
    //   },
      // {
      //   Header: `${t("Send_SMS")}`,
      //   accessor: "",
      //   Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Send </Button>
      // },
    //   {
    //     Header: `${t("Status")}`,
    //     accessor: "deactivated",
    //     Cell: props => <Grid item>
    //       <AntSwitch
    //         checked={!props.row.original.deactivated}
    //         onChange={() => handleDeactivate(props)}
    //         name="isDirectory"
    //       />
    //     </Grid>
    //   }
    ], [])
    const onInputCategoryChangeselect = (event, newInputValue, name) => {
      setFilter({
        ...selectfilter,
        [name]: newInputValue,
      });
    };
 
    const getCountryApiData = async () => {
      try {
        const result = await getCountriesData();
        // console.log(result, "Result627");
        setCountryData(result);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getCountryApiData();
    }, []);
  return (
    <>
      <div className="flex justify-between items-center">
      <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
                 <div className="bg-white p-2 flex items-center search-wrapper">
     
     <span>
       <img src="/assets/icons/search.svg" />
     </span>
     <input
       type="text"
       value={searchValue}
       placeholder="Search"
       className="w-96 mx-3"
       onChange={(e) => setSearchValue(e.target.value)}
     />
     <span>
       <img src="/assets/icons/submit-arrow.svg" />
     </span>
   </div>
                  <div className="flex justify-between flex-wrap items-center">
        
                  <Autocomplete
            options={countryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={selectfilter?.name}
            className="w-56 mr-5"
            onChange={(e, newVal) =>
              onInputCategoryChangeselect(e, newVal, "Country")
            }
            renderInput={(params) => (
              <TextField {...params} label={t("Filter_By_Country")} />
            )}
          />
        </div>
       
      </div>
      {
        data && <DirectoryTable columns={userColumns} data={data} 
         
        />
      }
    </>
  )
}

export default AllDirectory;