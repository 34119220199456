import styles from "../../Assets/Comment.module.css";
import React from 'react'
import {
  addCommentForum,
  BASE_URL_IMG,
  deleteCommentForum,
  deleteReplyCommentForum,
  getAdminDetails,
} from "../../Utils/services";
import avtarImg from "../../Assets/images/DummyDp.png";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FunctionContext } from "../../Context";
import { useEffect } from "react";
import moment from "moment/moment";
import InputText from "./InputText";
import { useTranslation } from "react-i18next";
import Commentdatabox from "./Commentdatabox";
const TRANSLATE_URL =`https://translation.googleapis.com/language/translate/v2?key=AIzaSyC2SUlb9pljbtvOW5w_iqJK0GM_DVCzLGk`;

const CommentBox = ({commentBoxDataProps, singlePostData, postId }) => { 
  const { postData, getForumsDataApi,FouramData, getForumsDataApis } = useContext(FunctionContext);
  const [showReplyInput, setShowReplyInput] = useState({});
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [showPerticularData, setShowPerticularData] = useState(singlePostData.reverse());
  const {t} = useTranslation();
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    try {
      const result = await addCommentForum({
        threadId: postId,
        comment: comment,
      });
      if (result.res.success) {
        toast.success(result.res.message);
        setComment("");
        getForumsDataApi();
        getForumsDataApis();
      }
      // console.log(result);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    // let _result = FouramData?.comments?.filter((items) => { 
    //   return items?._id === postId;
    // });
    
    // setShowPerticularData(_result && _result[0]?.comments);
    setShowPerticularData(FouramData?.comments.reverse());
    let createdAt = moment(moment.now()).fromNow(); 
  });

  useEffect(() => {
    getForumsDataApis();
    // getAdminDetailsApiData();
  }, [])





  return (
    <>
      {showPerticularData &&
        showPerticularData.map((data,index) => {  
          return (
            <React.Fragment key={index}>
               <Commentdatabox data={data} postId={postId} commentBoxDataProps={commentBoxDataProps}/>
           </React.Fragment>
          );
        })}
      <div className="grid grid-cols-8 my-6">
        <div className="col-span-1 flex justify-center items-center">
          <img src={avtarImg} className=" w-12 rounded-full" alt="" />
        </div>
        <div className="col-span-5">
          <form
            onSubmit={handleSubmitComment}
            className="flex justify-center items-center"
          >
            <input
              type="text"
              name="comment"
              className="commentSectionInput"
              placeholder={t("Comments")}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CommentBox;
