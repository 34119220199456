import { useContext, useEffect, useMemo } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { ModalContext } from '../../Context';
import { useState } from 'react';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { BASE_URL_IMG, deleteStoryPostApi,getStoriesCommentData,StoryStatusApi } from '../../Utils/services';
import StoryTable from './StoryTable';
import { useNavigate, useMatch } from 'react-router-dom';
import EditStory from '../ModalTemplate/EditStory';
import { toast } from 'react-toastify';
import { Button, Grid, TextField } from '@material-ui/core';
import AntSwitch from '../Common/AntSwitch'
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';


const Storypage = ({ data, getStoryDataApi, totalCount,adminData }) => {
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [totalPageToShow, setTotalPageToShow] = useState(1);
    const history = useNavigate();
    // let { path } = useMatch();

    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
    const { t } = useTranslation();
    const context = useContext(ModalContext)
    const { handleModalData } = context;

 

    useEffect(() => {
        if (totalCount && totalCount > 0) {
            setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
        }
        console.log(page,"page")
        if (page !== undefined) {
            getStoryDataApi({ page, postsPerPage });
        }
    }, [page, totalCount]);

    // const showPostDetail = item => {
    //     history.push({
    //         pathname: `${path}/${item._id}`,
    //         state: {
    //             data: item,
    //             title: 'Story Details'
    //         }
    //     })
    // }
    const showPostDetail = async (item) => {  
        localStorage.setItem('items', item?._id);
        try {
            const result = await getStoriesCommentData({
                id: item._id
            });
            if (result.success) {
                // toast.success(result.res.message)
                // getGalleryDataApi({ page, postsPerPage });
                 history(`${item._id}`,{
          
            state: {
                data: result?.data[0],
                title: 'Story Details',
                count: totalCount,
                mediadata:item,

            }
        })
            }

        } catch (error) {
            toast.error(error.messages)
        }
       
    }
    const deletePostGallery = async (id) => {
        try {
            const result = await deleteStoryPostApi({
                id: id
            });
            if (result.res.success) {
                // toast.success(result.res.message)
                toast.success("Story Deleted Successfully")
                getStoryDataApi({ page, postsPerPage });
            }

        } catch (error) {
            toast.error(error.messages)
        }
    }
  
    const HandleRemoveEvent = (id) => {
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('Remove_Story')}
            action={deletePostGallery}
            data={t('StoryRemoveText')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };


    const EditPostDetail = (item) => { 
        const Edit_MODAL = <EditStory
            forumData={item}
            getStoryDataApi={getStoryDataApi}
            isModalView={true}
            title={t('Edit_Story')}
        />
        handleModalData(Edit_MODAL)
    }

    const handleActiveDeactive = async (props) => { 
        const id = props.row.original._id;
        try {
          const result = await StoryStatusApi(id);
          if (result.res.success) {
            toast.success(result.res.message)
            getStoryDataApi();
          }
        } catch (error) {
          toast.error(error.message)
        }
      }

    const FeedsColumns = useMemo(
        () => [
            {
                Header: `${t('Featured')}`,
                accessor: 'isfeatured', // accessor is the "key" in the data
                Cell: ({ cell: { value } }) => value ?
                  <div className="rounded-full bg-green h-3 w-3"></div>
                  :
                  <div className="rounded-full bg-orange h-3 w-3"></div>,
              },
            {
                Header: `${t('Post_Photo')}`,
                accessor: data => { 
                    return (
                        <>
                           {data?.media !== null ? <img src={`${BASE_URL_IMG}${data && data?.media[0]?.link}`} alt="Post Image" className='w-28' /> : null} 
                        </>
                    )
                }
            },
            {
                Header: `${t('Title')}`,
                accessor: data => {
                    return (
                        <>
                            <h4 className='w-60 text-md'>{data?.title}</h4>
                        </>
                    )
                }
            },
            {
                Header: `${t('Country')}`,
                accessor: data => {
                    return (
                        <>
                            {data?.countries?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t('Tags')}`,
                accessor: data => {
                    return (
                        <>
                            {data?.tags?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t('Posted_On')}`,
                accessor: data => {
                    return (
                        <span>{formatDate(data?.date)}</span>
                    )
                }
            },
            {
                Header: `${t('Likes')}`,
                accessor: data => {
                    return (
                        <span>{data?.likes?.length}</span>
                    )
                }
            },
            {
                Header: `${t('Comments')}`,
                accessor: data => {
                    return (
                        <span>{data?.comments?.length}</span>
                    )
                }
            },
            // {
            //     Header: `${t('Views')}`,
            //     accessor: data => {
            //         return (
            //             <span>{data?.views}</span>
            //         )
            //     }
            // },
            {
                Header: `${t('Views')}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => showPostDetail(data)}
                        >
                            <span className="MuiButton-label">{t('View')}</span>
                        </button>
                    )
                },
            },
            {
                Header: `${t('Edit')}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => { 
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => EditPostDetail(data)}
                        >
                            <span className="MuiButton-label">{t('Edit')}</span>
                        </button>
                    )
                },
            },
            {
                Header: `${t('Delete')}`,
                accessor: adminData[0]?.AdminType === "Coordination team" ? "" : (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => HandleRemoveEvent(data?._id)}
                        >
                            <span className="MuiButton-label">{t('Delete')}</span>
                        </button>
                    )
                },
            },
            {
                Header: `${t('Status')}`,
                accessor: "deactivated",
                Cell: adminData[0]?.AdminType === "Coordination team" ? "" : props => { 
                  return (
                    <Grid item>
                      <AntSwitch
                        checked={props.row.original.status}
                        onChange={() => handleActiveDeactive(props)}
                        name="isDirectory"
                      />
                    </Grid>
                  )
                }
              }
        ], [adminData])

        const handlePageChange = (event, value) => {
            setPage(value);
        };

    return (
        <>
            <div className="flex justify-between items-center">
                <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
            </div>
            {data &&
                <StoryTable columns={FeedsColumns} data={data} />
            }

        </>
    )
}

export default Storypage;