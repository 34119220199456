import React, { useState, useContext, useEffect } from 'react';
import { DIRECTORY_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import AllDirectory from './AllDirectory';
import AuthContext from '../../Utils/auth-context';
import {  allAdminUsers, allDirectories, getCategoryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import AdminForm from '../ModalTemplate/AdminForm';
import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import DirectoryForm from '../ModalTemplate/DirectoryForm';
import NewRegistration from '../Users/NewRegistration';
import CategoryList from './CategoryList';
import CategoryFormAdd from '../ModalTemplate/CategoryFormAdd';


const a11yProps = (index) => {
  // console.log(index, "index, ")
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const Directory = ({match}) => {

  const {t} = useTranslation();

  const [tabsValue, setTabsValue] = useState(0);
  const [userData, setUserData] = useState([]);
  const context = useContext(ModalContext)
  const authCtx = useContext(AuthContext);
  const { handleModalData } = context;
  const [loader, setLoader] = useState(true);
  const [newRegistrationData, setNewRegistrationData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [Count, setCount] = useState();

  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
    var newurl = window.location.href.split('?')[0] + "?id=" + newValue;
    window.history.pushState({ path: newurl }, '', newurl);

  };
  function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(window.location.href);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
useEffect(()=>{
    var idValue = getParameterByName('id');
    if(idValue){
        setTabsValue(parseInt(idValue))
    }
},[])

  const getTablesData = async (sendData) => { 
    try {
      const result = await allDirectories(sendData);
      // console.log(result.res, "result direc");
      getAdminTableData()
      console.log(result.res?.data,"result.res?.data")
      const sortedData = result.res?.data.sort((a, b) => {
        const countryA = a.country.name.toUpperCase(); // Ignore case
        const countryB = b.country.name.toUpperCase(); // Ignore case
        if (countryA < countryB) {
          return -1;
        }
        if (countryA > countryB) {
          return 1;
        }
        return 0;
      });
      console.log(sortedData,"sortedData")
      setUserData(sortedData);
      setCount(result?.res?.pagination)
      setLoader(false)
      if (result.res > 0) {
     
        setLoader(false)
      
      }
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }
  const getcategoryData = async () => {
    try {
      const result = await getCategoryList();
      // console.log(result.res, "result direc");
      setNewRegistrationData(result?.res?.data);
      setLoader(false)
      if (result.res > 0) {
        setLoader(false)
      
      }
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getTablesData();
    getAdminTableData();
  }, [])
  
  const tabsData = [
    {
      label: `${t("directory")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <AllDirectory data={userData} Count={Count} getTablesData={getTablesData} adminData={adminData} />
        </>
      )
    },
    {
        label: `${t("Category")}`,
        isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
        data: (
          <>
            <Loader loader={loader} />
            <CategoryList data={newRegistrationData} getcategoryData={getcategoryData} adminData={adminData} />
          </>
        )
      },
   
  ]

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(<DirectoryForm getTablesData={getTablesData} title="add new user" buttonText="add directory" />);
        break;
        case 1:
          handleModalData(<CategoryFormAdd refreshDataMethod={getcategoryData} />);
          break;
      default:
        handleModalData(DIRECTORY_FORM);
        break;
    }
  }
  // console.log(tabsData, "tabsData")

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            className="px-10 homemainresponsive"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
}


export default Directory;

