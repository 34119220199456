import Table from '../../Common/Table';
import { useMemo } from 'react';
import { Button } from "@material-ui/core";
import { useContext } from 'react';
import { ModalContext } from '../../../Context'
import AddTags from '../../ModalTemplate/AddTags';
import AddSegments from '../../ModalTemplate/AddSegments';
import { useTranslation } from 'react-i18next';
const Segments = ({ segments, geSegmentApiData, adminData }) => {
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const editHandler = (data) => {
        const EDIT_SEGMENT = <AddSegments
            title={t('Edit_Segment')}
            id={data.cell.row.original._id}
            name={data.cell.row.original.name}
            countryName={data.cell.row.original.country}
            geSegmentApiData ={geSegmentApiData}
            btnText={t('SAVE')}
            edit
        />
        handleModalData(EDIT_SEGMENT, 'md')
    }

    const countryColumns = useMemo(
        () => [
            {
                Header: `${t('Segment')}`,
                accessor: 'name',
                Cell: ({ cell: { value } }) => <span className="capitalize pl-0">{value}</span>
            },
            {
                Header: `${t('Country')}`,
                accessor: 'country',
                Cell: ({ cell: { value } }) => <span className="capitalize pl-0">{value?.name}</span>
            },
            {
                Header: `${t('Added_On')}`,
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                id: "view",
                Header: `${t('Action')}`,
                accessor: "_id",
                Cell: adminData[0]?.AdminType === "Coordination team" ? "" : props => <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')}  </Button>,
            }
        ],
        []
    )

    return (
        <>
            {
                segments &&
                <Table columns={countryColumns} 
                data={segments} 
                />
            }
        </>
    )
}

export default Segments