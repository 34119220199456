import { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { addCountry, BASE_URL_IMG, deleteCountry, editCountry, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import { getCountriesData } from "../Master/getRequests"
import RemoveConfirmation from "./RemoveConfirmation"
import modalTextsFile from "./modalTextsFile"
import { Button } from "@material-ui/core";
import Loader from "../Common/Loader"
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const AddCountry = ({
    title,
    setCountryData,
    btnText,
    edit,
    id,
    name,
    region,
    image
}) => {
    const [fileName, setFileName] = useState('');
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState({
        country: name || "",
        flag: image || "",
        region: region || ""
    });
    const [regionVal, setRegionVal] = useState([]);
    const [regions, setRegions] = useState([]);
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const getCountryData = async () => {
        try {
            const result = await regionCountryList();
            if (result.res.data.region.length > 0) {
                setRegions(result.res.data.region.sort(dynamicSort("name")));
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getCountryData();
    }, []);

    const handleRegionData = (e, newInputValue, name) => {
        const _name = e.target.name
        const _value = e.target.value
        if (name == 'regionVal') {
            setRegionVal({
                ...regionVal,
                [name]: newInputValue
            })
        }
        else {
            setRegionVal({
                ...regionVal,
                [_name]: _value
            })
        }
    }


    const context = useContext(ModalContext);
    const { closeModal, handleModalData } = context;


    const delRequest = async (id) => {
        let data = {
            "id": id
        }
        try {
            const result = await deleteCountry(data);

            if (result.res.success) {
                const _data = await getCountriesData();
                setCountryData(_data);
                toast.success("Expertise Deleted!");
                closeModal();
            }
        }
        catch (e) {
            console.log(e);
            toast.error(e.message);
        }
    }

    const REMOVE_CONFIRMATION = <RemoveConfirmation
        title={t('REMOVE_COUNTRY')}
        data={t('countryRemoveText')}
        action={delRequest}
        id={id}
    />


    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);
            img.onload = () => {

                if (img.width === 210 && img.height === 140) {
                    setFileName(val.name);
                    setData({ ...data, "flag": val })
                }
                else {
                    setFileName("");
                    setData({ ...data, "flag": "" })
                }
            }
        }
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setData({ ...data, [name]: value })
    }



    const handleAdd = async () => {
        setLoader(true);
        const formData = new FormData();
        formData.append("name", data.country);
        // formData.append("region", data.region);
        formData.append("region", regionVal && regionVal.regionVal.name);
        formData.append("media", data.flag);
        try {
            const result = await addCountry(formData);
            if (result.res.success) {
                toast.success(result.res.message);
                const _data = await getCountriesData();
                setCountryData(_data);
                setLoader(false)
                closeModal();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    const handleEdit = async () => {
        setLoader(true);
        const formData = new FormData();
        formData.append("id", id);
        formData.append("name", data.country);
        // formData.append("region", data.region);
        formData.append("region", regionVal.length === 0 ? region : regionVal && regionVal.regionVal.name);
        // if (typeof image !== "string") {
        formData.append("media", data.flag);
        // }
        try {
            const result = await editCountry(formData);
            if (result.res.success) {
                toast.success(result.res.message);
                const _data = await getCountriesData();
                setCountryData(_data);
                setLoader(false);
                closeModal();
            }
        }
        catch (e) {
            setLoader(false);
            console.log(e);
            toast.error(e.messages);
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" >
                <div className="flex flex-col w-full">
                    <div className="mb-4">
                        <TextField id="name" label={t('Country_Name')} className="w-full" name="country" onChange={(e) => handleChange(e)} value={data.country} />
                    </div>

                    <div className="mb-4">

                        <div className="relative w-auto overflow-hidden">
                            {
                                edit && typeof data.flag === "string" &&
                                <img src={`${BASE_URL_IMG}${data.flag}`} className="mb-1" style={{ maxWidth: "50px" }} />
                            }
                            <TextField
                                id="profilephoto"
                                label={t('Add_Country_Flag')}
                                value={fileName}
                                className="w-full text-xs relative"

                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="flag"
                                // accept="image/png, image/jpeg"
                                onChange={(e) => handleFile(e.target.files[0])}

                            />
                            <div className="floating-box" >+</div>
                        </div>
                        <p className="text-sm text-red-800">{t('Imagedimensionsshouldbe210x140px')}</p>
                    </div>

                    <div className="mb-4">
                        {/* <TextField id="name" label="Region" value={data.region} className="w-full" name="region" onChange={(e) => handleChange(e)} /> */}
                        <Autocomplete
                            options={regions}
                            name ="regionVal"
                            defaultValue={{name : region, _id : 1}}
                            getOptionLabel={(option) => option.name}
                            sx={{ width: 300 }}
                            value={regionVal.name}
                            onChange={(e, newVal) => handleRegionData(e, newVal, 'regionVal')}
                            renderInput={(params) => <TextField {...params} label={t('Region')} />}
                        />
                    </div>
                </div>


                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="button" disabled={loader ? true : false} onClick={edit ? handleEdit : handleAdd}>
                        {btnText}
                    </button>
                </div>

                {
                    edit && (
                        <div className="flex justify-center mt-5">
                            <Button
                                variant="outlined"
                                color="secondary"
                                className="approve_decline_padding"
                                onClick={() => handleModalData(REMOVE_CONFIRMATION, 'sm')}
                            >
                                <img src="/assets/icons/falseicon.svg" alt="" />{" "}
                                <span className="pl-1">{t('Remove')}</span>
                            </Button>
                        </div>
                    )
                }
            </form>
        </>
    )
}

export default AddCountry;