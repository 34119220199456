import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { regionCountryList, getCategoryList, addDirectory, getSectorList, getSegmentApi, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import AntSwitch from '../Common/AntSwitch';
import { useTranslation } from 'react-i18next';


const DirectoryForm = ({
    getTablesData
}) => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [languageList, setLanguageList] = useState([]);
    const [languageLists, setLanguageLists] = useState([]);
    const [language, setLanguage] = useState("");
    const [formData, setFormData] = useState({});
    const [gender, setGender] = useState("Male");
    const [loader, setLoader] = useState(false);
    const [isApprove, setIsApprove] = useState(false)
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const { t } = useTranslation();
    useEffect(() => {
        getAdminDetailsApiData();
        // getLanguageListData();
        getSectorListData();
        getSegmentData();
    }, [])




    const getSectorListData = async () => {
        try {
            const result = await getSectorList();
            setSectorData(result.res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => { 
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const getSegmentData = async () => {
        try {
            if (country?.country?._id) {
                const result = await getSegmentApi();
               let _resultData = result?.res?.data?.filter((data) => {
                return data?.country?.name === country?.country?.name
               })
                setSegmentData(_resultData)
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, [country]);
    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }
    const onInputSectorChange = (event, newInputValue, name) => {
        setSector({
            ...sector,
            [name]: newInputValue
        })
    }
    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }

    const handleApprove = (e) => {
        setIsApprove(e.target.checked)
    }
    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }
    const onGenderChangeHandler = e => {
        e.preventDefault()
        const value = e.target.value
        setGender(value)
    }
    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    // const getLanguageListData = async () => {
    //     try {
    //         const result = await getCategoryList();
    //         setLanguageList(result?.res?.data)
            
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const getLanguageListData = async () => {
        try {
            if (country?.country?._id) {
                const result = await getCategoryList();
               let _resultData = result?.res?.data?.filter((data) => {
                
                return data?.country?.name === country?.country?.name
               })
               setLanguageList(_resultData)
            }
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getLanguageListData();
    }, [country]);


    const handleSubmit = async (e) => { 
        console.log(formData, "formData")
        e.preventDefault();
        setLoader(true);
        try {
            const results = await addDirectory({
                "OrganisationName": formData.name,
                "description": formData.aliasName,
                "category": language?.Category?._id,
                "Phone_No": formData.mobile,
                "country": country?.country?._id,
              
            });
            
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("Directory added successfully");
                setLoader(false)
                closeModal()
                getTablesData();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
console.log(language?.Category?.name,"country")
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title">{t('add_directory')}  </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Organisation_Name')}
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <TextField
                        label={t('Description_Name')}
                        name="aliasName"
                        value={formData.aliasName || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    {/* <TextField
                        label="Age"
                        name="age"
                        value={formData.age || ''}
                        onChange={e => onChangeHandler(e)}
                    /> */}
                    <TextField
                        label={t('Mobile_No.')}
                        name="mobile"
                        value={formData.mobile || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                 
                    <Autocomplete
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                       <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'Category')}
                        renderInput={(params) => <TextField {...params} label={t('Category')} />}
                    />
                    {/* {
                        country?.country?.name &&
                        <Autocomplete
                            id="combo-box-demo"
                            options={segmentData?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name || ""}
                            value={segment?.name}
                            onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                            renderInput={(params) => <TextField {...params} label="Segment" />}
                        />
                    } */}
                    {/* <Autocomplete
                        id="sector"
                        options={sectorData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={sector?.name}
                        onChange={(e, newVal) => onInputSectorChange(e, newVal, 'sector')}
                        renderInput={(params) => <TextField {...params} label="Sector" />}
                    /> */}
                    {/* <div className="mt-4 w-6/12">
                        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">Gender</label>
                        <RadioGroup aria-label="gender" name="gender" className="radio-group" defaultValue={gender} onChange={e => onGenderChangeHandler(e)}>
                            <FormControlLabel value="Male" control={<Radio />} label="Male" />
                            <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            <FormControlLabel value="Other" control={<Radio />} label="Other" />
                        </RadioGroup>
                    </div> */}
                </div>
                {/* <div className="mt-4 w-6/12 flex items-center">
                    <span className='text-minihubPara'>Approved</span>
                    <AntSwitch
                        checked={isApprove}
                        name="isApproved"
                        className='ml-5'
                        onChange={handleApprove}
                    />
                </div> */}
                <div className="flex justify-center mt-10">
                    <button 
                    className={`mx-auto ${ formData.name !== "" &&  formData.aliasName !== "" && formData.mobile !== "" && country?.country?.name !== undefined && language?.Category?.name !== undefined  ? "button-1" : "buttons-1"}`} 
                    type="submit"
                     disabled={formData.name !== "" &&  formData.aliasName !== "" && formData.mobile !== "" && country?.country?.name !== undefined && language?.Category?.name !== undefined ? false : true }
                     
                     >
                    {t('submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default DirectoryForm;