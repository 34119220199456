import { useEffect, useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ModalContext } from '../../Context'
import { toast } from "react-toastify";
import Loader from "../Common/Loader"
import { TextareaAutosize } from '@material-ui/core';
import { getCountriesData, getPartyData, getRegionData } from '../Master/getRequests';
import { addGeneralNotification, BASE_URL_IMG, getAdminDetails, getOraganizationApi, getThinktankApi } from '../../Utils/services';
import { useTranslation } from 'react-i18next';
const targetGroupsData = [
    {
        label: "All",
        value: "All"
    },
    // {
    //     label: "Region",
    //     value: "Region"
    // },
    {
        label: "Country",
        value: "Country"
    },
    // {
    //     label: "Party",
    //     value: "Party"
    // },
    // {
    //     label: "Organization",
    //     value: "Organization"
    // },
    // {
    //     label: "Thinktank",
    //     value: "Thinktank"
    // },
]

const AddGeneralNotificationForm = ({
    title,
    buttonText,
    getTablesData
}) => {
    const [loader, setLoader] = useState(false);
    const [allFieldsData, setAllFieldsData] = useState({
        title: '',
        description: '',
        groups: [],
        file: '',
        region: [],
        country: [],
        party: [],
        organization : [],
        thinktank : []
    })
    const [fileName, setFileName] = useState('');
    const [RegionData, setRegionData] = useState([]);
    const [CountryData, setCountryData] = useState([]);
    const [PartyData, setPartyData] = useState([]);
    const [OrganizationData, setOrganizationData] = useState([]);
    const [ThinktankData, setThinktankData] = useState([]);
    const context = useContext(ModalContext);
    const { closeModal } = context;

    const { t } = useTranslation();

    const getRegionDataApi = async () => {
        try {
            const result = await getRegionData();
            setRegionData(result)
        } catch (error) {
            console.log(error)
        }
    }
    // const getPartyDataApi = async () => {
    //     try {
    //         const result = await getPartyData();
    //         setPartyData(result)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // const getCountryDataApi = async () => {
    //     try {
    //         const result = await getCountriesData();
    //         setCountryData(result)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountryData(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    // const getThinkTankData = async () => {
    //     try {
    //         const result = await getThinktankApi();
    //         setThinktankData(result.res.data.thinktank)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // const getOrganitionData = async () => {
    //     try {
    //         const result = await getOraganizationApi();
    //         setOrganizationData(result.res.data.organization)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    useEffect(() => {
        getRegionDataApi();
        // getPartyDataApi();
        getAdminDetailsApiData();
        // getThinkTankData();
        // getOrganitionData();
    }, []);

    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);
            setFileName(val.name);
            setAllFieldsData({
                ...allFieldsData,
                file: val
            })
        }
    }

    const onInputChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'groups') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else if (name == 'region') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else if (name == 'country') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else if (name == 'party') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else if (name == 'organization') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else if (name == 'thinktank') {
            setAllFieldsData({
                ...allFieldsData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldsData({
                ...allFieldsData,
                [_name]: _value
            })
        }
    }

    const formSubmit = async (e) => {
        setLoader(true);
        e.preventDefault()


        try {
            const formData = new FormData();
            if(allFieldsData.party && allFieldsData.party.length > 0){
                const _partyDataToSend = allFieldsData.party.map((item)=>{
                    return item._id
                }) 
                formData.append("party" , _partyDataToSend)
            }
            if(allFieldsData.region && allFieldsData.region.length > 0){
                const _regionDataToSend = allFieldsData.region.map((item)=>{
                    return item.name
                }) 
                formData.append("region" , _regionDataToSend)
            }
            if(allFieldsData.country && allFieldsData.country.length > 0){
                const _countryDataToSend = allFieldsData.country.map((item)=>{
                    return item.name
                }) 
                formData.append("country" , _countryDataToSend)
            }
            if(allFieldsData.organization && allFieldsData.organization.length > 0){
                const _organizationDataToSend = allFieldsData.organization.map((item)=>{
                    return item._id
                }) 
                formData.append("organization" , _organizationDataToSend)
            }
            if(allFieldsData.thinktank && allFieldsData.thinktank.length > 0){
                const _thinktankDataToSend = allFieldsData.thinktank.map((item)=>{
                    return item._id
                }) 
                formData.append("thinktank" , _thinktankDataToSend)
            }

            formData.append("title", allFieldsData.title);
            formData.append("description", allFieldsData.description);
            formData.append("target", allFieldsData.groups.value && allFieldsData.groups.value);
            formData.append("media", allFieldsData.file);
            const result = await addGeneralNotification(formData);
            if(result.res.success){
                setLoader(false)
                // toast.success(result.res.message)
                toast.success("Notification sent")
                closeModal()
                getTablesData({
                    page : 1,
                    size : 10
                });
            }
        } catch (error) {
            setLoader(false)
            toast.error(error)
        }
    }
    console.log(allFieldsData?.groups?.label,"allFieldsData.groups")

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title">{t('GENERAL_NOTIFICATION')} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full">
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Title')}
                        name="title"
                        onChange={onInputChange}
                        value={allFieldsData.title || ''}
                    />
                    <TextareaAutosize
                        name="description"
                        label={t('Enter_Description')}
                        aria-label="minimum height"
                        value={allFieldsData.description}
                        onChange={onInputChange}
                        placeholder='Description'
                        minRows={3}
                    />

                    <div className="mb-4">
                        <div className="relative w-auto overflow-hidden">
                            {/* {
                                typeof allFieldsData.file === "string" &&
                                <img src={`${BASE_URL_IMG}${allFieldsData.file}`} className="mb-1" style={{ maxWidth: "100px" }} />
                            } */}
                            <TextField
                                id="profilephoto"
                                label={t('Add_a_Notification_Image')}
                                value={fileName || ''}
                                className="w-full text-xs relative"
                            />

                            <input
                                type="file"
                                accept="image/*"
                                className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                name="profile-photo"
                                onChange={(e) => handleFile(e.target.files[0])}
                            />
                            <div className="floating-box" >+</div>
                        </div>
                    </div>
                    <Autocomplete
                        name="groups"
                        options={targetGroupsData}
                        getOptionLabel={(option) => option.label}
                        value={allFieldsData.groups}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'groups')}
                        renderInput={(params) => <TextField {...params} id="groups" label={t('Target_Groups')} />}
                    />
                    {
                        allFieldsData.groups && allFieldsData.groups.value && allFieldsData.groups.value === "Region" && <Autocomplete
                        multiple
                        limitTags={2}
                        name="region"
                        options={RegionData}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.region}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'region')}
                        renderInput={(params) => <TextField {...params} id="region" label={t('Select_Region')} />}
                    />
                    }
                    {
                        allFieldsData.groups && allFieldsData.groups.value && allFieldsData.groups.value === "Country" && <Autocomplete
                        multiple
                        limitTags={2}
                        name="country"
                        options={CountryData}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.country}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} id="country" label={t('Country')} />}
                    />
                    }
                    {
                        allFieldsData.groups && allFieldsData.groups.value && allFieldsData.groups.value === "Party" && <Autocomplete
                        multiple
                        limitTags={2}
                        name="party"
                        options={PartyData}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.party}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'party')}
                        renderInput={(params) => <TextField {...params} id="party" label={t('Select_Party')} />}
                    />
                    }
                    {
                        allFieldsData.groups && allFieldsData.groups.value && allFieldsData.groups.value === "Organization" && <Autocomplete
                        multiple
                        limitTags={2}
                        name="organization"
                        options={OrganizationData}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.organization}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'organization')}
                        renderInput={(params) => <TextField {...params} id="organization" label={t('Select_Organization')} />}
                    />
                    }
                    {
                        allFieldsData.groups && allFieldsData.groups.value && allFieldsData.groups.value === "Thinktank" && <Autocomplete
                        multiple
                        limitTags={2}
                        name="thinktank"
                        options={ThinktankData}
                        getOptionLabel={(option) => option.name}
                        value={allFieldsData.thinktank}
                        onChange={(e, newVal) => onInputChange(e, newVal, 'thinktank')}
                        renderInput={(params) => <TextField {...params} id="thinktank" label={t('Select_Thinktank')} />}
                    />
                    }
                </div>

                <div className="flex justify-center mt-10">
                    <button 
                    // className="button-1 mx-auto" 
                    className={`mx-auto ${ allFieldsData.title !== "" && allFieldsData.description !== "" && fileName !== "" && allFieldsData?.groups?.label !== undefined ? "button-1" : "buttons-1"}`} 
                    onClick={formSubmit} 
                    disabled={allFieldsData.title !== "" && allFieldsData.description !== "" && fileName !== "" && allFieldsData?.groups?.label !== undefined ? false : true}
                    
                    >
                        {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default AddGeneralNotificationForm;