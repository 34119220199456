import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {  } from "../../Utils/services";
import { useTranslation } from "react-i18next";
// import { Globalinformation } from "../../Common/MainSectionProvider";

const PollingCard = ({ choices, _id, formservicedata, length, item,click }) => {
  const [getPercen, setPercen] = useState({});
  const [vote, setTotalVote] = useState();
  const [item1, setitem] = useState(item);
  const [showPercent, setShowPercent] = useState(true);
  const [postId, setPostId] = useState(null);
  const [question, setQustion] = useState(item?.question);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [choicesData, setChoicesData] = useState([]);

 // const { translateLanguage } = useContext(Globalinformation);
  const userid = localStorage.getItem("adminId");
  const { t } = useTranslation(["home"]);
  const TRANSLATE_URL = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyC2SUlb9pljbtvOW5w_iqJK0GM_DVCzLGk`;

//   const handleVoteGive = async (choiceId) => {
//     if(click){
//       setPostId(choiceId)
//       handleShowPercentage(choiceId);
//       try {
//         const result = await userPoll({
//           post: _id,
//           choice: choiceId,
//         });
//         //console.log(result.res.data.choices);
//         setChoicesData(result.res.data.choices);
//         if (result.res.success) {
//           handlepercentage();
//           toast.success(result.res.message);
//           formservicedata()
//         }
//       } catch (error) {
//         toast.error(error.message);
//       }
//     }
//   };

  const handlepercentage = () => { 
    let totalVoted = 0;
    let obj = {};
    choices &&
      choices.map((item) => {
        return (totalVoted = item.score + totalVoted);
       
      });
    choices &&
      choices.map((item) => {
        if (totalVoted > 0) {
          obj = {
            ...obj,
            [item._id]: ((item.score / totalVoted) * 100).toFixed(2),
          };
        } else {
          obj = {
            ...obj,
            [item._id]: 0,
          };
        }
      });
    setPercen(obj);
    setTotalVote(totalVoted)
  };
console.log(vote,"vote")
  useEffect(() => {
    checkuser(); // Call checkuser() function

    handlepercentage(); // Call handlepercentage() function

    choices.map((item) => {
      //console.log(item); // Log each item to the console

      // Update choicesData state by appending item.title
      setChoicesData((prevChoicesData) => [...prevChoicesData, item.title]);
    });
  }, [choices]);

  const handleShowPercentage = (id)=> {
    const _showPer =
      choices &&
      choices.filter((item) => {
        return id === item._id;
      });
    const _DataId =
      _showPer &&
      _showPer.map((val) => {
        return val._id;
      });

    if (_DataId[0] === id) {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  function getRemainingHours(date, length) {
    var now = new Date();
    var postedTime = Math.floor((now - new Date(date)) / 60000); // Difference in minutes
    var remainingMinutes = length - postedTime;
    var remainingHours = Math.floor(remainingMinutes / 60);
    return remainingHours;
  }

  const checkuser = () => {
    choices &&
      choices.filter((item) => {
        return item.users.map((vote) => {
          if (vote === userid) {
            setShowPercent(true);
            setPostId(item._id);
          }
        });
      });
    const time = getRemainingHours(item.date, item.length);
    //console.log(time, "item.length");

    if (time < 0) {
      setShowPercent(true);
    }
  };

  // Handle Translation

//   const handleChangeButton = () => {
//     let toLang = translateLanguage; // translate to norwegian
//     let text = `${question}`;

//     let choice = [];
//     choices.map((item) => choice.push(item.title));
//     let url = TRANSLATE_URL;
//     url += "&q=" + encodeURI(text);
//     url += "&q=" + encodeURI(choice);
//     url += `&target=${toLang}`;

//     fetch(url, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     })
//       .then((res) => res.json())
//       .then((response) => {
//         setQustion(response.data.translations[0].translatedText);
//         if(response.data.translations[1].translatedText.includes(',')){
//           setChoicesData(response.data.translations[1].translatedText.split(","));
//         }else{
//         setChoicesData(response.data.translations[1].translatedText.split(" "));
//         }

//       })
//       .catch((error) => {
//         console.log("There was an error with the translation request: ", error);
//       });
//   };

  return (
    <>
      <div className="card my-6">
        <div className="card-body">
          <div className="polling ">
            <div className="cursor-pointer flex justify-between">
              <h5 className="card-title activecardhead ">{question}</h5>
              {/* <span
                className="underline text-blue cursor-pointer translateres"
                // onClick={handleChangeButton}
              >{`${t("Translate")}`}</span> */}
            </div>
            <div>
              <ul className="">
                {choices &&
                  choices.map((item, index) => (
                    <li
                      className="polling"
                      key={index}
                    //   onClick={() => handleVoteGive(item._id)}
                    >
                      {showPercent && (
                        <>
                          <span
                            className="progress-bar"
                            style={{ width: `${getPercen[item._id]}%` }}
                          />
                          <span className="progress-text">
                            {" "}
                            {`${(getPercen[item._id])}%`}{" "}
                          </span>
                        </>
                      )}
                      <span>
                        {choicesData[index]} {postId === item._id ? "" : ""} 
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="paths flex justify-between">
              <div className="flex"><p>Total Vote : {vote}</p></div>
              <div className="postbutton flex items-center">
                <p>
                  {getRemainingHours(item.date, item.length) < 0
                    ? t('Poll_Ended')
                    : `Ends in ${getRemainingHours(
                        item.date,
                        item.length
                      )} hours`}
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PollingCard;