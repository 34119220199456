import Grid from "@material-ui/core/Grid";
import { filterPostType } from "../../functions";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import CommentGallerySection from "../Common/CommentGallerySection";
import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";
import {
  BASE_URL_IMG,
  deleteCoursePostApi,
  deleteQuestionPostApi,
} from "../../Utils/services";
import { image } from "../../Assets/images/pdf.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RemoveConfirmation from "../ModalTemplate/RemoveConfirmation";
import { toast } from "react-toastify";
import modalTextsFile from "../ModalTemplate/modalTextsFile";
import { ModalContext } from "../../Context";
import EditQuestion from "../ModalTemplate/EditQuestion";

const EducationQuestionDetail = ({
  data,
  Question,
  reportHandler,
  isModalView,
  isReportBtn,
  getTablesData,
  CourseId,
  Sessionname,
  coursename,

}) => {
  const location = useLocation();
  const history = useNavigate();
  // const data = location?.state?.data
  const Title = location?.state?.title;
  // const totalCount = location?.state.count
  const [singlePostData, setsinglePostData] = useState(data);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;

  useEffect(() => {
    setsinglePostData(data);
  }, [data]);

  const handleAdminPush = () => {
    history(-1);
  };
  console.log(BASE_URL_IMG, "BASE_URL_IMG");

  const checkLink = (url) => {
    return (
      <ReactPlayer
        url={`${BASE_URL_IMG}${url}`}
        controls={true}
        width="75%"
        loop={true}
      />
    );
  };
  const checkpdflink = (url) => {
    window.open(`${BASE_URL_IMG}${url}`, "_blank");
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(singlePostData);
    const [reorderData] = items.splice(result?.source?.index, 1);
    items.splice(result.destination.index, 0, reorderData);
    console.log(items);
    setsinglePostData(items);
  };

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteQuestionPostApi(id);
      console.log(result);
      if (result.res.success) {
        toast.success(result.res.success);
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title="Delete Question"
        action={HandleDeleteResource}
        data={modalTextsFile.QuestionDelete}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  const handleEditResource = async (data) => {
    const editResourceForm = <EditQuestion
      title="Edit Question"
      data={data}
      QuestionType={data}
      getFeedData={getTablesData}
    />
    handleModalData(editResourceForm)
  }

  return (
    <div className="container relative px-8">
      <div className="flex items-center justify-between mb-20">
        {/* <span
          onClick={handleAdminPush}
          className="text-sm text-sidenav font-bold cursor-pointer border-b-2 border-sidenav"
        >
          Go Back
        </span> */}
        <div className="flex items-center mb-4">
                <span  className="text-sm text-sidenav text-minihubPara  ">Course Name :{coursename}</span>
                <span className="px-2 text-sm">/</span>
                <span className="text-sidenav text-minihubPara capitalize text-sm">Session Name : {Sessionname}</span>
                <span className="px-2 text-sm">/</span>
                <span onClick={handleAdminPush} className="text-sidenav cursor-pointer capitalize text-sm">Sub-Session Name : {CourseId.title}</span>
            </div>
        {/* <div className="flex items-center">
                <span  className="text-md text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">Sub-Session Name : {CourseId.title}</span>
            </div>
      <div className="flex items-center">
                <span  className="text-md text-sidenav font-bold cursor-pointer border-b-2 border-sidenav">{Title}</span>
            </div> */}
      </div>
    
      <div className="ForumDetailsHead">
        {/* <h2>{Title}</h2> */}
      </div>
      <Grid container spacing={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <Grid
                {...provided.droppableProps}
                ref={provided.innerRef}
                item
                md="12"
              >
                {singlePostData?.length > 0 &&
                  singlePostData?.map((item, i) => {  
                    return (
                      <Draggable
                        key={item._id}
                        draggableId={item._id.toString()}
                        index={i}
                      >
                        {(provided) =>
                          item.QuestionType === "Single" || item.QuestionType === "Multiple"  ? (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className="Questionbox border border-primary mt-5"
                              >
                                <div className="questionsection">
                                  <h6
                                  key={i}
                                    {...provided.dragHandleProps}
                                    className="questionID d-flex justify-content-first align-items-center"
                                  >
                                    Question {i + 1}
                                  </h6>
                                  <p>{item.Question}</p>
                                  {item.Option.map((option, i) => (
                                    <>
                                      <div className="option">
                                        <h6 className="questionID d-flex justify-content-first align-items-center">
                                          {/* {i === 0 ? "A" : i === 1 ? "B" : i === 2 ? "C" : i === 3 ? "D" :i === 4 ? "E" : i === 5 ? "F" : i === 6 ? "G" : null} */}
                                          {i + 1}
                                        </h6>
                                        <li>{option}</li>
                                      </div>
                                    </>
                                  ))}
                                </div>
                                {  
                                    item?.QuestionType === "Single" ?
                                    <>
                                     <div className="answersection">
                                     <h6
                                    
                                      className="questionID d-flex justify-content-first align-items-center"
                                     >
                                    Answer
                                  </h6>
                                       {
                                        item.Answer.map((option, i) => ( 
                                          <>
                                            <div className="options">
                                              {/* <h6 className="questionID d-flex justify-content-first align-items-center">
                                                {" "}
                                                {i + 1}
                                              </h6> */}
                                              <li>{option}</li>
                                            </div>
                                          </>
                                        ))
                                       }
                                     </div>
                                    </> : null

                                  }
                                {  
                                    item?.QuestionType === "Multiple" ?
                                    <>
                                     <div className="answersection">
                                     <h6
                                    
                                      className="questionID d-flex justify-content-first align-items-center"
                                     >
                                    Answer
                                  </h6>
                                       {
                                        item.Answer.map((option, i) => ( 
                                          <>
                                            <div className="options">
                                              {/* <h6 className="questionID d-flex justify-content-first align-items-center">
                                                {" "}
                                                {i + 1}
                                              </h6> */}
                                              <li>{option}</li>
                                            </div>
                                          </>
                                        ))
                                       }
                                     </div>
                                    </> : null

                                  }
                               

                                <div className="actionsection">
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                    type="button"
                                      onClick={() => handleEditResource(item)}
                                  >
                                    <span className="MuiButton-label">
                                      Edit
                                    </span>
                                  </button>
                                  <button
                                    className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                    type="button"
                                    onClick={() => HandleRemoveEvent(item?._id)}
                                  >
                                    <span className="MuiButton-label">
                                      Delete
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : item.QuestionType === "video" ? (
                            <div className="Questionbox border border-primary mt-5">
                              <div className="questionsection">
                                <h6 className="questionID d-flex justify-content-first align-items-center">
                                  Question {i + 1}
                                </h6>
                                <p className="pb-5">{item.Question}</p>

                                <div className="videodiv">
                                  {checkLink(item.media?.mp4link)}
                                </div>
                                {/* {item.Option.map((option,i)=>
                                                    <>
                                                   <div className='option'>
                                                   <h6 className="questionID d-flex justify-content-first align-items-center"> {i + 1}</h6>
                                                    <li>{option}</li>
                                                   </div>
                                                    </>
                                               )} */}
                              </div>
                              <div className="actionsection">
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                  type="button"
                                    onClick={() => handleEditResource(item)}
                                >
                                  <span className="MuiButton-label">Edit</span>
                                </button>
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                  type="button"
                                  onClick={() => HandleRemoveEvent(item?._id)}
                                >
                                  <span className="MuiButton-label">
                                    Delete
                                  </span>
                                </button>
                              </div>
                            </div>
                          ) : item.QuestionType === "pdf" ? (
                            <div className="Questionbox border border-primary mt-5">
                              <div className="questionsection">
                                <h6 className="questionID d-flex justify-content-first align-items-center">
                                  Question {i + 1}
                                </h6>
                                <p>{item.Question}</p>
                                {/* {checkpdflink(item.media)} */}
                                <img
                                  className="toc-icon2"
                                  src={`${BASE_URL_IMG}${item.pdfimg}`}
                                  alt="pdfimg"
                                  onClick={() =>
                                    checkpdflink(item.media.link)
                                  }
                                />
                              </div>

                              <div className="actionsection">
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                                  type="button"
                                    onClick={() => handleEditResource(item)}
                                >
                                  <span className="MuiButton-label">Edit</span>
                                </button>
                                <button
                                  className="MuiButtonBase-root MuiButton-root MuiButton-text btn2 ml-4"
                                  type="button"
                                  onClick={() => HandleRemoveEvent(item?._id)}
                                >
                                  <span className="MuiButton-label">
                                    Delete
                                  </span>
                                </button>
                              </div>
                            </div>
                          ) : null
                        }
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </div>
  );
};

export default EducationQuestionDetail;
