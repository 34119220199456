import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { adminlogout } from './services';

const AuthContext = React.createContext({
    isSuperAdmin: false,
    token: '',
    refreshToken: '',
    isLoggedIn: false,
    login: (token, refreshToken) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {

    const initialToken = localStorage.getItem("token");
    const initialRefreshToken = localStorage.getItem("refreshToken");
    const [token, setToken] = useState(initialToken);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
    const userIsLoggedIn = !!token;
    const [isSuperAdmin, setIsSuperAdmin] = useState(localStorage.getItem("isSuperAdmin") || false);
    const [loading, setLoading] = useState(false);

    const loginHandler = (token, refreshToken, isSuperAdmin) => {
        setToken(token);
        setRefreshToken(refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("isSuperAdmin", isSuperAdmin);
        setIsSuperAdmin(isSuperAdmin);
    }

    // const logoutHandler = () => {
       
    // }
    const fcmtoken = localStorage.getItem("deviceToken")
    const refreshedToken = localStorage.getItem("refreshToken")
    const emailValue = localStorage.getItem("emailValue")

    const logoutHandler = async () => { 
        setLoading(true)
        try {
            if (emailValue && refreshedToken && fcmtoken) {
                const data = {
                    email: emailValue,
                    refreshToken: refreshedToken,
                    fcmToken:fcmtoken
                }
                
                const result = await adminlogout(data);
                console.log(result,"result")
                if (result.res.success) {
                    localStorage.clear();
                    setLoading(false);
                    setToken(null);
                    setRefreshToken(null);
                    localStorage.removeItem('refreshtoken');
                    localStorage.removeItem("isSuperAdmin");
                    setIsSuperAdmin(false);
                    localStorage.removeItem('token');
                }

            } else{
                localStorage.clear();
                setLoading(false);
                setToken(null);
                setRefreshToken(null);
                localStorage.removeItem('refreshtoken');
                localStorage.removeItem("isSuperAdmin");
                setIsSuperAdmin(false);
                localStorage.removeItem('token');
            }
        }
        catch (error) {
            toast.error(error.message);
            setLoading(false);
        }
    }

    const contextValue = {
        token: token,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIn,
        isSuperAdmin: isSuperAdmin,
        login: loginHandler,
        logout: logoutHandler
    }
    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext