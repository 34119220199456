import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useEffect } from 'react';
import { regionCountryList, addAdmin, addCategory, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader";
import { useTranslation } from 'react-i18next';

const CategoryFormAdd = ({
    refreshDataMethod
}) => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [formData, setFormData] = useState({});
    const [loader, setLoader] = useState(false);
    const context = useContext(ModalContext);
    const { closeModal } = context;
    const { t } = useTranslation();
    
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, [])

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            const results = await addCategory({
                "name": formData.name,
                "country": country?.country?._id,
            });
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false)
                closeModal()
                console.log('"Admin');
                refreshDataMethod();
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }
  
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title">  {t('add_new_category')} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t('Name')} 
                        name="name"
                        value={formData.name || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                      <Autocomplete
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                    {t('submit')}
                    </button>
                </div>
            </form>
        </>
    )
}

export default CategoryFormAdd;