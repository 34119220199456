import { useContext, useEffect, useMemo } from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { ModalContext } from '../../Context';
import { useState } from 'react';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { BASE_URL_IMG, deleteForumApi, deleteGalleryPostApi,GalleryStatusApi,forumsStatusApi, getGalleryCommentData,getpolldatabyid } from '../../Utils/services';
import GallaryTable from './GallaryTable';
import { useNavigate,  } from 'react-router-dom';
import EditGallery from '../ModalTemplate/EditGallery'; 
import { toast } from 'react-toastify';
import { Button, Grid, TextField } from '@material-ui/core';
import AntSwitch from '../Common/AntSwitch'
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import PollingCard from "../ModalTemplate/PollingCard"

const PollPage = ({ data, getGalleryDataApi, totalCount,adminData }) => { 
    const [page, setPage] = useState(1);
    const [postsPerPage] = useState(10);
    const [totalPageToShow, setTotalPageToShow] = useState(1);
    const history = useNavigate();
  //  let { path } = useMatch();
    const { t } = useTranslation();
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }

    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        if (totalCount && totalCount > 0) {
            setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
        }
        getGalleryDataApi({ page, postsPerPage });
    }, [page, totalCount])

    const showPostDetail = async (item) => {  
        localStorage.setItem('itemss', item?._id);
        try {
            const result = await getpolldatabyid(item._id);
          
                // toast.success(result.res.message)
                // getGalleryDataApi({ page, postsPerPage });
        //          history.push({
        //     pathname: `${path}/${item._id}`,
        //     state: {
        //         data: result?.res?.poll,
        //         title: 'Gallery Details',
        //         count: totalCount,
        //         mediadata:item,

        //     }
        // })
    //     <PollingCard
    //     choices={result?.res?.choices}
    //     _id={item?._id}
    //     // formservicedata={formservicedata}
    //     length={result?.res?.poll?.length}
    //     item={result?.res?.poll}
    //     click={true}
    //   />
    const REMOVE_CONFIRMATION = (
          <PollingCard
        choices={result?.res?.poll?.choices}
        _id={item?._id}
        // formservicedata={formservicedata}
        length={result?.res?.poll?.length}
        item={result?.res?.poll}
        click={true}
      />
      );
      handleModalData(REMOVE_CONFIRMATION, "sm");
            

        } catch (error) {
            toast.error(error.messages)
        }
       
    }
    // const deletePostGallery = async (id) => {
    //     try {
    //         const result = await deleteGalleryPostApi({
    //             id: id
    //         });
    //         if (result.res.success) {
    //             toast.success(result.res.message)
    //             getGalleryDataApi({ page, postsPerPage });
    //         }

    //     } catch (error) {
    //         toast.error(error.messages)
    //     }
    // }

    const HandleDeleteResource = async (id) => {
        try {
            const result = await deleteForumApi(id);
            console.log(result)
            if (result.res.success) {
                // toast.success(result.res.success)
                toast.success("Poll deleted successfully")
                getGalleryDataApi({ page, postsPerPage });
            }

        } catch (error) {
            toast.error(error.message)
        }
    }

    
    const HandleRemoveEvent = (id) => {
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('Remove_Poll')}
            action={HandleDeleteResource}
            data={t('pollRemoveText')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };


    const EditPostDetail = (item) => {
        const Edit_MODAL = <EditGallery
            forumData={item}
            getGalleryDataApi={getGalleryDataApi}
            isModalView={true}
            title={t('Edit_Gallery')}
        />
        handleModalData(Edit_MODAL)
    }
   
    const handleActiveDeactive = async (props) => { 
        const id = props.row.original._id;
        try {
          const result = await forumsStatusApi(id);
          if (result.res.success) {
            toast.success(result.res.message)
            getGalleryDataApi({ page, postsPerPage });
          }
        } catch (error) {
          toast.error(error.message)
        }
      }

    const FeedsColumns = useMemo(
        () => [
            // {
            //     Header: `${t('Post_Photo')}`,
            //     accessor: data => {
            //         return (
            //             <>
            //                 {/* <img src={`${BASE_URL_IMG}${data?.media[0]?.link}`} alt="Post Image" className='w-28' /> */}
            //             </>
            //         )
            //     }
            // },
            {
                Header: `${t('Question')}`,
                accessor: data => {
                    return (
                        <>
                            <h4 className='w-60 text-md'>{data?.question}</h4>
                        </>
                    )
                }
            },
            {
                Header: `${t('Country')}`,
                accessor: data => {
                    return (
                        <>
                            {data?.country?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t('Tags')}`,
                accessor: data => {
                    return (
                        <>
                            {data?.tag?.map(item => {
                                return (
                                    <span>{`${item?.name} , `}</span>
                                )
                            })}
                        </>
                    )
                }
            },
            {
                Header: `${t('Posted_On')}`,
                accessor: data => {
                    return (
                        <span>{formatDate(data?.date)}</span>
                    )
                }
            },
            // {
            //     Header: `${t('Likes')}`,
            //     accessor: data => {
            //         return (
            //             <span>{data?.likes?.length}</span>
            //         )
            //     }
            // },
            // {
            //     Header: `${t('Comments')}`,
            //     accessor: data => {
            //         return (
            //             <span>{data?.comments?.length}</span>
            //         )
            //     }
            // },
            // {
            //     Header: `${t('View')}`,
            //     accessor: data => {
            //         return (
            //             <span>{data?.views}</span>
            //         )
            //     }
            // },
            {
                Header: `${t('Action')}`,
                accessor: adminData[0]?.AdminType==="Coordination team"? "" : (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => showPostDetail(data)}
                        >
                            <span className="MuiButton-label">{t('View')}</span>
                        </button>
                    )
                },
            },
            // {
            //     Header: `${t('Edit')}`,
            //     accessor: adminData[0]?.AdminType==="Coordination team"? "" : (data) => {
            //         return (
            //             <button
            //                 className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
            //                 type="button"
            //                 key={data.id}
            //                 onClick={() => EditPostDetail(data)}
            //             >
            //                 <span className="MuiButton-label">{t('Edit')}</span>
            //             </button>
            //         )
            //     },
            // },
            {
                Header: `${t('Delete')}`,
                accessor: adminData[0]?.AdminType==="Coordination team"? "" : (data) => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={data.id}
                            onClick={() => HandleRemoveEvent(data._id)}
                        >
                            <span className="MuiButton-label">{t('Delete')}</span>
                        </button>
                    )
                },
            },
            {
                Header: `${t('Status')}`,
                accessor: "deactivated",
                Cell: adminData[0]?.AdminType==="Coordination team"? "" : props => { 
                  return (
                    <Grid item>
                      <AntSwitch
                        checked={props.row.original.status}
                        onChange={() => handleActiveDeactive(props)}
                        name="isDirectory"
                      />
                    </Grid>
                  )
                }
              }
        ], [adminData])

    return (
        <>
            <div className="flex justify-between items-center">
                <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
            </div>
            {data &&
                <GallaryTable columns={FeedsColumns} data={data} />
            }

        </>
    )
}

export default PollPage;