import UserForm from "./UserForm";
import FeedsForm from "./FeedsForm";
import PostDetails from "./PostDetails";
import RemoveConfirmation from "./RemoveConfirmation"
import modalTextsFile from "./modalTextsFile"
import ResetPassword from "./ResetPassword";
import GalleryForm from "./GalleryForm";
import StoryForm from "./StoryForm";
import NewsFeedForm from "./NewsFeedForm";
import DirectoryForm from "./DirectoryForm";
import Pollform from "./Pollform";

export const USER_FORM = <UserForm title="add new user" buttonText="add user"/>
export const DIRECTORY_FORM = <DirectoryForm title="add new directory" buttonText="add directory"/>
export const INTERNATIONAL_OFFICER_USER_FORM = <UserForm title="add new international Officer" buttonText="add international Officer"/>
export const FEEDS_FORM = (post, getFeedData) => <FeedsForm getFeedData ={getFeedData} title="add new forum" post={post}/>
export const GALLERY_FORM = (post, getFeedData) => <GalleryForm getFeedData ={getFeedData} title="add new GALLERY" post={post}/>
export const POLL_FORM = (post, getpollDataApi) => <Pollform getFeedData ={getpollDataApi} title="add new GALLERY" post={post}/>
export const STORY_FORM = (post, getFeedData) => <StoryForm getFeedData ={getFeedData} title="Add new story" post={post}/>
export const NEWSFEED_FORM = (post, getFeedData) => <NewsFeedForm getFeedData ={getFeedData} title="add new NEWSFEED" post={post}/>
export const POST_MODAL = <PostDetails />
export const REMOVE_CONFIRMATION = <RemoveConfirmation title="REMOVE FEED" data={modalTextsFile.postRemoveText}/>
export const RESET_PASSWORD = <ResetPassword />
