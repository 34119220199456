import { useContext, useEffect, useState } from "react";
import { getAdminDetails } from "../../Utils/services";
import AdminDetailEditForm from "../ModalTemplate/AdminDetailEditForm";
import { ModalContext } from "../../Context";
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { useTranslation } from 'react-i18next';

const ManageAcoount = () => {
    const [adminDetails, setAdminDetails] = useState([]);
    
    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const adminId = localStorage.getItem('adminId');
    const AdminType = localStorage.getItem('AdminType');
    const { t } = useTranslation();
    const getAdminDetailsApiData = async () => {
        const id = localStorage.getItem("adminId")
        try {
            const result = await getAdminDetails({
                id: id,
                "type": "admin"
            })
            if(result.success){
                setAdminDetails(result.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
    }, []);

    const reportHandler = () => {
        console.log('reportHandler');
    }

    const showPostDetail = adminDetails => {
        const POST_MODAL = <AdminDetailEditForm
            userAllData={adminDetails}
            reportHandler={reportHandler}
            isReportBtn={true}
            isModalView={true}
            getAdminDetailsApiData={getAdminDetailsApiData}
        />
        handleModalData(POST_MODAL)
    }

    return (
        <div className="container mt-8 px-8">
            <div className="user_data_admin">
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-center flex-col items-center">
                                <div className="w-28 h-28 rounded-full flex items-center justify-center bg-shadowImg imageMarginTop">
                                    <img src={DummyDpImg} alt="User-image" className="w-24 h-24 rounded-full" />
                                </div>
                                <h3 className="text-sidenav capitalize mt-2 text-3xl">{adminDetails.name}</h3>
                                {/* <p className="text-2xl text-paraText border-b border-muteText pb-2">{adminDetails.designation}</p> */}
                                {  adminDetails?.country?.length > 1 ?
                                    <p className="text-lg pt-2 capitalize text-muteText">{adminDetails?.country?.map((data) => { 
                                        return (
                                            <span>{`${data?.name}, `}</span>
                                        )
                                    })}</p> : <p className="text-lg pt-2 capitalize text-muteText">{adminDetails?.country?.map((data) => { 
                                        return (
                                            <span>{`${data?.name} `}</span>
                                        )
                                    })}</p>
                                }
                            </div>
                            <div className="my-8">
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Email")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.email}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Mobile_Number")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails.mobile}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Age")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails?.age}</span>
                                </div>
                                <div className="my-2 grid grid-cols-3 gap-4">
                                    <h3 className="text-lg text-sidenav py-2">{t("Language")}</h3>
                                    <span className="text-lg col-span-2 text-paraText">{adminDetails?.language?.map((data) =>{
                                        return(
                                            <span>{data?.name}</span>
                                        )
                                    })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {   AdminType === "Platform Admin" ? 
                        adminDetails._id === adminId ?
                        <div className="mb-8">
                        <div className="userDataMain px-10">
                            <div className="flex h-40 justify-end">
                                <div className="mt-9">
                                    <button
                                        className="button-1"
                                        onClick={() => showPostDetail(adminDetails)}
                                    >
                                        {t("Edit")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> : adminDetails.AdminType === "Regional Admin" || adminDetails.AdminType === "Country Admin"  || adminDetails.AdminType === "Coordination team"? 
                    <div className="mb-8">
                    <div className="userDataMain px-10">
                        <div className="flex h-40 justify-end">
                            <div className="mt-9">
                                <button
                                    className="button-1"
                                    onClick={() => showPostDetail(adminDetails)}
                                >
                                   {t("Edit")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null : AdminType === "Regional Admin" ? 
                   adminDetails._id === adminId ?
                   <div className="mb-8">
                   <div className="userDataMain px-10">
                       <div className="flex h-40 justify-end">
                           <div className="mt-9">
                               <button
                                   className="button-1"
                                   onClick={() => showPostDetail(adminDetails)}
                               >
                                  {t("Edit")}
                               </button>
                           </div>
                       </div>
                   </div>
               </div> : adminDetails.AdminType === "Country Admin"  || adminDetails.AdminType === "Coordination team"? 
               <div className="mb-8">
               <div className="userDataMain px-10">
                   <div className="flex h-40 justify-end">
                       <div className="mt-9">
                           <button
                               className="button-1"
                               onClick={() => showPostDetail(adminDetails)}
                           >
                               {t("Edit")}
                           </button>
                       </div>
                   </div>
               </div>
           </div> : null : AdminType === "Country Admin" ? 
               adminDetails._id === adminId ?
               <div className="mb-8">
               <div className="userDataMain px-10">
                   <div className="flex h-40 justify-end">
                       <div className="mt-9">
                           <button
                               className="button-1"
                               onClick={() => showPostDetail(adminDetails)}
                           >
                             {t("Edit")}
                           </button>
                       </div>
                   </div>
               </div>
           </div> : adminDetails.AdminType === "Coordination team"? 
           <div className="mb-8">
           <div className="userDataMain px-10">
               <div className="flex h-40 justify-end">
                   <div className="mt-9">
                       <button
                           className="button-1"
                           onClick={() => showPostDetail(adminDetails)}
                       >
                           {t("Edit")}
                       </button>
                   </div>
               </div>
           </div>
       </div> : null :  AdminType === "Coordination team" ? 
            adminDetails._id === adminId ?
            <div className="mb-8">
            <div className="userDataMain px-10">
                <div className="flex h-40 justify-end">
                    <div className="mt-9">
                        <button
                            className="button-1"
                            onClick={() => showPostDetail(adminDetails)}
                        >
                           {t("Edit")}
                        </button>
                    </div>
                </div>
            </div>
        </div> : adminDetails.AdminType === "Coordination team"?  null
    //     <div className="mb-8">
    //     <div className="userDataMain px-10">
    //         <div className="flex h-40 justify-end">
    //             <div className="mt-9">
    //                 <button
    //                     className="button-1"
    //                     onClick={() => showPostDetail(adminDetails)}
    //                 >
    //                     Edit
    //                 </button>
    //             </div>
    //         </div>
    //     </div>
    // </div> 
    : null : null
       
                    }
                </div>
            </div>
        </div>
    )
}

export default ManageAcoount