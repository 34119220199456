import { useMemo,  useEffect } from "react"
import Table from '../../Common/Table'
import { getRegionData } from "../getRequests";
import DummyDpImg from "../../../Assets/images/DummyDp.png"
import { useTranslation } from 'react-i18next';



const Regions = ({ regionData, setRegionData }) => {
    const { t } = useTranslation();
    useEffect(() => {
        (async () => {
            const data = await getRegionData();
            setRegionData(data);
        })();

    }, [])

    const regionColumns = useMemo(
        () => [
            {
                Header: `${t('Region')}`,
                accessor: 'name',
                Cell: ({ cell: { value } }) => <span className="capitalize">{value}</span>
            },

            {
                Header: `${t('Added_On')}`,
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            // {
            //     Header: 'Added By',
            //     accessor: 'createdBy',
            //     Cell: ({ cell: { value } }) => <div className='flex items-center'><img src={DummyDpImg} className=' w-10 h-10 flex object-cover rounded-full mr-2' />{value.name}</div>
            // },
        ],
        []
    )

    return (
        <>
            {regionData &&
                <Table columns={regionColumns} data={regionData} />
            }
        </>
    )
}

export default Regions