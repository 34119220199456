import { BASE_URL_IMG,BASE_URL,getPreviewpdf } from "../../Utils/services";
import {PREVIEW_PDF  } from "../../Utils/routes";
import styles from '../../Assets/Comment.module.css'
import { toast } from 'react-toastify';
const FilePost = ({ data }) => {  
    // console.log(data)

     const previewpdf = async (url) => { 
        try {
            const res = await getPreviewpdf(url)
           /// console.log(res,"res")
          //  const data = res.data;
           // toast.success(res.message);
            return data;
        } catch (error) {
            toast.error(error.message);        
        }
    }
    return (
        <div className="post-detail relative pt-4">
            <div className="p-4">
                <img src={`${BASE_URL_IMG}${data?.pdfimg}`} alt={data?.title} className="w-2/3" />
            </div>
            <div className="footer bg-white p-2">
                <h3 className="title text-sm font-semibold tracking-wider mb-1"> {data?.title} </h3>
            </div>
            <div className="flex justify-start sticky top-0">
                <div className="flex pl-2 pt-2 comment-bg rounded-tl">
                    <span className={styles.num}>{data && data?.downloadCount?.length}</span>
                    <img src="../../assets/comment/download.png" className={styles.icon} alt="likes" />
                </div>
                <div className="flex px-2 pt-2 comment-bg">
                    <span className={styles.num}>{data && data?.view?.length}</span>
                    <img src="../../assets/comment/eye.svg" className={styles.icon} alt="comment" />
                </div>
                {
                    data && data?.media[0]?.mediaType == "file" ?
                    <div className="flex px-2 pt-2 comment-bg">
                      
                      <a href={`${BASE_URL}${PREVIEW_PDF}?link=${data?.media[0]?.link}`}  target="_blank" rel="noopener noreferrer">Preview pdf</a>

                    {/* <button className={styles.num}  onClick={() => previewpdf(data?.media[0]?.link)}>Preview pdf</button> */}
                    {/* //<img src="../../assets/comment/eye.svg" className={styles.icon} alt="comment" /> */}
                </div> : null
                }
               
            </div>
        </div>
    )
}

export default FilePost;