import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState,useRef } from 'react';
import { addForumPost, getAdminDetails, getTags, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { ReactCrop } from 'react-image-crop';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import "cropperjs/dist/cropper.css";
import {Cropper} from 'react-cropper';



const FeedsPost = ({ getFeedData }) => {
    const { t } = useTranslation();
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState({});
    const [imgFile, setImgFile] = useState('');
    const [imgFilecrop, setImgFilecrop] = useState('');
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo")
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [isFeatured, setIsFeatured] = useState(false)
    const [isNotification, setIsNotification] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
   // const [result, setResult] = useState(null)
    const [disableButton, setDisableButton] = useState(false);
    const [crop, setCrop] = useState({ aspect: 2 / 1 });
    const [src, setSrc] = useState(null);
    const [image, setImage] = useState(null)
    const [result, setResult] = useState(null)
    const [contentType, setContentType] = useState("application/json")
    const [sendImageFile, setSendImageFile] = useState('');

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }

    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }

    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        // getCountryData();
        getAdminDetailsApiData()
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue(newInputValue);
    }
 

    const handleImgChangeFile = (val) => { 
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 600 && img.height === 300) {
                    setImgFile(val);
                    setMediaType("photo")
                    setImgFilecrop("")
                    setSrc(null)
                }
                else {
                    setImgFile("");
                    setContentType("application/json")
                    setMediaType("photo")
                    setImgFilecrop(val)
                    setSrc(URL.createObjectURL(val))
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }
    console.log(formData, "Form Data")
    
    const handlePostSubmit = async (e) => {
       
        e.preventDefault();
        const tagsData = tagsValue?.map((data) => {
            return data.id
        })
        const countryData = country?.country?.map((data) => {
            return data.name
        })
        console.log(tagsData,"tagsData")
        if(sendImageFile || imgFile){
            setLoader(true);
        const postFormData = new FormData();
        postFormData.append("question", formData.question ? formData.question : "");
        postFormData.append("tags", tagsData);
        postFormData.append("countrys", countryData);
        postFormData.append("isFeatured", isFeatured);
        postFormData.append("target", isNotification);
        postFormData.append("content", article ? article : "");
        postFormData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);
        postFormData.append("mediaType", mediaType)
    
        try {
            const result = await addForumPost(postFormData);
            if (result.res.success) {
                toast.success(result.res.message);
                closeModal();
                getFeedData();
                setLoader(false);
                console.log(result.res, "This is result response")
            }
        }
        
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
   
    const cropperRef = useRef(null);
    const getCropData = () => {
        if (typeof cropperRef.current?.cropper !== "undefined") {
          setResult(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
                fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            .then(res => res.blob())  
            .then(blob => {
              const file = new File([blob], cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
              setSendImageFile(file)
            })
        }
      };

      const getCropremove2 = () => {
        setImgFile("");
        setResult("");
        setMediaType("");
        // setshowImgError(false)
        setImgFilecrop("");
        setSrc(null); 

        const fileInput = document.querySelector('input[name="image-file"]');
        if (fileInput) {
            fileInput.value = ''; // Clear the value to allow re-selection of the same file
        }
      };
      console.log(sendImageFile,"sendImageFile")
      console.log(result,"sendImageFile")
      const tagCountryOptions = (tags || [])
      .filter(item => item.country && item.country.name) // Filter out items without a country or country name
      .map(item => ({
          tagName: item.name,
          countryName: item.country.name,
          id: item._id,
      }))
      .sort((a, b) => a.countryName.localeCompare(b.countryName));
  
 
    console.log(tagCountryOptions,"tagCountryOptions")
    return (
        <>
            <Loader loader={loader} />
            <div noValidate autoComplete="off" className="w-full" >
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData.question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={1.5}
                        />
                    </div>
                    <div className='editorSectionStart col-span-2'>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"     
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />  
                    </div>
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                     <Autocomplete
            id="combo-box-demo2"
            multiple
            options={tagCountryOptions}
            getOptionLabel={(option) => `${option.tagName} / ${option.countryName}`}
            sx={{ width: 300 }}
            value={tagsValue}
            onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
            renderInput={(params) => <TextField {...params} label="Tags" />}
        />
       
                    <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} defaultChecked color="primary" />} label={t('Featured')} labelPlacement="start" />
                    </div>
                    <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={isNotification} onChange={(e) => setIsNotification(e.target.checked)} defaultChecked color="primary" />} label={t('Send_Push_Notification')} labelPlacement="start" />
                    </div>
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item md={2}>
                            <span className="text-paraText">{t('Add_Media')}</span>
                        </Grid>
                    
                        {
                      <Grid item md={6}>
                        <div className="relative w-auto overflow-hidden">
                          <TextareaAutosize
                            id="profilephoto"
                            label="Add Photo"
                            value={(imgFile.name || result || "").slice(0,20)}
                            className="w-full text-xs"
                          />
                          <input
                            disabled={disableButton}
                            type="file"
                            accept="image/*"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="image-file"
                            // onChange={handleImgChangeFile}
                            onChange={(e) => handleImgChangeFile(e.target.files[0])}
                          />
                          <div className="floating-box" >+</div>
                        </div>
                      </Grid>
                    }
                  
                    </Grid>
                    <p className='mt-4 text-sidenav'>  {t('Imageshouldbe600x300px')}</p>
                    <Grid md={6}>
                    {
                      src &&
                      <div className="col-6 cropperclass">
                   
                         <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={2/1}
          aspectRatio={2 / 1} 
          //preview=".img-preview"
          src={src}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false}
        />
          <div className='Zoombtn'>
                        <button
                          variant="contained"
                          onClick={getCropData}
                          className='cropbutton'
                        >
                          Crop Image
                        </button>
                        <button
                          variant="contained"
                          onClick={getCropremove2}
                          className='cropbutton'
                        >
                          Remove Crop 
                        </button>
                        </div>
                      </div>
                    }
                  </Grid>
                </div>
                <div className="flex justify-center mt-10"   >
                    <button 
                    onClick={handlePostSubmit} 
                    
                    className={`mx-auto ${imgFile?.name !== undefined || result !== null && country?.country?.length > 0 && tagsValue?.length  && formData?.question ? "button-1" : "buttons-1"}`}
                     disabled={imgFile?.name  !== undefined ? false : true || result !== null && country?.country?.length > 0 && tagsValue?.length  && formData?.question ? false : true}
                     >
                        {t('submit')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default FeedsPost