import { useContext, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useEffect } from 'react';
import { regionCountryList, addUser, getResourcesCategory, addResources, editResources, BASE_URL_IMG, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const EditResourcesForm = ({
    getTablesData,
    id,
    data,
    title
}) => {  
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [formData, setFormData] = useState(data);
    const [loader, setLoader] = useState(false);
    const [CategoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState([]);
    const [pdfFile, setPdfFile] = useState('');
    const [mediaType, setMediaType] = useState("photo")
    const [VideoFile, setVideoFile] = useState("video");
    const [disableButton, setDisableButton] = useState(false);
    const [isFileChange, setIsFileChange] = useState(false);
    const [isFileChanges, setIsFileChanges] = useState(false);
    const [isCountryChange, setIsCountryChange] = useState(false);
    const [isCategoryChange, setIsCategoryChange] = useState(false)
    const [videoValidation, setVideoValidation] = useState("");
    const [disablePdf, setDisablePdf] = useState(false);
    const {t} = useTranslation();
    const context = useContext(ModalContext);
    const { closeModal } = context;

    useEffect(() => {
        getCategoryData();
        getAdminDetailsApiData();
    }, [])

    // const handleVideoFile = (val) => {
    //     setIsFileChange(true)
    //     setVideoFile(val);
    //     setMediaType("video")
    //     if (val.name !== "") {
    //         setDisableButton(true)
    //     }
    // }
    const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });
    const handleVideoFile = async (val) => {
        setMediaType("video");
        setIsFileChange(true)
        if (val && val.size > 0) {
          console.log(val, "val");
          if (val?.size && val?.size > 0) {
            if (Math.round(val.size / 1048576) > 50) {
              setVideoValidation("Video size should be less than 50 MB");
              setVideoFile("");
            }
            const duration = await getVideoDuration(val);
            let durationSecond = Math.round(duration);
    
            if (durationSecond && durationSecond > 300) {
              setVideoValidation("Video duration should be less than 5 minutes");
              setVideoFile("");
            } else {
              setVideoFile(val);
              setVideoValidation("");
              if (duration && duration > 300) {
                setVideoValidation("Video duration should be less than 2 minutes");
                setVideoFile("");
              } else {
                setVideoFile(val);
                setVideoValidation("");
              }
            }
            console.log(Math.round(val.size / 1048576), "Val");
          }
        }
        if (val?.name !== "") {
          setDisablePdf(true);
        }
      };
    const handlePdfFile = (val) => {
        setIsFileChanges(true)
        setPdfFile(val);
        setMediaType("file")
        if (val.name !== "") {
            setDisableButton(true)
        }
    }

    const onInputCountryChange = (event, newInputValue, name) => {
        setIsCountryChange(true);
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const getCategoryData = async () => {
        try {
            const result = await getResourcesCategory();
            setCategoryData(result?.data)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getCategoryData();
    }, [country]);
    const onInputCategoryChange = (event, newInputValue, name) => {
        setIsCategoryChange(true)
        setCategory({
            ...category,
            [name]: newInputValue
        })
    }

    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }
    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }

    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            
            let countryData = country?.country?.map((item) => { 
                return item?._id;
                
              });

            let countryDatas = data && data.countries.map((item) => { 
                return item?._id;
                
              });
            //  console.log(countryDatas,"countryDatas")
            //  console.log(countryData,"countryDatas")
             
            const postFormData = new FormData();
            postFormData.append("id", id);
            postFormData.append("title", formData.title ? formData.title : "");
            if (isCategoryChange) {
                postFormData.append("category", category?.category?._id);
            }
            if (isCountryChange) {
                postFormData.append("countrys", countryData ? countryData : countryDatas);
            }
            if (isFileChange) {
                postFormData.append("media", VideoFile && VideoFile);
                postFormData.append("mediaType", mediaType)
                
            }
            if (isFileChanges) {
                postFormData.append("media", pdfFile && pdfFile);
                postFormData.append("mediaType", mediaType)
                
            }
            // const dataToSend = {
            //     "id": id,
            //     "title": formData.title,
            //     "category": category?.category?._id,
            //     "countrys": country?.country?._id,

            // }
            const results = await editResources(postFormData);
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("Resource Edited Successfully");
                getTablesData();
                setLoader(false)
                closeModal()
               
            }
        }
        catch (e) {
            setLoader(false)
            console.log(e);
            toast.error(e.messages);
        }
    }

    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        label={t("Title")}
                        name="title"
                        value={formData.title || ''}
                        onChange={e => onChangeHandler(e)}
                    />
                    <Autocomplete
                        multiple
                        id="combo-box-demo2"
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        defaultValue={data && data?.countries}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t("Country")} />}
                    />
                    <Autocomplete
                        id="combo-box-demo"
                        options={CategoryData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        value={category?.name}
                        defaultValue={data?.category}
                        onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
                        renderInput={(params) => <TextField {...params} label={t("Category")} />}
                    />
                    <br />
                </div>
                <div className="mt-12 w-full">
                    <Grid container alignItems="center" spacing={1}>

                        <Grid item md={2}>
                            <span className="text-paraText">{t("Add_Media")}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t("Add_a_Video")}
                                    value={VideoFile.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="video/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="video-file"
                                    onChange={(e) => handleVideoFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/video.svg" alt="" />
                                {t("Video")}
                            </div>
                        </Grid>
                        <Grid item md={2} className="flex items-center justify-center">
                            <span className="text-muteText">{t("or")}</span>
                        </Grid>
                        <Grid item md={4}>

                            <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t("Add_a_PDF_File")}
                                    value={pdfFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disableButton}
                                    type="file"
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="pdf-file"
                                    onChange={(e) => handlePdfFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2">
                                <img src="../../assets/photos.svg" alt="" />
                                {t("Files")}
                            </div>
                        </Grid>
                        {
            <div className="flex  editimage">
              {data && data?.pdfimg  && (
                <img
                  src={`${BASE_URL_IMG}${data?.pdfimg}`}
                  alt="Post Image"
                  className="w-80 "
                />
              )}
              
            </div>
          }
                    </Grid>
           
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit" disabled={loader ? true : false}>
                    {t("Submit")}
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditResourcesForm;