import classNames from "classnames";
import { useEffect, useState } from "react";
import { BASE_URL_IMG } from "../../Utils/services";


const PostPolling = ({
    avatarClass,
    data,
    onClickHandler,
    isModalView,
    isReportBtn
}) => {
    const [getPercen, setPercen] = useState({})

    
    useEffect(() => {
        let totalVoted = 0
        let obj = {}

        data.choices && data.choices.map(item => totalVoted = item.score + totalVoted)
        data.choices && data.choices.map(item => {
            if (totalVoted > 0) {
                obj = {
                    ...obj,
                    [item._id]: (item.score/totalVoted) * 100 
                }
            }
            else {
                obj = {
                    ...obj,
                    [item._id]: 0 
                }
            }
        })

        setPercen(obj)
    }, [])

    return (
        <div className={classNames({
            "post-polling": true,
            "relative": true,
            "pt-3": true,
            'modal-view': isModalView,
        })}>
            <div className="header">
                <img alt="" src={`${BASE_URL_IMG}${data.postedBy.image}`} className={avatarClass} />
                <span className="pt-1 text-sm primary-color font-semibold tracking-wider"> { data.postedBy.name || '' } </span>
            </div>

            <div className="p-4 pt-10 bg-1">
                <p className="text-md primary-color font-semibold tracking-wider mb-1">
                    { data.question }
                </p>
                <ul>
                    {
                        data.choices && data.choices.map((item, index) => (
                            <li className="polling" key={index} >
                                <span className="progress-bar" style={{width: `${getPercen[item._id]}%`}} />
                                <span className="progress-text"> { `${getPercen[item._id]}%` } </span>
                                <span> { item.title } </span>
                            </li>
                        ))
                    }
                </ul>

                {
                    isReportBtn && (
                        <div className="flex mt-4">
                            <button
                                className="button-1"
                                type="button"
                                // disabled ={loader ? true : false}
                                // onClick={edit ? handleEdit : handleAdd}
                            >
                                Report
                            </button>
                        </div>
                    )
                }
            </div>

            
            <div className="footer p-2 bg-1">
                <h3 className="title text-sm font-semibold tracking-wider mb-1"> { data.title } </h3>
                <p className="text text-sm">
                    { data.caption }

                    {
                        !isModalView && (
                            <span className="text-btn2" onClick={() => onClickHandler(data)}>
                                Read Article
                            </span>
                        ) 
                    }
                </p>
            </div>
            
        </div>
    )
}

export default PostPolling;