import logo from "../../Assets/images/glp-logo-colour.png";
import { forgotPasswordApi, loginService } from "../../Utils/services";
import { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import authBg from "../../Assets/images/glp_banner.png"
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const handleForgetPassword = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const result = await forgotPasswordApi({
                email: email,
            });
            if (result.res.success) {
                setLoading(false);
                toast.success(result.res.message);
                history("/otp",{
                    state: {
                        verification_key: result.res.data.details,
                        email: email,
                    },
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.message);
            console.log(error);
        }
    };
    const {t} = useTranslation();
    return (
        <div className="w-full relative">
            <img src={authBg} alt="" />
            <div className="flex absolute top-1/2 left-0 w-full">
                <div className="w-2/5 logincontainer mx-auto auth-container">
                    <div className="w-1/2 mx-auto text-center">
                        <img
                            src={logo}
                            className="mx-auto mb-10"
                            alt="logo"
                        />

                        <h2 className="primary-color font-sans text-2xl	 font-bold mb-10">
                           {t("FORGOTPASSWORD")}
                        </h2>

                        <form className="auth-form" onSubmit={handleForgetPassword}>
                            <input
                                type="email"
                                placeholder={t("EmailID")}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {
                                loading ? <p>{t("Loading...")}</p> :
                                    <button
                                        className="primary-btn"
                                        type="submit"
                                    >
                                        {t("Send")}
                                    </button>
                            }

                            <span
                                className="text-btn"
                            >
                                <Link to="/login"> {t("Rememberpassword?")}</Link>
                            </span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;