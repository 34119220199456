import UsersTable from './UsersTable';
import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { allAdminUsers, getAdminDetails, InviteAllPeopleNotification, InviteNotification, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import { Autocomplete, Pagination } from '@material-ui/lab';


const AllUsers = ({ data, getTablesData, adminData, getAdminTableData,Count,getnewRegistraionTablesData}) => {   
  const [searchValue, setSearchValue] = useState("");
  // const [cordinater, setcordinater] = useState(adminData[0]?.AdminType);
  const modalContext = useContext(ModalContext);
  const [loader, setLoader] = useState(true);
  // const [adminData, setAdminData] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const { closeModal, handleModalData } = modalContext;
  const [CategoryData, setCategoryData] = useState([]);
const [category, setCategory] = useState([]);
  const handlePageChange = (event, value) => { 
    setPage(value);
  };
  useEffect(() => {
    getAdminTableData();
   
  }, [])

  useEffect(() => {
    const trimmedSearchValue = searchValue.trim();
  
    if (typeof trimmedSearchValue === "string" && trimmedSearchValue.match(/^\d+/)) {
      let intValue = parseInt(trimmedSearchValue, 10);
      if (!isNaN(intValue)) {
        const timeoutId = setTimeout(() => 
          getnewRegistraionTablesData({
            page: page,
            size: postsPerPage,
            number: intValue,
          }), 500);
        return () => clearTimeout(timeoutId);
      }
    } else {
      if (trimmedSearchValue && trimmedSearchValue.length >= 3) {
        const queryValue = trimmedSearchValue.startsWith('+') ? trimmedSearchValue.slice(1) : trimmedSearchValue;
        const timeoutId = setTimeout(() =>
          getnewRegistraionTablesData({
            page: page,
            size: postsPerPage,
            query: queryValue,
          }), 700);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [searchValue, Count, page]);
  
  
  // useEffect(() => {
  //   if (typeof searchValue === "string" && searchValue.match(/^\d+/)) {
  //     let intValue = parseInt(searchValue, 11);
  //     //console.log("intValue:", intValue);
  //     // if (typeof searchValue === "string") {
  //       if(intValue!= ''){
  //       const timeoutId = setTimeout(() =>
  //       getnewRegistraionTablesData({
  //              page: page,
  //              size: postsPerPage,
  //             number:intValue,
  //       }), 500);
  //     return () => clearTimeout(timeoutId);
  //   }
  //   // }
  //   } else {
  //     //console.log("searchValue is not a string starting with a number");
  //     if(searchValue!= '' && searchValue.length >= 3){
  //       const timeoutId = setTimeout(() =>
  //       getnewRegistraionTablesData({
  //              page: page,
  //              size: postsPerPage,
  //             query :searchValue,
  //       }), 700);
  //     return () => clearTimeout(timeoutId);
  //     }
  //   }

  // }, [searchValue,Count,page])

//   useEffect(() => { 
//     if (typeof searchValue === "string" && Number.isInteger(searchValue)) {
//     if(searchValue!= ''){
//     const timeoutId = setTimeout(() =>
//     getnewRegistraionTablesData({
//           //  page: page,
//           //  size: postsPerPage,
//           number:searchValue,
//     }), 500);
//   return () => clearTimeout(timeoutId);
//   }
// }}, [searchValue,Count])

  useEffect(() => {
    if(searchValue === ""){
      getnewRegistraionTablesData({
        page: page,
        size: postsPerPage,
     })
    }
},[Count,page,searchValue])

 

  useEffect(() => {
    setPageCount(Math.ceil(Count / postsPerPage));
  }, [Count])


  // console.log(pageCount,"pageCount")

  const history = useNavigate();
  const { t } = useTranslation();

  const editHandler = (data) => {
    const EDIT_PARTY = <UserEditForm
      title={t('Edit_user')}
      buttonText={t('Save_Changes')}
      id={data.row.original._id}
      perticularData={data.row.original}
      getnewRegistraionTablesData={getnewRegistraionTablesData}
    />
    handleModalData(EDIT_PARTY);
  }

  const handleInvite = async (data) => {
    console.log(data.row.original._id)
    try {
      const result = await InviteNotification({
        people: "user",
        id: data.row.original._id
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  const handleInviteAll = async () => {
    try {
      const result = await InviteAllPeopleNotification({
        people: "user",
      });
      if (result.res.success) {
        toast.success(result.res.message)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }



  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getnewRegistraionTablesData({
          page: page,
          size: postsPerPage,
       });
      }
    } catch (error) {
      toast.error(error.message)
    }

  }

  const handleDeactivate = async (props) => {
    const id = props.row.original._id;
    const USER_DEACTIVATE = <MaintenanceModeConfirmation
      title="Change User Status"
      data={modalTextsFile.userActivateDeactivate}
      action={() => handleMaintenance(id)}
    />
    handleModalData(USER_DEACTIVATE, 'sm')
  }

  // const getAdminTableData = async () => {
  //   try {
  //     const result = await allAdminUsers();
  //     if (result.data.length > 0) {
        
  //       setLoader(false)
  //       setAdminData(result.data);
  //     }
  //   }
  //   catch (error) {
  //     setLoader(false)
  //     toast.error(error.message);
  //   }
  // }

  const adminId = localStorage.getItem('adminId');
     
  const getAdminDetailsApiData = async () => {
      try {
          const result = await getAdminDetails({
              id: adminId,
              "type": "admin"
          })
          setCategoryData(result.data.country)
      } catch (error) {
          console.log(error)
      }
  }

  useEffect(()=>{
    getAdminDetailsApiData()
  },[])

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }

  const userColumns = useMemo(
    () => [
      {
        Header: `${t('Status')}`,
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
        
          <div className="rounded-full bg-orange h-3 w-3"></div>
          :
          <div className="rounded-full bg-green h-3 w-3"></div>
      },
      {
        Header: `${t('Users')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            <img src={DummyDpImg} alt="" className="w-12 h-12 rounded-full" />
            <span className="ml-2">{userData.aliasName ? userData.aliasName : " - "}</span>
          </div>
        ),
      },
      {
        Header: `${t('Mobile_No.')}`,
        accessor: userData => (
          <div className="flex justify-start items-center">
            <span className="ml-2">{userData.phone ? userData.phone : " - "}</span>
          </div>
        ),
      },
      {
        Header: `${t('Age')}`,
        accessor: Value => {
          return (
            <>
              {
                <span>{Value && Value.age ? Value.age : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Language')}`,
        accessor: Value => {
          return (
            <>
              {
                <span>{Value && Value.language ? Value?.language?.name : " - "}</span>
              }
            </>
          )
        }
      },
      {
        Header: `${t('Country')}`,
        accessor: properties => {
          return (
            <>
              <span className='capitalize'>{`${properties?.country ? properties?.country?.name : ' - '}`}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Sector')}`,
        accessor: properties => {
          return (
            <>
              <span className='capitalize'>{`${properties?.sector ? properties?.sector?.name : ' - '}`}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Segment')}`,
        accessor: properties => {
          return (
            <>
              <span className='capitalize'>{`${properties?.segment?.name ? properties?.segment?.name : ' - '}`}</span>
            </>
          )
        }
      },
      {  
        Header: `${t("Action")}`,
        accessor: "_id",
        Cell: props => adminData[0]?.AdminType === "Coordination team" ? "" : <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')} </Button>
      },
      // {
      //   Header: `${t("Send_SMS")}`,
      //   accessor: "",
      //   Cell: props => <Button className="btn2" onClick={() => handleInvite(props)}> Send </Button>
      // },
      {
        Header: `${t("Status")}`,
        accessor: "deactivated",

        Cell: adminData[0]?.AdminType==="Coordination team"? "" :props =><Grid item>
          <AntSwitch
            checked={!props.row.original.deactivated}
            onChange={() => handleDeactivate(props)}
            name="isDirectory"
          />
        </Grid>
      }
    ], [adminData,data])
  //  console.log(searchValue,"searchValue")
  return (
    <>
      <div className="flex justify-between flex-wrap items-center " >
        {/* {
          searchValue == "" ?    : <div></div>
        } */}
         <div>
          <Pagination
            count={pageCount}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          />
        </div>
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
        {/* <div>
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label="Filter By Category" />}
          />
        </div> */}
      </div>
      {
        data && <UsersTable columns={userColumns} data={data} />
      }
    </>
  )
}

export default AllUsers;