
import TextField from '@material-ui/core/TextField';
import { MenuItem, Select } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { addFeedPOll } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"




const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));


const FeedsPoll = ({ getFeedData }) => {
    const classes = useStyles();

    const [choices, setChoices] = useState(['', ''])
    const [loader, setLoader] = useState(false);
    const [length, setLength] = useState(1);
    const [question, setQuestion] = useState("");
    const [location, setLocation] = useState("");
    const [choicesData, setChoicesData] = useState({});
    const [newChoiceData, setNewChoiceData] = useState([])
    const context = useContext(ModalContext);
    const { closeModal } = context;

    const handleChange = (event) => {
        setLength(event.target.value);
    };
    const handleChoices = () => {
        if (choices.length < 4) {
            let newArray = [...choices];
            newArray = [...newArray, ""];
            setChoices(newArray);
        }
    }
    const handleChoicesData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setChoicesData({
            ...choicesData,
            [name]: {
                title: value
            }
        })
    }
    const handleFormSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        const _choicesData = []

        for (const key in choicesData) {
            _choicesData.push(choicesData[key])
        }
        try {
            const result = await addFeedPOll({
                'question': question,
                'location': location,
                'choices': _choicesData,
                'length': length,
            });
            if (result.res.success) {
                toast.success(result.res.message);
                closeModal();
                getFeedData();
                setLoader(false);
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);

        }
    }



    return (
        <>
            <Loader loader={loader} />
            <form noValidate autoComplete="off" className="w-full" onSubmit={handleFormSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div>
                        <TextareaAutosize
                            name="question"
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder="Ask  Question"
                            minRows={3}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                        <TextField
                            name="location"
                            id="location"
                            label="Location"
                            className="w-full"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </div>
                    <div>

                        {
                            choices.map((choice, id) => (
                                <TextField
                                    name={`choices${id + 1}`}
                                    key={id}
                                    id={`outlined-basic${id + 1}`}
                                    label={`Choice ${id + 1}`}
                                    className={classes.textField}
                                    variant="outlined"
                                    value={choicesData[`choices${id + 1}`] ? choicesData[`choices${id + 1}`].title : ""}
                                    onChange={(e) => handleChoicesData(e)}
                                />
                            ))
                        }
                        <p className="cursor-pointer primary-color font-bold text-right mb-4" ><span onClick={() => handleChoices()}>+ Add Poll</span></p>
                        <div className="flex justify-between items-center w-full">
                            <p className="primary-color font-bold">Poll Length</p>
                            <Select
                                name="length"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={length}
                                onChange={handleChange}
                            >
                                <MenuItem value={1}>1 day</MenuItem>
                                <MenuItem value={2}>2 day</MenuItem>
                                <MenuItem value={3}>3 day</MenuItem>
                                <MenuItem value={3}>4 day</MenuItem>
                                <MenuItem value={3}>5 day</MenuItem>
                                <MenuItem value={3}>6 day</MenuItem>
                                <MenuItem value={3}>7 day</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        Add Poll
                    </button>
                </div>
            </form>
        </>
    )
}

export default FeedsPoll