import React, { useState, useContext } from 'react';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import { allAdminUsers, getGeneralNotification } from '../../Utils/services';
import { toast } from 'react-toastify';
import GeneralNotification from './GeneralNotification';
import Loader from "../Common/Loader"
import AddGeneralNotificationForm from '../ModalTemplate/AddGeneralNotificationForm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const a11yProps = (index) => {
    // console.log(index, "index, ")
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const Notification = ({ match }) => {
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const context = useContext(ModalContext)
    const [loader, setLoader] = useState(true)
    const { handleModalData } = context;
    const [generalNotificationData, setGeneralNotifucationData] = useState([]);
    const [adminData, setAdminData] = useState([]);
    const [Count, setCount] = useState();


    useEffect(() => {
         getAdminTableData();
      }, [])

    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
    };
    const getTablesData = async (sendData) => { 
        try {
            const result = await getGeneralNotification(sendData);
            // console.log(result, "This is results user All Data");
            if (result.res.success) { 
                setLoader(false)
                // toast.success(result.res.message)
                setGeneralNotifucationData(result.res.data);
                setCount(result.res.count)
            }
        }
        catch (error) {
            setLoader(false)
            console.log(error, "Error");
            toast.error(error.message);
        }
    }
    const tabsData = [
        {
            label: `${t("GENERAL")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: (
                <>
                    <Loader loader={loader} />
                    <GeneralNotification Count={Count} data={generalNotificationData} getTablesData={getTablesData} adminData={adminData} />
                </>
            )
        },
    ]


    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(<AddGeneralNotificationForm getTablesData={getTablesData} title="GENERAL NOTIFICATION" buttonText={t('Send')} />);
                break;
            default:
                handleModalData(<AddGeneralNotificationForm getTablesData={getTablesData} title="GENERAL NOTIFICATION" buttonText={t('Send')}/>);
                break;
        }
    }

    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />

            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}


export default Notification;

