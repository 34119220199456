import React from 'react'
import styles from "../../Assets/Comment.module.css";
import { useTranslation } from 'react-i18next';
// import inputbtn from "../Assets/images/Social/inputbtn.png"
import avtarImg from "../../Assets/images/DummyDp.png";
import moment from 'moment';
import { v4 as uuidv4  } from "uuid";
const InputText = ({index,items,replytrans,Ids,handleChangeButtons,handleReplyDeleteComment,userNameHandlers}) => {
  const { t } = useTranslation(["home"]);



  return (
                        <div className="mt-4">
                        <div className="grid grid-cols-6">
                          <div className="col-span-1">
                            <img
                              src={avtarImg}
                              className=" w-8 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="pl-2 col-span-5">
                            <p className={styles.comment}>
                              <span className={styles.name}>{items.isAdmin ? (
                                  <>{items.name} </>
                                ) : (
                                  userNameHandlers(items)
                                )}{" "}</span>
                              <span className="pr-2 text-red-500">
                                {items?.isAdmin ? "Moderator" : ""}
                              </span>
                               {items.content}
                               {
                                 Ids == index && <p className="text-justify text-sm">{replytrans}</p>
                               }
                            </p>
                              <button
                                 onClick={() => handleReplyDeleteComment(items?._id,items?.commentId)}
                                    className={`pl-2 text-red-500 ${styles.num}`}
                               >
                               Delete
                              </button>
                              <button
                            //   key={uuidv4()}
                                 onClick={() => handleChangeButtons({index, items})}
                                    className={`pl-2 text-600 ${styles.num}`}
                               >
                               Translate
                              </button>
                            <div className="flex justify-start pt-2">
                              <div className={styles.num}>
                                {/* {formatDate(items.date)} */}
                               { moment(items.date).fromNow()}
                              </div>
                              <div className="flex pl-4">
                                <span className={styles.num}>
                                  {items.likes && items.likes.length}
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className={styles.icon}
                                  width="21.144"
                                  height="18.658"
                                  viewBox="0 0 21.144 18.658"
                                >
                                  <g transform="translate(0 0)">
                                    <g transform="translate(0 0)">
                                      <path
                                        className="a"
                                        d="M19.479,1.715a5.681,5.681,0,0,0-8.086,0l-.8.8-.8-.8A5.718,5.718,0,0,0,1.715,9.8L10.6,18.683,19.479,9.8a5.681,5.681,0,0,0,0-8.086"
                                        transform="translate(-0.025 -0.025)"
                                      />
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              {/* <div className="flex pl-4"><span className={styles.num}>{items.replies && items.replies.length}</span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon} width="17.622" height="18.659" viewBox="0 0 17.622 18.659"><path className="a" d="M15.2-1.332H2.423A2.628,2.628,0,0,0,0,1.456v9a2.629,2.629,0,0,0,2.414,2.788v4.084l5.1-4.083H15.2a2.628,2.628,0,0,0,2.423-2.789v-9A2.628,2.628,0,0,0,15.2-1.332ZM12.906,9.126H4.717V7.937h8.189Zm0-2.535H4.717V5.4h8.189Zm0-2.535H4.717V2.867h8.189Zm0,0" transform="translate(0 1.332)" /></svg>
                                                                </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

  )
}

export default InputText