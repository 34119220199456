import { Button } from "@material-ui/core";
import { ModalContext } from '../../Context'
import { useContext, useState } from "react";
import { useTranslation } from 'react-i18next';



const RemoveConfirmation = ({ title, data, action,id }) => {
    const {t} = useTranslation();
    const context = useContext(ModalContext)
    const { closeModal } = context;

    const clickHandler = async () => { 
        if(title === "DELETE GALLERY POST"){
        const res = await action(id);
        }
        else if(title === "suggested Topic"){ 
            const res = await action({
                id:id
            });
        } 
        else if(title === "Delete Course"){
            const res = await action({
                courseId:id
            });
        } 
        else if(title === "Delete Session"){
            const res = await action({
                sessionId:id
            });
        } 
        else if(title === "Remove SubSession"){
            const res = await action({
                sub_sessionId:id
            });
        } 
        else if(title === "Delete Question"){
            const res = await action({
                questionId:id
            });
        } 
        else{
                const res = await action(id);
            }
        
        closeModal()
    }

    return (
        <div className="warning-modal">
            <div className="flex items-center justify-center">
                <h3 className="title"> { title } </h3>
            </div>
            <div className="flex items-center justify-center text-center" dangerouslySetInnerHTML={{ __html: data }} />
    
            <div className="flex justify-between mx-auto mt-10">
                <Button className="btn2" onClick={closeModal}>
                    {t('Cancel')}
                </Button>

                <Button
                    variant="outlined"
                    color="secondary"
                    className="approve_decline_padding danger-bg"
                    onClick={clickHandler}
                >
                    <span className="cross-icon" />
                    <span className="pl-1"> {t('Remove')}  </span>
                </Button>
            </div>
        </div>
    )
}

export default RemoveConfirmation;