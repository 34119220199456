import React, { useState, useContext, useEffect } from 'react';
import { FEEDS_FORM, GALLERY_FORM, STORY_FORM,NEWSFEED_FORM} from '../ModalTemplate';
import { ModalContext } from '../../Context'
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import {fetchManageFlag, fetchManagePostFlag, fetchRemovedFlagPost,fetchStoryRemovedFlagPost, getNewsFeedApi,fetchManageStoryPostFlag, allAdminUsers} from '../../Utils/services';
import { toast } from 'react-toastify';
import Newsfeedpage from './Newsfeedpage';
// import ManageStory from './ManageStory';
// import RemovedFlagStory from './RemovedFlagStory';
import { useTranslation } from 'react-i18next';


const a11yProps = (index) => {
    return {
        id: `users-tab-${index}`,
        'aria-controls': `users-tabpanel-${index}`,
    };
}

const NewsFeed = () => {
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const [postData, setPostData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [totalCount, setotalCount] = useState()
    const [flagData, setFlagData] = useState([])
    const [removedData, setRemovedData] = useState([]);
    const [adminData, setAdminData] = useState([]);

    const context = useContext(ModalContext)
    const { handleModalData } = context;

    const getAdminTableData = async () => {
        try {
          const result = await allAdminUsers();
          if (result.data.length > 0) {
            
            setLoader(false)
            setAdminData(result.data);
          }
        }
        catch (error) {
          setLoader(false)
          toast.error(error.message);
        }
      }

    const getForumsDataApi = async (data) => {  
        try {
            const result = await getNewsFeedApi(data);
            
            setPostData(result.res.data)
            
             setotalCount(result.res.count)
        }
        catch (error) {
            setLoader(false)
            console.log(error)
            toast.error(error.message);
        }
    }

    const getManageFlag = async () => {
        try {
            const res = await fetchManageStoryPostFlag()
            let _data = res?.data?.commentdata;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = result[0].concat(res?.data?.flaggedReply)
            setFlagData(_AllData);
            // toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }
    const getRemovedFlag = async () => {
        try {
            const res = await fetchStoryRemovedFlagPost();
            let _data = res?.data?.removedComment;
            const commentData = _data?.map((item) => {
                return item.comments
            })
            const result = Object.entries(
                commentData.reduce((r, { ReportTime, ...rest }) => {
                    r[ReportTime] = (r[ReportTime] || []).concat(Object.values(rest));
                    return r;
                }, {})
            ).map(([k, v]) => [...v]);
            let _AllData = result[0].concat(res?.data?.flaggedReply)
            setRemovedData(_AllData);
            toast.success(res.message)
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() =>{
        getAdminTableData();
        // getManageFlag();
    }, []);

    const handlerTabs = (event, newValue) => {
        setTabsValue(newValue);
    };
    const tabsData = [
        {
            label: `${t("NewsFeed")}`,
            isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
            data: <Newsfeedpage totalCount ={totalCount}  data={postData} getForumsDataApi={getForumsDataApi} adminData={adminData} />
        },
        // {
        //     label: "Manage Story",
        //     isNotshowAdd: true,
        //     data: <ManageStory flagData={flagData} getManageFlag={getManageFlag}  />
        // },
        // {
        //     label: "Removed Story",
        //     isNotshowAdd: true,
        //     data: <RemovedFlagStory flagData={removedData} getManageFlag={getRemovedFlag} />
        // }
    ]

    const handleModalForm = () => {
        switch (tabsValue) {
            case 0:
                handleModalData(NEWSFEED_FORM(true, getForumsDataApi));
                break;

            default:
                handleModalData(NEWSFEED_FORM(true, getForumsDataApi));
                break;
        }
    }
    return (
        <div className="relative">
            <InnerHeader
                tabsData={tabsData}
                handleModalForm={handleModalForm}
                tabsValue={tabsValue}
                handlerTabs={handlerTabs}
                a11yProps={a11yProps}
                formBtn
            />
            {
                tabsData.map((obj, index) => (
                    <TabPanel
                        className="px-10 homemainresponsive"
                        value={tabsValue}
                        index={index}
                    >
                        {obj.data}
                    </TabPanel>
                ))
            }
        </div>
    );
}
export default NewsFeed;

