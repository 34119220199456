import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const ReactCalendarPage = ({minihubData,liData, eventAllData, showEvent, value, onChange, activeStartDateChange }) => {  
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }
   
   


    const handleTilesClassName = ({ date }) => {
     if(minihubData == undefined && eventAllData == undefined){
        const _newData = [];
        liData && liData.map((item) => {
            _newData.push(item.current)
        })
       const _res = _newData.filter((item) => {
            return formatDate(item) === formatDate(date)
        })

        for (let x in _newData) {
            if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
                return "cutome_calander_class"
            }
        }
     } else if(liData == undefined && eventAllData == undefined){
        const _newData = [];
        minihubData && minihubData.map((item) => {
            _newData.push(item.current)
        })
       const _res = _newData.filter((item) => {
            return formatDate(item) === formatDate(date)
        })

        for (let x in _newData) {
            if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
                return "cutome_calander_class"
            }
        }
     } else if(liData == undefined && minihubData == undefined){
        const _newData = [];
        eventAllData && eventAllData.map((item) => {
            _newData.push(item.current)
        })
       const _res = _newData.filter((item) => {
            return formatDate(item) === formatDate(date)
        })

        for (let x in _newData) {
            if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
                return "cutome_calander_class"
            }
        }
     }
    }

    return (
        <>
            <Calendar
                onChange={onChange}
                events={showEvent}
                value={value}
                onActiveStartDateChange={activeStartDateChange}
                // className="cutome_calander_class"
                tileClassName={handleTilesClassName}
                
            />
        </>
    );
}

export default ReactCalendarPage
