import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import AntSwitch from '../Common/AntSwitch'
import { RadioGroup, FormControlLabel, Radio, TextareaAutosize } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { editFaq, editUser, getLanguageList, getSectorList, getSegmentApi, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,ContentState,convertFromHTML } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
const FaqEdit = ({
    title,
    buttonText,
    getAdminFAQTableData,
    id,
    perticularData
}) => {  
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [language, setLanguage] = useState(perticularData?.language?.name || []);
    const [formData, setFormData] = useState(perticularData ? perticularData : {} || "");
    const [gender, setGender] = useState(perticularData?.gender);
    const [loader, setLoader] = useState(false);
    const [isApprove, setIsApprove] = useState(perticularData?.isApproved)
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);

    const [article, setArticle] = useState("");
    const [editorState, setEditorState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(
        convertFromHTML(perticularData?.answer)
    )))
    const { t } = useTranslation();
    const onEditorStateChange = (editorState) => { 
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
   
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const getSegmentData = async () => {
        try {
            const result = await getSegmentApi();
            let _resultData = result?.res?.data?.filter((data) => {
                return data?.country?.name === perticularData?.country?.name
            })
            setSegmentData(_resultData)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    useEffect(() => {
        getSegmentData();
    }, []);
    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }

    useEffect(() => {
        getLanguageListData();
    }, [])

    const onInputLanguageChange = (event, newInputValue, name) => {
        setLanguage({
            ...language,
            [name]: newInputValue
        })
    }
 




    const getLanguageListData = async () => {
        try {
            const result = await getLanguageList();
            setLanguageList(result?.res?.data?.languages)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }
    const context = useContext(ModalContext);
    const { closeModal } = context;


    const onChangeHandler = (e)=> {  
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({...formData, [name]: value})
    }

    const handleSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        // const addFormData = new FormData();
        // addFormData.append("name", formData.name);
        // addFormData.append("aliasName", formData.aliasName);
        // addFormData.append("language", language?.language?.name ? language?.language?.name : perticularData?.language?.name);
        // addFormData.append("gender", gender);
        // addFormData.append("age", formData.age);
        // addFormData.append("sector", sector?.sector?.name ? sector?.sector?.name : perticularData?.sector?.name);
        // addFormData.append("isApproved", isApprove);
        // addFormData.append("segment", segment?.segment?.name ? segment?.segment?.name : perticularData?.segment?.name);
        // addFormData.append("id", id);
        
        try {
            const results = await editFaq({
                "language" : language?.language?.name? language?.language?.name : language,
                "quation":  formData?.quation ? formData?.quation : "" ,
                "answer" :  article ? article : perticularData?.answer,
                "id"    :  id
            });
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("FAQ updated successfully");
                getAdminFAQTableData();
                setLoader(false);
                closeModal();
               
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.messages);
        }
    }

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form autoComplete="off" className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                <div className='col-span-2'>
                    <TextareaAutosize
                       name="quation"
                       value={formData.quation || ''}
                       onChange={(e) => onChangeHandler(e)}
                       className="w-full"
                       id="Question"
                       aria-label="Empty"
                       placeholder={t('Question')}
                       minRows={3}
                    />
                  </div>
                  <div className='editorSectionStart col-span-2'>
                        <Editor
                        placeholder='Answer'
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                          
                        />
                      </div>
                
                    <Autocomplete
                        id="combo-box-demo2"
                        options={languageList?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        defaultValue={perticularData?.language}
                        sx={{ width: 300 }}
                        value={language?.name}
                        onChange={(e, newVal) => onInputLanguageChange(e, newVal, 'language')}
                        renderInput={(params) => <TextField {...params} label={t('Language')} />}
                    />
                
                    {
                        perticularData?.country?.name &&
                        <Autocomplete
                            id="combo-box-demo"
                            options={segmentData?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name || ""}
                            value={segment?.name}
                            defaultValue={perticularData?.segment}
                            onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                            renderInput={(params) => <TextField {...params} label={t('Segment')}  />}
                        />
                    }
                 
                 
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                        {buttonText}
                    </button>
                </div>
            </form>
        </>
    )
}

export default FaqEdit;