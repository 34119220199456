import classNames from "classnames"
import { useEffect, useState } from "react";
import ReactHlsPlayer from 'react-hls-player';
import Slider from "react-slick";
import { BASE_URL, BASE_URL_IMG, getAdminDetails,TRANSLATE_URL } from "../../Utils/services";
import styles from "../../Assets/Comment.module.css";
import { PREVIEW_PDF } from "../../Utils/routes";



const settings = {
    speed: 800,
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    dots: true,
    arrows: false
};

// const TRANSLATE_URL =`https://translation.googleapis.com/language/translate/v2?key=AIzaSyC2SUlb9pljbtvOW5w_iqJK0GM_DVCzLGk`;

const PostDetail = ({
    data,
    isModalView,
}) => { 

    const [translateLanguage, settranslateLanguage] = useState();
    const [commenttrans,setcommenttrans] =useState("")
    const [commenttranss,setcommenttranss] =useState("")
    

    useEffect(() => {
        getAdminDetailsApiData();
      }, [])
      const adminId = localStorage.getItem('adminId');
         
      const getAdminDetailsApiData = async () => {
          try {
              const result = await getAdminDetails({
                  id: adminId,
                  "type": "admin"
              })
              settranslateLanguage(result.data.language[0].name)
          } catch (error) {
              console.log(error)
          }
      }
      
      function removeHtmlTagsAndSpaces(str) {
        str = str.replace(/<[^>]+>/g, '');
        str = str.replace(/&nbsp;/g, ' ');
        str = str.replace(/\s+/g, ' ');
        str = str.trim();
        return str;
      }
      const handleChangeButton =()=>{ 
        let toLang = translateLanguage; // translate to norwegian
        let temp;
        // let text =`${qusTion}`; 
        let textContent =`${data?.question ? data?.question : data?.title}`
        let textContents =`${removeHtmlTagsAndSpaces(data.content ? data.content :  data.caption)}`
        if(toLang === "English"){
          temp = "en";
        }else if(toLang === "हिंदी"){
          temp = "hi"
        }
        else if(toLang === "বাংলা"){
          temp = "bn"
        }
        else if(toLang === "မြန်မာစာ"){
          temp = "my"
        }
        else if(toLang === "Bahasa"){
          temp = "id"
        }
        else if(toLang === "অসমীয়া"){
          temp = "as"
        }
        else if(toLang === "Melayu"){
          temp = "ms"
        }
        else if(toLang === "नेपाली"){
          temp = "ne"
        }
        else if(toLang === "ไทย"){
          temp = "th"
        }
        else if(toLang === "ខ្មែរ"){
          temp = "km"
        }
        let url =TRANSLATE_URL;
        url += '&q=' + encodeURI(textContents);
        url += '&q=' + encodeURI(textContent);
        url += `&target=${temp}`;
        const token = localStorage.getItem("token");
        
        fetch(url, { 
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`, // notice the Bearer before your token
          }
        })
        .then(res => res.json())
        .then((response) => {
           setcommenttrans(response.data.translations[0].translatedText)
           setcommenttranss(response.data.translations[1].translatedText)
        //    setId(index)
         
        })
        .catch(error => {
          console.log("There was an error with the translation request: ", error);
        });
      
      }

    const checkLink = url => {
        const linkType = url.split('.').slice(-1)[0]
        if (linkType === 'm3u8') {
            return (
                <ReactHlsPlayer
                    src={url}
                    autoPlay={false}
                    controls={true}
                    width="100%"
                    height="auto"
                    hlsConfig={{
                        maxLoadingDelay: 4,
                        minAutoBitrate: 0,
                        lowLatencyMode: true,
                    }}
                />
            )
        }
        else {
            return <img src={`${BASE_URL_IMG}${url}`}  />
        }
    }
console.log(data && data,"data && data?.pdf_media")
    return (
        <>
            {
                data && (
                    <div className={classNames({
                        'post-detail': true,
                        'relative': true,
                        'modal-view': isModalView,
                        'without-text': (data?.media?.length === 0 && !data?.isPoll)
                    })}>
                        <>
                         
                            <div className="content">
                                <Slider {...settings}>
                                    {
                                        data?.media?.map(item => (
                                            <div className="w-full">
                                                {checkLink(item.link)}
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                            <div className = "viewtranslate">
                            <button
                      // onClick={() => handleChangeButton(data.content)}
                      onClick={()=>handleChangeButton({data})}
                      className={`pl-2 text-600 ${styles.num}`}
                    >
                    Translate
                    </button>
                    </div>
                            <div className="footer p-2 pr-16">
                                <h3 className="text-md font-semibold tracking-wider mb-1"> {data?.question ? data?.question : data?.title} </h3>
                                <p className="text text-sm">
                                    <div
                                        dangerouslySetInnerHTML={{ __html: data.content ? data.content :  data.caption }}
                                    />
                                </p>
                            </div>
                            <div className="footer p-2 pr-16">
                                <h3 className="text-md font-semibold tracking-wider mb-1"> {commenttranss} </h3>
                                <p className="text text-sm">
                                   {commenttrans}
                                </p>
                            </div>
                            <div className="storydownloads flex">
                            <div className="flex px-2 pt-2 comment-bg">
                    <span className={styles.num}>{data && data?.view?.length}</span>
                    <img src="../../assets/comment/eye.svg" className={styles.icon} alt="comment" />
                </div>
                
                            {
                    data && data?.pdf_media &&  data?.pdf_media[0]?.pdfType == "file" ?
                    <div className="flex px-2 pt-2 comment-bg">
                      
                      <a href={`${BASE_URL}${PREVIEW_PDF}?link=${data?.pdf_media[0]?.linkPDF}`}  target="_blank" rel="noopener noreferrer">Preview pdf</a>

                    
                </div> : null
                }
                </div>
                        </>
                    </div>
                )
            }
        </>
    )
}

export default PostDetail