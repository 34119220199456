import styles from "../../Assets/Comment.module.css";
import {
  addGalleryCommentForum,
  BASE_URL_IMG,
  deleteCommentGallery,
  deleteReplyCommentForum,
} from "../../Utils/services";
import avtarImg from "../../Assets/images/DummyDp.png";
import { TextField } from "@material-ui/core";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { FunctionGalleryContext } from "../../Context";
import { useEffect } from "react";
import React from 'react'
import CommentGallerydatabox from "./CommentGallerydatabox";
import { useTranslation } from "react-i18next";

const CommentGalleryBox = ({
  commentBoxDataProps,
  singlePostData,
  postId,
  totalCount,
}) => { 
  const { postDatas, getGalleryDataApi, GalleryDatas, getGalleryDataApis } = useContext(FunctionGalleryContext);
  const [showReplyInput, setShowReplyInput] = useState({});
  const [comment, setComment] = useState("");
  const [reply, setReply] = useState("");
  const [showPerticularData, setShowPerticularData] = useState(singlePostData.reverse());
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const [aliasName, setAliasName] = useState();
  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const {t} = useTranslation();
  const handleSubmitComment = async (e) => {
    e.preventDefault();
    try {
      const result = await addGalleryCommentForum({
        postId: postId,
        comment: comment,
      });
      if (result.res.success) {
        toast.success(result.res.message);
        setComment("");
        getGalleryDataApi({ page, postsPerPage });
        getGalleryDataApis()
      }
      console.log(result);
    } catch (error) {
      toast.error(error.message);
    }
  };



  useEffect(() => {
    // let _result = postDatas?.filter((items) => {
    //   return items?._id === postId;
    // });
    // setShowPerticularData(_result && _result[0]?.comments);
    setShowPerticularData(GalleryDatas?.comments.reverse());
  },[GalleryDatas]);
  useEffect(() => {
    getGalleryDataApis();
  }, [])


  // const userNameHandlers = (item) => {
  //   let repliesdata = item.replies?.map((item) => {
  //     return item?.user;
  //   });
  //   for (const x in repliesdata) {
  //     let result = commentBoxDataProps.user_reply.filter(
  //       (items) => items._id === repliesdata[x]
  //     );
  //   //   let AlternateName = result[0];
  //   // return AlternateName?.aliasName;
  //   console.log(result, "result666")
  //   }
   
    
  // };

  return (
    <>
      {showPerticularData &&
        showPerticularData.map((data ,index) => {
          return (
            <React.Fragment key={index}>
              <CommentGallerydatabox data={data} totalCount={totalCount} postId={postId} commentBoxDataProps={commentBoxDataProps}/>
           </React.Fragment>
          );
        })}
      <div className="grid grid-cols-8 my-6">
        <div className="col-span-1 flex justify-center items-center">
          <img src={avtarImg} className=" w-12 rounded-full" alt="" />
        </div>
        <div className="col-span-5">
          <form
            onSubmit={handleSubmitComment}
            className="flex justify-center items-center"
          >
            <input
              type="text"
              name="comment"
              className="commentSectionInput"
              placeholder={t("Comments")}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CommentGalleryBox;
