import React, { useState, useContext, useEffect } from 'react';
import { USER_FORM } from '../ModalTemplate';
import { TabPanel } from "../Common/TabPanel"
import InnerHeader from '../Common/InnerHeader';
import { ModalContext } from '../../Context'
import { allAdminUsers, allUsers, getResources } from '../../Utils/services';
import { toast } from 'react-toastify';
import AdminForm from '../ModalTemplate/AdminForm';
import UserForm from '../ModalTemplate/UserForm';
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import AllResources from './AllResources';
import AddResourcesForm from '../ModalTemplate/AddResourcesForm';


const a11yProps = (index) => {
  // console.log(index, "index, ")
  return {
    id: `users-tab-${index}`,
    'aria-controls': `users-tabpanel-${index}`,
  };
}

const Resources = ({match}) => {

  const {t} = useTranslation();

  const [tabsValue, setTabsValue] = useState(0);
  const [ResourcesData, setResourcesData] = useState([]);
  const context = useContext(ModalContext)
  const { handleModalData } = context;
  const [loader, setLoader] = useState(true);
  const [adminData, setAdminData] = useState([]);
  const [totalCount, setotalCount] = useState()


  const getAdminTableData = async () => {
    try {
      const result = await allAdminUsers();
      if (result.data.length > 0) {
        
        setLoader(false)
        setAdminData(result.data);
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message);
    }
  }

  const handlerTabs = (event, newValue) => {
    setTabsValue(newValue);
  };

  const getTablesData = async (data) => {
    try {
      const result = await getResources(data);
      
      setResourcesData(result?.res?.data)
      setotalCount(result?.res?.pagination)
      setLoader(false)
    }
    catch (error) {
      setLoader(false)
      console.log(error, "Error");
      toast.error(error.message);
    }
  }

  useEffect(() => {
    // getTablesData();
    getAdminTableData();
  }, [])
  
  const tabsData = [
    {
      label: `${t("Resources")}`,
      isNotshowAdd : adminData[0]?.AdminType === "Coordination team" ? true : false,
      data: (
        <>
          <Loader loader={loader} />
          <AllResources totalCount={totalCount?.totalCount && totalCount?.totalCount} data={ResourcesData} getTablesData={getTablesData} adminData={adminData} />
        </>
      )
    },
  ]

  const handleModalForm = () => {
    switch (tabsValue) {
      case 0:
        handleModalData(<AddResourcesForm getTablesData={getTablesData} title="add Resources" buttonText="add resources" />);
        break;
      default:
        handleModalData(<AddResourcesForm getTablesData={getTablesData} title="add Resources" buttonText="add resources"  />);
        break;
    }
  }
  // console.log(tabsData, "tabsData")

  return (
    <div className="relative">
      <InnerHeader
        tabsData={tabsData}
        handleModalForm={handleModalForm}
        tabsValue={tabsValue}
        handlerTabs={handlerTabs}
        a11yProps={a11yProps}
        formBtn
      />

      {
        tabsData.map((obj, index) => (
          <TabPanel
            className="px-10 homemainresponsive"
            value={tabsValue}
            index={index}
          >
            {obj.data}
          </TabPanel>
        ))
      }
    </div>
  );
}


export default Resources;

