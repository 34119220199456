import { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { resetPassword } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import { useNavigate } from 'react-router-dom';


const _formData = {
    oldPassword: '',
    newPassword: '',
    newPassword2: ''
}

const ResetPassword = () => {
    const [formData, setFormData] = useState(_formData)
    // const [submitDisable, setSubmitDisable] = useState(true)
    const context = useContext(ModalContext)
    const { closeModal } = context;
    const [errors, setErrors] = useState({});
    const history = useNavigate();


    // useEffect(() => {
    //     const isDisable = (formData.oldPassword == '' || formData.newPassword == '' || formData.newPassword2 == '')
    //     setSubmitDisable(isDisable)
    // }, [formData])


    const onChangeHandler = e => {
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleValidation = () => {
        let _errors = {};
        let formIsValid = true;
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;
        let specialCharaceter = /[!@#\$%\^&\*_]/
        if (!formData.oldPassword) {
            formIsValid = false;
            _errors["oldPassword"] = "Old Password can not be empty";
        }
        else if (!formData.newPassword) {
            formIsValid = false;
            _errors["newPassword"] = "Password can not be empty";
        }
        else if (!formData.newPassword.match(lowerCaseLetters)) {
            formIsValid = false;
            _errors["newPassword"] = "Password should be atleast one lowercase";
        }
        else if (!formData.newPassword.match(upperCaseLetters)) {
            formIsValid = false;
            _errors["newPassword"] = "Password should be atleast one Upercase";
        }
        else if (!formData.newPassword.match(numbers)) {
            formIsValid = false;
            _errors["newPassword"] = "Password should be atleast one number";
        }
        else if (formData.newPassword.length < 8) {
            formIsValid = false;
            _errors["newPassword"] = "Password should be 8 charaters";
        }
        else if (!formData.newPassword.match(specialCharaceter)) {
            formIsValid = false;
            _errors["newPassword"] = "Password should be atleast one special character";
        }
        else if (formData.newPassword !== formData.newPassword2) {
            formIsValid = false;
            _errors["newPassword2"] = "Password are not match";
        }
        setErrors(_errors)
        return formIsValid;
    }

    const changePassword = async () => {
        try {
            const result = await resetPassword(formData);
            console.log(result);
            if (result.res.success) {
                toast.success(result.res.message)
                closeModal();
                history('/login')

            }
        }

        catch (e) {
            console.log(e);
            toast.error(e.message);
        }
    }


    const handleSubmit = e => {
        e.preventDefault()
        if (handleValidation()) {
            changePassword();
        }
    }


    return (
        <>
            <div className="flex items-center justify-center">
                <h3 className="title"> Reset Password </h3>
            </div>

            <form noValidate autoComplete="off" className="w-full" onSubmit={e => handleSubmit(e)} >
                <div className="flex flex-col w-full">
                    <div className="mb-4">
                        <TextField
                            id="oldPassword"
                            label="Enter Old Password"
                            className="w-full mb-5"
                            type="password"
                            name="oldPassword"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {
                            errors.oldPassword && <p className="text-sm text-left text-red-800">{errors.oldPassword}</p>
                        }
                    </div>

                    <div className="mb-4">
                        <TextField
                            id="newPassword"
                            name="newPassword"
                            label="Enter New Password"
                            className="w-full mb-5"
                            type="password"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {
                            errors.newPassword && <p className="text-sm text-left text-red-800">{errors.newPassword}</p>
                        }
                    </div>
                    <div className="mb-4">
                        <TextField
                            id="newPassword2"
                            name="newPassword2"
                            label="Confirm Password"
                            className="w-full mb-5"
                            type="password"
                            onChange={(e) => onChangeHandler(e)}
                        />
                        {
                            errors.newPassword2 && <p className="text-sm text-left text-red-800">{errors.newPassword2}</p>
                        }
                    </div>
                </div>


                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" type="submit">
                        Submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default ResetPassword;