import Table from '../../Common/Table';
import { useMemo } from 'react';
import { Button } from "@material-ui/core";
import { useEffect, useState, useContext } from 'react';
import { getCountriesData } from '../getRequests';
import AddCountry from '../../ModalTemplate/AddCountry'
import { ModalContext } from '../../../Context'
import { BASE_URL_IMG, getSectorList, deleteTag } from '../../../Utils/services';
import { toast } from 'react-toastify';
import DummyDpImg from "../../../Assets/images/DummyDp.png"
import AddSector from '../../ModalTemplate/AddSector';
import AddTags from '../../ModalTemplate/AddTags';
import { useTranslation } from 'react-i18next';
import RemoveConfirmation from '../../ModalTemplate/RemoveConfirmation';
const Tag = ({ tags, geTagsApi, adminData }) => {
    const context = useContext(ModalContext)
    const { handleModalData } = context;
    const { t } = useTranslation();
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const editHandler = (data) => {  
        const EDIT_COUNTRY = <AddTags
            title={t('EDIT_TAG')}
            id={data.cell.row.original._id}
            countryName={data.cell.row.original.country}
            name={data.cell.row.original.name}
            langauge={data.cell.row.original.language}
            geTagsApi ={geTagsApi}
            btnText={t('SAVE')}
            edit
        />
        handleModalData(EDIT_COUNTRY, 'md')
    }

    const handledeleteTag = async (item) => {  
        try {
            const result = await deleteTag({
                id: item.cell.row.original._id
            });
            if (result.res.success) {
                // toast.success(result.res.message)
                toast.success("Tag deleted successfully")
                geTagsApi()
            }

        } catch (error) {
            toast.error(error.messages)
        }
    }

    const HandleRemoveEvent = (id) => {
        const REMOVE_CONFIRMATION = (
          <RemoveConfirmation
            title={t('Remove_Tags')}
            action={handledeleteTag}
            data={t('TagRemoveText')}
            id={id}
          />
        );
        handleModalData(REMOVE_CONFIRMATION, "sm");
      };

    const countryColumns = useMemo(
        () => [
            {
                Header: `${t('Tag')}`,
                accessor: 'name',
                Cell: ({ cell: { value } }) => <span className="capitalize pl-0">{value}</span>
            },
            {
                Header: `${t('Added_On')}`,
                accessor: 'createdOn',
                Cell: ({ cell: { value } }) => new Date(value).toDateString()
            },
            {
                Header: `${t('Country')}`,
                accessor: 'country',
                Cell: ({ cell: { value } }) => <div className='flex items-center'>{value?.name}</div>
            },
            {
                id: "view",
                Header: `${t('Action')}`,
                accessor: "_id",
                Cell: adminData[0]?.AdminType === "Coordination team" ? "" : props => <Button className="btn2" onClick={() => editHandler(props)}> {t('Edit')}  </Button>,
            },
            {
                id: "Delete",
                Header: `${t('Action')}`,
                accessor: "_id",
                Cell: adminData[0]?.AdminType === "Coordination team" ? "" : props => <Button className="btn2" onClick={() => HandleRemoveEvent(props)}> {t('Delete')} </Button>,
            }
        ],
        []
    )

    return (
        <>
            {
                tags &&
                <Table columns={countryColumns} 
                // data={tags.sort(dynamicSort("name"))} 
                data={tags} 
                
                />
            }
        </>
    )
}

export default Tag