import { useMemo } from "react"
import FeedsTable from "../Feeds/FeedsTable"
import { BASE_URL_IMG, flagGalleryReinstate, flagStoryReinstate, getStoriesCommentData, getStoryMediaData, singleStoryApi } from "../../Utils/services"
import { useNavigate } from "react-router-dom"
import { Button } from "@material-ui/core";
import { toast } from "react-toastify"
import { useEffect } from "react";
import StoryTable from "./StoryTable";
import { useTranslation } from 'react-i18next';


const RemovedFlagStory = ({ flagData, getManageFlag, postId,adminData }) => {
    const history = useNavigate();
    useEffect(() => {
        getManageFlag();
    }, [])
    const { t } = useTranslation();
    // const showPostDetail = async (postitem , data) => {
    //     try {
    //         const result = await singleStoryApi(postitem)
    //         let sendData = result?.res?.data;
    //         history.push({
    //             pathname: `Story/${postitem}`,
    //             state: {
    //                 data: sendData,
    //                 title: 'Story Report Details'
    //             }
    //         })
            
    //     } catch (error) {
    //         console.log(error.message)
    //     }

    // }
    const showPostDetail = async (item,data) => {  
        localStorage.setItem('items', item);
        try {
            const result = await getStoriesCommentData({
                id: item
            });
            const results = await getStoryMediaData({
                id: item
            });
            if (result.success) {
                // toast.success(result.res.message)
                // getGalleryDataApi({ page, postsPerPage });
                 history(`${item}`,{
          
            state: {
                data: result?.data[0],
                title: 'Story Details',
                // count: totalCount,
                mediadata:results?.data,

            }
        })
            }

        } catch (error) {
            toast.error(error.messages)
        }
       
    }
    const formatDate = date => {
        const _date = new Date(date)
        const month = _date.getMonth() + 1
        const year = _date.getFullYear()
        const newdate = _date.getDate()
        return `${newdate}/${month}/${year}`
    }


    const reinstateHandler = async (id, DataId, commentId) => {
        try {
            if (commentId !== undefined) {
                const res = await flagStoryReinstate({
                    id: id,
                    commentId: commentId,
                    replyId: DataId
                })
                // toast.success(res.message)
                toast.success("Story Reinstated Successfully")
                getManageFlag();
            }
            else{
                const res = await flagStoryReinstate({
                    id: id,
                    commentId: DataId,
                })
                toast.success(res.message)
                getManageFlag();
            }

        } catch (error) {
            toast.error(error.message)
        }
    }

    const FeedsColumns = useMemo(
        () => [
            {
                Header: `${t('Comment/Reply')}`,
                accessor:  data => {
                    return (
                        <div className="my-12 flex justify-center flex-col">
                            {
                                data && data.media && data.media[0] &&
                                <img src={`${BASE_URL_IMG}${data?.media[0]?.link}`} alt="" className="w-72" />
                            }
                            <span className="mt-2 max-w-xs">{data?.content}</span>
                        </div>
                    )
                }
            },
            {
                Header: `${t('Removed_By')}`,
                accessor: data => {
                    return (
                        <>

                            <div className="flex my-12 justify-start items-center">
                                <span className="ml-2">{data?.removed?.removedBy?.name ? data?.removed?.removedBy?.name : " - "}</span>
                            </div>
                        </>
                    )
                }
            },
            {
                Header: `${t('Removed_On')}`,
                accessor: data => {
                    return (
                        <>
                            <div className="flex my-12 justify-start items-center">
                                <span className="ml-2">{data?.removed?.removedOn ? formatDate(data?.removed?.removedOn) : " - "}</span>
                            </div>
                        </>
                    )
                }
            },
            {
                Header: `${t('Issue')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{item?.issue}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Comments')}`,
                accessor: data => {
                    return (
                        <>
                            {
                                data?.flag?.report?.map((item) => {
                                    return (
                                        <div className="flex my-12 justify-start items-center">
                                            <span className="ml-2">{item?.comments}</span>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            },
            {
                Header: `${t('Type')}`,
                accessor: data => {
                    return (
                        <>
                            <span>{data.postId && data.commentId ? "Reply" : "Comment"}</span>
                        </>
                    )
                }
            },
            {
                Header: `${t('Action')}`,
                accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
                    return (
                        <div className="footer -ml-2 my-6" >
                            <Button
                                variant="outlined"
                                className="approval_button_style w-40"
                                onClick={() => reinstateHandler(data?.postId, data?._id, data?.commentId)}
                            >
                                <span className="pl-1">
                                {t('REINSTATE_POST')}
                                </span>
                            </Button>
                        </div>
                    )
                }
            },
            {
                Header: `${t('View_Post')}`,
                accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
                    return (
                        <button
                            className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
                            type="button"
                            key={postId}
                            onClick={() => showPostDetail(data.postId, data)}
                        >
                            <span className="MuiButton-label">{t('View')}</span>
                        </button>
                    )
                }
            },

        ], [adminData])

    return (
        <>
            {flagData &&
                <StoryTable columns={FeedsColumns} data={flagData} />
            }

        </>
    )
}

export default RemovedFlagStory