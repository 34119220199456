import React from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "../../routes";
import Home from "../Home";

const MainWrapper = ({ match, handleModalData, isModalOpen, modalData }) => {
  return (
    <>
      <Routes>
        {routes.map((route, i) => {
          return (
            <Route
              exact
              key={i}
              path={`${route.path}`}
              element={
                <route.component
                  modalData={modalData}
                  handleModalData={handleModalData}
                  isModalOpen={isModalOpen}
                  // {...routeProps}
                />
              }
            />
          );
        })}
        {/* <Route path="/" element={<Home />}></Route> */}
      </Routes>
    </>
  );
};

export default MainWrapper;
