import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState,useRef } from 'react';
import { addFeedPost, addForumPost, getTags, regionCountryList,addStoryPost, getAdminDetails } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import "cropperjs/dist/cropper.css";
import {Cropper} from 'react-cropper';

const StoryForm = ({ getFeedData, title }) => {
    const [isFeatured, setIsFeatured] = useState(false)
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState({});
    const [imgFile, setImgFile] = useState('');
    const [imgFile2, setImgFile2] = useState('');
    const [imgFile3, setImgFile3] = useState('');
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo")
    const [pdfFile, setPdfFile] = useState('');
    const [mediaTypepdf, setMediaTypepdf] = useState("file");
    const [disablePdf, setDisablePdf] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
    const [showImgError, setshowImgError] = useState(false);
    const { t } = useTranslation();
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }
    const [isNotification, setIsNotification] = useState(false)
    const [disableButton, setDisableButton] = useState(false);
    const [imgFilecrop, setImgFilecrop] = useState('');
    const [crop, setCrop] = useState({ aspect: 4 / 3 });
    const [src, setSrc] = useState(null);
    const [sendImageFile, setSendImageFile] = useState('');
    const [result, setResult] = useState(null)
    const [image, setImage] = useState(null)
    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }
    const [pdfValidation, setPdfValidation] = useState("");
    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }

    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    // const handleImgFile = (val) => { 
    //     if (val) {
    //       // Create a FileReader object
    //       const reader = new FileReader();
      
    //       // Set up a callback for when the file is loaded
    //       reader.onload = (event) => {
    //         // The result property of the FileReader contains the binary data
    //         const byteArray = new Uint8Array(event.target.result);
      
    //         // Now you have the bytecode in the byteArray variable
    //         console.log(byteArray);
            
    //         // Do whatever you need with the bytecode here
    //       };
      
    //       // Read the image file as an ArrayBuffer
    //       reader.readAsArrayBuffer(val);
    //     }
    //   };
    const handleImgFile = (val) => { 
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 200) {
                    setImgFile(val);
                    setMediaType("photo")
                    setshowImgError(false)
                    setImgFilecrop("")
                    setSrc(null)

                }
                else {
                    setImgFile("");
                    setshowImgError(true)
                    setMediaType("photo")
                    setImgFilecrop(val)
                    setSrc(URL.createObjectURL(val))
                }
            }
        }
    }
    const handleImgFile2 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile2(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile2("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile3 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile3(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile3("");
                    setshowImgError(true)
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handlePostSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        const tagsData = tagsValue?.tags?.map((data) => {
            return data._id
        })
        const countryData = country?.country?.map((data) => {
            return data._id
        })
        const postFormData = new FormData();
        postFormData.append("title", formData.question ? formData.question : "");
        postFormData.append("tags", tagsData);
        postFormData.append("country", countryData);
        postFormData.append("caption", article ? article : "");
        postFormData.append("media", sendImageFile && sendImageFile ? sendImageFile: imgFile);
       postFormData.append("pdf_media", pdfFile && pdfFile);
        postFormData.append("pdfType", mediaTypepdf && mediaTypepdf);
        postFormData.append("mediaType", mediaType)
        postFormData.append("isfeatured", isFeatured);
        postFormData.append("target", isNotification);
        try {
            const result = await addStoryPost(postFormData);
            if (result.res.success) {
                // toast.success(result.res.message);
                toast.success("Story Posted Successfully");
                closeModal();
                getFeedData({
                    page: 0,
                    postsPerPage: 10
                });
                setLoader(false);
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const cropperRef = useRef(null);
    const getCropData = () => { 
        if (typeof cropperRef.current?.cropper !== "undefined") {
          setResult(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
                fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
            .then(res => res.blob())  
            .then(blob => {
              const file = new File([blob], cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
              setSendImageFile(file)
              console.log(file,"filefile")
            })
        }
      };
      const handlePdfFile = (val) => {
        if(val.size <= 30 * 1024 * 1024) {
        setPdfFile(val);
        setMediaTypepdf("file")
    } else {
        setPdfFile("");
        setPdfValidation("Pdf size should be less than 50 MB");
      }
        // if (val.name !== "") {
        //     setDisableVideo(true)
        // }
    }
    // function getCroppedImg() { 
    //     const canvas = document.createElement("canvas");
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     // canvas.width = crop.width;
    //     // canvas.height = crop.height;
    
    //     var originWidth = crop.width * scaleX;
    //     var originHeight = crop.height * scaleY;
        
    
    //     // maximum width/height
    //     var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
    //     var targetWidth = originWidth,
    //       targetHeight = originHeight;
    //     if (originWidth > maxWidth || originHeight > maxHeight) {
    //       if (originWidth / originHeight > maxWidth / maxHeight) {
    //         targetWidth = maxWidth;
    //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
    //       } else {
    //         targetHeight = maxHeight;
    //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
    //       }
    //     }
    
    //     // set canvas size
    //     canvas.width = targetWidth; 
    //     canvas.height = targetHeight;
    
    //     const ctx = canvas.getContext("2d");
    
    //     ctx.drawImage(
    //       image,
    //       crop.x * scaleX,
    //       crop.y * scaleY,
    //       crop.width * scaleX,
    //       crop.height * scaleY,
    //       0,
    //       0,
    //       targetWidth,
    //       targetHeight
    //     );
    //     console.log(canvas, "canvas")
    //     const base64Image = canvas.toDataURL("image/jpeg");
    //     var profile = new Image();
    //     profile.src = base64Image;
    //     setResult(profile.src)
    
    //     fetch(profile.src)
    //       .then(res => res.blob())  
    //       .then(blob => {
    //         const file = new File([blob], profile.src, { type: "image/jpeg" })
    //         setSendImageFile(file)
    //       })
    //   }
    const getCropremove2 = () => {
        setImgFile("");
        setResult("");
        setMediaType("");
        setshowImgError(false)
        setImgFilecrop("");
        setSrc(null); 
    
        const fileInput = document.querySelector('input[name="image-file"]');
        if (fileInput) {
            fileInput.value = ''; // Clear the value to allow re-selection of the same file
        }
      };
    // console.log(tagsValue && tagsValue?.tags && tagsValue?.tags[0]?.name,"imgFile?.name")
    console.log(imgFile?.name,"imgFile?.name")
    
    console.log(sendImageFile,"sendImageFile")
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {t('add_new_STORY')}  </h3>
            </div>
            <div noValidate autoComplete="off" className="w-full" >
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="question"
                            value={formData.question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Title')}
                            minRows={3}
                        />
                    </div>
                    <div className='editorSectionStart col-span-2'>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                embedded: {
                                    embedCallback: embedVideoCallBack
                                }
                            }}
                        />
                    </div>
                    <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label={t('Country')} />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label={t('Tags')} />}
                    />
                    <div className="flex items-center mt-5 customeCheckbox">
                        <FormControlLabel control={<Checkbox name="All" checked={isNotification} onChange={(e) => setIsNotification(e.target.checked)} defaultChecked color="primary" />} label={t('Send_Push_Notification')} labelPlacement="start" />
                    </div>
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item md={1}>
                            <span className="text-paraText">{t('Add_Media')}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto ">
                                <TextareaAutosize
                                    id="profilephoto"
                                    label={t('Add_Photo_1')}
                                    value={(imgFile?.name || result || "").slice(0, 20)}
                                    className="w-full text-xs"
                                />
                                <input
                                 disabled={disableButton}
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                {t('Photo')}
                            </div>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto ">
                                <TextField
                                    id="profilephoto"
                                    // label={t("Add_a_PDF_File")}
                                    value={pdfFile.name || ""}
                                    className="w-full text-xs"
                                />

                                <input
                                    disabled={disablePdf}
                                    type="file"
                                    accept="application/pdf, application/vnd.ms-excel"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="pdf-file"
                                    onChange={(e) => handlePdfFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            {pdfValidation && pdfValidation && (
                  <p className="text-red-600">{pdfValidation}</p>
                )}
                            <div className="media-box mt-2">
                                <img src="../../assets/photos.svg" alt="" />
                                {t("Files")}
                            </div>
                        </Grid>
                        <Grid item md={3}>
                        <div className="flex items-center mt-5 customeCheckbox">
                                  <FormControlLabel control={<Checkbox name="All" checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} defaultChecked color="primary" />} label="Featured" labelPlacement="start" />
                            </div>
                            
                            {/* <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t('Add_Photo_2')}
                                    value={imgFile2.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile2(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                {t('Photo')}
                            </div> */}
                        </Grid>
                        {/* <Grid item md={3}> */}
                            {/* <div className="relative w-auto overflow-hidden">
                                <TextField
                                    id="profilephoto"
                                    label={t('Add_Photo_3')}
                                    value={imgFile3.name || ""}
                                    className="w-full text-xs"
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile3(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                {t('Photo')}
                            </div> */}
                           
                          
                          
                        {/* </Grid> */}
                        
                      {src ? "" : <p className='mt-4 text-sidenav'> {t('Note_Imageshouldbe400x200px')}</p>} 
                        <Grid md={4}>
                    {
                      src &&
                      <div className="col-6 cropperclass">
                        {/* <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        /> */}
                                          <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={2/1}
          aspectRatio={2 / 1} 
          //preview=".img-preview"
          src={src}
          ref={cropperRef}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false}
        />
         <div className='Zoombtn'>
                        <button
                          variant="contained"
                          onClick={getCropData}
                          className='cropbutton'
                        >
                          Crop Image
                        </button>
                        <button
                          variant="contained"
                          onClick={getCropremove2}
                          className='cropbutton'
                        >
                          Remove Crop 
                        </button>
                        </div>
                      </div>
                    }
                  </Grid>
                    </Grid>
                </div>
            
                <div className="flex justify-center mt-10">
                    <button onClick={handlePostSubmit} 
                    className={`mx-auto ${formData.question  !== "" && country && country?.country && country?.country[0]?.name !== undefined && tagsValue && tagsValue?.tags && tagsValue?.tags[0]?.name !== undefined && imgFile?.name !== undefined  || sendImageFile !== "" ?  "button-1" : "buttons-1"}`} 
                     disabled={formData.question  !== "" && country && country?.country && country?.country[0]?.name !== undefined && tagsValue && tagsValue?.tags && tagsValue?.tags[0]?.name !== undefined && imgFile?.name !== undefined || sendImageFile !== ""  ? false : true }>
                       {t('submit')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default StoryForm