import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
// import Robot from "../assets/robot.gif";
export default function Welcome({currentusername}) {
  const [userName, setUserName] = useState("");
  const {t} = useTranslation();

  return (
    <>
    <div className="Welcome">
      <h1>
        {t("Welcome")} <span>{currentusername?.name}!</span>
      </h1>
      <h3>{t("Please_selectachattoStartmessaging")}</h3>
      </div>
    </>
  );
}

