import { useEffect, useState, useContext,useRef } from "react";
import PostDetail from "../Common/PostDetail";
import SimpleListing from "../Common/SimpleListing";
import GridListing from "../Common/GridListing";
import demoData from "./demoData";
import YoutubeVideoPost from '../Common/YoutubeVideoPost';
import FilePost from '../Common/FilePost';
import PostPolling from '../Common/PostPolling';
import ArticlePost from '../Common/ArticlePost';
import { getHomeApiData } from "../../Utils/services";
import { toast } from "react-toastify";
import ReactChartPage from "./ReactChartPage";
import { ModalContext } from '../../Context'
import PostDetails from "../ModalTemplate/PostDetails"
import { filterPostTypehome } from '../../Homefunction'
import classNames from "classnames";
import Loader from "../Common/Loader"
import { useTranslation } from 'react-i18next';
import AllEvent from "./Homes";
import { useNavigate, useMatch ,useParams } from 'react-router-dom';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    TimePicker,
  } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
const _chartData = {
    labels: [],
    datasets: [
      {
        label: "Updates",
        data: [],
        fill: true,
        backgroundColor: "rgba(217, 238, 247, 0.8)",
        borderColor: "rgba(20, 114, 249, 0.8)",
      },
    ]
};

const downward = '/assets/icons/downRedArrow.svg'
const upward = '/assets/icons/upward-arrow.png'

const HomesToolkit = ({ allHomeData , getHomeAllData,loader }) => {
    const {t} = useTranslation();
    const [tabsValue, setTabsValue] = useState(0);
    const [popularSearchData, setpopularSearchData] = useState([]);
    const [popularMembersData, setPopularMembersData] = useState([]);
    const [partyData, setPartyData] = useState([]);
    const [hubData, setHubData] = useState([]);
   // const [allHomeData, setAllHomeData] = useState({});
    const [chartData, setChartData] = useState(_chartData)
    const [recentPost, setRecentPost] = useState([]);
    const [pollData, setPollData] = useState([]);
    const [postData, setPostData] = useState([]);
    //const [loader, setLoader] = useState(true);
    const [showPrintButton, setShowPrintButton] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const context = useContext(ModalContext)
    const { handleModalData, closeModal } = context;

    useEffect(() => {
        if(showPrintButton){
            getHomeAllData({ startDate, endDate })
        } else{
            getHomeAllData()
        }
    }, []);
    const contentRef = useRef(null);
    const handlePrint = () => {
        const printContents = contentRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
    
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    
        // After printing, reload the page to restore the original content.
        window.location.reload();
      };

    const history = useNavigate();
    const handleView = () => {
        // You can add any validation or logic here if needed.
        setShowPrintButton(true);
        getHomeAllData({startDate,endDate});
      };
    const showResourceDetail = (item) => {  
        history(`/Toolkits/${item._id}`,{
        
          state: {
            data: item,
            title: 'Toolkit Details'
          }
        })
      }
//    console.log(allHomeData,"allHomeData")
    // const getHomeAllData = async () => {
    //     try {
    //         const result = await getHomeApiData();
    //         // const home_data = result.res;
    //         setLoader(false)
    //         const home_data = result.res;
            
    //         setAllHomeData(home_data)

    //         if (home_data.popularSearch.length > 0) {
    //             setpopularSearchData(home_data.popularSearch)
    //         }

    //         if (home_data.popularMembers.length > 0) {
    //             setPopularMembersData(home_data.popularMembers)
    //         }

    //         if (home_data.party.length > 0) {
    //             setPartyData(home_data.party)
    //         }

    //         if (home_data.hub.length > 0) {
    //             setHubData(home_data.hub)
    //         }

    //         if (home_data.recentPosts.length > 0) {
    //             setRecentPost(home_data.recentPosts)
    //             const _allData = home_data.recentPosts;
    //             const _pollData = _allData.filter(item =>{
    //                 if(item.isPoll == true){
    //                     return item
    //                 }
    //             })
    //             const _postData = _allData.filter(item =>{
    //                 if(item.isPoll == false){
    //                     return item
    //                 }
    //             })
    //             setPollData(_pollData)
    //             setPostData(_postData)
    //         }
    //         const labels = home_data.updates.graph.map(item => item._id)
    //         const data = home_data.updates.graph.map(item => item.count)
    //         _chartData.labels = labels
    //         _chartData.datasets[0].data = data
    //         console.log(_chartData, '_chardata', home_data);
    //         setChartData(_chartData)
    //     }
    //     catch (error) {
    //         setLoader(false)
    //         console.log(error)
    //         // toast.error(error.message);
    //     }
    // }

    const showPostDetail = (data) => {
        const POST_MODAL = <PostDetails
            data={data}
            isModalView={true}
            isReportBtn={true}
        />
        handleModalData(POST_MODAL)
    }


    const AdminType = localStorage.getItem("AdminType")
    const region = localStorage.getItem("region")
   

    return (
        <>
          <Loader loader={loader} />
            {
                Object.keys(allHomeData).length > 0 && (
                  <>
                                         <div className="flex flex-row items-center mb-4 w-3/5 ml-5">
                   <MuiPickersUtilsProvider utils={DateFnsUtils}>
                   <div className="">
        {/* <label htmlFor="start-date" className="mb-2">Start Date:</label> */}
        {/* <input
          type="date"
          id="start-date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
          style={{ width: '350px' }}
        /> */}
         
           <KeyboardDatePicker
              label={'Start Date'}
            //   placeholder={`${start}`}
              value={startDate}
              onChange={(date) => (setStartDate(date))}
              format={"dd-MM-yyyy"}
              
            //   disablePast={true}
              required
            />
        </div>
      <div className="ml-2">
        {/* <label htmlFor="end-date" className="mb-2">End Date:</label> */}
     
              <KeyboardDatePicker
              label={'End Date'}
            //   placeholder={`${end}`}
              value={endDate}
              onChange={(date) => setEndDate(date)}
              format={"dd-MM-yyyy"}
            //   disablePast={true}
            minDate={startDate}
              required
              className=" "
            />
            
        {/* <input
          type="date"
          id="end-date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className="p-2 border border-gray-300 rounded"
          style={{ width: '350px' }}
        /> */}
        </div>
        <div className="">
        <button onClick={handleView} className="mx-auto mt-5 ml-5 button-1">
        View
      </button>
      </div>
      {showPrintButton && (
        <button onClick={handlePrint} className=" mx-auto ml-2 mt-5 button-1">
          Print PDF
        </button>
      )}
       </MuiPickersUtilsProvider>
      </div>
                    <div className="px-10 homemainresponsive grid grid-cols-12 gap-x-20" >

                        <div className="col-span-9" id="contentToPrint" ref={contentRef}>

                          
                           {/* {
                           AdminType && AdminType === "Platform Admin" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.TotalUsers?.count }
                                       </h1>
                                       <span className="new-added-users">
                                           { `+${allHomeData.TotalUsers.change} users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.TotalactiveUsers?.count }
                                       </h1>

                                 
                                       <span className="new-added-users">
                                           { `+${allHomeData.TotalactiveUsers.change} users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.totalinactiveUsers?.count}
                                       </h1>
                                     
                                        <span className="new-added-users">
                                           { `+${allHomeData?.totalinactiveUsers.change} users` }
                                       </span>
                                   </div>
                               </div>
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.TotalUsers?.count }
                                       </h1>
                                       <span className="new-added-users">
                                           { `+${allHomeData?.TotalUsers?.change} users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="border-r-2 text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")}</p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.TotalactiveUsers?.count }
                                       </h1>

                                     
                                       <span className="new-added-users">
                                           { `+${allHomeData?.TotalactiveUsers?.change} users` }
                                       </span>
                                   </div>
                               </div>

                               <div className="text-center">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData?.totalinactiveUsers?.count}
                                       </h1>
                                    
                                        <span className="new-added-users">
                                           { `+${allHomeData?.totalinactiveUsers?.change} users` }
                                       </span>
                                   </div>
                               </div>
                           </div> : 
                              AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                                </div>
                                <div className="Country">
                                 
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.TotalUsers?.count }
                                        </h1>
                                        <span className="new-added-users">
                                            { `+${allHomeData.TotalUsers.change} users` }
                                        </span>
                                    </div>
                                </div>
 
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">  {t("ACTIVEUSERS")}</p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalactiveUsers?.count }
                                        </h1>
 
                                      
                                        <span className="new-added-users">
                                            { `+${allHomeData.TotalactiveUsers.change} users` }
                                        </span>
                                    </div>
                                </div>
 
                                <div className="text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t("INACTIVEUSERS")}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalinactiveUsers?.count}
                                        </h1>
                                       
                                         <span className="new-added-users">
                                            { `+${allHomeData?.totalinactiveUsers.change} users` }
                                        </span>
                                    </div>
                                </div>
                            </div> : 
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t("ALLCOUNTRYUSERS")}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                              <div className="border-r-2 text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t("TOTALUSERS")}</p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.TotalUsers?.count }
                                      </h1>
                                      <span className="new-added-users">
                                          { `+${allHomeData.TotalUsers.change} users` }
                                      </span>
                                  </div>
                              </div>

                              <div className="border-r-2 text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t("ACTIVEUSERS")} </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData?.TotalactiveUsers?.count }
                                      </h1>

                                   
                                      <span className="new-added-users">
                                          { `+${allHomeData.TotalactiveUsers.change} users` }
                                      </span>
                                  </div>
                              </div>

                              <div className="text-center">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t("INACTIVEUSERS")}</p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData?.totalinactiveUsers?.count}
                                      </h1>
                                    
                                       <span className="new-added-users">
                                          { `+${allHomeData?.totalinactiveUsers.change} users` }
                                      </span>
                                  </div>
                              </div>
                          </div> : null
                           } */}
                         
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('NEWREGISTRATIONUSERS')}</p>
                                </div>
                                <div className="Country">
                               
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('PENDINGREGISTRATION')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl downward mr-2">
                                            { allHomeData.totalPendingUsers }
                                        </h1>
                                    
                                    </div>
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEWREGISTRATIONINLAST1WEEK')}</p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-4xl  mr-2">
                                            { allHomeData?.TotalUsers?.change}
                                        </h1>
                                   
                                    </div>
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('PENDINGREVIEW/REPORTEDUSERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl downward mr-2">
                                            { allHomeData?.totalReportedUsers}
                                        </h1>
                                     
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('VERIFIEDUSERS')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalVerifyUsers }
                                        </h1>

                                      
                                    </div>
                                </div>

                                <div className=" border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('DELETEDUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totaldeletedUsers}
                                        </h1>
                                    
                                    </div>
                                </div>
                                
                                <div className=" border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('DECLINEDUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalDeclinedUsers}
                                        </h1>                           
                                      
                                    </div>
                                </div>
                            </div> */}
                            {/*  */}
                           {/* { AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                             </div>
                             <div className="Country">
                               
                             </div>
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHUSERS')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.count}
                                     </h1>
                                     <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
  
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAUSERS')} </p>
                                 
                                 <div 
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                         "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                     </h1>
  
                                    
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
  
                             <div className="text-center ">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                     </h1>
                                     
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALUSERS')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDUSERS')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                     </h1>
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                         </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                              
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                             </div>
                             <div className="Country">
                              
                             </div>
                             <div className="border-r-2 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHUSERS')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.count}
                                     </h1>
                                     <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalBangladeshUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>

                           

                             <div className="text-center border-r-2 ">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalIndiaUsers?.count}
                                     </h1>
                                  
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalIndiaUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                            
                             <div className="text-center  ">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALUSERS')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.totalUsersBycountry?.totalNepalUsers?.count}
                                     </h1>
                                    
                                      <span className="new-added-users">
                                         { `+${allHomeData?.totalUsersBycountry?.totalNepalUsers?.change} users` }
                                     </span>
                                 </div>
                             </div>
                           
                         </div> :
                           AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                          
                           </div>
                           <div className="Country text-center mb-5">
                               <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYWISEUSERS')}</p>
                           </div>
                           <div className="Country">
                             
                           </div>
                       

                           <div className="border-r-2 text-center">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAUSERS')} </p>
                               
                               <div 
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                       "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.count }
                                   </h1>

                                  
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>

                         
                           <div className="text-center border-r-2 ">
                               <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAUSERS')}</p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalIndonesiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                           <div className="text-center">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalMalaysiaUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                           <div className="text-center border-r-2 mt-5">
                               <p className="text-muteText text-xs font-semibold mb-2">  {t('MYAMMARUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalMyammarUsers?.count}
                                   </h1>
                                   
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalMyammarUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                          
                           <div className="text-center border-r-2 mt-5">
                               <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDUSERS')} </p>
                               <div
                                   className={classNames({
                                       "flex justify-center": true,
                                       "items-center": true,
                                       "upward": (allHomeData?.inactiveUsers?.change > 0),
                                       "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                   })}
                               >
                                   <h1 className="text-2xl primary-color mr-2">
                                       { allHomeData?.totalUsersBycountry?.totalThailandUsers?.count}
                                   </h1>
                                    <span className="new-added-users">
                                       { `+${allHomeData?.totalUsersBycountry?.totalThailandUsers?.change} users` }
                                   </span>
                               </div>
                           </div>
                       </div>  :  AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('COUNTRYUSERS')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('USERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalUsers?.count}
                                        </h1>
                                        <span className="new-added-users">
                                            { `+${allHomeData?.TotalUsers?.change} users` }
                                        </span>
                                    </div>
                                </div>
     
                              
                            </div> : null
                           } */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALRESOURCESDETAIL')}</p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWRESOURCES')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalViewResourcesInLast7Days}
                                        </h1>
                                    
                                    </div>
                                </div>

                                <div className="border-r-2 text-center">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALDOWNLOADRESOURCES')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0),
                                            "downward": !(allHomeData?.totalUsersBycountry?.totalCambodiaUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedResources}
                                        </h1>

                                      
                                    </div>
                                </div>

                                <div className="text-center ">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('LAST7DAYSDOWNLOADRESOURCES')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedResourcesInLast7Days}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALVIEWRESOURCES')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalviewResources}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTFORUM')}</p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('TOTALCOMMENTFORUM')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInForum}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTFORUMLAST7DAYS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInForumInLast7Days}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTGALLERY')}</p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALCOMMENTGALLERY')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInGallery}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTGALLERYLAST7DAYS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInGalleryInLast7Days}
                                        </h1>
                                       
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALCOMMENTSTORY')}</p>
                                </div>
                                <div className="Country">
                                    
                                </div>
                               
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('COMMENTSTORY')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInStory}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('COMMENTSTORYLAST7DAYS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalCommentsInStoryInLast7Days}
                                        </h1>
                                       
                                    </div>
                                </div>
                               
                            </div> */}
                           {
                            !showPrintButton ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                              
                            </div>
                            <div className="Country text-center mb-5">
                                <p className="text-muteText text-xs font-bold mb-2"> {t('7DAYSVIEWNEWSFEEDSANDTOOLKITS')}</p>
                            </div>
                            <div className="Country">
                               
                            </div>
                            {/* <div className="text-center border-r-2 mt-5">
                                <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWNEWSFEEDS')}</p>
                                <div
                                    className={classNames({
                                        "flex justify-center": true,
                                        "items-center": true,
                                        "upward": (allHomeData?.inactiveUsers?.change > 0),
                                        "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                    })}
                                >
                                    <h1 className="text-2xl primary-color mr-2">
                                        { allHomeData?.TotalViewNewsFeedInLast7Days}
                                    </h1>
                                   
                                </div>
                            </div> */}
                            <div className="text-center  mt-5">
                                <p className="text-muteText text-xs font-semibold mb-2">{t('LAST7DAYSVIEWTOOLKITS')}</p>
                                <div
                                    className={classNames({
                                        "flex justify-center": true,
                                        "items-center": true,
                                        "upward": (allHomeData?.inactiveUsers?.change > 0),
                                        "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                    })}
                                >
                                    <h1 className="text-2xl primary-color mr-2">
                                        { showPrintButton ? "" : allHomeData?.TotalViewToolkitsInLast7Days}
                                    </h1>
                                 
                                </div>
                            </div>
                          
                           
                        </div> : null
                           }
                           
                          
                           {/* {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInBangladesh}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInCambodia}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndia}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndonesia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMalaysia}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMyammar}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInNepal}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInThailand}
                                     </h1>
                                 
                                 </div>
                             </div>
                            
                            
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                  
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInBangladesh}
                                     </h1>
                                  
                                 </div>
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndia}
                                     </h1>
                                   
                                 </div>
                             </div>
                            
                             <div className="text-center  mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInNepal}
                                     </h1>
                                   
                                 </div>
                             </div>
                           
                            
                            
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                 
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                 
                                 </div>
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInCambodia}
                                     </h1>
                                  
                                 </div>
                             </div>
                          
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInIndonesia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIAACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMalaysia}
                                     </h1>
                                   
                                 </div>
                             </div>
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARACTIVEFOURAMCOUNT')} </p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInMyammar}
                                     </h1>
                                
                                 </div>
                             </div>
                         
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDACTIVEFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForumInThailand}
                                     </h1>
                                  
                                 </div>
                             </div>
                            
                            
                            </div> :
                             AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('TOTALACTIVEFORUMCOUNTRYWISECOUNT')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                           
                             <div className="text-center border-r-2 mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALFOURAMCOUNT')}</p>
                                 <div
                                     className={classNames({
                                         "flex justify-center": true,
                                         "items-center": true,
                                         "upward": (allHomeData?.inactiveUsers?.change > 0),
                                         "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                     })}
                                 >
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.activeForum}
                                     </h1>
                                  
                                 </div>
                             </div>
                            
                            
                            
                            </div> : null
                           } */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOP15SEARCHKEYWORDS')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                              { allHomeData?.SearchData.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('KEYWORD')}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         {item}
                                     </h1>
                                    
                                 </div>
                             </div>
                             </>
                              )})
                              }
                           </div> */}
                           {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2"> {t('TOP5TAGSUSEDINSEARCH')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                              { allHomeData?.tagdata.map((item,i) => { 
                                 return(
                                    <>
                                 <div className="border-r-2 mt-5 text-center">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('TAGS')}</p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         {item}
                                     </h1>
                                    
                                 </div>
                             </div>
                             </>
                              )})
                              }
                           </div> */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText downward text-xs font-bold mb-2">{t('ALLFLAGGEDCOMMENTALLSECTION')}</p>
                                </div>
                                <div className="Country">
                                    
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('FLAGGEDCOMMENTFORUM')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-4xl downward   mr-2">
                                            { allHomeData?.TotalFlaggedCommentsInForum}
                                        </h1>
                                    
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('FLAGGEDCOMMENTGALLERY')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-4xl  downward   mr-2">
                                            { allHomeData?.TotalFlaggedCommentsInGallery}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('FLAGGEDCOMMENTSTORY')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-4xl downward   mr-2">
                                            { allHomeData?.TotalFlaggedCommentsInStory}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> */}
                            {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('FORUMFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDCOMMENT')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           {  allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumCambodia}
                                       </h1>

                                   
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumIndonesia}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumMalaysia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumMyammar}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumNepal}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('FORUMFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                             

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAFLAGGEDCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumIndia}
                                       </h1>
                                  
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                            
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText downward text-xs font-bold mb-2">{t('FORUMFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                           

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAFLAGGEDCOMMENT')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          {  allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumCambodia}
                                      </h1>

                                    
                                  </div>
                              </div>

                           
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDCOMMENT')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumIndonesia}
                                      </h1>
                                  
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumMalaysia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumMyammar}
                                      </h1>
                                    
                                  </div>
                              </div>
                           
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">  {t('THAILANDFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInForumBycountry?.TotalFlaggedCommentsInForumThailand}
                                      </h1>
                                    
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                              
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText downward text-xs font-bold mb-2">{t('FORUMFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                             
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('FLAGGEDCOMMENT')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl downward mr-2">
                                         { allHomeData.TotalFlaggedCommentsInForum}
                                     </h1>
                                  
                                 </div>
                             </div>
                         </div> : null
                           } */}
                           {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('GALLERYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIAFLAGGEDCOMMENT')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           {  allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryCambodia}
                                       </h1>

                                      
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryIndonesia}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryMalaysia}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryMyammar}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryNepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('GALLERYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                              

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('INDIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-4xl downward primary-color mr-2">
                                           { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                             
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs downward font-bold mb-2">{t('GALLERYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                            

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDCOMMENT')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-4xl downward  mr-2">
                                          {  allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryCambodia}
                                      </h1>

                                   
                                  </div>
                              </div>

                           
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2">  {t('INDONESIAFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryIndonesia}
                                      </h1>
                                     
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}   </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryMalaysia}
                                      </h1>
                                     
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryMyammar}
                                      </h1>
                                    
                                  </div>
                              </div>
                             
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDCOMMENT')}   </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInGalleryBycountry?.TotalFlaggedCommentsInGalleryThailand}
                                      </h1>
                                     
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs downward font-bold mb-2">{t('GALLERYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                                 
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('FLAGGEDCOMMENT')}  </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl downward mr-2">
                                         { allHomeData.TotalFlaggedCommentsInGallery}
                                     </h1>
                                    
                                 </div>
                             </div>
                         </div> : null
                           } */}
                            {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('GALLERYFLAGGEDPOST/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDPOST')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInBangladesh}
                                       </h1>
                                      
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDPOST')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           {  allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInCambodia}
                                       </h1>

                                     
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAFLAGGEDPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInMalaysia}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARFLAGGEDPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInMyammar}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALFLAGGEDPOST')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInNepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDPOST')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInThailand}
                                       </h1>
                                      
                                   </div>
                               </div>
                           </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('GALLERYFLAGGEDPOST/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDPOST')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                          

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAFLAGGEDPOST')}    </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-4xl downward primary-color mr-2">
                                           { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                              
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs downward font-bold mb-2">{t('GALLERYFLAGGEDPOST/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                            

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDPOST')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-4xl downward  mr-2">
                                          {  allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInCambodia}
                                      </h1>

                                   
                                  </div>
                              </div>

                            
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDPOST')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInIndonesia}
                                      </h1>
                                   
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDPOST')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInMalaysia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARFLAGGEDPOST')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInMyammar}
                                      </h1>
                                    
                                  </div>
                              </div>
                            
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDPOST')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedPostInGalleryBycountry?.totalFlaggedPostInGalleryInThailand}
                                      </h1>
                                     
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs downward font-bold mb-2">{t('GALLERYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('FLAGGEDCOMMENT')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl downward mr-2">
                                         { allHomeData.totalFlaggedPostInGallery}
                                     </h1>
                                   
                                 </div>
                             </div>
                         </div> : null
                           } */}
                            
                         {/* {
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs downward font-bold mb-2">{t('TOTALGALLERYFLAGGEDPOST')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className=" text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('FLAGGEDCOMMENT')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalFlaggedPostInGallery}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> 
                         } */}

                            {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs downward font-bold mb-2">{t('STORYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDCOMMENT')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           {  allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryCambodia}
                                       </h1>

                                      
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('INDIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryMalaysia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryMyammar}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('STORYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHFLAGGEDCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>


                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                              
                               
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALFLAGGEDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                             
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText downward text-xs font-bold mb-2">{t('STORYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                          

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAFLAGGEDCOMMENT')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          {  allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryCambodia}
                                      </h1>

                                    
                                  </div>
                              </div>

                            
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryIndonesia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryMalaysia}
                                      </h1>
                                     
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARFLAGGEDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryMyammar}
                                      </h1>
                                     
                                  </div>
                              </div>
                             
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDFLAGGEDCOMMENT')}   </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                          { allHomeData.totalflaggedcommentsInStoryBycountry?.TotalFlaggedCommentsInStoryThailand}
                                      </h1>
                                  
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText downward text-xs font-bold mb-2">{t('STORYFLAGGEDCOMMENT/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('FLAGGEDCOMMENT')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl downward mr-2">
                                         { allHomeData.TotalFlaggedCommentsInStory}
                                     </h1>
                                   
                                 </div>
                             </div>
                         </div> : null
                           } */}
                            {/* <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('LANGUAGEWISE/USERS')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ASSAMESELANGUAGEUSERS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalAssameseUsers }
                                        </h1>
                                     
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLALANGUAGEUSERS')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalBanglaUsers }
                                        </h1>

                                    
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ENGLISHLANGUAGEUSERS')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalEnglishUsers}
                                        </h1>
                                    
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('HINDILANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalHindiUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BAHASALANGUAGEUSERS')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalIndonessiaUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('KHMERLANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalKhemarUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYANMARLANGUAGEUSERS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalMyanmarUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALILANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalNepaliUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MELAYULANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalMelayuUsers}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDLANGUAGEUSERS')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotalThailandUsers}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                              
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('CourseSectionDetail')}</p>
                                </div>
                                <div className="Country">
                                
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('Total_enrolled_users')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUsers}
                                        </h1>
                                   
                                    </div>
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('one_course_completed')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.users_completed_one_course}
                                        </h1>
                                    
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('two_course_completed')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.users_completed_two_course}
                                        </h1>

                                  
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('ONGOINGCOURSE')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.ongoingUser}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('totalenrolledUserslast7days')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.totalenrolledUserslast7days}
                                        </h1>
                                      
                                    </div>
                                </div>
                              
                            </div> */}
                           {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWS/COUNTRYWISE ')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHRESOURCESVIEWS')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInBangladesh}
                                       </h1>
                                      
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIARESOURCESVIEWS')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInCambodia}
                                       </h1>

                                      
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInMalaysia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInMyammar}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWS/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHRESOURCESVIEWS')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                             

                               <div className="text-center border-r-2 mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                             
                         
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInNepal}
                                       </h1>
                                   
                                   </div>
                               </div>
                              
                           </div> :
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                
                              </div>
                           

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIARESOURCESVIEWS')} </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          {  allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInCambodia}
                                      </h1>

                                  
                                  </div>
                              </div>

                              
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIARESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInIndonesia}
                                      </h1>
                                   
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIARESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInMalaysia}
                                      </h1>
                                     
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARRESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInMyammar}
                                      </h1>
                                    
                                  </div>
                              </div>
                          
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDRESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesBycountry?.TotalviewResourcesInThailand}
                                      </h1>
                                    
                                  </div>
                              </div>
                          </div> :
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                  
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t('RESOURCESVIEWS')} </p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.TotalviewResources}
                                      </h1>
                                  </div>
                              </div>
                          </div> :null
                           } */}
                           {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                   
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWSLAST7DAYS/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHRESOURCESVIEWS')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInBangladesh}
                                       </h1>
                                   
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIARESOURCESVIEWS')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInCambodia}
                                       </h1>

                                   
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('INDIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('INDONESIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInIndonesia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('MALAYSIARESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInMalaysia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 ">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInMyammar}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInNepal}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDRESOURCESVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> :
                            AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                              
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWSLAST7DAYS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                               
                              </div>
                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHRESOURCESVIEWS')} </p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInBangladesh}
                                      </h1>
                                   
                                  </div>
                              </div>

                            

                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIARESOURCESVIEWS')}</p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInIndia}
                                      </h1>
                                    
                                  </div>
                              </div>
                            
                              
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALRESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInNepal}
                                      </h1>
                                     
                                  </div>
                              </div>
                             
                          </div> :
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWSLAST7DAYS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                
                              </div>
                            

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIARESOURCESVIEWS')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          {  allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInCambodia}
                                      </h1>

                        
                                  </div>
                              </div>

                            
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIARESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInIndonesia}
                                      </h1>
                                  
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIARESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInMalaysia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARRESOURCESVIEWS')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInMyammar}
                                      </h1>
                                   
                                  </div>
                              </div>
                             
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDRESOURCESVIEWS')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalViewResourcesInLast7DaysBycountry?.TotalviewResourcesInLast7DaysInThailand}
                                      </h1>
                               
                                  </div>
                              </div>
                          </div> :
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                          
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('RESOURCESVIEWSLAST7DAYS/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('RESOURCESVIEWS')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData.TotalViewResourcesInLast7Days}
                                     </h1>
                                    
                                 </div>
                             </div>
                         </div> : null
                           } */}

{/* {    AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                    
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALDOWNLOADRESOURCES/COUNTRYWISE')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHDOWNLOADRESOURCES')}  </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInBangladesh}
                                        </h1>
                                     
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADRESOURCES')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            {  allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInCambodia}
                                        </h1>

                                       
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADRESOURCES')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInIndia}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADRESOURCES')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInIndonesia}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIADOWNLOADRESOURCES')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInMalaysia}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-5 ">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADRESOURCES')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInMyammar}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADRESOURCES')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInNepal}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDDOWNLOADRESOURCES')}   </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInThailand}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOTALDOWNLOADRESOURCES/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                   
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHDOWNLOADRESOURCES')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInBangladesh}
                                       </h1>
                                      
                                   </div>
                               </div>

                              

                               <div className="text-center border-r-2 mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInNepal}
                                       </h1>
                                       
                                   </div>
                               </div>
                            
                           </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                   
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('TOTALDOWNLOADRESOURCES/COUNTRYWISE')}</p>
                                 </div>
                                 <div className="Country">
                                   
                                 </div>
                              
  
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADRESOURCES')}  </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInCambodia}
                                         </h1>
  
                                     
                                     </div>
                                 </div>
  
                              
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADRESOURCES')}  </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInIndonesia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIADOWNLOADRESOURCES')}   </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInMalaysia}
                                         </h1>
                                         
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADRESOURCES')}  </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInMyammar}
                                         </h1>
                                     
                                     </div>
                                 </div>
                              
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDDOWNLOADRESOURCES')}   </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totaldownloadedResourcesBycountry?.TotaldownloadedResourcesInThailand}
                                         </h1>
                                      
                                     </div>
                                 </div>
                             </div>  :
                               AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('TOTALDOWNLOADRESOURCES/COUNTRYWISE')}</p>
                                </div>
                                <div className="Country">
                                 
                                </div>
                                <div className=" text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">  {t('TOTALDOWNLOADRESOURCES')}  </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.TotaldownloadedResources}
                                        </h1>
                                  
                                    </div>
                                </div>
                            </div> : null
                          } */}
                           {/* {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADRESOURCESLAST7DAYS/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHDOWNLOADRESOURCES')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADRESOURCES')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInCambodia}
                                       </h1>

                                    
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInIndia}
                                       </h1>
                                  
                                   </div>
                               </div> 
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInIndonesia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIADOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInMalaysia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInMyammar}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInNepal}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDDOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('DOWNLOADRESOURCESLAST7DAYS/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHDOWNLOADRESOURCES')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                             

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInIndia}
                                       </h1>
                                  
                                   </div>
                               </div>
                            
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADRESOURCES')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInNepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                            
                           </div> :
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADRESOURCESLAST7DAYS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                           

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADRESOURCES')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          {  allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInCambodia}
                                      </h1>

                                 
                                  </div>
                              </div>

                            
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADRESOURCES')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInIndonesia}
                                      </h1>
                                 
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIADOWNLOADRESOURCES')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInMalaysia}
                                      </h1>
                                  
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADRESOURCES')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInMyammar}
                                      </h1>
                                 
                                  </div>
                              </div>
                           
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDDOWNLOADRESOURCES')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totaldownloadedResourcesInLast7DaysBycountry?.TotaldownloadedResourcesInLast7DaysInThailand}
                                      </h1>
                                   
                                  </div>
                              </div>
                          </div> :
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADRESOURCESLAST7DAYS/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                              <div className=" text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">  {t('TOTALDOWNLOADRESOURCES')}  </p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.TotaldownloadedResourcesInLast7Days}
                                      </h1>
                                  
                                  </div>
                              </div>
                          </div> : null
                           } */}
                            {/* {   AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2"> {t('NEWSFEEDSVIEWSLAST7DAYS')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHNEWSFEEDSVIEWS')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIANEWSFEEDSVIEWS')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInCambodia}
                                          </h1>
  
                                      
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIANEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInIndia}
                                          </h1>
                                    
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIANEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInIndonesia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIANEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInMalaysia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 ">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARNEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALNEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDNEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInNepal}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('NEWSFEEDSVIEWSLAST7DAYS')} </p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHNEWSFEEDSVIEWS')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIANEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                               
                               
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALNEWSFEEDSVIEWS')} </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                               
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('NEWSFEEDSVIEWSLAST7DAYS')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                          
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIANEWSFEEDSVIEWS')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInCambodia}
                                         </h1>
 
                                      
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIANEWSFEEDSVIEWS')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInIndonesia}
                                         </h1>
                                    
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIANEWSFEEDSVIEWS')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInMalaysia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARNEWSFEEDSVIEWS')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDNEWSFEEDSVIEWS')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalViewNewsFeedInLast7DaysBycountry?.TotalviewNewsFeedInLast7DaysInThailand}
                                         </h1>
                                     
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('NEWSFEEDSVIEWSLAST7DAYS')} </p>
                                </div>
                                <div className="Country">
                                  
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('NEWSFEEDSVIEWS')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.TotalViewNewsFeedInLast7Days}
                                        </h1>
                                    
                                    </div>
                                </div>
                            </div> : null 

                            } */}
                            {!showPrintButton ? (
  <div>
                           {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOOLKITSVIEWSLAST7DAYS')} </p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHTOOLKITSVIEWS')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInBangladesh}
                                       </h1>
                                  
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIATOOLKITSVIEWS')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInCambodia}
                                       </h1>

                                     
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInIndonesia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInMalaysia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARTOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInMyammar}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALTOOLKITSVIEWS')}</p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInNepal}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDTOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOOLKITSVIEWSLAST7DAYS')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHTOOLKITSVIEWS')}</p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                              

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('INDIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInIndia}
                                       </h1>
                                     
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALTOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInNepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                              
                           </div> :
                             AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOOLKITSVIEWSLAST7DAYS')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                              

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIATOOLKITSVIEWS')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInCambodia}
                                       </h1>

                                   
                                   </div>
                               </div>

                           
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIATOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInMalaysia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARTOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInMyammar}
                                       </h1>
                                     
                                   </div>
                               </div>
                            
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDTOOLKITSVIEWS')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalViewToolkitsInLast7DaysBycountry?.TotalviewToolkitsInLast7DaysInThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('TOOLKITSVIEWSLAST7DAYS')} </p>
                               </div>
                               <div className="Country">
                                   
                               </div>
                               <div className=" text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('TOOLKITSVIEWS')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.TotalViewToolkitsInLast7Days}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> : null
                           }
                            </div>
) : null }
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('TOOLKITSDOWNLOAD')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                              
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('TOTALDOWNLOADTOOLKITS')}</p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData?.TotaldownloadedToolkits}
                                        </h1>
                                      
                                    </div>
                                </div>
                               
                            </div>
                            {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENT/COUNTRYWISE')}</p>
                                  </div>
                                  <div className="Country">
                                     
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')} </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInBangladesh}
                                          </h1>
                                      
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2">  {t('CAMBODIACOMMENT')} </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInCambodia}
                                          </h1>
  
                                        
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2">{t('INDIACOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInIndia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInIndonesia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}   </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInMalaysia}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInMyammar}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInNepal}
                                          </h1>
                                         
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInThailand}
                                          </h1>
                                      
                                      </div>
                                  </div>
                              </div> :
                               AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENT/COUNTRYWISE')}</p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHCOMMENT')} </p>
                                     <div className="flex items-center justify-center">
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInBangladesh}
                                         </h1>
                                    
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center border-r-2 mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2">{t('INDIACOMMENT')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInIndia}
                                         </h1>
                                        
                                     </div>
                                 </div>
                                
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInNepal}
                                         </h1>
                                        
                                     </div>
                                 </div>
                               
                             </div> : 
                              AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                   
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENT/COUNTRYWISE')} </p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                              
 
                                 <div className="border-r-2 text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')} </p>
                                     
                                     <div 
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.activeUsers?.change > 0),
                                             "downward": !(allHomeData?.activeUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             {  allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInCambodia}
                                         </h1>
 
                                       
                                     </div>
                                 </div>
 
                               
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2">{t('INDONESIACOMMENT')} </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInIndonesia}
                                         </h1>
                                      
                                     </div>
                                 </div>
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInMalaysia}
                                         </h1>
                                      
                                     </div>
                                 </div>
                                 <div className="text-center mt-5 border-r-2">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInMyammar}
                                         </h1>
                                       
                                     </div>
                                 </div>
                              
                                 <div className="text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                     <div
                                         className={classNames({
                                             "flex justify-center": true,
                                             "items-center": true,
                                             "upward": (allHomeData?.inactiveUsers?.change > 0),
                                             "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                         })}
                                     >
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.totalcommentInForumBycountry?.TotalCommentsInForumInThailand}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             </div> :
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                  
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENT/COUNTRYWISE')} </p>
                                 </div>
                                 <div className="Country">
                                   
                                 </div>
                                 <div className=" text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2">  {t('COMMENT')}  </p>
                                     <div className="flex items-center justify-center">
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData?.TotalCommentsInForum}
                                         </h1>
                                      
                                     </div>
                                 </div>
                             </div> : null 
                            } */}
                           {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                              
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENTLAST7DAYS/COUNTRYWISE')}  </p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInBangladesh}
                                       </h1>
                                   
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInCambodia}
                                       </h1>

                                   
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInIndonesia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('MALAYSIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInMalaysia}
                                       </h1>
                                   
                                   </div>
                               </div> 
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInMyammar}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInNepal}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> :
                              AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENTLAST7DAYS/COUNTRYWISE')} </p>
                                </div>
                                <div className="Country">
                                
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHCOMMENT')}  </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInBangladesh}
                                        </h1>
                                      
                                    </div>
                                </div>
 
                               
 
                                <div className="text-center border-r-2 mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('INDIACOMMENT')} </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInIndia}
                                        </h1>
                                      
                                    </div>
                                </div>
                              
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInNepal}
                                        </h1>
                                       
                                    </div>
                                </div>
                              
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENTLAST7DAYS/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                            

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInCambodia}
                                       </h1>

                                     
                                   </div>
                               </div>

                             
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInMalaysia}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInMyammar}
                                       </h1>
                                    
                                   </div>
                               </div>
                            
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInForumInLast7DaysBycountry?.TotalCommentInForumIn7daysInThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> :
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                               
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('FORUMCOMMENTLAST7DAYS/COUNTRYWISE')}  </p>
                             </div>
                             <div className="Country">
                               
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('COMMENT')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData.TotalCommentsInForumInLast7Days}
                                     </h1>
                                  
                                 </div>
                             </div>
                         </div> : null 
                           } */}
                             {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENT/COUNTRYWISE')}</p>
                                </div>
                                <div className="Country">
                                
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Bangladesh}
                                        </h1>
                                    
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIACOMMENT')}   </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            {  allHomeData.totalcommentsInGalleryBycountry?.Cambodia}
                                        </h1>

                                     
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.India}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Indonesia}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Malaysia}
                                        </h1>
                                    
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Myammar}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Nepal}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totalcommentsInGalleryBycountry?.Thailand}
                                        </h1>
                                     
                                    </div>
                                </div>
                            </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Bangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                              

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.India}
                                       </h1>
                                    
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Nepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                              
                           </div> :
                             AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                            

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalcommentsInGalleryBycountry?.Cambodia}
                                       </h1>

                                     
                                   </div>
                               </div>

                             
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('INDONESIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Indonesia}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Malaysia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">  {t('MYAMMARCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Myammar}
                                       </h1>
                                 
                                   </div>
                               </div>
                            
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentsInGalleryBycountry?.Thailand}
                                       </h1>
                                  
                                   </div>
                               </div>
                           </div> : 
                            AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                
                              </div>
                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">  {t('COMMENT')}  </p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.TotalCommentsInGallery}
                                      </h1>
                                    
                                  </div>
                              </div>
                          </div>  : null 
                          } */}
                           {/* { AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENTLAST7DAYS/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('BANGLADESHCOMMENT')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInBangladesh}
                                       </h1>
                                    
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('CAMBODIACOMMENT')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInCambodia}
                                       </h1>

                                   
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInMalaysia}
                                       </h1>
                                  
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInMyammar}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}   </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInNepal}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInThailand}
                                       </h1>
                                    
                                   </div>
                               </div>
                           </div> :
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ?
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENTLAST7DAYS/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')} </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInBangladesh}
                                       </h1>
                                      
                                   </div>
                               </div>

                             

                               <div className="text-center border-r-2 mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInIndia}
                                       </h1>
                                      
                                   </div>
                               </div>
                            
                             
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInNepal}
                                       </h1>
                                       
                                   </div>
                               </div>
                             
                           </div> :
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENTLAST7DAYS/COUNTRYWISE')} </p>
                              </div>
                              <div className="Country">
                                  
                              </div>
                           

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          {  allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInCambodia}
                                      </h1>

                                   
                                  </div>
                              </div>

                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInIndonesia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2">  {t('MALAYSIACOMMENT')}   </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInMalaysia}
                                      </h1>
                                  
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInMyammar}
                                      </h1>
                                 
                                  </div>
                              </div>
                            
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInGalleryIn7daysBycountry?.TotalCommentInGalleryIn7daysInThailand}
                                      </h1>
                                  
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                               
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('GALLERYCOMMENTLAST7DAYS/COUNTRYWISE')} </p>
                             </div>
                             <div className="Country">
                              
                             </div>
                             <div className="text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('COMMENT')}  </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData?.TotalCommentsInGalleryInLast7Days}
                                     </h1>
                                  
                                 </div>
                             </div>
                         </div> : null
                           } */}
                           {/* {  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENT/COUNTRYWISE')}</p>
                               </div>
                               <div className="Country">
                                
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')} </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           {  allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInCambodia}
                                       </h1>

                                    
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInIndonesia}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInMalaysia}
                                       </h1>
                                 
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInMyammar}
                                       </h1>
                                   
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}   </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInNepal}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDCOMMENT')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl primary-color mr-2">
                                           { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInThailand}
                                       </h1>
                                     
                                   </div>
                               </div>
                           </div> :
                            AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                
                              </div>
                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                  <div className="flex items-center justify-center">
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInBangladesh}
                                      </h1>
                                  
                                  </div>
                              </div>

                             

                              <div className="text-center border-r-2 mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInIndia}
                                      </h1>
                                   
                                  </div>
                              </div>
                           
                            
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInNepal}
                                      </h1>
                                    
                                  </div>
                              </div>
                            
                          </div>  : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENT/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                          

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          {  allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInCambodia}
                                      </h1>

                                    
                                  </div>
                              </div>

                             
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInIndonesia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInMalaysia}
                                      </h1>
                                     
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInMyammar}
                                      </h1>
                                     
                                  </div>
                              </div>
                            
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl primary-color mr-2">
                                          { allHomeData.totalcommentInStoryBycountry?.TotalCommentsInStoryInThailand}
                                      </h1>
                                    
                                  </div>
                              </div>
                          </div> :
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ? 
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                            
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENT/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                               
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2"> {t('COMMENT')}  </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl primary-color mr-2">
                                         { allHomeData.TotalCommentsInStory}
                                     </h1>
                                
                                 </div>
                             </div>
                         </div> : null
                           } */}
                            {/* {    AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                    
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENTLAST7DAYS/COUNTRYWISE')}</p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHCOMMENT')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInBangladesh}
                                          </h1>
                                       
                                      </div>
                                  </div>
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInCambodia}
                                          </h1>
  
                                     
                                      </div>
                                  </div>
  
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInIndia}
                                          </h1>
                                     
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInIndonesia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIACOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInMalaysia}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInMyammar}
                                          </h1>
                                      
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInNepal}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInThailand}
                                          </h1>
                                       
                                      </div>
                                  </div>
                              </div> : 
                                AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                    
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENTLAST7DAYS/COUNTRYWISE')}</p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2">  {t('BANGLADESHCOMMENT')}  </p>
                                      <div className="flex items-center justify-center">
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInBangladesh}
                                          </h1>
                                        
                                      </div>
                                  </div>
  
                                
  
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIACOMMENT')}   </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInIndia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                                 
                              
                                
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInNepal}
                                          </h1>
                                       
                                      </div>
                                  </div>
                                 
                              </div> :
                                AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                                <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                                <div className="Country">
                                     
                                  </div>
                                  <div className="Country text-center mb-5">
                                      <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENTLAST7DAYS/COUNTRYWISE')}</p>
                                  </div>
                                  <div className="Country">
                                    
                                  </div>
                               
  
                                  <div className="border-r-2 text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIACOMMENT')}  </p>
                                      
                                      <div 
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.activeUsers?.change > 0),
                                              "downward": !(allHomeData?.activeUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              {  allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInCambodia}
                                          </h1>
  
                                        
                                      </div>
                                  </div>
  
                               
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIACOMMENT')}   </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInIndonesia}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIACOMMENT')}   </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInMalaysia}
                                          </h1>
                                         
                                      </div>
                                  </div>
                                  <div className="text-center mt-5 border-r-2">
                                      <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInMyammar}
                                          </h1>
                                        
                                      </div>
                                  </div>
                                
                                  <div className="text-center mt-5">
                                      <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDCOMMENT')}  </p>
                                      <div
                                          className={classNames({
                                              "flex justify-center": true,
                                              "items-center": true,
                                              "upward": (allHomeData?.inactiveUsers?.change > 0),
                                              "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                          })}
                                      >
                                          <h1 className="text-2xl primary-color mr-2">
                                              { allHomeData.totalcommentInStoryInLast7DaysBycountry?.TotalCommentInStoryIn7daysInThailand}
                                          </h1>
                                       
                                      </div>
                                  </div>
                              </div> : 
                               AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                               <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                               <div className="Country">
                                    
                                 </div>
                                 <div className="Country text-center mb-5">
                                     <p className="text-muteText text-xs font-bold mb-2">{t('STORYCOMMENTLAST7DAYS/COUNTRYWISE')}</p>
                                 </div>
                                 <div className="Country">
                                    
                                 </div>
                                 <div className=" text-center mt-5">
                                     <p className="text-muteText text-xs font-semibold mb-2">  {t('COMMENT')}  </p>
                                     <div className="flex items-center justify-center">
                                         <h1 className="text-2xl primary-color mr-2">
                                             { allHomeData.TotalCommentsInStoryInLast7Days}
                                         </h1>
                                       
                                     </div>
                                 </div>
                             </div>  : null
                            } */}
                        
                       
                          {    AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                 
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADTOOLKIT/COUNTRYWISE')} </p>
                                </div>
                                <div className="Country">
                                 
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHDOWNLOADTOOLKIT')}  </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInBangladesh}
                                        </h1>
                                     
                                    </div>
                                </div>

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADTOOLKIT')}  </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            {  allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInCambodia}
                                        </h1>

                                   
                                    </div>
                                </div>

                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInIndia}
                                        </h1>
                                   
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInIndonesia}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIADOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInMalaysia}
                                        </h1>
                                    
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInMyammar}
                                        </h1>
                                  
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInNepal}
                                        </h1>
                                     
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInThailand}
                                        </h1>
                                     
                                    </div>
                                </div>
                            </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                   
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADTOOLKIT/COUNTRYWISE')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHDOWNLOADTOOLKIT')} </p>
                                    <div className="flex items-center justify-center">
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInBangladesh}
                                        </h1>
                                     
                                    </div>
                                </div>

                             

                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIADOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInIndia}
                                        </h1>
                                      
                                    </div>
                                </div>
                             
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInNepal}
                                        </h1>
                                       
                                    </div>
                                </div>
                             
                            </div> : 
                              AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                              <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                              <div className="Country">
                                  
                                </div>
                                <div className="Country text-center mb-5">
                                    <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADTOOLKIT/COUNTRYWISE')}</p>
                                </div>
                                <div className="Country">
                                   
                                </div>
                              

                                <div className="border-r-2 text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIADOWNLOADTOOLKIT')} </p>
                                    
                                    <div 
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.activeUsers?.change > 0),
                                            "downward": !(allHomeData?.activeUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            {  allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInCambodia}
                                        </h1>

                                      
                                    </div>
                                </div>

                             
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIADOWNLOADTOOLKIT')}   </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInIndonesia}
                                        </h1>
                                      
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('MALAYSIADOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInMalaysia}
                                        </h1>
                                       
                                    </div>
                                </div>
                                <div className="text-center mt-5 border-r-2">
                                    <p className="text-muteText text-xs font-semibold mb-2"> {t('MYAMMARDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInMyammar}
                                        </h1>
                                     
                                    </div>
                                </div>
                              
                                <div className="text-center mt-5">
                                    <p className="text-muteText text-xs font-semibold mb-2">{t('THAILANDDOWNLOADTOOLKIT')}  </p>
                                    <div
                                        className={classNames({
                                            "flex justify-center": true,
                                            "items-center": true,
                                            "upward": (allHomeData?.inactiveUsers?.change > 0),
                                            "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                        })}
                                    >
                                        <h1 className="text-2xl primary-color mr-2">
                                            { allHomeData.totaldownloadedToolkitsbycountry?.TotaldownloadedToolkitsInThailand}
                                        </h1>
                                      
                                    </div>
                                </div>
                            </div> : 
                              AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                               
                            </div>
                            <div className="Country text-center mb-5">
                                <p className="text-muteText text-xs font-bold mb-2"> {t('DOWNLOADTOOLKIT/COUNTRYWISE')}</p>
                            </div>
                            <div className="Country">
                               
                            </div>
                            <div className=" text-center mt-5">
                                <p className="text-muteText text-xs font-semibold mb-2"> {t('DOWNLOADTOOLKIT')}  </p>
                                <div className="flex items-center justify-center">
                                    <h1 className="text-2xl primary-color mr-2">
                                        { allHomeData.TotaldownloadedToolkits}
                                    </h1>
                                   
                                </div>
                            </div>
                        </div> : null
                          }

{  AdminType && AdminType === "Platform Admin" && region === "undefined" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                  
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('TOOLKITPOST/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                  
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHPOST')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                           { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInBangladesh}
                                       </h1>
                                      
                                   </div>
                               </div>

                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAPOST')}  </p>
                                   
                                   <div 
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.activeUsers?.change > 0),
                                           "downward": !(allHomeData?.activeUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInCambodia}
                                       </h1>
                                       
                                     
                                   </div>
                               </div>

                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostsToolkitsInIndia}
                                       </h1>
                                    
                                   </div>
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInIndonesia}
                                       </h1>
                                     
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInMalaysia}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInMyammar}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2">{t('NEPALPOST')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInNepal}
                                       </h1>
                                      
                                   </div>
                               </div>
                               <div className=" border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDPOST')} </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInThailand}
                                       </h1>
                                      
                                   </div>
                               </div>
                           </div> : 
                             AdminType && AdminType === "Regional Admin" && region === "South Asia" ? 
                             <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                             <div className="Country">
                                 
                               </div>
                               <div className="Country text-center mb-5">
                                   <p className="text-muteText downward text-xs font-bold mb-2">{t('TOOLKITPOST/COUNTRYWISE')} </p>
                               </div>
                               <div className="Country">
                                 
                               </div>
                               <div className="border-r-2 text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('BANGLADESHPOST')}  </p>
                                   <div className="flex items-center justify-center">
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInBangladesh}
                                       </h1>
                                     
                                   </div>
                               </div>

                          

                               <div className="text-center mt-5 border-r-2">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('INDIAPOST')}    </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-2xl downward mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostsToolkitsInIndia}
                                       </h1>
                                   
                                   </div>
                               </div>
                              
                               <div className="text-center mt-5">
                                   <p className="text-muteText text-xs font-semibold mb-2"> {t('NEPALPOST')}  </p>
                                   <div
                                       className={classNames({
                                           "flex justify-center": true,
                                           "items-center": true,
                                           "upward": (allHomeData?.inactiveUsers?.change > 0),
                                           "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                       })}
                                   >
                                       <h1 className="text-4xl downward primary-color mr-2">
                                       { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInNepal}
                                       </h1>
                                     
                                   </div>
                               </div>
                              
                           </div> : 
                            AdminType && AdminType === "Regional Admin" && region === "Southeast Asia" ? 
                            <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                            <div className="Country">
                                 
                              </div>
                              <div className="Country text-center mb-5">
                                  <p className="text-muteText text-xs downward font-bold mb-2">{t('TOOLKITPOST/COUNTRYWISE')}</p>
                              </div>
                              <div className="Country">
                                 
                              </div>
                            

                              <div className="border-r-2 text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('CAMBODIAPOST')}  </p>
                                  
                                  <div 
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.activeUsers?.change > 0),
                                          "downward": !(allHomeData?.activeUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-4xl downward  mr-2">
                                      { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInCambodia}
                                      </h1>

                                   
                                  </div>
                              </div>

                            
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('INDONESIAPOST')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                      { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInIndonesia}
                                      </h1>
                                   
                                  </div>
                              </div>
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('MALAYSIAPOST')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                      { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInMalaysia}
                                      </h1>
                                    
                                  </div>
                              </div>
                              <div className="text-center mt-5 border-r-2">
                                  <p className="text-muteText text-xs font-semibold mb-2">{t('MYAMMARPOST')}  </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                      { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInMyammar}
                                      </h1>
                                    
                                  </div>
                              </div>
                            
                              <div className="text-center mt-5">
                                  <p className="text-muteText text-xs font-semibold mb-2"> {t('THAILANDPOST')} </p>
                                  <div
                                      className={classNames({
                                          "flex justify-center": true,
                                          "items-center": true,
                                          "upward": (allHomeData?.inactiveUsers?.change > 0),
                                          "downward": !(allHomeData?.inactiveUsers?.change > 0)
                                      })}
                                  >
                                      <h1 className="text-2xl downward mr-2">
                                      { allHomeData.totalPostToolkitsBycountry?.TotalPostToolkitsInThailand}
                                      </h1>
                                     
                                  </div>
                              </div>
                          </div> : 
                           AdminType && AdminType === "Country Admin" || AdminType && AdminType === "Coordination team" ?
                           <div className="grid grid-cols-3 gap-x-10 px-5 py-10 bg-white mt-5">
                           <div className="Country">
                                
                             </div>
                             <div className="Country text-center mb-5">
                                 <p className="text-muteText text-xs downward font-bold mb-2">{t('RESOURCESPOST/COUNTRYWISE')}</p>
                             </div>
                             <div className="Country">
                                
                             </div>
                             <div className=" text-center mt-5">
                                 <p className="text-muteText text-xs font-semibold mb-2">{t('POST')} </p>
                                 <div className="flex items-center justify-center">
                                     <h1 className="text-2xl downward mr-2">
                                         { allHomeData.TotalPostsInGallery}
                                     </h1>
                                   
                                 </div>
                             </div>
                         </div> : null
                           }
                          
                           

                        </div>
                        <div className="col-span-3 ">
                            <div className="recent-posts sticky-sidebar sticky top-0">
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14">  {t('PopularForum')} </h1>
                                {
                                    allHomeData.popularForum && allHomeData.popularForum.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumBangladesh')} </h1>
                                {
                                    allHomeData.popularForumInBangladesh && allHomeData.popularForumInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumCambodia')} </h1>
                                {
                                    allHomeData.popularForumInCambodia && allHomeData.popularForumInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumIndia')} </h1>
                                {
                                    allHomeData.popularForumInIndia && allHomeData.popularForumInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumIndonesia')} </h1>
                                {
                                    allHomeData.popularForumInIndonesia && allHomeData.popularForumInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumMalaysia')} </h1>
                                {
                                    allHomeData.popularForumInMalaysia && allHomeData.popularForumInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumMyammar')}</h1>
                                {
                                    allHomeData.popularForumInMyammar && allHomeData.popularForumInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumNepal')} </h1>
                                {
                                    allHomeData.popularForumInNepal && allHomeData.popularForumInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularForumThailand')} </h1>
                                {
                                    allHomeData.popularForumInThailand && allHomeData.popularForumInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeed')}  </h1>
                                {
                                    allHomeData.popularNewsfeed && allHomeData.popularNewsfeed.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedBangladesh')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInBangladesh && allHomeData.popularNewsfeedInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedCambodia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInCambodia && allHomeData.popularNewsfeedInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14">{t('PopularNewsFeedIndia')} </h1>
                                {
                                    allHomeData.popularNewsfeedInIndia && allHomeData.popularNewsfeedInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedIndonesia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInIndonesia && allHomeData.popularNewsfeedInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedMalaysia')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInMalaysia && allHomeData.popularNewsfeedInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedMyammar')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInMyammar && allHomeData.popularNewsfeedInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedNepal')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInNepal && allHomeData.popularNewsfeedInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularNewsFeedThailand')}  </h1>
                                {
                                    allHomeData.popularNewsfeedInThailand && allHomeData.popularNewsfeedInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPosts')} </h1>
                                {
                                    allHomeData.popularNewsfeed && allHomeData.popularNewsfeed.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsBangladesh')} </h1>
                                {
                                    allHomeData.popularPostsInBangladesh && allHomeData.popularPostsInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsCambodia')} </h1>
                                {
                                    allHomeData.popularPostsInCambodia && allHomeData.popularPostsInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsIndia')} </h1>
                                {
                                    allHomeData.popularPostsInIndia && allHomeData.popularPostsInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsIndonesia')} </h1>
                                {
                                    allHomeData.popularPostsInIndonesia && allHomeData.popularPostsInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsMalaysia')}</h1>
                                {
                                    allHomeData.popularPostsInMalaysia && allHomeData.popularPostsInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsMyammar')} </h1>
                                {
                                    allHomeData.popularPostsInMyammar && allHomeData.popularPostsInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsNepal')} </h1>
                                {
                                    allHomeData.popularPostsInNepal && allHomeData.popularPostsInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularPostsThailand')} </h1>
                                {
                                    allHomeData.popularPostsInThailand && allHomeData.popularPostsInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('Popularresources')}  </h1>
                                {
                                    allHomeData.popularResources && allHomeData.popularResources.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesBangladesh')} </h1>
                                {
                                    allHomeData.popularResourcesInBangladesh && allHomeData.popularResourcesInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesCambodia')} </h1>
                                {
                                    allHomeData.popularResourcesInCambodia && allHomeData.popularResourcesInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesIndia')} </h1>
                                {
                                    allHomeData.popularResourcesInIndia && allHomeData.popularResourcesInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesIndonesia')} </h1>
                                {
                                    allHomeData.popularResourcesInIndonesia && allHomeData.popularResourcesInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesMalaysia')} </h1>
                                {
                                    allHomeData.popularResourcesInMalaysia && allHomeData.popularResourcesInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesMyammar')} </h1>
                                {
                                    allHomeData.popularResourcesInMyammar && allHomeData.popularResourcesInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesNepal')} </h1>
                                {
                                    allHomeData.popularResourcesInNepal && allHomeData.popularResourcesInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularresourcesThailand')}</h1>
                                {
                                    allHomeData.popularResourcesInThailand && allHomeData.popularResourcesInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkits')}  </h1>
                                {
                                    allHomeData.popularToolkits && allHomeData.popularToolkits.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsBangladesh')} </h1>
                                {
                                    allHomeData.popularToolkitsInBangladesh && allHomeData.popularToolkitsInBangladesh.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsCambodia')} </h1>
                                {
                                    allHomeData.popularToolkitsInCambodia && allHomeData.popularToolkitsInCambodia.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsIndia')} </h1>
                                {
                                    allHomeData.popularToolkitsInIndia && allHomeData.popularToolkitsInIndia.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsIndonesia')} </h1>
                                {
                                    allHomeData.popularToolkitsInIndonesia && allHomeData.popularToolkitsInIndonesia.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsMalaysia')} </h1>
                                {
                                    allHomeData.popularToolkitsInMalaysia && allHomeData.popularToolkitsInMalaysia.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsMyammar')} </h1>
                                {
                                    allHomeData.popularToolkitsInMyammar && allHomeData.popularToolkitsInMyammar.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsNepal')} </h1>
                                {
                                    allHomeData.popularToolkitsInNepal && allHomeData.popularToolkitsInNepal.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularToolkitsThailand')} </h1>
                                {
                                    allHomeData.popularToolkitsInThailand && allHomeData.popularToolkitsInThailand.map(item => (
                                        <div className="mb-20 cursor-pointer" onClick={() => showResourceDetail(item)}>
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 {/* <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStory')}  </h1>
                                {
                                    allHomeData.popularstories && allHomeData.popularstories.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryBangladesh')} </h1>
                                {
                                    allHomeData.popularstoriesInBangladesh && allHomeData.popularstoriesInBangladesh.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryCambodia')} </h1>
                                {
                                    allHomeData.popularstoriesInCambodia && allHomeData.popularstoriesInCambodia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryIndia')} </h1>
                                {
                                    allHomeData.popularstoriesInIndia && allHomeData.popularstoriesInIndia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryIndonesia')} </h1>
                                {
                                    allHomeData.popularstoriesInIndonesia && allHomeData.popularstoriesInIndonesia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryMalaysia')} </h1>
                                {
                                    allHomeData.popularstoriesInMalaysia && allHomeData.popularstoriesInMalaysia.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryMyammar')} </h1>
                                {
                                    allHomeData.popularstoriesInMyammar && allHomeData.popularstoriesInMyammar.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryNepal')} </h1>
                                {
                                    allHomeData.popularstoriesInNepal && allHomeData.popularstoriesInNepal.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                }
                                 <h1 className="text-xl tracking-wider primary-color font-semibold mb-14"> {t('PopularStoryThailand')} </h1>
                                {
                                    allHomeData.popularstoriesInThailand && allHomeData.popularstoriesInThailand.map(item => (
                                        <div className="mb-20">
                                            { filterPostTypehome(item, showPostDetail) }
                                        </div>
                                    ))
                                } */}
                                
                            </div>

                        </div>

                    </div>
                    </>
                )
                
            }
        </>
    )
}

export default HomesToolkit;