import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useContext, useEffect, useState, useRef } from "react";
import {
  editForumPost,
  editGalleryPost,
  getAdminDetails,
  getTags,
  regionCountryList,
  BASE_URL_IMG,
} from "../../Utils/services";
import { toast } from "react-toastify";
import { ModalContext } from "../../Context";
import Loader from "../Common/Loader";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { filterPostType } from "../../functions";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "cropperjs/dist/cropper.css";
import { Cropper } from "react-cropper";

const EditGallery = ({ forumData, getGalleryDataApi, title }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(forumData?.country);
  const [formData, setFormData] = useState(forumData ? forumData : {});
  const [imgFile, setImgFile] = useState(
    forumData?.media[0]?.mediaType === "photo" ? forumData?.media[0]?.link : ""
  );
  const [VideoFile, setVideoFile] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mediaType, setMediaType] = useState("photo");
  const [youtubelink, setYoutubeLink] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsValue, setTagsValue] = useState(forumData?.tag);
  const [isMediaEdit, setIsMediaEdit] = useState(false);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(forumData?.caption))
    )
  );
  const [showImgError, setshowImgError] = useState(false);
  const [article, setArticle] = useState("");
  const [imgFile2, setImgFile2] = useState(
    forumData?.media[1]?.mediaType === "photo" ? forumData?.media[1]?.link : ""
  );
  const [imgFile3, setImgFile3] = useState(
    forumData?.media[2]?.mediaType === "photo" ? forumData?.media[2]?.link : ""
  );
  // const [disableButton, setDisableButton] = useState(false);
  const [imgFilecrop, setImgFilecrop] = useState("");
  const [imgFilecrop2, setImgFilecrop2] = useState("");
  const [imgFilecrop3, setImgFilecrop3] = useState("");
  const [src, setSrc] = useState(null);
  const [src2, setSrc2] = useState(null);
  const [src3, setSrc3] = useState(null);
  const [crop, setCrop] = useState({ aspect: 2 / 1 });
  const [crop2, setCrop2] = useState({ aspect: 2 / 1 });
  const [crop3, setCrop3] = useState({ aspect: 2 / 1 });
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [sendImageFile, setSendImageFile] = useState("");
  const [sendImageFile2, setSendImageFile2] = useState("");
  const [sendImageFile3, setSendImageFile3] = useState("");
  const [result, setResult] = useState(null);
  const [result2, setResult2] = useState(null);
  const [result3, setResult3] = useState(null);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    let convertedData = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    setArticle(convertedData);
  };
  const embedVideoCallBack = (link) => {
    link = link.replace("watch?v=", "embed/");
    link = link.replace("/watch/", "/embed/");
    link = link.replace("youtu.be/", "youtube.com/embed/");
    return link;
  };

  // const getCountryData = async () => {
  //     try {
  //         const result = await regionCountryList();
  //         setCountries(result?.res?.data?.country)
  //     }
  //     catch (error) {
  //         console.log(error);
  //         toast.warning("Something went wrong!")
  //     }
  // }
  const adminId = localStorage.getItem("adminId");

  const getAdminDetailsApiData = async () => {
    try {
      const result = await getAdminDetails({
        id: adminId,
        type: "admin",
      });
      setCountries(result.data.country);
    } catch (error) {
      console.log(error);
    }
  };

  const geTagsApi = async () => {
    try {
      const result = await getTags();
      if (result.res.success) {
        setTags(result.res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getAdminDetailsApiData();
    geTagsApi();
  }, []);

  const onInputCountryChange = (event, newInputValue, name) => {
    setCountry({
      ...country,
      [name]: newInputValue,
    });
  };

  const onInputTagsChange = (event, newInputValue, name) => {
    setTagsValue({
      ...tagsValue,
      [name]: newInputValue,
    });
  };

  const handleImgFile = (val) => {
    setIsMediaEdit(true);
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 200) {
          setImgFile(val);
          setMediaType("photo");
          setshowImgError(false);
          setImgFilecrop("");
          setSrc(null);
        } else {
          setImgFile("");
          setshowImgError(true);
          setMediaType("photo");
          setImgFilecrop(val);
          setSrc(URL.createObjectURL(val));
        }
      };
    }
    if (val.name !== "") {
      setDisableButton(true);
    }
  };

  const handleImgFile2 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 200) {
          setImgFile2(val);
          setMediaType("photo");
          setshowImgError(false);
          setImgFilecrop2("");
          setSrc2(null);
        } else {
          setImgFile2("");
          setshowImgError(true);
          setMediaType("photo");
          setImgFilecrop2(val);
          setSrc2(URL.createObjectURL(val));
        }
      };
    }
  };
  const handleImgFile3 = (val) => {
    if (!!val) {
      let img = new Image();
      img.src = window.URL.createObjectURL(val);

      img.onload = () => {
        if (img.width === 400 && img.height === 200) {
          setImgFile3(val);
          setMediaType("photo");
          setshowImgError(false);
        } else {
          setImgFile3("");
          setshowImgError(true);
          setImgFile3("");
          setshowImgError(true);
          setMediaType("photo");
          setImgFilecrop3(val);
          setSrc3(URL.createObjectURL(val));
        }
      };
    }
  };
  const handleVideoFile = (val) => {
    setIsMediaEdit(true);
    setVideoFile(val);
    setMediaType("video");
    if (val.name !== "") {
      setDisableButton(true);
    }
  };
  const handlePdfFile = (val) => {
    setIsMediaEdit(true);

    setPdfFile(val);
    setMediaType("file");
    if (val.name !== "") {
      setDisableButton(true);
    }
  };
  const HandleYoutubeLink = (e) => {
    setIsMediaEdit(true);

    setYoutubeLink(e.target.value);
    setMediaType("link");
    if (e.target.value !== "") {
      setDisableButton(true);
    }
  };

  const context = useContext(ModalContext);
  const { closeModal } = context;

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData, "Form Data");

  const handlePostSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    let tagsData;
    if (tagsValue?.tags) {
      tagsData = tagsValue?.tags?.map((data) => {
        return data._id;
      });
    } else {
      tagsData = tagsValue?.map((data) => {
        return data._id;
      });
    }
    let countryData;
    if (country?.country) {
      countryData = country?.country?.map((data) => {
        return data._id;
      });
    } else {
      countryData = country?.map((data) => {
        return data._id;
      });
    }

    const postFormData = new FormData();
    postFormData.append("title", formData.title ? formData.title : "");
    postFormData.append("tags", tagsData ? tagsData : formData.tag);
    postFormData.append("id", forumData?._id);
    postFormData.append(
      "countrys",
      countryData ? countryData : forumData?.country
    );
    postFormData.append("caption", article ? article : formData.caption);
    // if (isMediaEdit) {
    //     postFormData.append("media", imgFile && imgFile || VideoFile && VideoFile || pdfFile && pdfFile || youtubelink && youtubelink);
    //     postFormData.append("mediaType", mediaType)
    // }
    postFormData.append(
      "media",
      sendImageFile && sendImageFile ? sendImageFile : ""
    );
    postFormData.append(
      "media",
      sendImageFile2 && sendImageFile2 ? sendImageFile2 : ""
    );
    postFormData.append(
      "media",
      sendImageFile3 && sendImageFile3 ? sendImageFile3 : ""
    );
    postFormData.append("mediaType", mediaType);

    try {
      const result = await editGalleryPost(postFormData);
      if (result.res.success) {
        // toast.success(result.res.message);
        toast.success("Post edited successfully");
        closeModal();
        getGalleryDataApi({
          page: 1,
          postsPerPage: 10,
        });
        setLoader(false);
        console.log(result.res, "This is result response");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.error(error.message);
    }
  };

  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  const cropperRef = useRef(null);
  const cropperRef2 = useRef(null);
  const cropperRef3 = useRef(null);

  // const getCropData = () => {
  //   if (typeof cropperRef.current?.cropper !== "undefined") {
  //     setResult(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
  //           fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
  //         setSendImageFile(file)
  //       })
  //   }
  // };
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();

      // Resize the canvas to reduce file size
      const resizedCanvas = document.createElement("canvas");
      const maxWidth = 800; // Adjust the maximum width as needed
      const maxHeight = 800; // Adjust the maximum height as needed
      const scale = Math.min(
        maxWidth / croppedCanvas.width,
        maxHeight / croppedCanvas.height
      );

      resizedCanvas.width = croppedCanvas.width * scale;
      resizedCanvas.height = croppedCanvas.height * scale;

      const ctx = resizedCanvas.getContext("2d");
      ctx.drawImage(
        croppedCanvas,
        0,
        0,
        resizedCanvas.width,
        resizedCanvas.height
      );

      // Convert the resized canvas to a data URL
      const resizedDataURL = resizedCanvas.toDataURL("image/jpeg", 0.8); // Adjust quality as needed
      setResult(resizedDataURL);
      // Create a Blob from the data URL and set the compressed file
      fetch(resizedDataURL)
        .then((res) => res.blob())
        .then((blob) => {
          const compressedFile = new File([blob], "compressed_image1.jpg", {
            type: "image/jpeg",
          });

          // Set the compressed file for further use (e.g., sending to the server)
          setSendImageFile(compressedFile);
          //  setImgFile(compressedFile);

          // Optional: Set the compressed image data URL
          console.log(resizedDataURL, "resizedDataURL");
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  };
  // const getCropData2 = () => {
  //   if (typeof cropperRef2.current?.cropper !== "undefined") {
  //     setResult2(cropperRef2.current?.cropper.getCroppedCanvas().toDataURL())
  //           fetch(cropperRef2.current?.cropper.getCroppedCanvas().toDataURL())
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], cropperRef2.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
  //         setSendImageFile2(file)
  //       })
  //   }
  // };
  const getCropData2 = () => {
    if (typeof cropperRef2.current?.cropper !== "undefined") {
      const croppedCanvas2 = cropperRef2.current?.cropper.getCroppedCanvas();

      // Resize the canvas to reduce file size
      const resizedCanvas2 = document.createElement("canvas");
      const maxWidth = 800; // Adjust the maximum width as needed
      const maxHeight = 800; // Adjust the maximum height as needed
      const scale = Math.min(
        maxWidth / croppedCanvas2.width,
        maxHeight / croppedCanvas2.height
      );

      resizedCanvas2.width = croppedCanvas2.width * scale;
      resizedCanvas2.height = croppedCanvas2.height * scale;

      const ctx = resizedCanvas2.getContext("2d");
      ctx.drawImage(
        croppedCanvas2,
        0,
        0,
        resizedCanvas2.width,
        resizedCanvas2.height
      );

      // Convert the resized canvas to a data URL
      const resizedDataURL2 = resizedCanvas2.toDataURL("image/jpeg", 0.8); // Adjust quality as needed

      // Create a Blob from the data URL and set the compressed file
      fetch(resizedDataURL2)
        .then((res) => res.blob())
        .then((blob) => {
          const compressedFile2 = new File([blob], "compressed_image2.jpg", {
            type: "image/jpeg",
          });

          // Set the compressed file for further use (e.g., sending to the server)
          setSendImageFile2(compressedFile2);
          // setImgFile(compressedFile);

          // Optional: Set the compressed image data URL
          setResult2(resizedDataURL2);
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  };
  const getCropData3 = () => {
    if (typeof cropperRef3.current?.cropper !== "undefined") {
      const croppedCanvas3 = cropperRef3.current?.cropper.getCroppedCanvas();

      // Resize the canvas to reduce file size
      const resizedCanvas3 = document.createElement("canvas");
      const maxWidth = 800; // Adjust the maximum width as needed
      const maxHeight = 800; // Adjust the maximum height as needed
      const scale = Math.min(
        maxWidth / croppedCanvas3.width,
        maxHeight / croppedCanvas3.height
      );

      resizedCanvas3.width = croppedCanvas3.width * scale;
      resizedCanvas3.height = croppedCanvas3.height * scale;

      const ctx = resizedCanvas3.getContext("2d");
      ctx.drawImage(
        croppedCanvas3,
        0,
        0,
        resizedCanvas3.width,
        resizedCanvas3.height
      );

      // Convert the resized canvas to a data URL
      const resizedDataURL3 = resizedCanvas3.toDataURL("image/jpeg", 0.8); // Adjust quality as needed

      // Create a Blob from the data URL and set the compressed file
      fetch(resizedDataURL3)
        .then((res) => res.blob())
        .then((blob) => {
          const compressedFile3 = new File([blob], "compressed_image3.jpg", {
            type: "image/jpeg",
          });

          // Set the compressed file for further use (e.g., sending to the server)
          setSendImageFile3(compressedFile3);
          // setImgFile(compressedFile);

          // Optional: Set the compressed image data URL
          setResult3(resizedDataURL3);
        })
        .catch((error) => {
          console.error("Error resizing image:", error);
        });
    }
  };
  // const getCropData3 = () => {
  //   if (typeof cropperRef3.current?.cropper !== "undefined") {
  //     setResult3(cropperRef3.current?.cropper.getCroppedCanvas().toDataURL())
  //           fetch(cropperRef3.current?.cropper.getCroppedCanvas().toDataURL())
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], cropperRef3.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
  //         setSendImageFile3(file)
  //       })
  //   }
  // };
  // function getCroppedImg() {
  //     const canvas = document.createElement("canvas");
  //     const scaleX = image.naturalWidth / image.width;
  //     const scaleY = image.naturalHeight / image.height;
  //     // canvas.width = crop.width;
  //     // canvas.height = crop.height;

  //     var originWidth = crop.width * scaleX;
  //     var originHeight = crop.height * scaleY;

  //     // maximum width/height
  //     var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
  //     var targetWidth = originWidth,
  //       targetHeight = originHeight;
  //     if (originWidth > maxWidth || originHeight > maxHeight) {
  //       if (originWidth / originHeight > maxWidth / maxHeight) {
  //         targetWidth = maxWidth;
  //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
  //       } else {
  //         targetHeight = maxHeight;
  //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
  //       }
  //     }

  //     // set canvas size
  //     canvas.width = targetWidth;
  //     canvas.height = targetHeight;

  //     const ctx = canvas.getContext("2d");

  //     ctx.drawImage(
  //       image,
  //       crop.x * scaleX,
  //       crop.y * scaleY,
  //       crop.width * scaleX,
  //       crop.height * scaleY,
  //       0,
  //       0,
  //       targetWidth,
  //       targetHeight
  //     );
  //     console.log(canvas, "canvas")
  //     const base64Image = canvas.toDataURL("image/jpeg");
  //     var profile = new Image();
  //     profile.src = base64Image;
  //     setResult(profile.src)

  //     fetch(profile.src)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], profile.src, { type: "image/jpeg" })
  //         setSendImageFile(file)
  //       })
  //   }
  // function getCroppedImg2() {
  //     const canvas = document.createElement("canvas");
  //     const scaleX = image2.naturalWidth / image2.width;
  //     const scaleY = image2.naturalHeight / image2.height;
  //     // canvas.width = crop.width;
  //     // canvas.height = crop.height;

  //     var originWidth = crop2.width * scaleX;
  //     var originHeight = crop2.height * scaleY;

  //     // maximum width/height
  //     var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
  //     var targetWidth = originWidth,
  //       targetHeight = originHeight;
  //     if (originWidth > maxWidth || originHeight > maxHeight) {
  //       if (originWidth / originHeight > maxWidth / maxHeight) {
  //         targetWidth = maxWidth;
  //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
  //       } else {
  //         targetHeight = maxHeight;
  //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
  //       }
  //     }

  //     // set canvas size
  //     canvas.width = targetWidth;
  //     canvas.height = targetHeight;

  //     const ctx = canvas.getContext("2d");

  //     ctx.drawImage(
  //       image2,
  //       crop2.x * scaleX,
  //       crop2.y * scaleY,
  //       crop2.width * scaleX,
  //       crop2.height * scaleY,
  //       0,
  //       0,
  //       targetWidth,
  //       targetHeight
  //     );
  //     console.log(canvas, "canvas")
  //     const base64Image = canvas.toDataURL("image/jpeg");
  //     var profile = new Image();
  //     profile.src = base64Image;
  //     setResult2(profile.src)

  //     fetch(profile.src)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], profile.src, { type: "image/jpeg" })
  //         setSendImageFile2(file)
  //       })
  //   }
  // function getCroppedImg3() {
  //     const canvas = document.createElement("canvas");
  //     const scaleX = image3.naturalWidth / image3.width;
  //     const scaleY = image3.naturalHeight / image3.height;
  //     // canvas.width = crop.width;
  //     // canvas.height = crop.height;

  //     var originWidth = crop3.width * scaleX;
  //     var originHeight = crop3.height * scaleY;

  //     // maximum width/height
  //     var maxWidth = 1200, maxHeight = 1200 / (4 / 3);
  //     var targetWidth = originWidth,
  //       targetHeight = originHeight;
  //     if (originWidth > maxWidth || originHeight > maxHeight) {
  //       if (originWidth / originHeight > maxWidth / maxHeight) {
  //         targetWidth = maxWidth;
  //         targetHeight = Math.round(maxWidth * (originHeight / originWidth));
  //       } else {
  //         targetHeight = maxHeight;
  //         targetWidth = Math.round(maxHeight * (originWidth / originHeight));
  //       }
  //     }

  //     // set canvas size
  //     canvas.width = targetWidth;
  //     canvas.height = targetHeight;

  //     const ctx = canvas.getContext("2d");

  //     ctx.drawImage(
  //       image3,
  //       crop3.x * scaleX,
  //       crop3.y * scaleY,
  //       crop3.width * scaleX,
  //       crop3.height * scaleY,
  //       0,
  //       0,
  //       targetWidth,
  //       targetHeight
  //     );
  //     console.log(canvas, "canvas")
  //     const base64Image = canvas.toDataURL("image/jpeg");
  //     var profile = new Image();
  //     profile.src = base64Image;
  //     setResult3(profile.src)

  //     fetch(profile.src)
  //       .then(res => res.blob())
  //       .then(blob => {
  //         const file = new File([blob], profile.src, { type: "image/jpeg" })
  //         setSendImageFile3(file)
  //       })
  //   }
  // console.log(src,"src")
  // console.log(src2,"src2")
  // console.log(src3,"src3")
  // console.log(forumData,"forumData")

  const getCropremove = () => {
    setImgFile("");
    setResult("");
    setMediaType("");
    setshowImgError(false);
    setImgFilecrop("");
    setSrc(null);

    const fileInput = document.querySelector('input[name="image-file"]');
    if (fileInput) {
      fileInput.value = ""; // Clear the value to allow re-selection of the same file
    }
  };
  const getCropremove2 = () => {
    setImgFile2("");
    setResult2("");
    setMediaType("");
    setshowImgError(false);
    setImgFilecrop2("");
    setSrc2(null);

    const fileInput = document.querySelector('input[name="image-file2"]');
    if (fileInput) {
      fileInput.value = ""; // Clear the value to allow re-selection of the same file
    }
  };
  const getCropremove3 = () => {
    setImgFile3("");
    setResult3("");
    setMediaType("");
    setshowImgError(false);
    setImgFilecrop3("");
    setSrc3(null);
    const fileInput = document.querySelector('input[name="image-file3"]');
    if (fileInput) {
      fileInput.value = ""; // Clear the value to allow re-selection of the same file
    }
  };
  return (
    <>
      <Loader loader={loader} />
      <div className="flex items-center justify-center">
        <h3 className="title"> {title} </h3>
      </div>
      <div noValidate autoComplete="off" className="w-full">
        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
          <div className="col-span-2">
            <TextareaAutosize
              name="title"
              value={formData.title || ""}
              onChange={(e) => onChangeHandler(e)}
              className="w-full"
              id="postname"
              aria-label="Empty"
              placeholder={t("Title")}
              minRows={3}
            />
          </div>
          <div className="editorSectionStart col-span-2">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                embedded: {
                  embedCallback: embedVideoCallBack,
                },
              }}
            />
          </div>
          <Autocomplete
            id="combo-box-demo22"
            multiple
            options={countries?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            sx={{ width: 300 }}
            value={country?.name}
            defaultValue={forumData?.country}
            onChange={(e, newVal) => onInputCountryChange(e, newVal, "country")}
            renderInput={(params) => (
              <TextField {...params} label={t("Country")} />
            )}
          />
          <Autocomplete
            id="combo-box-demo2"
            multiple
            options={tags?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name}
            sx={{ width: 300 }}
            value={tagsValue?.name}
            defaultValue={forumData?.tag}
            onChange={(e, newVal) => onInputTagsChange(e, newVal, "tags")}
            renderInput={(params) => (
              <TextField {...params} label={t("Tags")} />
            )}
          />
        </div>
        <div className="mt-12 w-12/12">
          <Grid container alignItems="center" spacing={4}>
            <Grid item md={1}>
              <span className="text-paraText">{t("Add_Media")}</span>
            </Grid>
            <Grid item md={3}>
              <div className="relative w-auto overflow-hidden">
                <TextareaAutosize
                  id="profilephoto"
                  label={t("Add_Photo_1")}
                  value={(imgFile.name || result || "").slice(0, 25)}
                  className="w-full text-xs"
                  minRows={3}
                />
                <input
                  type="file"
                  accept="image/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="image-file"
                  onChange={(e) => handleImgFile(e.target.files[0])}
                  required
                />
                <div className="floating-box">+</div>
              </div>
              <div className="media-box mt-2">
                <img src="../../assets/photos.svg" alt="" />
                {t("Replace_Photo")}
              </div>
            </Grid>
            <Grid item md={3}>
              <div className="relative w-auto overflow-hidden">
                <TextareaAutosize
                  id="profilephoto"
                  label={t("Add_Photo_2")}
                  value={(imgFile2.name || result2 || "").slice(0, 25)}
                  className="w-full text-xs"
                  minRows={3}
                />
                <input
                  type="file"
                  accept="image/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="image-file"
                  onChange={(e) => handleImgFile2(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
              <div className="media-box mt-2">
                <img src="../../assets/photos.svg" alt="" />
                {t("Replace_Photo")}
              </div>
            </Grid>
            <Grid item md={3}>
              <div className="relative w-auto overflow-hidden">
                <TextField
                  id="profilephoto"
                  label={t("Add_Photo_3")}
                  value={(imgFile3.name || result3 || "").slice(0, 25)}
                  className="w-full text-xs"
                  minRows={3}
                />
                <input
                  type="file"
                  accept="image/*"
                  className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                  name="image-file"
                  onChange={(e) => handleImgFile3(e.target.files[0])}
                />
                <div className="floating-box">+</div>
              </div>
              <div className="media-box mt-2">
                <img src="../../assets/photos.svg" alt="" />
                {t("Replace_Photo")}
              </div>
            </Grid>
            {src ? (
              ""
            ) : (
              <p className="mt-4 text-sidenav">
                {t("Note_Imageshouldbe400x200px")}{" "}
              </p>
            )}
            <Grid md={4}>
              {src && (
                <div className="col-3 cropperclass">
                  {/* <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        /> */}
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={2 / 1}
                    aspectRatio={2 / 1}
                    //preview=".img-preview"
                    src={src}
                    ref={cropperRef}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                  />
                  <div className="Zoombtn">
                    <button
                      variant="contained"
                      onClick={getCropData}
                      className="cropbutton"
                    >
                      Crop Image 1
                    </button>
                    <button
                      variant="contained"
                      onClick={getCropremove}
                      className="cropbutton"
                    >
                      Remove Crop 1
                    </button>
                  </div>
                </div>
              )}
            </Grid>
            <Grid md={4}>
              {src2 && (
                <div className="col-3 cropperclass">
                  {/* <ReactCrop
                          src={src2}
                          onImageLoaded={setImage2}
                          crop={crop2}
                          onChange={setCrop2}
                        /> */}
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={2 / 1}
                    aspectRatio={2 / 1}
                    //preview=".img-preview"
                    src={src2}
                    ref={cropperRef2}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                  />
                  <div className="Zoombtn">
                    <button
                      variant="contained"
                      onClick={getCropData2}
                      className="cropbutton"
                    >
                      Crop Image 2
                    </button>
                    <button
                      variant="contained"
                      onClick={getCropremove2}
                      className="cropbutton"
                    >
                      Remove Crop 2
                    </button>
                  </div>
                </div>
              )}
            </Grid>
            <Grid md={4}>
              {src3 && (
                <div className="col-3 cropperclass">
                  {/* <ReactCrop
                          src={src3}
                          onImageLoaded={setImage3}
                          crop={crop3}
                          onChange={setCrop3}
                        /> */}
                  <Cropper
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={2 / 1}
                    aspectRatio={2 / 1}
                    //preview=".img-preview"
                    src={src3}
                    ref={cropperRef3}
                    viewMode={1}
                    guides={true}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    checkOrientation={false}
                  />
                  <div className="Zoombtn">
                    <button
                      variant="contained"
                      onClick={getCropData3}
                      className="cropbutton"
                    >
                      Crop Image 3
                    </button>
                    <button
                      variant="contained"
                      onClick={getCropremove3}
                      className="cropbutton"
                    >
                      Remove Crop 3
                    </button>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          {
            <div className="flex  editimage">
              {forumData?.media[0]?.link && src === null && (
                <img
                  src={`${BASE_URL_IMG}${forumData?.media[0]?.link}`}
                  alt="Post Image"
                  className="w-80 "
                />
              )}
              {forumData?.media[1]?.link && src2 === null && (
                <img
                  src={`${BASE_URL_IMG}${forumData?.media[1]?.link}`}
                  alt="Post Image"
                  className="w-80 "
                />
              )}
              {forumData?.media[2]?.link && src3 === null && (
                <img
                  src={`${BASE_URL_IMG}${forumData?.media[2]?.link}`}
                  alt="Post Image"
                  className="w-80 "
                />
              )}
            </div>
          }
          {/* {
    src2 === null ? 
    <div className='flex justify-center editimage'>
    <img
    src={`${BASE_URL_IMG}${forumData?.media[1]?.link}`}
    alt="Post Image"
    className="w-80 "
  />
  </div> : null
  } */}
          {/* {
    src3 === null ? 
    <div className='flex justify-center editimage'>
    <img
    src={`${BASE_URL_IMG}${forumData?.media[2]?.link}`}
    alt="Post Image"
    className="w-80 "
  />
  </div> : null
  } */}
        </div>
        <div className="flex justify-center mt-10">
          <button
            onClick={handlePostSubmit}
            className="button-1 mx-auto"
            disabled={loader ? true : false}
          >
            {t("submit")}
          </button>
        </div>
      </div>
    </>
  );
};

export default EditGallery;
