import { useContext, useEffect, useMemo, useState } from 'react';
import AntSwitch from '../Common/AntSwitch'
import { Button, Grid, TextField } from '@material-ui/core';
import { ModalContext } from '../../Context';
import UserEditForm from '../ModalTemplate/UserEditForm';
import { BASE_URL_IMG, deleteResourcesPerticularData, getResourcesCategory, InviteNotification, ResourceStatusApi, userDeactivate } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useNavigate, useMatch } from "react-router-dom";
import MaintenanceModeConfirmation from '../ModalTemplate/MaintenanceModeConfirmation';
import modalTextsFile from '../ModalTemplate/modalTextsFile';
import { useTranslation } from 'react-i18next';
import DummyDpImg from "../../Assets/images/DummyDp.png"
import ResourcesTable from './ResourcesTable';
import EditResourcesForm from '../ModalTemplate/EditResourceForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RemoveConfirmation from '../ModalTemplate/RemoveConfirmation';
import { getCountriesData } from "../Master/getRequests";
// import VideoPlayer from "./Components/Common/VideoPlayer"
import VideoPlayer from "../Common/VideoPlayer"
import { Pagination } from '@material-ui/lab';

const AllResources = ({ data, getTablesData, adminData, totalCount }) => {
  const [searchValue, setSearchValue] = useState("");
  const [CategoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState([]);
  const [showFilteredData, setShowFilteredData] = useState([]);
  const modalContext = useContext(ModalContext);
  const { closeModal, handleModalData } = modalContext;
  const [selectfilter, setFilter] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  // let { path } = useMatch();
  const [currentPage, setCurrentPage] = useState(page);
  function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
  const getCategoryData = async () => {
    try {
      const result = await getResourcesCategory();
      setCategoryData(result?.data)
    }
    catch (error) {
      console.log(error);
      toast.warning("Something went wrong!")
    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);

  const onInputCategoryChange = (event, newInputValue, name) => {
    setCategory({
      ...category,
      [name]: newInputValue
    })
  }
  // console.log(totalCount,"totalCount")
  // useEffect(() => { 
  //   if (totalCount && totalCount) {
  //     setTotalPageToShow(Math.ceil((totalCount && totalCount) / postsPerPage));
  //   } else if (selectfilter?.Country?.name !== undefined) {
  //     getTablesData({
  //       country: selectfilter?.Country?.name,
  //     });
  //   } else if (selectfilter?.Country?.name === undefined) { 
  //     getTablesData({
  //       page: page,
  //       size: postsPerPage,
  //     });
  //   }
  // }, [page, totalCount, selectfilter?.Country?.name, category?.Tag?.name]);

//   useEffect(()=>{ 
//     if (totalCount && totalCount > 0) {
//         setTotalPageToShow(Math.ceil((totalCount) / postsPerPage));
//     }
// // if(selectfilter?.Country?.name !== undefined  )
// if(category?.Tag?.name !== undefined  || selectfilter?.Country?.name !== undefined  )
// {
//   getTablesData({
       
//         country: selectfilter?.Country?.name,
//         tag:category?.Tag?.name
//     });
// }
// // else if(category?.Tag?.name !== undefined ){
// else if(category?.Tag?.name == undefined  || selectfilter?.Country?.name == undefined ){
//   getTablesData({
       
//         page: page,
//         size: postsPerPage,
//     });
// } else if(selectfilter?.Country?.name !== undefined ){
//   getTablesData({
//         country:selectfilter?.Country?.name
//     });
// } else if(category?.Tag?.name !== undefined  ){
//   getTablesData({
//         tag:category?.Tag?.name
//     });
// } 
// },[page, totalCount,selectfilter?.Country?.name,category?.Tag?.name])


useEffect(() => {
  const trimmedSearchValue = searchValue.trim();
  const countryName = selectfilter?.Country?.name;
  const tagName = category?.category?.name;

  const isFilterApplied = 
      (tagName !== undefined && tagName !== "") ||
      (countryName !== undefined && countryName !== "") ||
      trimmedSearchValue !== "";

  if (isFilterApplied) {
      // Reset to page 1 when any filter or search is applied
      setCurrentPage(1);
  }
}, [selectfilter?.Country?.name, category?.category?.name, searchValue]);

// Effect to handle fetching data whenever the page or filters change
useEffect(() => { 
  const trimmedSearchValue = searchValue.trim();
  const countryName = selectfilter?.Country?.name;
  const tagName = category?.category?.name;

  const payload = {
      page: currentPage,
      size: postsPerPage,
      country: countryName || undefined,
      tag: tagName || undefined,
      search: trimmedSearchValue || undefined,
  };

  getTablesData(payload);

  if (totalCount && totalCount > 0) {
      setTotalPageToShow(Math.ceil(totalCount / postsPerPage));
  }

}, [currentPage, postsPerPage, totalCount, selectfilter?.Country?.name, category?.category?.name, searchValue]);














  useEffect(() => {
    if (category?.category?._id) {
      let _result = data?.filter((item) => {
        return item?.category?.id === category?.category?._id
      })
      setShowFilteredData(_result)
    }
    else {
      setShowFilteredData(data)
    }
  });

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }
  const handlePageChange = (event, value) => { 
    setPage(value);
    setCurrentPage(value);
};
  const history = useNavigate();
  const { t } = useTranslation();

  const handleMaintenance = async (id) => {
    try {
      const _result = await userDeactivate(id);
      if (_result.res.success) {
        toast.success(_result.res.message)
        closeModal();
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const HandleDeleteResource = async (id) => {
    try {
      const result = await deleteResourcesPerticularData(id);
      console.log(result)
      if (result.res.success) {
        toast.success("Resources Deleted Successfully")
        getTablesData();
      }

    } catch (error) {
      toast.error(error.message)
    }
  }

  const HandleRemoveEvent = (id) => {
    const REMOVE_CONFIRMATION = (
      <RemoveConfirmation
        title={t('Remove_Resource')}
        action={HandleDeleteResource}
        data={t('ResourcesRemoveText')}
        id={id}
      />
    );
    handleModalData(REMOVE_CONFIRMATION, "sm");
  };

  // const handleDeactivate = async (props) => {
  //   const id = props.row.original._id;
  //   const USER_DEACTIVATE = <MaintenanceModeConfirmation
  //     title="Change User Status"
  //     data={modalTextsFile.userActivateDeactivate}
  //     action={() => handleMaintenance(id)}
  //   />
  //   handleModalData(USER_DEACTIVATE, 'sm')
  // }

  const handleEditResource = async (data) => {  
    const editResourceForm = <EditResourcesForm
      data={data}
      id={data._id}
      getTablesData={getTablesData}
      title={t('Edit_new_Resource')}
    />
    handleModalData(editResourceForm)
  }

  const showResourceDetail = (item) => { 
    history(`${item._id}`,{
     
      state: {
        data: item,
        title: 'Resource Details'
      }
    })
  }

  const handleActiveDeactive = async (props) => {
    const id = props.row.original._id;
    try {
      const result = await ResourceStatusApi(id);
      if (result.res.success) {
        toast.success(result.res.message)
        getTablesData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const userColumns = useMemo(
    () => [
      {
        Header: `${t('Status')}`,
        accessor: 'status', // accessor is the "key" in the data
        Cell: ({ cell: { value } }) => value ?
          <div className="rounded-full bg-green h-3 w-3"></div>
          :
          <div className="rounded-full bg-orange h-3 w-3"></div>,
      },
      {
        Header: `${t('Media_Image')}`,
        accessor: data => { 
          return (
            <>
              {
                data?.media[0]?.mediaType === "file" ?
                  <img src={`${BASE_URL_IMG}${data?.pdfimg}`} alt="pdfimg" className='w-28' />
                  :
              //     <VideoPlayer
              //     data={data}
              //     // onClickHandler={onClickHandler}
              //     // isReportBtn={isReportBtn}
              //     // isModalView={isModalView}
              // />
                  <img src={`${BASE_URL_IMG}${data?.videoimg}`} alt="videoimg" className='w-28' />
              }
            </>
          )
        }
      },
      {
        Header:  `${t('Title')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.title}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Category')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.category?.name}</span>
            </>
          )
        }
      },
      // {
      //   Header: "Country",
      //   accessor: data => {
      //     return (
      //       <>
      //         <span>{data?.countries[0]?.name}</span>
      //       </>
      //     )
      //   }
      // },
      {
        Header: `${t('Country')}`,
        accessor: data => { 
            return (
                <>
                    {data?.countries?.map(item => { 
                        return (
                            <p>{`${item?.name} ,`}</p>
                        )
                    })}
                </>
            )
        }
    },
      {
        Header: `${t('Views')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.view?.length}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Downloads')}`,
        accessor: data => {
          return (
            <>
              <span>{data?.downloadCount?.length}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Posted_On')}`,
        accessor: data => {
          return (
            <>
              <span>{formatDate(data?.date)}</span>
            </>
          )
        }
      },
      {
        Header: `${t('Edit')}`,
        accessor: adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => handleEditResource(data)}
            >
              <span className="MuiButton-label">{t('Edit')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('View')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => showResourceDetail(data)}
            >
              <span className="MuiButton-label">{t('View')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('Delete')}`,
        accessor:adminData[0]?.AdminType === "Coordination team" ? "" : data => {
          return (
            <button
              className="MuiButtonBase-root MuiButton-root MuiButton-text btn2"
              type="button"
              onClick={() => HandleRemoveEvent(data?._id)}
            >
              <span className="MuiButton-label">{t('Delete')}</span>
            </button>
          )
        }
      },
      {
        Header: `${t('Status')}`,
        accessor: "deactivated",
        Cell:adminData[0]?.AdminType === "Coordination team" ? "" : props => {
          return (
            <Grid item>
              <AntSwitch
                checked={props.row.original.status}
                onChange={() => handleActiveDeactive(props)}
                name="isDirectory"
              />
            </Grid>
          )
        }
      }
    ], [adminData])
    // console.log(showFilteredData, "showFilteredData")
    const onInputCategoryChangeselect = (event, newInputValue, name) => { 
      setFilter({
        ...selectfilter,
        [name]: newInputValue
      })
    }
    const getCountryApiData = async () => {
      try {
        const result = await getCountriesData();
       // console.log(result, "Result627");
        setCountryData(result);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getCountryApiData();
    }, []);
  return (
    <>
      <div className="flex justify-between flex-wrap items-center">
      <Pagination
                    count={totalPageToShow ? totalPageToShow : 10}
                    variant="outlined"
                    shape="rounded"
                    className="flex items-center"
                    onChange={handlePageChange}
                    page={page}
                />
        <div className="bg-white p-2 flex items-center search-wrapper">
          <span>
            <img src="/assets/icons/search.svg" />
          </span>
          <input
            type="text"
            value={searchValue}
            placeholder={t('Search')}
            className="w-96 mx-3"
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <span>
            <img src="/assets/icons/submit-arrow.svg" />
          </span>
        </div>
       
        <div className="flex justify-between flex-wrap items-center">
        <Autocomplete
            options={countryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
             value={selectfilter?.name}
            className="w-56 mr-5"
            onChange={(e, newVal) => onInputCategoryChangeselect(e, newVal, 'Country')}
            renderInput={(params) => <TextField {...params} label={t('Filter_By_Country')} />}
          />
          <Autocomplete
            options={CategoryData?.sort(dynamicSort("name"))}
            getOptionLabel={(option) => option?.name || ""}
            value={category?.name}
            className="w-56"
            onChange={(e, newVal) => onInputCategoryChange(e, newVal, 'category')}
            renderInput={(params) => <TextField {...params} label={t('Filter_By_Category')} />}
          />
        </div>
      </div>
      {
        showFilteredData && <ResourcesTable columns={userColumns} data={showFilteredData.filter((val, index) => {
          if (searchValue === "") { 
            return val
          }
          else if (val.title && val.title.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          else if (val.category?.name && val.category?.name.toLowerCase().includes(searchValue.toLowerCase())) {
            return val
          }
          // else if (val.email && val.email.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.designation && val.designation.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.language && val.language.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if (val.sector && val.sector.toLowerCase().includes(searchValue.toLowerCase())) {
          //   return val
          // }
          // else if ((val.access && val.access ? "yes" : "no").includes(searchValue.toLowerCase())) {
          //   return val
          // }
        })} />
      }
    </>
  )
}

export default AllResources;