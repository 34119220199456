import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useContext, useEffect, useState } from 'react';
import { addCoursePost, addFeedPost, addForumPost, addQuestionPost, editQuestionPost, getAdminDetails, getTags, regionCountryList } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import Loader from "../Common/Loader"
import { Checkbox, FormControlLabel, Radio, RadioGroup ,FormLabel,FormControl,FormGroup,} from '@material-ui/core';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { CountryOPtion, CourseOption, CourseType, Questiontype } from '../Common/Option';
import { useTranslation } from 'react-i18next';

const EditQuestion = ({QuestionType, getFeedData, title,data,courseid }) => {    
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [formData, setFormData] = useState(data ? data : {});
    const [imgFile, setImgFile] = useState('');
    const [imgFile2, setImgFile2] = useState('');
    const [imgFile3, setImgFile3] = useState('');
    const [loader, setLoader] = useState(false);
    const [mediaType, setMediaType] = useState("photo")
    const [tags, setTags] = useState([]);
    const [tagsValue, setTagsValue] = useState([]);
    const [CourseType, setCourseType] = useState(data?.QuestionType);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [article, setArticle] = useState("");
    const [showImgError, setshowImgError] = useState(false);
    const [VideoFile, setVideoFile] = useState("");
    const [videoValidation, setVideoValidation] = useState("");
    const [pdfValidation, setPdfValidation] = useState("");
    const [disablePdf, setDisablePdf] = useState(false);
    const [disableVideo, setDisableVideo] = useState(false);
    const [pdfFile, setPdfFile] = useState("");
    const [serviceList, setServiceList] = useState(data?.Option || [{ service: "" }]);
    const [serviceLists, setServiceLists] = useState(data?.Answer || [{ service: "" }]);
      const [Answer, setAnswer] = useState([]);
      const [flavors, setFlavors] = useState(data?.Answer || []);
    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...serviceList];
      list[index] = value;
      setServiceList(list);
    };
    console.log(serviceList, "serviceList");
    const handleServiceRemove = (index) => {
      const list = [...serviceList];
      list.splice(index, 1);
      setServiceList(list);
    };
    const {t} = useTranslation();
    const handleServiceAdd = () => {
      setServiceList([...serviceList,  "" ]);
    };
    const handleServiceChanges = (e, index) => {
      const { name, value } = e.target;
      const list = [...serviceLists];
      list[index] = value;
      setServiceLists(list);
    };
    console.log(serviceLists, "serviceList");
    const handleServiceRemoves = (index) => {
      const list = [...serviceLists];
      list.splice(index, 1);
      setServiceLists(list);
    };
  
    const handleServiceAdds = () => {
      setServiceLists([...serviceLists,  "" ]);
    };

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
        let convertedData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        setArticle(convertedData)
    }

    const embedVideoCallBack = (link) => {
        link = link.replace("watch?v=", "embed/");
        link = link.replace("/watch/", "/embed/");
        link = link.replace("youtu.be/", "youtube.com/embed/");
        return link
    }

    // const getCountryData = async () => {
    //     try {
    //         const result = await regionCountryList();
    //         setCountries(result?.res?.data?.country)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
    const geTagsApi = async () => {
        try {
            const result = await getTags();
            if (result.res.success) {
                setTags(result.res.data)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAdminDetailsApiData()
        // getCountryData();
        geTagsApi();
    }, [])

    const onInputCountryChange = (event, newInputValue, name) => {
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputTagsChange = (event, newInputValue, name) => {
        setTagsValue({
            ...tagsValue,
            [name]: newInputValue
        })
    }

    const onInputSectorsChange = (event, newInputValue, name) => {
        setCourseType({
          ...CourseType,
          [name]: newInputValue,
        });
      };

    const handleImgFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile2 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile2(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile2("");
                    setshowImgError(true)
                }
            }
        }
    }
    const handleImgFile3 = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setImgFile3(val);
                    setMediaType("photo")
                    setshowImgError(false)

                }
                else {
                    setImgFile3("");
                    setshowImgError(true)
                }
            }
        }
    }

    const context = useContext(ModalContext);
    const { closeModal } = context;

    const onChangeHandler = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handlePdfFile = (val) => { 
        if(val.size <= 50 * 1024 * 1024) {
          setPdfFile(val);
          setMediaType("file");
        } else {
          setPdfFile("");
          setPdfValidation(`${t("Pdf_sizeshouldbelessthan50MB")}`);
        }
       
        if (val.name !== "") {
          setDisableVideo(true);
        }
      };


    const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

    const handleVideoFile = async (val) => {
        setMediaType("video");
        if (val && val.size > 0) {
          console.log(val, "val");
          if (val?.size && val?.size > 0) {
            if (Math.round(val.size / 1048576) > 10) {
              setVideoValidation("Video size should be less than 10 MB");
              setVideoFile("");
            }
            const duration = await getVideoDuration(val);
            let durationSecond = Math.round(duration);
    
            if (durationSecond && durationSecond > 120) {
              setVideoValidation("Video duration should be less than 2 minutes");
              setVideoFile("");
            } else {
              setVideoFile(val);
              setVideoValidation("");
              if (duration && duration > 120) {
                setVideoValidation("Video duration should be less than 2 minutes");
                setVideoFile("");
              } else {
                setVideoFile(val);
                setVideoValidation("");
              }
            }
            console.log(Math.round(val.size / 1048576), "Val");
          }
        }
        if (val?.name !== "") {
          setDisablePdf(true);
        }
      };
    
   
    const handlePostSubmit = async (e) => { 
        setLoader(true);
        e.preventDefault();
        const tagsData = tagsValue?.tags?.map((data) => {
            return data._id
        })
        const countryData = country?.country?.map((data) => {
            return data._id
        })
        let linksdata = serviceList.map((item) => { 
            return item;
          });

          console.log(linksdata, "linksdata");
          let linksdatas = serviceLists.map((item) => {
            return item;
          });
          console.log(linksdatas, "linksdata");

        let postFormData;
        if(CourseType === "Single"){
         postFormData = new FormData();
        postFormData.append("Question", formData.Question ? formData.Question : data?.Question);
        postFormData.append("MultipleAns", Answer.length > 0 ? Answer : data?.Answer[0]);
        postFormData.append("options", linksdata ? linksdata : data?.Option);
        postFormData.append("id", data?._id);
        // postFormData.append("SessionId", QuestionType?.sessionId);
        // postFormData.append("sub_SessionId", QuestionType?._id);
        // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
        postFormData.append("QuestionType", CourseType ? CourseType : data?.QuestionType);
        postFormData.append("hint", formData.hint ? formData.hint : data?.hint);
     
        }else if(CourseType === "Multiple"){
            postFormData = new FormData();
            postFormData.append("Question", formData.Question ? formData.Question : data?.Question);
            // postFormData.append("Answer", formData.Answer ? formData.Answer : data?.Answer);
            postFormData.append("MultipleAns", flavors ? flavors : data?.Answer);
            postFormData.append("options", linksdata ? linksdata : data?.Option);
            postFormData.append("id", data?._id);
            // postFormData.append("SessionId", QuestionType?.sessionId);
            // postFormData.append("sub_SessionId", QuestionType?._id);
            // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
            postFormData.append("QuestionType", CourseType ? CourseType : data?.QuestionType);
            postFormData.append("hint", formData.hint ? formData.hint : data?.hint);
        }else if(CourseType=== "video" ){
            postFormData = new FormData();
        postFormData.append("Question", formData.Question ? formData.Question : data?.Question);
        // postFormData.append("Answer", formData.Answer ? formData.Answer : "");
        // postFormData.append("options", linksdata ? linksdata : "");
        postFormData.append("id", data?._id);
        // postFormData.append("SessionId", QuestionType?.sessionId);
        // postFormData.append("sub_SessionId", QuestionType?._id);
        // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
        // postFormData.append("QuestionType", CourseType ? CourseType : data?.QuestionType);
          
        } else if(CourseType === "pdf"){
            postFormData = new FormData();
            postFormData.append("Question", formData.Question ? formData.Question : data?.Question);
            // postFormData.append("Answer", formData.Answer ? formData.Answer : "");
            // postFormData.append("options", linksdata ? linksdata : "");
            postFormData.append("id", data?._id);
            // postFormData.append("SessionId", QuestionType?.sessionId);
            // postFormData.append("sub_SessionId", QuestionType?._id);
            // postFormData.append("media", VideoFile ? VideoFile : pdfFile ? pdfFile : null);
            // postFormData.append("QuestionType", CourseType ? CourseType : data?.QuestionType);
           
        }
         
        try {
            const result = await editQuestionPost(postFormData);
            if (result.res.success) {
               
                // setTimeout(() => {
                    toast.success(result.res.message);
                    closeModal();
                    setLoader(false);
                    getFeedData(courseid); 
                // }, 1000);
              
               
                console.log(result.res, "This is result response")
            }
        }
        catch (error) {
            setLoader(false);
            console.log(error);
            toast.error(error.message);
        }
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const onAdminChangeHandler = e => {
        e.preventDefault()
        setCountry([]);
        const value = e.target.value
        setCourseType(value);
      
    }

    const onAdminChangeAnswer = (e) => {
        e.preventDefault();
        // setCountry([]);
        const value = e.target.value;
        setAnswer(value);
      };

      const handleFlavorChange = (e) => { 
        const value = e.target.value;
        const checked = e.target.checked;
        console.log(value,checked)
       if(checked){
        setFlavors(prev => [...prev, value])
       }
       else{
        setFlavors(prev => prev.filter((e) => (e !== value)))
      
       }
        
      };
    
    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <form noValidate autoComplete="off" className="w-full" onSubmit={handlePostSubmit}>
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    {   QuestionType?.QuestionType === "Single" ?
                        <RadioGroup aria-label="CourseType" name="CourseType" className="radio-group" defaultValue={CourseType} onChange={e => onAdminChangeHandler(e)}>
                          {/* <FormControlLabel value="video" control={<Radio />} label="video" /> */}
                          <FormControlLabel value="Single" control={<Radio />} label={t('Single')}/>
                          {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" /> */}
                          {/* <FormControlLabel value="Multiple" control={<Radio />} label="Multiple" /> */}
                      </RadioGroup> : null
                    }
                    {   QuestionType?.QuestionType === "Multiple" ?
                        <RadioGroup aria-label="CourseType" name="CourseType" className="radio-group" defaultValue={CourseType} onChange={e => onAdminChangeHandler(e)}>
                          {/* <FormControlLabel value="video" control={<Radio />} label="video" /> */}
                          {/* <FormControlLabel value="Single" control={<Radio />} label="Single" /> */}
                          {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" /> */}
                          <FormControlLabel value="Multiple" control={<Radio />} label={t('Multiple')} />
                      </RadioGroup> : null
                    }
                    {   QuestionType.QuestionType === "video"  &&
                        <RadioGroup aria-label="CourseType" name="CourseType" className="radio-group" defaultValue={CourseType} onChange={e => onAdminChangeHandler(e)}>
                          <FormControlLabel value="video" control={<Radio />} label="video" />
                          {/* <FormControlLabel value="Single" control={<Radio />} label="Single" /> */}
                          {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" /> */}
                          {/* <FormControlLabel value="Multiple" control={<Radio />} label="Multiple" /> */}
                      </RadioGroup>
                    }
                    {   QuestionType.QuestionType ===  "Pdf"  &&
                        <RadioGroup aria-label="CourseType" name="CourseType" className="radio-group" defaultValue={CourseType} onChange={e => onAdminChangeHandler(e)}>
                          <FormControlLabel value="video" control={<Radio />} label="video" />
                          {/* <FormControlLabel value="Single" control={<Radio />} label="Single" /> */}
                          {/* <FormControlLabel value="pdf" control={<Radio />} label="pdf" /> */}
                          {/* <FormControlLabel value="Multiple" control={<Radio />} label="Multiple" /> */}
                      </RadioGroup>
                    }
                    <div className='col-span-2'>
                        <TextareaAutosize
                            name="Question"
                            value={formData.Question || ''}
                            onChange={(e) => onChangeHandler(e)}
                            className="w-full"
                            id="postname"
                            aria-label="Empty"
                            placeholder={t('Question')}
                            minRows={3}
                        />

                    </div>
                    {
                        QuestionType?.QuestionType === "Single" || QuestionType?.QuestionType === "Multiple" ? 
                        <>
                       
                    {/* <div className='col-span-2'> */}
                    {/* <TextField
                         className="w-full"
                       id="Option"
                       label="Option"
                       name="Option"
                       value={formData.Option || ''}
                       onChange={(e) => onChangeHandler(e)}
                       minRows={3}
                      /> */}
                       {serviceList.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                       <TextField
                         label={t('Add_Option')}
                        name="links"
                        type="text"
                        id="otherLink"
                        value={singleService}
                        onChange={(e) => handleServiceChange(e, index)}
                      
                        />
                      {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_More_Option')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('REMOVED')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
{
     CourseType === "Multiple" &&
     <>
     {/* {serviceLists.map((singleService, index) => (
                        <div key={index} className="services">
                          <div className="first-division">
                       <TextField
                         label="Add Multiple Answer"
                        name="links"
                        type="text"
                        id="otherLinks"
                        value={singleService}
                        onChange={(e) => handleServiceChanges(e, index)}
                      
                        />
                      {serviceLists.length - 1 === index &&
                      serviceLists.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdds}
                          className="add-btn"
                        >
                          <span>Add Multiple Answer</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceLists.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemoves(index)}
                        className="remove-btn"
                      >
                        <span>Remove</span>
                      </button>
                    )}
                  </div>
                </div>
              ))} */}
               <FormControl component="fieldset">
        <FormLabel component="legend">{t('Select_Multiple_Answer')}</FormLabel>
        <FormGroup>
          {
            serviceList.length === 1 ? 
            <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          /> :  serviceList.length === 2 ? 
          <>
            <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          </> : serviceList.length === 3 ? 
          <>
              <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                onChange={handleFlavorChange}
                defaultChecked={flavors[0] === "1" ? true : false}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                defaultChecked={flavors[0]   === "3" ? true : flavors[1]   === "3" ? true : flavors[2]   === "3" ? true : false}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          </> : serviceList.length === 4 ? 
          <>
                <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')} 
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                defaultChecked={flavors[0]   === "3" ? true : flavors[1]   === "3" ? true : flavors[2]   === "3" ? true : false}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                defaultChecked={flavors[0]   === "4" ? true : flavors[1]   === "4" ? true : flavors[2]   === "4" ? true : flavors[3]   === "4" ? true : false}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          </> : serviceList.length === 5 ? 
          <>
                  <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                defaultChecked={flavors[0]   === "3" ? true : flavors[1]   === "3" ? true : flavors[2]   === "3" ? true : false}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                defaultChecked={flavors[0]   === "4" ? true : flavors[1]   === "4" ? true : flavors[2]   === "4" ? true : flavors[3]   === "4" ? true : false}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                defaultChecked={flavors[0]   === "5" ? true : flavors[1]   === "5" ? true : flavors[2]   === "5" ? true : flavors[3]   === "5" ? true : flavors[4]   === "5" ? true : false}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          </> : serviceList.length === 6 ? 
          <>
                         <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                defaultChecked={flavors[0]   === "3" ? true : flavors[1]   === "3" ? true : flavors[2]   === "3" ? true : false}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                defaultChecked={flavors[0]   === "4" ? true : flavors[1]   === "4" ? true : flavors[2]   === "4" ? true : flavors[3]   === "4" ? true : false}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                defaultChecked={flavors[0]   === "5" ? true : flavors[1]   === "5" ? true : flavors[2]   === "5" ? true : flavors[3]   === "5" ? true : flavors[4]   === "5" ? true : false}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.F}
                defaultChecked={flavors[0]   === "6" ? true : flavors[1]   === "6" ? true : flavors[2]   === "6" ? true : flavors[3]   === "6" ? true : flavors[4]   === "6" ? true : flavors[5]   === "6" ? true : false}
                onChange={handleFlavorChange}
                value="6"
              />
            }
            label={t('six')}
          />
          </> : serviceList.length === 7 ? 
          <>
                                   <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.A}
                defaultChecked={flavors[0] === "1" ? true : false}
                onChange={handleFlavorChange}
                value="1"
              />
            }
            label={t('one')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.B}
                defaultChecked={flavors[0]   === "2" ? true : flavors[1]   === "2" ? true : false}
                onChange={handleFlavorChange}
                value="2"
              />
            }
            label={t('two')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.C}
                defaultChecked={flavors[0]   === "3" ? true : flavors[1]   === "3" ? true : flavors[2]   === "3" ? true : false}
                onChange={handleFlavorChange}
                value="3"
              />
            }
            label={t('three')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.D}
                defaultChecked={flavors[0]   === "4" ? true : flavors[1]   === "4" ? true : flavors[2]   === "4" ? true : flavors[3]   === "4" ? true : false}
                onChange={handleFlavorChange}
                value="4"
              />
            }
            label={t('four')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.E}
                defaultChecked={flavors[0]   === "5" ? true : flavors[1]   === "5" ? true : flavors[2]   === "5" ? true : flavors[3]   === "5" ? true : flavors[4]   === "5" ? true : false}
                onChange={handleFlavorChange}
                value="5"
              />
            }
            label={t('five')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.F}
                defaultChecked={flavors[0]   === "6" ? true : flavors[1]   === "6" ? true : flavors[2]   === "6" ? true : flavors[3]   === "6" ? true : flavors[4]   === "6" ? true : flavors[5]   === "6" ? true : false}
                onChange={handleFlavorChange}
                value="6"
              />
            }
            label={t('six')}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flavors?.G}
                defaultChecked={flavors[0]   === "7" ? true : flavors[1]   === "7" ? true : flavors[2]   === "7" ? true : flavors[3]   === "7" ? true : flavors[4]   === "7" ? true : flavors[5]   === "7" ? true : flavors[6]   === "7" ? true : false}
                onChange={handleFlavorChange}
                value="7"
              />
            }
            label={t('seven')}
          />
          </> : null
          }
      
        </FormGroup>
        {/* <FormHelperText>
          Ice cream may be harmful, consult your doctor.
        </FormHelperText> */}
      </FormControl>
     </>
}
{
     CourseType === "Single" && 
     <div className=' col-span-2'>
     {/* <TextField
      className="w-full"
    id="Answer"
    label="Answer"
    name="Answer"
    value={formData.Answer || ''}
    onChange={(e) => onChangeHandler(e)}
    minRows={3}
   /> */}
    <FormLabel component="legend">{t('Select_Answer')}</FormLabel>
    <RadioGroup
              aria-label="Answer"
              name="Answer"
              className="radio-group"
              defaultValue={data?.Answer[0]}
              onChange={(e) => onAdminChangeAnswer(e)}
            >
              {
                serviceList.length === 1 ?
                <FormControlLabel value="1" control={<Radio />} label={t('one')}/> :
                serviceList.length === 2 ? 
                <>
                 <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                 <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                </> : serviceList.length === 3 ? 
                <>
              <FormControlLabel value="1" control={<Radio />} label={t('one')} />
              <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
              <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                </> : serviceList .length === 4 ? 
                <>
              <FormControlLabel value="1" control={<Radio />} label={t('one')}  />
              <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
              <FormControlLabel value="3" control={<Radio />} label={t('three')} />
              <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                </> : serviceList .length === 5 ? 
                  <>
                  <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                  <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                  <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                  <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                  <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                    </> : serviceList .length === 6 ? 
                      <>
                      <FormControlLabel value="1" control={<Radio />} label={t('one')} />
                      <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                      <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                      <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                      <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                      <FormControlLabel value="6" control={<Radio />}label={t('six')}/>
                        </> : serviceList .length === 7 ? 
                      <>
                      <FormControlLabel value="1" control={<Radio />} label={t('one')}/>
                      <FormControlLabel value="2" control={<Radio />} label={t('two')}/>
                      <FormControlLabel value="3" control={<Radio />} label={t('three')} />
                      <FormControlLabel value="4" control={<Radio />}label={t('four')}/>
                      <FormControlLabel value="5" control={<Radio />}label={t('five')}/>
                      <FormControlLabel value="6" control={<Radio />}label={t('six')}/>
                      <FormControlLabel value="7" control={<Radio />}label={t('seven')}/>
                        </> : null
              }
           
            </RadioGroup>
   
 </div>
}
<div className=' col-span-2'>
     <TextField
      className="w-full"
      id="hint"
      label="hint"
      name="hint"
      value={formData.hint || ""}
    onChange={(e) => onChangeHandler(e)}
    minRows={3}
    required
   />
   
 </div>
                    {/* </div> */}
                        </> : null
                    }
                    
                  
                    {/* <Autocomplete
                       id="sectors"
                       options={Questiontype?.sort(dynamicSort("name"))}
                       getOptionLabel={(option) => option?.value || ""}
                       sx={{ width: 300 }}
                       value={CourseType?.value}
                       onChange={(e, newVal) => onInputSectorsChange(e, newVal, "CourseType")}
                       renderInput={(params) => <TextField {...params} label="CourseType" />}
                     /> */}
                     
                   
                    {/* <Autocomplete
                        id="combo-box-demo22"
                        multiple
                        options={countries?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={country?.name}
                        onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                        renderInput={(params) => <TextField {...params} label="Country" />}
                    />
                    <Autocomplete
                        id="combo-box-demo2"
                        multiple
                        options={tags?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name}
                        sx={{ width: 300 }}
                        value={tagsValue?.name}
                        onChange={(e, newVal) => onInputTagsChange(e, newVal, 'tags')}
                        renderInput={(params) => <TextField {...params} label="Tags" />}
                    /> */}
                     
                </div>
              
                <div className="mt-12 w-full">
          <Grid container alignItems="center" spacing={1}>
            {
                  QuestionType.QuestionType === "video" || QuestionType.QuestionType ===  "Pdf" ?
           
            <Grid item md={2}>
              <span className="text-paraText">Add Media</span>
            </Grid>
            : null
             }
            {
                  QuestionType.QuestionType === "video" ?
                  <Grid item md={4}>
                  <div className="relative w-auto overflow-hidden">
                    <TextField
                      id="profilephoto"
                      label="Add a Video"
                      value={VideoFile.name || ""}
                      className="w-full text-xs"
                    />
                    <input
                      disabled={disableVideo}
                      type="file"
                      accept="video/*"
                      className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                      name="video-file"
                      onChange={(e) => handleVideoFile(e.target.files[0])}
                    />
                    <div className="floating-box">+</div>
                  </div>
                  {videoValidation && videoValidation && (
                    <p className="text-red-600">{videoValidation}</p>
                  )}
                  <div className="media-box mt-2">
                    <img src="../../assets/video.svg" alt="" />
                    Video
                  </div>
                  <div className="note">
                    <span>Video size should be less than 10 MB</span>
                  </div>
                </Grid> : null
            }
           
            <Grid item md={2} className="flex items-center justify-center">
              {/* <span className="text-muteText">or</span> */}
            </Grid>
            {
                QuestionType.QuestionType === "pdf" ?
                <Grid item md={4}>
                <div className="relative w-auto overflow-hidden">
                  <TextField
                    id="profilephoto"
                    label="Add a PDF File"
                    value={pdfFile.name || ""}
                    className="w-full text-xs"
                  />
  
                  <input
                    disabled={disablePdf}
                    type="file"
                    accept="application/pdf, application/vnd.ms-excel"
                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                    name="pdf-file"
                    onChange={(e) => handlePdfFile(e.target.files[0])}
                  />
                  <div className="floating-box">+</div>
                  {pdfValidation && pdfValidation && (
                  <p className="text-red-600">{pdfValidation}</p>
                )}
                </div>
                <div className="media-box mt-2">
                  <img src="../../assets/photos.svg" alt="" />
                  Files
                </div>
                <div className="note">
                  <span>Pdf size should be less than 50 MB</span>
                </div>
              </Grid>: null

            }
           
            
          </Grid>
        </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" disabled={loader ? true : false}>
                       submit
                    </button>
                </div>
            </form>
        </>
    )
}

export default EditQuestion