import { useContext, useEffect, useState,useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Loader from "../Common/Loader"
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel, Radio, RadioGroup, TextareaAutosize } from '@material-ui/core';
import 'date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Autocomplete } from '@material-ui/lab';
import { addEvent, editEvent, getAdminDetails, getCountries, getHubs, getMinihubs, getSectorList,getSectorLists, getSegments,BASE_URL_IMG } from '../../Utils/services';
import { toast } from 'react-toastify';
import { ModalContext } from '../../Context';
import timezoneList from "./timezone.json"
import { useTranslation } from 'react-i18next';
import "cropperjs/dist/cropper.css";
import {Cropper} from 'react-cropper';
const EditEventForm = ({
    title,
    buttonText,
    getEventApiData,
    eventDetails,
    eventId,
    eventIds
}) => {     
    const [loader, setLoader] = useState(false);
    const [allFieldData, setAllFieldData] = useState({
        name: eventDetails && eventDetails.name,
        postTo: eventDetails?.postTo || '',
        repeat: '',
        description: eventDetails && eventDetails.description,
        organizer: eventDetails.organiser,
        address: eventDetails.location
    })
    const [links, setLinks] = useState(eventDetails && eventDetails.links);
    const [startTime, setStartTime] = useState(new Date(eventDetails.startTime));
    const [endTime, setEndTime] = useState(new Date(eventDetails.endTime));
    const [imgFile, setImgFile] = useState("");
    const [start, setStart] = useState(new Date(eventDetails.start));
    const [end, setEnd] = useState(new Date(eventDetails.end));
    const [isLinkSplit, setIsLinkSplit] = useState(false)
    const [result, setResult] = useState();
    const [src, setSrc] = useState(null);
    const [mediaType, setMediaType] = useState("photo");
    const [sendImageFile, setSendImageFile] = useState('');
    const [imgFilecrop, setImgFilecrop] = useState('');
    const [showImgError, setshowImgError] = useState(false);
    const [agoradata, setagoradata] = useState(false);
    const [allTimezone, setallTimezone] = useState({
        timezone: { id: 1, text: eventDetails.timezone },
    })
    const [isInviteOnly, setIsInviteOnly] = useState(eventDetails?.InviteOnly);
    const [isGetHelp, setisGetHelp] = useState(eventDetails?.isGethelp);
    const [displayOnHomepage, setdisplayOnHomepage] = useState(eventDetails?.displayOnHomepage);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState();
    const [segmentData, setSegmentData] = useState([]);
    const [segment, setSegment] = useState([]);
    const [sector, setSector] = useState([]);
    const [sectorData, setSectorData] = useState([]);
    const [segs, setsegs] = useState();
    const [serviceList, setServiceList] = useState(eventDetails?.otherlink || [{ service: "" }]);
    const { t } = useTranslation();
    const handleServiceChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...serviceList];
      list[index] = value;
      console.log(list,"othersdata")
      setServiceList(list);
    };
    console.log(serviceList, "serviceList");
    const handleServiceRemove = (index) => {
      const list = [...serviceList];
      list.splice(index, 1);
      setServiceList(list);
    };
  
    const handleServiceAdd = () => {
      setServiceList([...serviceList,  "" ]);
    };

    const context = useContext(ModalContext);
    const { closeModal } = context;
  
    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const onInputSectorChange = (event, newInputValue, name) => {
        setSector({
            ...sector,
            [name]: newInputValue
        })
    }
    console.log(sector, "Sector")
    const handleImgFile = (val) => {
      if (!!val) {
          let img = new Image();
          img.src = window.URL.createObjectURL(val);

          img.onload = () => {
              if (img.width === 400 && img.height === 200) {
                  setImgFile(val);
                  setMediaType("photo")
                  setshowImgError(false)
                  setImgFilecrop("")
                  setSrc(null)
              }
              else {
                  setImgFile("");
                  setshowImgError(true)
                  setMediaType("photo")
                  setImgFilecrop(val)
                  setSrc(URL.createObjectURL(val))
              }
          }
      }
  }
    // const getCountryData = async () => {
    //     try {
    //         const result = await getCountries();
    //         setCountries(result?.res?.countries)
    //     }
    //     catch (error) {
    //         console.log(error);
    //         toast.warning("Something went wrong!")
    //     }
    // }
    const adminId = localStorage.getItem('adminId');
     
    const getAdminDetailsApiData = async () => {
        try {
            const result = await getAdminDetails({
                id: adminId,
                "type": "admin"
            })
            setCountries(result.data.country)
        } catch (error) {
            console.log(error)
        }
    }
   
    const getSegmentApiData = async () => {
        if(segs){
        try {
          const result = await getSegments({
           "id": segs
          });
        setSegmentData(result?.res?.data);
        } catch (error) {
          console.log(error);
          toast.warning("Something went wrong! test");
        }
    }
      };

    const getSectorListData = async () => {
        try {
            const result = await getSectorLists();
            
            setSectorData(result.res.data)
        } catch (error) {
            toast.error(error.message)
        }
    }
    useEffect(() => {
        getAdminDetailsApiData();
    }, [country]);

    useEffect(() => {
        getSectorListData()
    }, [])

    useEffect(() => {
        getSegmentApiData()
        // let _segmentData = countries?.filter((data) => {
        //     return data?.country_code === country?.country?.country_code
        // })
        // setSegmentData(_segmentData && _segmentData[0]?.segments)
    }, [country])


    const onInputCountryChange = (event, newInputValue, name) => {  
        let newInputValues = newInputValue?.map((item) => {
            return item._id
          });
          setsegs(newInputValues)
        setCountry({
            ...country,
            [name]: newInputValue
        })
    }

    const onInputSegmentChange = (event, newInputValue, name) => {
        setSegment({
            ...segment,
            [name]: newInputValue
        })
    }

    const onInputTimezoneChange = (event, newInputValue, name) => {
        const _name = event.target.name
        const _value = event.target.value

        if (name == 'timezone') {
            setallTimezone({
                ...allTimezone,
                [name]: newInputValue
            })
        }
        else {
            setallTimezone({
                ...allTimezone,
                [_name]: _value
            })
        }
    }

    const handleChange = (event, newInputValue, name) => {
        const _name = event.target.name;
        const _value = event.target.value;
        if (name == "repeat") {
            setAllFieldData({
                ...allFieldData,
                [name]: newInputValue
            })
        }
        else {
            setAllFieldData({
                ...allFieldData,
                [_name]: _value
            })
        }
    }
    const handleLinkChange = (e) => {
        setLinks(e.target.value)
        setIsLinkSplit(true)
    }

    const handlePostSubmit = async (e) => { 
      setLoader(true);
     // setAgoraLinks(e.target.value)
     
     setagoradata(e.target.checked)
      e.preventDefault();
      if(agoradata === false){
      try {
        function generateRandomCode() {
          // Generate a random number between 100000 and 999999 (inclusive)
          const randomCode = Math.floor(100000 + Math.random() * 900000);
        
          return randomCode;
        }
        const sixDigitCode = generateRandomCode();
          // const result = await GetyAgoralink({
          //   token_key : agoratoken?.token,  
          //   channel_id : agoratoken?.channelName,
              
          // });
          // if (result.res.success) { 
            const currentOrigin = window.location.origin;
            const linkWithCode = `${currentOrigin}/${sixDigitCode}`;
            setLinks(linkWithCode)
              setLoader(false);
          // }
      }
    
      catch (error) {
          setLoader(false);
          console.log(error);
          toast.error(error.message);
      }
    } else {
      setLinks("")
      setLoader(false);
    }
  }

    const handleSubmit = async (e) => {  
      setLoader(true)
        e.preventDefault();
       
        const nowutc = new Date(startTime); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
        const Starttime = new Date(
          nowutc.getTime() - nowutc.getTimezoneOffset() * 60000
        ).toISOString();
        const nowsutc = new Date(endTime); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
        const Endtime = new Date(
          nowsutc.getTime() - nowsutc.getTimezoneOffset() * 60000
        ).toISOString();
        console.log(Starttime, "Starttime");
        console.log(Endtime, "Endtime");
        const year = start.getFullYear();
        const month = start.getMonth() + 1;
        try {
            
            let linksdata = serviceList.map((item) => { 
                return item;
              });

            let segmentDatas = eventDetails && eventDetails?.segments.map((item) => {
                return item?._id;
              });
            let segmentData = segment?.segment?.map((item) => {
                return item?._id;
              });
            let sectorDatas = eventDetails && eventDetails?.sector.map((item) => {
                return item?.name;
              });
            let sectorData = sector?.sector?.map((item) => {
                return item?.name;
              });
              let countryData = country?.country?.map((item) => { 
                return item?.name;
                
              });
              let countryDatas = eventDetails && eventDetails.countries.map((item) => { 
                return item?.name;
                
              });
            const _NewLinks = links?.length > 0 && isLinkSplit && links?.split(",");
            const postFormData = new FormData();
            postFormData.append("id", eventId);
            postFormData.append("eventId", eventIds);
            postFormData.append("name", allFieldData.name);
            postFormData.append("organiser", allFieldData.organizer);
            postFormData.append("description", allFieldData.description);
            postFormData.append("postTo", allFieldData.postTo);
            postFormData.append("startTime", startTime);
            postFormData.append("endTime", endTime);
            postFormData.append("Mobile_startTime", Starttime);
            postFormData.append("Mobile_endTime", Endtime);
            postFormData.append("start", start);
            postFormData.append("end", end);
            postFormData.append("timezone", allTimezone.timezone.text);
            postFormData.append("location", allFieldData.address);
            postFormData.append("countrys", countryData ? countryData : countryDatas);
            postFormData.append("InviteOnly", isInviteOnly);
            postFormData.append("isGethelp", isGetHelp);
            postFormData.append("displayOnHomepage", displayOnHomepage);
            postFormData.append("segment", isInviteOnly && segmentData ? segmentData : segmentDatas,);
            postFormData.append(
              "sector", sectorData ? sectorData : sectorDatas
            );
          //   postFormData.append("target", countrydata?.Target.value);
         //   postFormData.append("target", countryNotification);
            postFormData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);

            const postOnlineData = new FormData();
            postOnlineData.append("id", eventId);
            postOnlineData.append("eventId", eventIds);
      postOnlineData.append("name", allFieldData.name);
      postOnlineData.append("organiser", allFieldData.organizer);
      postOnlineData.append("description", allFieldData.description);
      postOnlineData.append("postTo", allFieldData.postTo);
      postOnlineData.append("startTime", startTime);
      postOnlineData.append("endTime", endTime);
      postOnlineData.append("Mobile_startTime", Starttime);
      postOnlineData.append("Mobile_endTime", Endtime);
      postOnlineData.append("start", start);
      postOnlineData.append("end", end);
      postOnlineData.append("timezone", allTimezone.timezone.text);
      postOnlineData.append("links", _NewLinks.length > 0 ? _NewLinks : links);
      //   postOnlineData.append("links", linksdata );
      postOnlineData.append("countrys", countryData ? countryData : countryDatas);
      postOnlineData.append("InviteOnly", isInviteOnly);
      postOnlineData.append("isGethelp", isGetHelp);
      postOnlineData.append("displayOnHomepage", displayOnHomepage);
      postOnlineData.append("segment", isInviteOnly ? segmentData : "");
      postOnlineData.append(
        "sector",
        sectorData ? sectorData : sectorDatas
      );
    //   postOnlineData.append("target", countrydata?.Target.value);
     // postOnlineData.append("target", countryNotification);
      postOnlineData.append("media", sendImageFile && sendImageFile ? sendImageFile : imgFile);
      postOnlineData.append("otherlinks", linksdata && linksdata);
      const postHybridData = new FormData();
      postHybridData.append("id", eventId);
      postHybridData.append("eventId", eventIds);
      postHybridData.append("name", allFieldData.name);
      postHybridData.append("organiser", allFieldData.organizer);
      postHybridData.append("description", allFieldData.description);
      postHybridData.append("postTo", allFieldData.postTo);
      postHybridData.append("startTime", startTime);
      postHybridData.append("endTime", endTime);
      postHybridData.append("Mobile_startTime", Starttime);
      postHybridData.append("Mobile_endTime", Endtime);
      postHybridData.append("start", start);
      postHybridData.append("end", end);
      postHybridData.append("timezone", allTimezone.timezone.text);
      postHybridData.append("links", _NewLinks.length > 0 ? _NewLinks : links);
      //   postOnlineData.append("links", linksdata );
      postHybridData.append("countrys", countryData ? countryData : countryDatas);
      postHybridData.append("InviteOnly", isInviteOnly);
      postHybridData.append("isGethelp", isGetHelp);
      postHybridData.append("displayOnHomepage", displayOnHomepage);
      postHybridData.append("segment", isInviteOnly && segmentData ? segmentData : segmentDatas);
      postHybridData.append(
        "sector",
        sectorData ? sectorData : sectorDatas,
      );
    //   postOnlineData.append("target", countrydata?.Target.value);
     // postHybridData.append("target", countryNotification);
      postHybridData.append("media", sendImageFile && sendImageFile ? sendImageFile :imgFile);
      postHybridData.append("otherlinks", linksdata?.length > 0 ? linksdata : eventDetails?.otherlink);
      postHybridData.append("location", allFieldData.address);
            const results = await editEvent(allFieldData.postTo === "offline" ? postFormData
                // {
                  
                //     "id": eventId,
                //     "name": allFieldData.name,
                //     "organiser": allFieldData.organizer,
                //     "description": allFieldData.description,
                //     "postTo": allFieldData.postTo,
                //     "startTime": startTime,
                //     "endTime": endTime,
                //     "Mobile_startTime": Starttime,
                //     "Mobile_endTime": Endtime,
                //     "start": start,
                //     "end": end,
                //     "location": allFieldData.address,
                //     "timezone": allTimezone.timezone.text,
                //     "countrys": countryData ? countryData : countryDatas,
                //     "InviteOnly": isInviteOnly,
                //     "segment": isInviteOnly && segmentData ? segmentData : segmentDatas,
                //     "sector" : sectorData ? sectorData : sectorDatas,
                //     "isGethelp" : isGetHelp ,
                //     "displayOnHomepage" : displayOnHomepage ,
                //     "image" : sendImageFile && sendImageFile ? sendImageFile : imgFile 
                // } 
                : allFieldData.postTo === "hybrid" ? postHybridData
                // {
                //     "id": eventId,
                //     "name": allFieldData.name,
                //     "organiser": allFieldData.organizer,
                //     "description": allFieldData.description,
                //     "postTo": allFieldData.postTo,
                //     "Mobile_startTime": Starttime,
                //     "Mobile_endTime": Endtime,
                //     "startTime": startTime,
                //     "endTime": endTime,
                //     "start": start,
                //     "end": end,
                //     "links": _NewLinks?.length > 0 ? _NewLinks : links,
                //     "otherlinks": linksdata?.length > 0 ? linksdata : eventDetails?.otherlink,
                //     "timezone": allTimezone.timezone.text,
                //     "countrys": countryData ? countryData : countryDatas,
                //     "InviteOnly": isInviteOnly,
                //     "segment": isInviteOnly && segmentData ? segmentData : segmentDatas,
                //     "sector" : sectorData ? sectorData : sectorDatas,
                //     "isGethelp" : isGetHelp ,
                //     "displayOnHomepage" : displayOnHomepage ,
                //     "location": allFieldData.address,
                //     "image" : imgFile && imgFile ? imgFile : null 
                // } 
                : postOnlineData
                // {
                //     "id": eventId,
                //     "name": allFieldData.name,
                //     "organiser": allFieldData.organizer,
                //     "description": allFieldData.description,
                //     "postTo": allFieldData.postTo,
                //     "startTime": startTime,
                //     "endTime": endTime,
                //     "Mobile_startTime": Starttime,
                //     "Mobile_endTime": Endtime,
                //     "start": start,
                //     "end": end,
                //     "links": _NewLinks?.length > 0 ? _NewLinks : links,
                //     "otherlinks": linksdata?.length > 0 ? linksdata : eventDetails?.otherlink,
                //     "timezone": allTimezone.timezone.text,
                //     "countrys": countryData ? countryData : countryDatas,
                //     "InviteOnly": isInviteOnly,
                //     "segment": isInviteOnly && segmentData ? segmentData : segmentDatas,
                //     "sector" : sectorData ? sectorData : sectorDatas,
                //     "isGethelp" : isGetHelp ,
                //     "displayOnHomepage" : displayOnHomepage ,
                //     "image" : imgFile && imgFile ? imgFile : null
                    
                // }
            );
            if (results.res.success) {
                // toast.success(results.res.message);
                toast.success("Event edited successfully");
                closeModal();
                getEventApiData(year, month);
                setLoader(false);
            }
        }
        catch (error) {
            toast.error(error.messages);
            setLoader(false);

        }
    }
    const cropperRef6 = useRef(null);
    const getCropData = () => {
        if (typeof cropperRef6.current?.cropper !== "undefined") {
          setResult(cropperRef6.current?.cropper.getCroppedCanvas().toDataURL())
                fetch(cropperRef6.current?.cropper.getCroppedCanvas().toDataURL())
            .then(res => res.blob())  
            .then(blob => {
              const file = new File([blob], cropperRef6.current?.cropper.getCroppedCanvas().toDataURL(), { type: "image/jpeg" })
              setSendImageFile(file)
            })
        }
      };
 console.log(startTime,"startTime")
 const englishSectors = eventDetails.sector.filter(sector => sector.language === "English");

    return (
        <>
            <Loader loader={loader} />
            <div className="flex items-center justify-center">
                <h3 className="title"> {title} </h3>
            </div>
            <div noValidate autoComplete="off" className="w-full">
            <div className="flex items-center mt-5 customeCheckbox InviteOnlyCheckbox">
                <div className="flex items-center mt-5 customeCheckbox InviteOnlyCheckbox">
                    <FormControlLabel control={<Checkbox name="All" checked={isInviteOnly} onChange={(e) => setIsInviteOnly(e.target.checked)}  color="primary" />} label={t('Invite_Only')} labelPlacement="end" />
                </div>
                <div className="flex items-center mt-5 customeCheckbox InviteOnlyCheckbox">
                    <FormControlLabel control={<Checkbox name="All" checked={isGetHelp}   onChange={(e) => setisGetHelp(e.target.checked)}  color="primary" />} label={t('Get_Help')} labelPlacement="end" />
                </div>
                <div className="flex items-center mt-5 customeCheckbox InviteOnlyCheckbox">
                    <FormControlLabel control={<Checkbox name="All" checked={displayOnHomepage}   onChange={(e) => setdisplayOnHomepage(e.target.checked)}  color="primary" />} label={t('displayOnHomepage')} labelPlacement="end" />
                </div>
                </div>
                {
                    title === "Add Event" || title === "Edit Event" &&
                    <div className="mt-4 w-6/12 flex flex-row items-center" >
                        <h1 className="text-paraText">{t('Post_To')}</h1>
                        <RadioGroup
                            aria-label="event"
                            defaultValue={eventDetails?.postTo}
                            name="postTo"
                            className="radio-group"
                            value={allFieldData.postTo}
                            onChange={handleChange}
                        >
                            <span className="ml-8">
                                <FormControlLabel  value="offline" className="text-muteText radio-label" control={<Radio color="default" />} label={t('Offline_Event')} />
                                <FormControlLabel  value="digital" className="text-muteText radio-label" control={<Radio color="default" />} label={t('Digital_Event')} />
                            </span>
                        </RadioGroup>
                    </div>
                }
                <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                    <TextField
                        id="eventName"
                        label={t('Event_Name')}
                        name="name"
                        value={allFieldData.name}
                        onChange={handleChange}
                    />
                    <TextField
                        id="organiser"
                        label={t('Organizer_Name')}
                        name="organizer"
                        value={allFieldData.organizer}
                        onChange={handleChange}
                    />
                    <Autocomplete
                        name="timezone"
                        options={timezoneList}
                        getOptionLabel={(option) => option.text}
                        value={allTimezone.timezone}
                        onChange={(e, newVal) => onInputTimezoneChange(e, newVal, 'timezone')}
                        renderInput={(params) => <TextField {...params} id="timezone" label={t('Select_Timezone')} />}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label={t('Start_Time')}
                            name="time"
                            value={startTime}
                            minutesStep={5}
                            ampm={false}
                            onChange={setStartTime}
                        />
                        <TimePicker
                            showTodayButton
                            todayLabel="now"
                            label={t('End_Time')}
                            name="time"
                            value={endTime}
                            minutesStep={5}
                            ampm={false}
                            onChange={setEndTime}
                        />

                        <KeyboardDatePicker
                            label={t('Event_Start_Date')}
                            placeholder={`${start}`}
                            value={start}
                            onChange={date => setStart(date)}
                            inputFormat="yyyy/MM/dd"
                            disablePast={true}
                        />
                        <KeyboardDatePicker
                            label={t('Event_End_Date')}
                            placeholder={`${end}`}
                            value={end}
                            onChange={date => setEnd(date)}
                            inputFormat="yyyy/MM/dd"
                            disablePast={true}
                        />
                        <Autocomplete
                            multiple
                            id="combo-box-demo"
                            options={countries?.sort(dynamicSort("name"))}
                            getOptionLabel={(option) => option?.name || ""}
                            sx={{ width: 300 }}
                            value={country?.name}
                            defaultValue ={eventDetails && eventDetails.countries }
                            onChange={(e, newVal) => onInputCountryChange(e, newVal, 'country')}
                            renderInput={(params) => <TextField {...params} label={t('Country')} />}
                        />
                        {
                           isInviteOnly &&
                            <Autocomplete
                                multiple
                                id="combo-box-demo"
                                options={segmentData?.sort(dynamicSort("name"))}
                                getOptionLabel={(option) => option?.name || ""}
                                sx={{ width: 300 }}
                                value={segment?.name}
                                defaultValue={eventDetails && eventDetails?.segments}
                                onChange={(e, newVal) => onInputSegmentChange(e, newVal, 'segment')}
                                renderInput={(params) => <TextField {...params} label={t('Segment')}  />}
                            />
                        }
                    </MuiPickersUtilsProvider>
                    {
                        eventDetails.postTo === "offline" ?
                            <TextField
                                id="address"
                                label={t('Address')}
                                name="address"
                                value={allFieldData.address}
                                placeholder="Event address"
                                onChange={handleChange}
                            />
                            : eventDetails.postTo === "online" ?
                            <>
                                <FormControlLabel
            control={
              <Checkbox
                name="Agroa"
                checked={agoradata}
               // onChange={(e) => setagoradata(e.target.checked)}
                onChange={(e) => handlePostSubmit(e)}
                defaultChecked
                color="primary"
              />
              
            }
            label={t('Internal Link (100ms)')}
            labelPlacement="end"

            
          />
                            <TextField
                                id="otherLink"
                                label={t('Add_Link')}
                                name="links"
                                value={links}
                                placeholder="Use HTTPS URLS"
                                onChange={handleLinkChange}
                                disabled
                            />
                             {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="first-division">
                    <TextField
                      label={t('Add_Other_Link')}
                      name="links"
                      type="text"
                      id="otherLink"
                      value={singleService}
                    //   defaultValue={singleService}
                      onChange={(e) => handleServiceChange(e, index)}
                      
                    />
                    {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_Other_Link')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('Remove')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
                            </>
                            :
                            <>
                              {/* <TextField
                                id="address"
                                label={t('Address')}
                                name="address"
                                value={allFieldData.address}
                                placeholder="Event address"
                                onChange={handleChange}
                            /> */}
                               <FormControlLabel
            control={
              <Checkbox
                name="Agroa"
                checked={agoradata}
               // onChange={(e) => setagoradata(e.target.checked)}
                onChange={(e) => handlePostSubmit(e)}
                defaultChecked
                color="primary"
              />
              
            }
            label={t('Internal Link (100ms)')}
            labelPlacement="end"

            
          />
                            <TextField
                                id="otherLink"
                                label={t('Add_Link')}
                                name="links"
                                value={links}
                                placeholder="Use HTTPS URLS"
                                onChange={handleLinkChange}
                                disabled
                            />
                             {serviceList.map((singleService, index) => (
                <div key={index} className="services">
                  <div className="first-division">
                    <TextField
                      label={t('Add_Other_Link')}
                      name="links"
                      type="text"
                      id="otherLink"
                      value={singleService}
                    //   defaultValue={singleService}
                      onChange={(e) => handleServiceChange(e, index)}
                      
                    />
                    {serviceList.length - 1 === index &&
                      serviceList.length < 7 && (
                        <button
                          type="button"
                          onClick={handleServiceAdd}
                          className="add-btn"
                        >
                          <span>{t('Add_Other_Link')}</span>
                        </button>
                      )}
                  </div>
                  <div className="second-division">
                    {serviceList.length !== 1 && (
                      <button
                        type="button"
                        onClick={() => handleServiceRemove(index)}
                        className="remove-btn"
                      >
                        <span>{t('Remove')}</span>
                      </button>
                    )}
                  </div>
                </div>
              ))}
                            </>
                    }
                       
                    <Autocomplete
                       multiple
                        id="sector"
                        options={sectorData?.sort(dynamicSort("name"))}
                        getOptionLabel={(option) => option?.name || ""}
                        sx={{ width: 300 }}
                        value={sector?.name}
                        defaultValue ={englishSectors && englishSectors}
                        onChange={(e, newVal) => onInputSectorChange(e, newVal, 'sector')}
                        renderInput={(params) => <TextField {...params} label={t('Sector')} />}
                    />
                    <TextareaAutosize
                        name="description"
                        id="eventDescription"
                        aria-label="Empty"
                        placeholder={t('Enter_Event_Description')}
                        minRows={5}
                        value={allFieldData.description}
                        onChange={handleChange}
                    />
                </div>
                <div className="mt-12 w-12/12">
                    <Grid container alignItems="center" spacing={4}>
                        <Grid item md={1}>
                            <span className="text-paraText">{t('Add_Media')}</span>
                        </Grid>
                        <Grid item md={4}>
                            <div className="relative w-auto overflow-hidden">
                                <TextareaAutosize
                                    id="profilephoto"
                                    label={t('Add_Photo_1')}
                                    value={(imgFile.name || result || "").slice(0,40)}
                                    className="w-full text-xs"
                                    minRows={3} 
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                    name="image-file"
                                    onChange={(e) => handleImgFile(e.target.files[0])}
                                />
                                <div className="floating-box" >+</div>
                            </div>
                            <div className="media-box mt-2" >
                                <img src="../../assets/photos.svg" alt="" />
                                {t('Photo')}
                            </div>
                            {/* <p className='mt-4 text-sidenav'> {t('Image_shouldbe400x400px')}</p> */}
                        </Grid>
                        {src ? "" : <p className='mt-4 text-sidenav'> {t('Note_Imageshouldbe400x200px')}</p>} 
                
                       
                    </Grid>
                    {/* <img
                  // src={`${BASE_URL_IMG}${eventDetails?.image}`}
                  alt="Post Image"
                  className="w-80"
                /> */}
                    <Grid md={5}>
                    {
                      src ?
                      <div className="col-6 cropperclass">
                       
                                    <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={2/1}
          aspectRatio={2 / 1} 
          //preview=".img-preview"
          src={src}
          ref={cropperRef6}
          viewMode={1}
          guides={true}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          checkOrientation={false}
        />
                        <button
                          variant="contained"
                          onClick={getCropData}
                          className='cropbutton'
                        >
                          Crop Image
                        </button>
                      </div> :
                      <div className='flex justify-center editimage'>
  <img
  src={`${BASE_URL_IMG}${eventDetails?.image}`}
  alt="Post Image"
  className="w-80 "
/>
</div>

                    }
                  </Grid>
                </div>
                <div className="flex justify-center mt-10">
                    <button className="button-1 mx-auto" onClick={handleSubmit} disabled={loader ? true : false}>
                        {buttonText}
                    </button>
                </div>
            </div>
        </>
    )
}

export default EditEventForm;